import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { District } from '../models/models';

@Injectable()
export abstract class IDistrictService  {
    abstract getDistricts():Observable<District[]>;

    abstract getDistrict(districtId:string):Observable<District>;

    abstract createDistrict(districtName:string):Observable<any>;

    abstract updateDistrict(districtId: string, updatedName:string):Observable<any>;

    abstract deleteDistrict(districtId):Observable<any>;
}