import { Component, OnInit } from '@angular/core';
import { PerformanceSummary } from 'src/app/models/models';

@Component({
  selector: 'simulation-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css']
})
export class PerformanceComponent implements OnInit {
  performanceSummary: PerformanceSummary;

  constructor() { }

  ngOnInit() {
  }

}
