   <!-- Unit System -->
   <div *ngIf="showUnitSystemChoices" class="grid field col-12">
    <div class="col-12 md:col-2">
        <label>Company Unit System</label>
    </div>
    <div class="col-12 md:col-2">
        <p-dropdown [options]="workbookConfigurationUnitSystemChoices" [(ngModel)]="_unitSystem" (onChange)="unitSystemChanged()" [disabled]="jobId"></p-dropdown>
    </div>
    </div>
    
  <!-- Configurations Table -->
      <p-table class="fixed-table" #dt [columns]="workbookConfigurationCols" [value]="workbookConfigurationMeasurements"
        [loading]="!workbookConfigurationsTableReady" sortField="name">
      <!-- <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
          </colgroup>
      </ng-template> -->
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th *ngFor="let col of columns" [style.width]="col.width" >
                  {{col.header}}
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-configuration let-index="rowIndex">
          <tr [pReorderableRow]="index" >
              <td><input type="text" [style]="{'width':'100%'}"  pInputText placeholder="Enter Name" [(ngModel)]="configuration.measurementDisplayName" 
                    (change)="displayNameChanged(configuration)"
                    [disabled]="configuration.companyDrivenConfiguration || configuration.readOnlyJobConfiiguration"/></td>
              <td>
                  <p-dropdown [options]="workbookWellKnownMeasurementOptions" placeholder="Select Known Measurement"
                    [style]="{'width':'100%'}"
                
                 
                  [(ngModel)]="configuration.wellKnownMeasurementName" appendTo="body" (onChange)="wellKnownMeasurementChanged(configuration)"
                      
                      [disabled]="configuration.companyDrivenConfiguration || configuration.readOnlyJobConfiiguration"></p-dropdown>
              </td>
              <td>
                  <p-dropdown [options]="workbookConfigurationsDataTypes" placeholder="Select Type" [(ngModel)]="configuration.measurementDataType"
                  [style]="{'width':'100%'}"  
                  appendTo="body" (onChange)="measurementTypeChanged(configuration)" 
                      [disabled]="configuration.companyDrivenConfiguration || configuration.readOnlyJobConfiiguration"></p-dropdown>
              </td>
              <td>
                  <p-dropdown [options]="configuration.unitOptions" placeholder="Select Units" 
                  [style]="{'width':'100%'}" 
                  [disabled]="configuration.measurementDataType == 'Text' || configuration.companyDrivenConfiguration || configuration.readOnlyJobConfiiguration"
                      [(ngModel)]="configuration.unitOfMeasurementId" appendTo="body" 
                      (onChange)="unitsChanged(configuration)"></p-dropdown>
              </td>
              <td>
                <p-multiSelect [options]="currentWorkbookConfigurationJobTypes" defaultLabel="Select Job Types" [(ngModel)]="configuration.jobTypes"
                [style]="{'width':'100%'}" 
                appendTo="body"  [disabled]="configuration.companyDrivenConfiguration || configuration.readOnlyJobConfiiguration" 
                    (onChange)="jobTypesChanged(configuration)"></p-multiSelect>
            </td>
              <td>
                  <p-multiSelect [options]="workbookActivities" defaultLabel="Select Activity" [(ngModel)]="configuration.activities"
                  [style]="{'width':'100%'}"  
                  appendTo="body"  [disabled]="configuration.companyDrivenConfiguration || configuration.readOnlyJobConfiiguration" 
                      (onChange)="activitiesChanged(configuration)"></p-multiSelect>
              </td>
              <td>
                  <input type="text" pInputText placeholder="Enter Values" [(ngModel)]="configuration.lookupChoicesAsString"
                  [style]="{'width':'100%'}"   
                  (change)="lookupChoicesChanged(configuration)"
                      [disabled]="configuration.measurementDataType != 'Text' || configuration.companyDrivenConfiguration || configuration.readOnlyJobConfiiguration"
                      pTooltip="For Measuremements whose values are driven from a pre-defined list of 'Look-up Choices'.  Define the 'Look-up Choices' separated by a comma here. (Text Only)" />
              </td>
              <td>
                <input type="number" pInputText placeholder="Enter Limit" [(ngModel)]="configuration.mininumLimit"
                [style]="{'width':'100%'}"    
                (change)="limitsChanged(configuration)"
                    [disabled]="configuration.measurementDataType == 'Text'"
                    pTooltip="For Measuremements where a the value should not be below this MININUM threshold. (Number Measurements Only)" />
            </td>
            <td>
                <input type="number" pInputText placeholder="Enter Limit" [(ngModel)]="configuration.maximumLimit"
                [style]="{'width':'100%'}" 
                (change)="limitsChanged(configuration)"
                    [disabled]="configuration.measurementDataType == 'Text'"
                    pTooltip="For Measuremements where a the value should not be below this MAXIMUM threshold. (Number Measurements Only)" />
            </td>
              <td>
                  <i *ngIf="!configuration.companyDrivenConfiguration && !configuration.readOnlyJobConfiiguration" class="fas fa-sort" style="margin-right:5px;" pReorderableRowHandle></i>
                  <i *ngIf="!configuration.companyDrivenConfiguration && !configuration.readOnlyJobConfiiguration" class="far fa-trash-alt" (click)="confirmDeleteConfiguration(configuration)"></i></td>
          </tr>
      </ng-template>
      <ng-template pTemplate="summary">
          <button pButton type="button" label="Add Configuration" (click)="addConfiguration()" icon="fa fa-plus"
              class="add-button"></button>
      </ng-template>
  </p-table>
    