export class DeviceMeasurementChannelConfiguration {
  measurementName: string;
  measurementDisplayName: string;
  unitOfMeasurementId: string;
  wellKnownMeasurementName: string;
  measurementDataType: string;

  // client side only properties
  unitOptions: any;

  constructor() {}

  public setMeasurementName() {
    if (this.measurementDisplayName) {
        this.measurementName = this.measurementDisplayName.replace(/ |(|)|-|_|&/g, '', );
    }
}
}
