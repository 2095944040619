import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, Message, SelectItem } from 'primeng/api';

import { User, UserRoles, Company, Features } from '../../../models/models';
import { IUserService,
         ICompanyService,
         IFeaturesService,
         IApplicationContextService,
         IOpsViewerMessagesService } from '../../../services/services';
import { Title } from '@angular/platform-browser';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { first } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  public isCompanyAdministrationFeatureEnabled = false;
  public featuresReady = false;

  public cols: any[] = [];
  public colsReady = false;

  public user: User;
  public usersReady = false;
  public users: User[] = [];
  public userRoles: SelectItem[] = [];

  public companyNamesSelection: SelectItem[] = [];

  isUserReady = false;
  everythingReady = false;

  messages: Message[] = [];

  constructor(
    private _userService: IUserService,
    private _userManagerService:IUserManagerService,
    private _companyService: ICompanyService,
    private _featureService: IFeaturesService,
    private _router: Router,
    private _confirmationService: ConfirmationService,
    private _titleService: Title,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService
   ) { }

  ngOnInit() {
    this.setPageTitle();
    this.getUsers();
    this.getFeatures();
    this.getAuthenticatedUser();
  }

  setPageTitle() {
    this._titleService.setTitle('OpsViewer Users');
  }

  getFeatures() {
    this._featureService.isFeatureEnabled(Features.CompanyAdministration).subscribe(
      result => {
        this.isCompanyAdministrationFeatureEnabled = result;
        this.featuresReady = true;
        this.setColumns();
      }
    );
  }

  setColumns() {
    if (this.isCompanyAdministrationFeatureEnabled) {
      this.cols = [
        {field: 'email', header: 'Email', width: '', filterMatchMode: 'contains'},
        {field: 'name', header: 'Name', width: '', filterMatchMode: 'contains'},
        {field: 'companyNames', header: 'Companies', width: '', filterMatchMode: 'contains', alternativeFilterField: 'companyNames'},
        {field: '', header: '', width: '90px'},
      ];
    } else {
      this.cols = [
        {field: 'email', header: 'Email', width: '250px', filterMatchMode: 'contains'},
        {field: 'name', header: 'Name', width: '250px', filterMatchMode: 'contains'},
        {field: 'isEmployee', header: 'Employee?', width: '100px' },
        {field: 'role', header: 'Role', width: '100px', filterMatchMode: 'in'},
        {field: 'companyNames', header: 'Companies', width: '250px', filterMatchMode: 'contains', alternativeFilterField: 'companyNames'},
        {field: 'allCompanies', header: 'Global?', width: '75px' },
        {field: 'active', header: 'Active?', width: '75px' },
        {field: '', header: '', width: '60px'},
      ];
    }

    this.userRoles = [];
    this.userRoles.push({ label: UserRoles.admin, value: UserRoles.admin });
    this.userRoles.push({ label: UserRoles.editor, value: UserRoles.editor });
    this.userRoles.push({ label: UserRoles.reader, value: UserRoles.reader });

    this.colsReady = true;
    this.checkIfEverythingReady();
  }

  login() {
    //this._msalService.login();
  }
  logout() {

    //this._msalService.logout();
  }
  getUsers() {
    this.messages = [];

    this._userService.getUsers().subscribe(
      results => {
        this.users = results;
        this.users.forEach(user => {
          if (!user.name) { user.name = user.firstName + ' ' + user.lastName; }
        });
        this.getCompanies();
      },
      error => {
        this.handleError(error, 'Could not get users');
      }
    );
  }

  getCompanies() {
    this._companyService.getCompanies().subscribe(
      results => {
        this.companyNamesSelection = [];
        results.forEach(result => {
          this.companyNamesSelection.push({ label: result.name, value: result.id });
          if (this.isCompanyAdministrationFeatureEnabled) {
            const users = this.users.filter(u => u.companyRoles && u.companyRoles.findIndex(r => r.companyId === result.id) >= 0);
            if (users) {
              users.map(u => {
                if (!u.companyNames) {
                  u.companyNames = result.name;
                } else {
                  u.companyNames = u.companyNames + ', ' + result.name;
                }
              });
            }
          } else {
            const users = this.users.filter(user => user.companyIds != null && user.companyIds.includes(result.id) );
            users.forEach(user => {
              if (!user.companies) { user.companies = []; }
              const companyToAdd = { label: result.name, value: result.id };
              if (!user.companies.includes(companyToAdd)) { user.companies.push(companyToAdd); }
              if (!user.companyNames) {
                user.companyNames = companyToAdd.label;
              } else {
                user.companyNames = user.companyNames + ', ' + companyToAdd.label;
              }
            });
          }
        });
        this.usersReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        this.handleError(error, 'Could not get companies');
      }
    );
  }

  getAuthenticatedUser() {

    this._userManagerService.getCurrentUser().subscribe(u=>{
      this.user = u;
      this.isUserReady = true;
      this.checkIfEverythingReady();
    });

    // this._msalService.getUserEmail().subscribe(
    //   userEmail => {
    //     if (userEmail) {
    //       this._userService.getUserByEmail(userEmail).subscribe(
    //         userResult => {
    //           this.user = userResult;
    //           this.isUserReady = true;
    //           this.checkIfEverythingReady();
    //         },
    //         error => { this.handleError(error, 'Could not get user'); }
    //       );
    //     }
    //   },
    //   error => { this.handleError(error, 'Could not get user'); }
    // );
  }

  checkIfEverythingReady() {
    this.everythingReady = this.featuresReady && this.colsReady && this.usersReady && this.isUserReady;
  }

  // SuperAdmin checking only once CompanyAdmin Feature is used, otherwise
  // original Admin role has ability to delete
  isSuperAdmin(): boolean {
    if (this.user) {
      if (this.isCompanyAdministrationFeatureEnabled) {
        if (this.user.isGlobalAdmin) { return true; }
      } else if (this.user.role === UserRoles.admin) {return true; }
    }
    return false;
  }

  navigateToAddUser() {
    this._router.navigate(['/user/' + encodeURIComponent('new')]);
  }

  navigateToDetail(userId: string) {
    this._router.navigate(['/user/' + encodeURIComponent(userId)]);
  }

  refresh() {
    this.cols = [];
    this.usersReady = false;
    this.companyNamesSelection = [];
    this.messages = [];
    this.ngOnInit();
  }

  confirmDelete(user: User) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + user.name + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteUser(user.id); }// ,
        // reject: () => { }
      }
    );
  }

  deleteUser(userId: string) {
    this._userService.deleteUser(userId).subscribe(
      result => {
        this.refresh();
        this._messageService.infoMessage('User Deleted', '');
        this._applicationContextService.alertUserDetailsUpdated();
      },
      error => { this.handleError(error, 'Could not Delete User'); }
    );
  }

  handleError(error: any, displayMessage: string) {
    this._messageService.errorMessage(error, displayMessage);
  }

}
