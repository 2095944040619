import { BaseEntity } from './baseEntity';
import { v4 as uuidv4 } from 'uuid';
import { WorkbookMeasurementChannnelConfiguration } from './workbookMeasurementChannelConfiguration';
import { UnitSystemChoice } from './unitSystemChoice';
import { WellKnownMeasurementChoice } from './wellKnownMeasurement';
import { WorkbookJobTypes } from './workbookJobTypes';

export class CompanyWorkbookConfiguration implements BaseEntity {
    id: string;
    companyId: string;
    workbookMeasurementChannelConfigurations: WorkbookMeasurementChannnelConfiguration[];

    constructor(id: string, companyId: string) {
        if (id) {
            this.id = id;
        } else {
            this.id = uuidv4();
        }
        this.companyId = companyId;
        this.workbookMeasurementChannelConfigurations = [];
    }

    public inCategory(item: string, categories: string[]) {
        return categories.indexOf(item) >= 0;
    }

    public setDefaultMeasurements(activityOptions: string[], unitSystem: string, wellKnownMeasurements: WellKnownMeasurementChoice[]) {

        // Comments
        const comments = new WorkbookMeasurementChannnelConfiguration();
        comments.buildMeasurement(
            'Comments',
            'Comments',
            wellKnownMeasurements,
            unitSystem,
            'Text',
            activityOptions,
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout, WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(comments);

        // Depth
        const depthActNames = [ "RIH-Start", "RIH", "Tag", "No Show", "Stall", "Milling", "Washing", "SWP Sent", "Sweep Out of Coil", "Through Plug", "Pickup", "Weight Check", "Rate Change", "Short Trip", "Circulating", "OOH", "POOH", "OOH-End", ];
        const depth = new WorkbookMeasurementChannnelConfiguration();
        depth.buildMeasurement(
            'Depth',
            'Depth',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => depthActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        depth.mininumLimit = 0;
        depth.maximumLimit = 25000;
        this.workbookMeasurementChannelConfigurations.push(depth);

        // Funnel Visc (s)
        const viscActNames = [ "Water Quality Test", "Function BHA", "RIH", "Tag", "No Show", "Washing", "SWP Sent", "Sweep Out of Coil", "SWP on Surface", "Short Trip", "Rate Change", "Circulating", "POOH", ];
        const funnelVisc = new WorkbookMeasurementChannnelConfiguration();
        funnelVisc.buildMeasurement(
            'FunnelViscosity',
            'Funnel Viscosity',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => viscActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        funnelVisc.mininumLimit = 26;
        funnelVisc.maximumLimit = 500;
        this.workbookMeasurementChannelConfigurations.push(funnelVisc);

        // Fluid System
        const fluidSystem = new WorkbookMeasurementChannnelConfiguration();
        fluidSystem.buildMeasurement(
            'FluidSystem',
            'Fluid System',
            wellKnownMeasurements,
            unitSystem,
            'Text',
            activityOptions.filter(i => viscActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout],
            ['SLW', 'SWP', 'FW']
        );
        this.workbookMeasurementChannelConfigurations.push(fluidSystem);

        // Plug Number
        const plugNumberActNames = [ "Tag", "Milling", "No Show", "Stall", "Through Plug" ];
        const plugNumber = new WorkbookMeasurementChannnelConfiguration();
        plugNumber.buildMeasurement(
            'PlugNumber',
            'Plug Number',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => plugNumberActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        this.workbookMeasurementChannelConfigurations.push(plugNumber);

        // SWP Vol
        const swpVol = new WorkbookMeasurementChannnelConfiguration();
        swpVol.buildMeasurement(
            'SweepVolume',
            'Sweep Volume',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(a => a == "SWP Sent"),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        swpVol.mininumLimit = 0;
        this.workbookMeasurementChannelConfigurations.push(swpVol);

        const wellheadActNames = [ "RIH-Start", "RIH", "Tag", "Milling", "No Show", "Stall", "Washing", "SWP Sent", "Sweep Out of Coil", "Through Plug", "Pickup", "Weight Check", "SWP on Surface", "Dump Plug Catcher", "Short Trip", "Rate Change", "Circulating", "POOH", ];
        // Circulating Pressure
        const circulatingActNames = [ "Function BHA", ...wellheadActNames ];
        const circPressure = new WorkbookMeasurementChannnelConfiguration();
        circPressure.buildMeasurement(
            'CirculatingPressure',
            'Circulating Pressure',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => circulatingActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        circPressure.mininumLimit = 0;
        circPressure.maximumLimit = 20000;
        this.workbookMeasurementChannelConfigurations.push(circPressure);

        // Wellhead Pressure
        const whPress = new WorkbookMeasurementChannnelConfiguration();
        whPress.buildMeasurement(
            'WellheadPressure',
            'Wellhead Pressure',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => wellheadActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        whPress.mininumLimit = 0;
        whPress.maximumLimit = 20000;
        this.workbookMeasurementChannelConfigurations.push(whPress);

        // Pump Rate
        const pumpRate = new WorkbookMeasurementChannnelConfiguration();
        pumpRate.buildMeasurement(
            'PumpRate',
            'Pump Rate',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => circulatingActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        pumpRate.mininumLimit = 0;
        pumpRate.maximumLimit = 50;
        this.workbookMeasurementChannelConfigurations.push(pumpRate);

        const flowbackRate = new WorkbookMeasurementChannnelConfiguration();
        flowbackRate.buildMeasurement(
            'FlowbackRate',
            'Flowback Rate',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => wellheadActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        flowbackRate.mininumLimit = 0;
        flowbackRate.maximumLimit = 50;
        this.workbookMeasurementChannelConfigurations.push(flowbackRate);

        // Solids
        const solidsActNames = [ "SWP on Surface", "Dump Plug Catcher", ];

        const solidsHeight = new WorkbookMeasurementChannnelConfiguration();
        solidsHeight.buildMeasurement(
            'SolidsHeight',
            'Solids Height',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => solidsActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        )
        solidsHeight.mininumLimit = 0;
        this.workbookMeasurementChannelConfigurations.push(solidsHeight);

        const solids = new WorkbookMeasurementChannnelConfiguration();
        solids.buildMeasurement(
            'SolidsWeight',
            'Solids Weight',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => solidsActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        solids.mininumLimit = 0;
        this.workbookMeasurementChannelConfigurations.push(solids);

        // Solids Type
        const solidsType = new WorkbookMeasurementChannnelConfiguration();
        solidsType.buildMeasurement(
            'SolidsType',
            'Solids Type',
            wellKnownMeasurements,
            unitSystem,
            'Text',
            activityOptions.filter(i => solidsActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout],
            ['All', 'Plug Parts', 'Sand']
        );
        this.workbookMeasurementChannelConfigurations.push(solidsType);

        const pickupActNames = [ "Tag", "Milling", "No Show", "Stall", "Washing", "Pickup", "Weight Check", ]
        // Tubing Torque
        const torqueActNames = [...pickupActNames, "Through Plug", ];
        const tubingTorque = new WorkbookMeasurementChannnelConfiguration();
        tubingTorque.buildMeasurement(
            'TubingTorque',
            'Torque',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => torqueActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        this.workbookMeasurementChannelConfigurations.push(tubingTorque);

        const freeTorque = new WorkbookMeasurementChannnelConfiguration();
        freeTorque.buildMeasurement(
            'FreeTorque',
            'Free Torque',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => torqueActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        this.workbookMeasurementChannelConfigurations.push(freeTorque);

        // Tubing Weight
        const tubingWeight = new WorkbookMeasurementChannnelConfiguration();
        tubingWeight.buildMeasurement(
            'TubingWeight',
            'Weight',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => torqueActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        this.workbookMeasurementChannelConfigurations.push(tubingWeight);

        // Plug Type
        const plugTypeActNames = [ "Tag", "Milling", "No Show", "Stall", ];
        const plugType = new WorkbookMeasurementChannnelConfiguration();
        plugType.buildMeasurement(
            'PlugType',
            'Plug Type',
            wellKnownMeasurements,
            unitSystem,
            'Text',
            activityOptions.filter(i => plugTypeActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout],
            ['CFP', 'DFP', 'Sand', 'Other']
        );
        this.workbookMeasurementChannelConfigurations.push(plugType);

        // Pickup/Setdown
        const pickupSetdown = new WorkbookMeasurementChannnelConfiguration();
        pickupSetdown.buildMeasurement(
            'PickupSetdown',
            'Pickup/Setdown',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => pickupActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        this.workbookMeasurementChannelConfigurations.push(pickupSetdown);

        // Stalls Between Plug
        const stalls = new WorkbookMeasurementChannnelConfiguration();
        stalls.buildMeasurement(
            'StallsBetweenPlug',
            'Stalls Between Plug',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(a => a == "Tag"),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        stalls.mininumLimit = 0;
        this.workbookMeasurementChannelConfigurations.push(stalls);

        const drillTime = new WorkbookMeasurementChannnelConfiguration();
        drillTime.buildMeasurement(
            'DrillTime',
            'Drill Time',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(a => a == "Through Plug"),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        drillTime.mininumLimit = 0;
        this.workbookMeasurementChannelConfigurations.push(drillTime);

        // Ph
        const phActNames = [ "Water Quality Test" ];
        const ph = new WorkbookMeasurementChannnelConfiguration();
        ph.buildMeasurement(
            'Ph',
            'Ph',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => phActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        ph.mininumLimit = 0;
        ph.maximumLimit = 14;
        this.workbookMeasurementChannelConfigurations.push(ph);

        // Fluid Density
        const fluidDensity = new WorkbookMeasurementChannnelConfiguration();
        fluidDensity.buildMeasurement(
            'FluidDensity',
            'Fluid Density',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => phActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        fluidDensity.mininumLimit = 8.3;
        fluidDensity.maximumLimit = 25;
        this.workbookMeasurementChannelConfigurations.push(fluidDensity);

        // Gas in Flowback
        const gasActNames = [ "RIH", "Tag", "Milling", "Washing", "SWP Sent", "Through Plug", "Pickup", "SWP on Surface", "Dump Plug Catcher", "Short Trip", "Circulating", "POOH", "OOH", ];
        const gasInFlowback = new WorkbookMeasurementChannnelConfiguration();
        gasInFlowback.buildMeasurement(
            'GasInFlowback',
            'Gas Present in Flowback',
            wellKnownMeasurements,
            unitSystem,
            'Text',
            activityOptions.filter(i => gasActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout],
            ['None', 'Trace', 'Light', 'Medium', 'Heavy']
        )
        this.workbookMeasurementChannelConfigurations.push(gasInFlowback);

        // --------- Flowback/Well Testing Job UPSTREAM properties -------------------


        const chokeUpstream = new WorkbookMeasurementChannnelConfiguration();
        chokeUpstream.buildMeasurement(
            'ChokeUpstream',
            'Choke Upstream',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(chokeUpstream);

        const dumpIntervalUpstream = new WorkbookMeasurementChannnelConfiguration();
        dumpIntervalUpstream.buildMeasurement(
            'DumpIntervalUpstream',
            'Dump Interval (Upstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(dumpIntervalUpstream);

        const separatorPress = new WorkbookMeasurementChannnelConfiguration();
        separatorPress.buildMeasurement(
            'SeparatorPressureUpstream',
            'Separator Pressure (Upstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(separatorPress);

        const casingTubingProduction = new WorkbookMeasurementChannnelConfiguration();
        casingTubingProduction.buildMeasurement(
            'CasingTubingProduction',
            'Casing/Tubing (Production)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(casingTubingProduction);

        const casingTubingInt = new WorkbookMeasurementChannnelConfiguration();
        casingTubingInt.buildMeasurement(
            'CasingTubingInt',
            'Casing/Tubing (INT)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(casingTubingInt);

        const casingTubingSurface = new WorkbookMeasurementChannnelConfiguration();
        casingTubingSurface.buildMeasurement(
            'CasingTubingSurface',
            'Casing/Tubing (Surface)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(casingTubingSurface);

        const manifoldPressure = new WorkbookMeasurementChannnelConfiguration();
        manifoldPressure.buildMeasurement(
            'ManifoldPressure',
            'Manifold Pressure',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(manifoldPressure);

        const sandSizeUpstream = new WorkbookMeasurementChannnelConfiguration();
        sandSizeUpstream.buildMeasurement(
            'SandSizeUpstream',
            'Sand Size (Upstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(sandSizeUpstream);

        const sandWeightUpstream = new WorkbookMeasurementChannnelConfiguration();
        sandWeightUpstream.buildMeasurement(
            'SandWeighteUpstream',
            'Sand Weight (Upstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(sandWeightUpstream);

        const sandVolumeUpstream = new WorkbookMeasurementChannnelConfiguration();
        sandVolumeUpstream.buildMeasurement(
            'SandVolumeUpstream',
            'Sand Volume (Upstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(sandVolumeUpstream);

        const damagesUpstream = new WorkbookMeasurementChannnelConfiguration();
        damagesUpstream.buildMeasurement(
            'DamagesChargebacksUpstream',
            'Damages/Chargebacks (Upstream)',
            wellKnownMeasurements,
            unitSystem,
            'Text',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(damagesUpstream);

        const priceUpstream = new WorkbookMeasurementChannnelConfiguration();
        priceUpstream.buildMeasurement(
            'PriceUpstream',
            'Price (Upstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(priceUpstream);

        const wellheadTemp = new WorkbookMeasurementChannnelConfiguration();
        wellheadTemp.buildMeasurement(
            'WellheadTemp',
            'Wellhead Temperature',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Upstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(wellheadTemp);

        // --------- Flowback/Well Testing Job DOWNSTREAM properties -------------------

        const accumulator = new WorkbookMeasurementChannnelConfiguration();
        accumulator.buildMeasurement(
            'Accumulator',
            'Accumulator',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(accumulator);

        const separatorPressure = new WorkbookMeasurementChannnelConfiguration();
        separatorPressure.buildMeasurement(
            'SeparatorPressureDownstream',
            'Separator Pressure (Downstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(separatorPressure);

        const differentialPressure = new WorkbookMeasurementChannnelConfiguration();
        differentialPressure.buildMeasurement(
            'DifferentialPressure',
            'Differential Pressure',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(differentialPressure);

        const separatorTemp = new WorkbookMeasurementChannnelConfiguration();
        separatorTemp.buildMeasurement(
            'SeparatorTemp',
            'Separator Temperature',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(separatorTemp);

        const h2oPerHr = new WorkbookMeasurementChannnelConfiguration();
        h2oPerHr.buildMeasurement(
            'H2OPerHr',
            'H2O Volume Per Hour',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(h2oPerHr);

        const oilPerHr = new WorkbookMeasurementChannnelConfiguration();
        oilPerHr.buildMeasurement(
            'OilPerHr',
            'Oil Volume Per Hour',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(oilPerHr);

        const chokeDownstream = new WorkbookMeasurementChannnelConfiguration();
        chokeDownstream.buildMeasurement(
            'ChokeDownstream',
            'Choke (Downstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(chokeDownstream);

        const dumpIntervalDownstream = new WorkbookMeasurementChannnelConfiguration();
        dumpIntervalDownstream.buildMeasurement(
            'DumpIntervalDownstream',
            'Dump Interval (Downstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(dumpIntervalDownstream);

        const downstreamFluidActNames = [ "Water Quality Test", "Downstream", ];
        const fluidWeight = new WorkbookMeasurementChannnelConfiguration();
        fluidWeight.buildMeasurement(
            'FluidWeight',
            'Fluid Weight',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => downstreamFluidActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout, WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(fluidWeight);

        const sandSizeDownstream = new WorkbookMeasurementChannnelConfiguration();
        sandSizeDownstream.buildMeasurement(
            'SandSizeDownstream',
            'Sand Size (Downstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(sandSizeDownstream);

        const sandWeightDownstream = new WorkbookMeasurementChannnelConfiguration();
        sandWeightDownstream.buildMeasurement(
            'SandWeighteDownstream',
            'Sand Weight (Downstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(sandWeightDownstream);

        const sandVolumeDownstream = new WorkbookMeasurementChannnelConfiguration();
        sandVolumeDownstream.buildMeasurement(
            'SandVolumeDownstream',
            'Sand Volume (Downstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(sandVolumeDownstream);

        const damagesDownstream = new WorkbookMeasurementChannnelConfiguration();
        damagesDownstream.buildMeasurement(
            'DamagesChargebacksDownstream',
            'Damages/Chargebacks (Downstream)',
            wellKnownMeasurements,
            unitSystem,
            'Text',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(damagesDownstream);

        const priceDownstream = new WorkbookMeasurementChannnelConfiguration();
        priceDownstream.buildMeasurement(
            'PriceDownstream',
            'Price (Downstream)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['Downstream'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(priceDownstream);

        const chlorides = new WorkbookMeasurementChannnelConfiguration();
        chlorides.buildMeasurement(
            'Chlorides',
            'Chlorides',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => downstreamFluidActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting, WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout]
        );
        this.workbookMeasurementChannelConfigurations.push(chlorides);

        // --------- Flowback/Well Testing Job E&P Report properties -------------------
        const producedGasRate = new WorkbookMeasurementChannnelConfiguration();
        producedGasRate.buildMeasurement(
            'ProducedGasRate',
            'Produced Gas Rate',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['E&P Report'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(producedGasRate);

        const productionTemperature = new WorkbookMeasurementChannnelConfiguration();
        productionTemperature.buildMeasurement(
            'ProductionTemp',
            'Production Temperature',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['E&P Report'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(productionTemperature);

        const diffPressSales = new WorkbookMeasurementChannnelConfiguration();
        diffPressSales.buildMeasurement(
            'DifferentialPressureSales',
            'Differential Pressure (Sales Meter)',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['E&P Report'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(diffPressSales);

        const linePress = new WorkbookMeasurementChannnelConfiguration();
        linePress.buildMeasurement(
            'LinePress',
            'Line Pressure',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            ['E&P Report'],
            activityOptions,
            [WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(linePress);

        const h2sActNames = [ "Arrive on Location", "Water Quality Test", "E&P Report", ];
        const h2s = new WorkbookMeasurementChannnelConfiguration();
        h2s.buildMeasurement(
            'H2S',
            'H2S',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => h2sActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout, WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(h2s);

        const co2ActNames = [ "Water Quality Test", "E&P Report", ];
        const co2 = new WorkbookMeasurementChannnelConfiguration();
        co2.buildMeasurement(
            'CO2',
            'CO2',
            wellKnownMeasurements,
            unitSystem,
            'Number',
            activityOptions.filter(i => co2ActNames.indexOf(i) >= 0),
            activityOptions,
            [WorkbookJobTypes.tcpFracDrillout, WorkbookJobTypes.fESCODrillout, WorkbookJobTypes.flowbackWellTesting]
        );
        this.workbookMeasurementChannelConfigurations.push(co2);

    }
}
