import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { VendorJob } from '../models/models';

@Injectable()
export abstract class IVendorJobService  {
    abstract republishWorkbook(jobId: string): Observable<any>;

    abstract getJobs(): Observable<VendorJob[]>;

    abstract getJob(jobId: string): Observable<VendorJob>;

    abstract getJobFromConsumerJob(jobId: string): Observable<VendorJob>;

    abstract getDashboardLink(jobId: string): Observable<string>;

    abstract createJob(job: VendorJob): Observable<any>;

    abstract updateJob(jobId: string, updatedJob: VendorJob): Observable<any>;

    abstract deleteJob(jobId): Observable<any>;

    abstract isUserServiceProvider(jobId: string): Observable<boolean>;

    abstract getCompanyIdForJob(jobId: string): Observable<string>;

    abstract getVendorJobsForVendor(vendorId: string): Observable<VendorJob[]>;

    abstract isDataOnJob(jobId: string): Observable<boolean>;
}
