<div *ngIf="job != null && job.performanceSummary != null" class="grid field col-12">
  <div class="grid field col-12">
      <!-- Plugs Drilled To Date -->
      <div class="col-12 md:col-1">
          <label>Plugs Drilled To Date</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.performanceSummary.plugsDrilled}}</label>
      </div>
      <!-- Job Time -->
      <div class="col-12 md:col-1">
          <label>Job Time (hr)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.performanceSummary.jobTime | number:'1.1-2'}}</label>
      </div>
      <!-- Short Trips -->
      <div class="col-12 md:col-1">
          <label>Short Trips</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.performanceSummary.shortTrips}}</label>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- Plugs Per Short Trip -->
      <div class="col-12 md:col-1">
          <label>Plugs Per Short Trip</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.performanceSummary.plugPerShortTrip | number:'1.1-2'}}</label>
      </div>
      <!-- Time Per Plug -->
      <div class="col-12 md:col-1">
          <label>Time Per Plug (hr/plug)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.performanceSummary.timePerPlug | number:'1.1-2'}}</label>
      </div>
      <!-- Total Running Pipe -->
      <div class="col-12 md:col-1">
          <label>Total Running Pipe Feet (ft)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.performanceSummary.totalRunningPipe | number:'1.0-0'}}</label>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- Running Ratio -->
      <div class="col-12 md:col-1">
          <label>Running Ft/MD Ratio</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.performanceSummary.runningFtMdRatio | number:'1.1-2'}}</label>
      </div>
      <!-- Productive Ft Per Hour -->
      <div class="col-12 md:col-1">
          <label>Productive Time (ft/hr)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.performanceSummary.productiveFtPerHour | number:'1.0-0'}}</label>
      </div>
  </div>
</div>
