import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-file-pop-up',
  templateUrl: './file-pop-up.component.html',
  styleUrls: ['./file-pop-up.component.css']
})
export class FilePopUpComponent {
  jobId:string;
  constructor(dialogConfig:DynamicDialogConfig ) { 
    this.jobId = dialogConfig.data.jobId;
  }

}
