import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Company, CompanyName, CompanyContactInfo } from '../models/models';
import { Injectable } from '@angular/core';
import { ICompanyService } from './iCompany.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

import * as polly from 'polly-js';
// TODO: Updated HttpClient ==> AuthHttp for jwt token based requests

@Injectable()
export class CompanyService extends ICompanyService {

    constructor(private _http: HttpClient) {
        super();
    }

    getCompanies(): Observable<Company[]> {
        
        const retryRequest = polly().retry(5).executeForPromise(() => this._http.get<Company[]>('api/company').toPromise());

        return from(retryRequest).pipe(
                catchError((err: HttpErrorResponse, caught: Observable<Company[]>) => {
                    if (err.status === 0) {
                        console.error(err);
                    }
                    return caught;
                }
              ));



    }

    getCompany(companyId: string): Observable<Company> {
        const request = this._http.get('api/company/' + companyId).pipe(map(
            result => {
                return result as Company;
            }
        ));
        return request;
    }

    generateAPIKey(companyName: string): Observable<string> {
        return this._http.get<string>('api/company/generateAPIKey/' + companyName);
    }


    createCompany(company: Company): Observable<any> {
        const request = this._http.post('api/company', company).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    updateCompany(id: string, company: Company): Observable<any> {
        const request = this._http.put('api/company/' + id, company).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    deleteCompany(companyId): Observable<any> {
        const request = this._http.delete('api/company/' + companyId).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    getCompanyCategories(): Observable<string[]> {
        return new Observable<string[]>();
    }

    getCompanyNames(): Observable<CompanyName[]> {
        return this._http.get<CompanyName[]>('api/company/names');
    }

    getCompanyNamesForUser(): Observable<CompanyName[]> {
        return this._http.get<CompanyName[]>('api/company/namesforuser');
    }

    getVendorCompanyNames(): Observable<CompanyName[]> {
        return this._http.get<CompanyName[]>('api/company/vendors');
    }

    getConsumerCompanyNames(): Observable<CompanyName[]> {
        return this._http.get<CompanyName[]>('api/company/consumers');
    }

    getNonMigratedCompanyNames(): Observable<CompanyName[]> {
        return this._http.get<CompanyName[]>('api/company/notmigrated');
    }

    getCreatedAfterOrMigratedCompanyNames(): Observable<CompanyName[]> {
        return this._http.get<CompanyName[]>('api/company/migrated');
    }

    getConsumerCreatedAfterOrMigratedCompanyNames(): Observable<CompanyName[]> {
        return this._http.get<CompanyName[]>('api/company/migrated/consumers');
    }

    getCompanyContactInfo(companyId: string): Observable<CompanyContactInfo> {
        return this._http.get<CompanyContactInfo>('api/company/' + companyId + '/contacts');
    }
}
