import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IJobService,
         ICompanyService,
         ICountyDataService,
         IDeviceService,
         IDistrictService,
         IUserService,
         IFeaturesService,
         IJobWorkbookConfigurationService } from '../../../services/services';
import { Job,
         WellInfo,
         WellTypes,
         Casing,
         Plug,
         FluidInfo,
         PerformanceSummary,
         FluidSummary,
         States,
         CTWOTubingODs,
         CTWOTubingOD,
         CasingTubingTypes,
         Features,
         JobWorkbookConfiguration,
         Company,
         WorkbookJobTypes,
         District,
         User,
         CompanyTypes, } from '../../../models/models';
import { Message, SelectItem } from 'primeng/api';
// import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { CountyData } from '../../../services/iCountyData';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { StringTypes } from 'src/app/models/wellInfo';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { first } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { getWorkbookTypesSelection } from 'src/app/models/workbookJobTypes';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css']
})
export class JobDetailComponent implements OnInit {
  public user: User;
  public userReady = false;
  public jobId: string;
  public job: Job;
  public jobReady = false;
  public company: Company;
  public isNew = false;
  // public jobDateRange:Date[] = [];
  public calendarStartTime: Date;
  public calendarEndTime: Date;
  public jobDatesReady = false;
  public supervisors: SelectItem[] = [];
  public supervisorsReady = false;

  public companies: SelectItem[] = [];
  public companiesReady = false;

  public devices: SelectItem[] = [];
  public devicesReady = false;
  public selectedDevices: string[] = [];

  public sandReportBeginTime: Date;
  public sandReportEndTime: Date;
  public dataSheetReportBeginTime: Date;
  public dataSheetReportEndTime: Date;

  public everythingReady = false;

  public messages: Message[] = [];
  public modelInvalid = false;
  public showModelInvalidDialog = false;

  workbookTypes: SelectItem[] = getWorkbookTypesSelection();
  wellTypes: SelectItem[] = [
    { label: WellTypes.horizontal, value: WellTypes.horizontal },
    { label: WellTypes.vertical, value: WellTypes.vertical },
  ];

  public states = States;
  public counties: SelectItem[] = [];
  public districts: SelectItem[] = [];
  public procedures: SelectItem[] = [
    { label: 'Drill Out', value: 'Drill Out'},
    { label: 'Drill Out <2 Plugs', value: 'Drill Out <2 Plugs'},
    { label: 'DO w/ Logging Run', value: 'DO w/ Logging Run'},
    { label: 'DO w/ Fishing Trip', value: 'DO w/ Fishing Trip'},
    { label: 'Fishing Trip', value: 'Fishing Trip'},
    { label: 'Wash Out', value: 'Wash Out'},
    { label: 'Toe Prep', value: 'Toe Prep'},
    { label: 'Other', value: 'Other'}
  ];
  public millTriCones: SelectItem[] = [
    { label: 'Mill', value: 'Mill' },
    { label: 'Tri-Cone', value: 'Tri-Cone' },
    { label: 'PDC', value: 'PDC' },
  ];
  public stringTypes: SelectItem[] = [
    { label: StringTypes.coilTubing, value: StringTypes.coilTubing },
    { label: StringTypes.jointTubing, value: StringTypes.jointTubing }
  ];
  public ctwoTubingODs: SelectItem[] = []; // = CTWOTubingODs;
  public hozTubingODs: SelectItem[] = [];
  public selectedCTWOTubingOD: CTWOTubingOD;
  public selectedHozTubingOD: CTWOTubingOD;
  public casingTubingTypes = CasingTubingTypes;
  public casingTubingDirections: SelectItem[] = [
    { label: 'Horizontal', value: 'Horizontal' },
    { label: 'Vertical', value: 'Vertical' }
  ];
  public gallonsPerCubicFeet = 7.48052; // TODO: Remove after only using NewCasingPlugs feature
  public feetPerInch = 0.0833333; // TODO: Remove after only using NewCasingPlugs feature
  public gallonsPerBarrel = 42; // TODO: Remove after only using NewCasingPlugs feature
  public securityToken: string;
  public availableChemicals: SelectItem[] = [
    { label: 'Blue Dye 3102', value: 'Blue Dye 3102' },
    { label: 'Nano iPop 5102', value: 'Nano iPop 5102' },
    { label: 'ProScav', value: 'ProScav' },
    { label: 'Red Dye 3101', value: 'Red Dye 3101' },
    { label: 'iFR8320', value: 'iFR8320' },
    { label: 'iLube 5103', value: 'iLube 5103' }
  ];

  public serviceProviderOptions: SelectItem[] = [];
  public serviceProvidersReady = true;
  public isUserServiceProvider = false;

  public offlineWorkbookFeatureEnabled = false;
  public newCasingPlugsFeatureEnabled = false;
  public companyAdministrationEnabled = false;
  public featuresReady = false;

  public saving = false;
  public dashboardLink: string;

  public enableOfflineWorkbookSet = false;
  public jobTypeSet = false;
  public readyToLoadWorkbookConfiguration = false;
  public jobWorkbookConfiguration: JobWorkbookConfiguration;

  public testing = 'Not Defined';

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _jobService: IJobService,
    private _companyService: ICompanyService,
    private _deviceService: IDeviceService,
    private _districtService: IDistrictService,
    private _userService: IUserService,
    private _userManagerService: IUserManagerService,
    private _titleService: Title,
    private _countyDataService: ICountyDataService,
    private _featuresService: IFeaturesService,
    private _jobWorkbookConfigurationService: IJobWorkbookConfigurationService,
    private _currentUserService: ICurrentUserService ) { }

  ngOnInit() {
    this.getAuthenticatedUser();
    this.getEnabledFeatures();
    this.getJobNameFromRoute();
    this.getCompanies();
    this.getDevices();
    this.getDistricts();
    this.getSupervisors();
    this.getCTWOTubingODs();
    this.setSecurityToken();
  }
  setSecurityToken(){

    this._currentUserService.getAccessToken().subscribe(s=>{
      //console.warn(s);
      this.securityToken = s;
    });

    
  }
  getEnabledFeatures() {
    this._featuresService.getEnabledFeatures().subscribe(
      result => {
       if (result.includes(Features.OfflineWorkbook)) { this.offlineWorkbookFeatureEnabled = true; }
       if (result.includes(Features.NewCasingPlugs)) { this.newCasingPlugsFeatureEnabled = true; }
       if (result.includes(Features.CompanyAdministration)) { this.companyAdministrationEnabled = true; }
       this.featuresReady = true;
       this.checkIfEverythingReady();
      },
      error => {
        console.error(error);
        
      }
    );
  }

  getAuthenticatedUser() {

    this._userManagerService.getCurrentUser().subscribe(u=>{
      this.user = u;
      this.userReady = true;
      this.checkIfEverythingReady();
    });
  }

  getJobNameFromRoute() {
    this._route.params.subscribe(
      params => {
        this.jobId = params['id'];
        if (!this.jobId || this.jobId.toUpperCase() === 'NEW') {
          this.isNew = true;
          this.createNewJob();
        } else {
          this.getJob();
          this.getIsUserServiceProviderForJob();
        }
        this.setPageTitle(this.jobId);
      });
  }



  setPageTitle(jobId: string) {
    if (!jobId || jobId === 'New' || jobId === '') {
      this._titleService.setTitle('New Job');
    } else { this._titleService.setTitle(jobId); }
  }

  createNewJob() {
    // var id = uuidv4();
    const id = null;
    this.job = new Job(id, null, null, [], null, null);
    this.jobReady = true;
    this.calendarStartTime = new Date();
    this.calendarEndTime = null;
    this.jobDatesReady = true;
    this.job.wellInfo = new WellInfo();
    this.job.fluidInfo = new FluidInfo();
    this.job.performanceSummary = new PerformanceSummary();
    this.job.fluidSummary = new FluidSummary();
    this.checkIfEverythingReady();
  }

  setJobIdAndName() {
    // Added rule to make all Job Ids to be Uppercase to aid in desktop matching
    this.job.id = this.job.id.toUpperCase();
    this.job.name = this.job.id;
    this.checkToEnableWorkbookConfiguration();
  }

  getJob() {
    this._jobService.getJob(this.jobId).subscribe(
      async result => {
        if (result == null) {
          this.messages = [];
          this.messages.push({
            severity: 'error',
            summary: 'Could not get Job',
            detail: 'Could not find job with Name: ' + this.jobId
          });
        } else {
          if (result.isMigrated) {
            this.redirectToVendorJob(this.jobId);
          }
          this.job = result;
          this.setCalendarDates();
          this.getCounties(this.job.state);
          if (this.job.companyId) {
            this.company = await this.getCompany(this.job.companyId).toPromise().then(r => r);
          }

          // check for null values in nested objects
          if (this.job.wellInfo == null) { this.job.wellInfo = new WellInfo(); }
          if (this.job.fluidInfo == null) { this.job.fluidInfo = new FluidInfo(); }
          if (this.job.performanceSummary == null) { this.job.performanceSummary = new PerformanceSummary(); }
          if (this.job.fluidSummary == null) { this.job.fluidSummary = new FluidSummary(); }
          if (this.job.deviceList == null) { this.job.deviceList = []; }

          // Set Wellinfo arrays to be empty if null
          if (this.job.wellInfo.casings == null) { this.job.wellInfo.casings = []; }
          if (this.job.wellInfo.plugs == null) { this.job.wellInfo.plugs = []; }

          // Set selected CT-WO Tubing OD
          this.selectedCTWOTubingOD = { id: this.job.wellInfo.ctWoTubingOdId, value: this.job.wellInfo.ctWoTubingOd };
          this.selectedHozTubingOD = { id: this.job.wellInfo.hozTubingOdId, value: this.job.wellInfo.hozTubingOd };

          this.jobReady = true;
          this.checkIfEverythingReady();

          this.enableOfflineWorkbookSet = true;
          this.checkToEnableWorkbookConfiguration();
        }
      },
      error => {
        this.messages = [];
        this.messages.push({
        severity: 'error',
        summary: 'Could not get Job',
        detail: 'Could not find job with Name: ' + this.jobId
      });
      console.error(error);
    });

    this._jobService.getDashboardLink(this.jobId).subscribe(r => this.dashboardLink = r);
  }

  getIsUserServiceProviderForJob() {
    this._jobService.isUserServiceProvider(this.jobId).subscribe(
      result => { this.isUserServiceProvider = result; }
    );
  }

  getCompanies() {
    this._companyService.getNonMigratedCompanyNames().subscribe(
      results => {
        results.forEach(result => {
          if (!result.companyType || result.companyType === CompanyTypes.Consumer) {
            this.companies.push({
              label: result.companyName,
              value: result.companyId }
            );
          }
        });
        this.companiesReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get list of companies',
          detail: ''
        });
        console.error(error);
    });
  }

  getCounties(stateName: string) {
    this.counties = [];
    if (stateName == null || stateName === '') {
      return;
    }
    this._countyDataService.getCounties(stateName).subscribe(
      data => {
        data.forEach(result => {
          this.counties.push({
            label: result.name,
            value: result.name
          });
        });
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get list of counties',
          detail: ''
        });
        console.error(error);
      }
    );
  }

  getDevices() {
    this._deviceService.getDevices().subscribe(
      results => {
        results.forEach(result => {
          this.devices.push({
            label: result.category + ': ' + result.name,
            value: result.id }
          );
        });
        this.devicesReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get list of devices',
          detail: ''
        });
        console.error(error);
    });
  }

  getDistricts() {
    this._districtService.getDistricts().subscribe(
      results => {
        this.districts = results.map(p => Object.assign( <SelectItem>{}, p, { label: p.name, value: p.id }));
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get list of districts',
          detail: ''
        });
        console.error(error);
      }
    );
  }

  getSupervisors() {
    this.messages = [];

    this._userService.getUsers().subscribe(
      results => {
        results.forEach(u => {
          if (u.isEmployee === true) {
            this.supervisors.push({
              label: u.firstName + ' ' + u.lastName,
              value: u.id
            });
          }
        });
        this.supervisorsReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get Supervisors and Operators',
          detail: ''
        });
        console.error(error);
      }
    );
  }

  getCTWOTubingODs() {
    const tubingODs: SelectItem[] = CTWOTubingODs;
    this.ctwoTubingODs = tubingODs.sort((a, b) => {
      if (a.label < b.label) { return -1; }
      if (a.label > b.label) { return 1; }
      return 0;
    });
    this.hozTubingODs = this.ctwoTubingODs;
  }

  checkIfEverythingReady() {
    this.everythingReady = false;
    if (this.userReady &&
        this.jobReady &&
        this.companiesReady &&
        this.jobDatesReady &&
        this.devicesReady &&
        this.supervisorsReady &&
        this.featuresReady) {
      this.cleanJobDevices();
      this.setServiceProviderOptions();
      this.everythingReady = true;
    }
  }

  cleanJobDevices() {
    // Remove devices from job that do not exist in available devices list to prevent
    // broken drop down selection
    this.job.deviceList.forEach(jobDevice => {
      let jobDeviceFound = false;
      this.devices.forEach(availableDevice => {
        if (availableDevice.value === jobDevice) { jobDeviceFound = true; }
      });
      if (!jobDeviceFound) {
        this.job.deviceList = this.job.deviceList.filter(device => device !== jobDevice);
      }
    });
  }

  setCalendarDates() {
    this.jobDatesReady = false;
    // Convert from UTC
    this.calendarStartTime = new Date(this.job.startTime);
    if (this.job.endTime) {
      this.calendarEndTime = new Date(this.job.endTime);
    }

    this.jobDatesReady = true;
    this.checkIfEverythingReady();
  }

  setDates() {
    // Convert To UTC
    this.job.startTime = new Date(this.calendarStartTime);
    if (this.calendarEndTime) {
      this.job.endTime = new Date(this.calendarEndTime);
    }
  }

  clearEndDate() {
    this.calendarEndTime = null;
    this.job.endTime = null; // Will not be updated in setDates if calendarEndTime is null
  }

  isAdmin(): boolean {
    if (this.companyAdministrationEnabled) {
      return this._userService.isUserAdmin(this.user, this.job.companyId, false);
    } else {
      return this._userService.isUserAdmin(this.user, this.job.companyId, true);
    }
  }

  isEditorOrAdmin(): boolean {
    if (this.companyAdministrationEnabled) {
      return this._userService.isUserEditorOrAdmin(this.user, this.job.companyId, false);
    } else {
      return this._userService.isUserEditorOrAdmin(this.user, this.job.companyId, true);
    }
  }

  handleErrorsFromConfiguration(event: any) {
    this.messages = event;
  }

  updateWorkbookConfiguration(event: any) {
    this.jobWorkbookConfiguration = event;
  }

  validateJobModel(): boolean {
    if (!this.job.name) { return false; }
    if (!this.job.companyId) { return false; }
    // TODO: Does at least one device need to be associated?  If so uncomment
    //      Also uncomment HTML validation message
    // if(!this.job.deviceList || this.job.deviceList.length < 1) return false;
    if (!this.job.startTime) { return false; }
    // if(!this.job.endTime) return false;
    if (!this.job.wellInfo.name) { return false; }

    if ((this.job.workbookType === WorkbookJobTypes.flowbackWellTesting) && !this.job.wellInfo.wellType) {
      return false;
    }
    if (this.job.enableOfflineWorkbook && !this.job.workbookType) { return false; }

    if ((this.job.workbookType === WorkbookJobTypes.flowbackWellTesting) &&
        (!this.job.frcsDayEngineer || !this.job.dayOperators ||
         !this.job.frcsNightEngineer || ! this.job.nightOperators)) {
           return false;
         }

    return true;
  }

  saveJob() {
    this.saving = true;
    this.setDates();
    this.showModelInvalidDialog = this.modelInvalid = !this.validateJobModel();

    if (!this.showModelInvalidDialog) {
      if (this.isNew) {
        this._jobService.createJob(this.job).subscribe(
          result => {
            this.saveJobConfiguration();
           },
          errorResult => {
            let returnedErrors = '';
            if (errorResult.error.Message.includes('Validation Error')) {
              returnedErrors = errorResult.error.Message;
            }
            this.messages = [];
            this.messages.push({
              severity: 'error',
              summary: 'Could not Create Job',
              detail: returnedErrors
            });
            this.saving = false;
          }
        );
      } else {
        this._jobService.updateJob(this.jobId, this.job).subscribe(
          result => {
            this.saveJobConfiguration();
          },
          errorResult => {
            let returnedErrors = '';
            if (errorResult.error.Message.includes('Validation Error')) {
              returnedErrors = errorResult.error.Message;
            }
            this.messages = [{
              severity: 'error',
              summary: 'Could not Update Job',
              detail: returnedErrors
            }];
            this.saving = false;
          }
        );
      }
    } else {
      this.saving = false;
    }
  }

  saveJobConfiguration() {
    if (this.offlineWorkbookFeatureEnabled && this.job.enableOfflineWorkbook) {
      this._jobWorkbookConfigurationService.addOrUpdateJobWorkbookConfiguration(this.jobWorkbookConfiguration).subscribe(
        result => {
          this.returnToJobList();
        },
        error => {
          this.messages = [{
            severity: 'error',
            summary: 'Could not Update Job Workbook Configuration',
            detail: ''
          }];
          this.saving = false;
        }
      );
    } else {
      this.returnToJobList();
    }
  }

  closeInvalidModelDialog() {
    this.showModelInvalidDialog = false;
  }

  returnToJobList() {
    this._router.navigate(['/jobs/']);
  }

  endJob() {
    this.job.status = 'Finished';
    // Does end date need to be updated to now?
    // this.job.endTime = new Date(Date.now());
    this.saveJob();
  }

  downloadJobExport() {
    window.open('/api/job/export/' + this.job.id + '?token=' + this.securityToken, '_blank');
  }

  downloadJobSummaryExport() {
    window.open('/api/job/exportsummary/' + this.job.id + '?token=' + this.securityToken, '_blank');
  }

  downloadSandReport() {
    const beginTime = moment(this.sandReportBeginTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(this.sandReportEndTime).format('YYYY-MM-DDTHH:mm:ss');
    window.open('/api/reports/sand/' +
    this.job.id +
    '?beginTime=' + beginTime +
    '&endTime=' + endTime +
    '&token=' + this.securityToken, '_blank');
  }

  downloadDataSheetReport() {
    const beginTime = moment(this.dataSheetReportBeginTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(this.dataSheetReportEndTime).format('YYYY-MM-DDTHH:mm:ss');
    window.open('/api/reports/datasheet/' +
    this.job.id +
    '?beginTime=' + beginTime +
    '&endTime=' + endTime +
    '&token=' + this.securityToken, '_blank');
  }

  addCasing() {
    const casingToAdd = new Casing();
    casingToAdd.number = this.job.wellInfo.casings.length + 1;
    casingToAdd.volume = 0;
    this.job.wellInfo.casings.push(casingToAdd);
  }

  addPlug() {
    const plugToAdd = new Plug();
    plugToAdd.number = this.job.wellInfo.plugs.length + 1;
    this.job.wellInfo.plugs.push(plugToAdd);
  }

  addChemical() {
    let chemicalIndex = 0;
    if (!this.job.fluidSummary.chemicals) {
      this.job.fluidSummary.chemicals = [];
    }
    if (this.job.fluidSummary.chemicals.length > 0) {
      chemicalIndex = this.job.fluidSummary.chemicals.length - 1;
    }
    this.job.fluidSummary.chemicals.push({ key: '', value: 0.0 });
  }

  navigateToDashboard() {

    // this async actions causes pop up blocker to fire
    // this._jobService.getDashboardLink(this.jobId)
    //     .subscribe(l=>window.open(l))

    window.open(this.dashboardLink);
  }

  navigateToWorkbook() {
    if (this.offlineWorkbookFeatureEnabled && this.job.enableOfflineWorkbook) {
      const decodedJobId = decodeURIComponent(this.jobId);
      this._router.navigate(['/workbook2/' + encodeURIComponent(decodedJobId)]);
    } else {
      this._router.navigate(['/workbook/' + encodeURIComponent(this.jobId)]);
    }
  }

  setLateralLengthEst() {
    if (this.job.wellInfo && this.job.wellInfo.tvd && this.job.wellInfo.md) {
      this.job.wellInfo.lateralLengthEst = this.job.wellInfo.md - this.job.wellInfo.tvd;
    }
  }

  setCTWOTubingOD() {
    this.job.wellInfo.ctWoTubingOd = this.selectedCTWOTubingOD.value;
    this.job.wellInfo.ctWoTubingOdId = this.selectedCTWOTubingOD.id;
  }

  setHozTubingOD() {
    this.job.wellInfo.hozTubingOd = this.selectedHozTubingOD.value;
    this.job.wellInfo.hozTubingOdId = this.selectedHozTubingOD.id;
  }

  // TODO: Remove after only using NewCasingPlugs feature
  setCasingVolume(casing: Casing) {
    if (casing) {
      if (casing.type && casing.length) {
        const innerDiameter = this.getInnerDiameterFromCasingType(casing.type);
        if (innerDiameter) {
          // calculate volume of cylinder and convert to gallons
          this.job.wellInfo.casings[casing.number - 1].volume =
            Math.PI *
            Math.pow((innerDiameter / 2 * this.feetPerInch), 2) *
            casing.length *
            this.gallonsPerCubicFeet *
            (1 / this.gallonsPerBarrel);
        }
      }

      // Also Set Casing ID for first Horizontal Casing
      this.checkIfCanSetCasingID(casing);
    }
  }

  // TODO: Remove after only using NewCasingPlugs feature
  checkIfCanSetCasingID(casing: Casing) {
    const firstHorizontalCasing = this.job.wellInfo.casings.find(c => c.direction === 'Horizontal');
    if (firstHorizontalCasing) {
      if (firstHorizontalCasing.number === casing.number) {
        this.job.wellInfo.csgId = this.getInnerDiameterFromCasingType(casing.type);
      }
    }
  }

  // TODO: Remove after only using NewCasingPlugs feature
  getInnerDiameterFromCasingType(casingType: string): number {
    // Parse out Inner Diameter from string in format:
    // 4.5in OD, 9.5 lbs/ft, 4.09 ID, 3.965 in Drift
    // Number and decimal places of ID can change, so using comma separators
    if (!casingType) { return null; }
    const casingTypeArray = casingType.split(',');
    if (!casingTypeArray[2]) { return null; }
    const innerDiameter = casingTypeArray[2];
    innerDiameter.trim();
    innerDiameter.replace(' ID', '');
    innerDiameter.replace(' id', '');
    innerDiameter.replace('ID', '');
    innerDiameter.replace('id', '');
    return parseFloat(innerDiameter);
  }

  // TODO: Remove after only using NewCasingPlugs feature
  deleteCasing(casingNumber: number) {
    if (this.job.wellInfo.casings && this.job.wellInfo.casings.length >= casingNumber) {
      this.job.wellInfo.casings.splice(casingNumber - 1, 1);
      if (casingNumber < (this.job.wellInfo.casings.length + 1)) { // Add one since one has been removed
        // Renumber casings after deletion
        this.job.wellInfo.casings.forEach(casing => {
          if (casing.number >= casingNumber) {
            casing.number = casing.number - 1;
          }
        });
      }
    }
  }

  // TODO: Remove after only using NewCasingPlugs feature
  deletePlug(plugNumber: number) {
    if (this.job.wellInfo.plugs && this.job.wellInfo.plugs.length >= plugNumber) {
      this.job.wellInfo.plugs.splice(plugNumber - 1, 1);
      if (plugNumber < (this.job.wellInfo.plugs.length + 1)) { // Add one since one has been removed
        // Renumber casings after deletion
        this.job.wellInfo.plugs.forEach(plug => {
          if (plug.number >= plugNumber) {
            plug.number = plug.number - 1;
          }
        });
      }
    }
  }

  updateCsgId(csgId: any) {
    this.job.wellInfo.csgId = csgId;
  }

  deleteChemical(index: number) {
    if (index != null) {
      this.job.fluidSummary.chemicals.splice(index, 1);
    }
  }

  setOperator() {
    if (this.job.companyId) {
      this.getCompany(this.job.companyId).subscribe(
        result => {
          this.company = result;
          this.setDefaultServiceProviders(this.company);
          this.job.enableOfflineWorkbook = this.company.enableOfflineWorkbook;
          this.enableOfflineWorkbookSet = true;
          this.checkToEnableWorkbookConfiguration();
        }
      );
    }
  }

  getCompany(companyId: string): Observable<Company> {
    return new Observable<Company>(observer => {
      this._companyService.getCompany(companyId).subscribe(
        result => {
          observer.next(result);
          observer.complete();
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  setServiceProviderOptions() {
    if (this.job && this.job.companyId) {
      this.serviceProviderOptions = this.companies.filter(c => c.value !== this.job.companyId);
    } else { this.serviceProviderOptions = this.companies; }
    this.serviceProvidersReady = true;
  }

  setDefaultServiceProviders(company: Company) {
    this.serviceProvidersReady = false;
      this.job.serviceProviderIds = [];
      // Default selected Service Providers to those defined by
      // company that is associated to job
      if (company.serviceProviderIds) {
        company.serviceProviderIds.forEach(s => {
          this.job.serviceProviderIds.push(s);
        });
      }
      this.setServiceProviderOptions();
  }

  setJobType() {
    this.checkToEnableWorkbookConfiguration();
  }

  checkToEnableWorkbookConfiguration() {
    if (this.job.id && this.enableOfflineWorkbookSet && this.job.workbookType && this.company) {
      this.readyToLoadWorkbookConfiguration = true;
    }
  }

  republishWorkbook() {
    this._jobService.republishWorkbook(this.jobId).subscribe(
      result => {},
      errorResult => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not Republish Workbook Data',
          detail: ''
        });
      }
    );
  }

  handleError(error: any, displayMessage: string) {
    this.messages = [{
      severity: 'error',
      summary: displayMessage,
      detail: error as string
    }];
    
    console.error(error);
  }

  redirectToVendorJob(jobId: string) {
    this._router.navigate(['/vendorjob/' + jobId]);
  }
}
