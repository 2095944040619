<div class="grid field col-12">
    <p-button 
        class="col-12 md:col-2 ui-lg-2"
        (click)="addExpense()"
        label="Add Expense">
    </p-button>
</div>
<div class="grid field col-12">
    <p-table [value]="expenses" dataKey="id" editMode="row">
        <ng-template pTemplate="header">
            <tr>
                <th>Description</th>
                <th>Cost/Factor</th>
                <th>Factor</th>
                <th style="width:8em"></th>
                <th style="width:8em"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="rowData.description" required [ngStyle]="{'width':'100%'}">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{rowData.description}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            $ <input pInputText type="number" [(ngModel)]="rowData.costPerFactor" size="6" [ngStyle]="{'width':'100%'}" min="0">
                        </ng-template>
                        <ng-template pTemplate="output">
                            $ {{rowData.costPerFactor}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="expenseCostFactor" [(ngModel)]="rowData.expenseCostFactorId" [style]="{'width':'100%'}"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{rowData.expenseCostFactorId}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="text-align:center">
                    <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" (click)="onRowEditInit(rowData)"></button>
                    <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check" class="ui-button-success" style="margin-right: .5em" (click)="onRowEditSave(rowData)"></button>
                    <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times" class="p-button-danger" (click)="onRowEditCancel(rowData, ri)"></button>
                </td>
                <td>
                    <p-button icon="pi pi-trash" (click)="remove(rowData)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>