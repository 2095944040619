import { SelectItem } from "primeng/api";

export enum WorkbookJobTypes {
  tcpFracDrillout = 'TCP/Frac/Drillout',
  fESCODrillout = 'FESCO Drilllout',
  flowbackWellTesting = 'Flowback/Well Testing',
  hydroLyftJob= 'HydroLyft Job',
  fRCSDilloutWashout = 'FRCS Drillout/Washout',
  eQTDrilloutWashout = 'EQT Drillout/Washout'
}
export function getWorkbookTypesSelection() : SelectItem[] { return [
  { label: WorkbookJobTypes.tcpFracDrillout, value: WorkbookJobTypes.tcpFracDrillout },
  { label: WorkbookJobTypes.fESCODrillout, value: WorkbookJobTypes.fESCODrillout },
  { label: WorkbookJobTypes.flowbackWellTesting, value: WorkbookJobTypes.flowbackWellTesting },
  { label: WorkbookJobTypes.hydroLyftJob, value: WorkbookJobTypes.hydroLyftJob },
  { label: WorkbookJobTypes.fRCSDilloutWashout, value: WorkbookJobTypes.fRCSDilloutWashout },
  { label: WorkbookJobTypes.eQTDrilloutWashout, value: WorkbookJobTypes.eQTDrilloutWashout },
]};