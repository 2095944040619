import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Job } from '../models/models';
import { Injectable } from '@angular/core';
import { IJobService } from './iJob.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import * as polly from 'polly-js';

@Injectable()
export class JobService extends IJobService {

    constructor(private _http:HttpClient) {
        super();
    }

    getJobs(): Observable<Job[]> {

        var retryRequest = polly().retry(5).executeForPromise(()=>this._http.get<Job[]>('api/job').toPromise());

        return from(retryRequest).pipe(
                catchError((err: HttpErrorResponse, caught: Observable<Job[]>) => {
                    if (err.status === 0) {
                      
                        console.error(err);
                    }
                    return caught;
                }
              ));
        

    };

    getJob(jobId: string): Observable<Job> {
        return this._http.get<Job>('api/job/' + jobId);
    }

    getDashboardLink(jobId: string): Observable<string> {
        let request = this._http.get('api/job/dashboardlink/' + jobId).pipe(map(
            result => {
                return result as string;
            }
        ));
        return request;
    }

    createJob(job: Job): Observable<any> {
        let request = this._http.post('api/job', job).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    updateJob(jobId: string, updatedJob: Job): Observable<any> {
        let request = this._http.put('api/job/' + jobId, updatedJob).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    deleteJob(jobId): Observable<any> {
        let request = this._http.delete('api/job/' + jobId).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    republishWorkbook(jobId:string): Observable<any> {
        let request = this._http.get('api/job/republish/' + jobId).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    isUserServiceProvider(jobId:string): Observable<boolean> {
        let request = this._http.get('api/job/isServiceProvider/' + jobId).pipe(map(
            result => {
                return result as boolean;
            }
        ));
        return request;
    }

    getCompanyIdForJob(jobId:string): Observable<string> {
        let request = this._http.get('api/job/' + jobId + "/companyId").pipe(map(
            result => {
                return result as string;
            }
        ));
        return request;
    }
}