import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { CompanyListComponent } from './components/company/company-list/company-list.component';
import { CompanyDetailComponent } from './components/company/company-detail/company-detail.component';
import { JobListComponent } from './components/job/job-list/job-list.component';
import { JobDetailComponent } from './components/job/job-detail/job-detail.component';
import { PostJobAnalysisComponent } from './components/job/post-job-analysis/post-job-analysis.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserDetailComponent } from './components/user/user-detail/user-detail.component';
import { DeviceListComponent } from './components/device/device-list/device-list.component';
import { DeviceDetailComponent } from './components/device/device-detail/device-detail.component';
import { WorkbookComponent } from './components/workbook/workbook.component';
import { OfflineWorkbookComponent } from './components/offlineWorkbook/offlineWorkbook.component';
import { OfflineWorkbookDetailComponent } from './components/offlineWorkbook/offlineWorkbook-detail/offlineWorkbook-detail.component';
import { CanEditGuard } from './guards/canEdit.guard';
import { RequestComponent } from './components/request/request.component';
import { FeatureGuard } from './guards/feature.guard';
import { VendorJobComponent } from './components/job/vendor-job/vendor-job.component';
import { ConsumerJobComponent } from './components/job/consumer-job/consumer-job.component';
import { VendorConsumerJobsComponent } from './components/job/vendor-consumer-jobs/vendor-consumer-jobs.component';
import { JobsRedirectComponent } from './components/jobs-redirect/jobs-redirect.component';
import { JobLinkerAdminComponent } from './components/job-linker-admin/job-linker-admin.component';
import { MsalGuard } from '@azure/msal-angular';
import { GlobalFilesContainerComponent } from './components/files/global-files-container/global-files-container.component';
import { TicketListComponent } from './components/ticket-system/ticket-list/ticket-list.component';


export const routes: Routes = [
   
    
    {
        // Needed for hash routing
        path: 'error',
        component: JobsRedirectComponent
      },
      {
        // Needed for hash routing
        path: 'state',
        component: JobsRedirectComponent
      },
      {
        // Needed for hash routing
        path: 'code',
        component: JobsRedirectComponent
      },
    { path: '', component: JobsRedirectComponent },
    { path: 'companies', component: CompanyListComponent, canActivate: [MsalGuard, CanEditGuard] },
    { path: 'company/:id', component: CompanyDetailComponent, canActivate: [MsalGuard, CanEditGuard] },
    { path: 'jobs', component: JobListComponent, canActivate: [MsalGuard] },
    { path: 'jobs2', component: VendorConsumerJobsComponent, canActivate: [MsalGuard] },
    { path: 'job/:id', component: JobDetailComponent, canActivate: [MsalGuard] },
    { path: 'jobs/postjobanalysis', component: PostJobAnalysisComponent, canActivate: [MsalGuard]},
    { path: 'users', component: UserListComponent, canActivate: [MsalGuard, CanEditGuard] },
    { path: 'user/:id', component: UserDetailComponent, canActivate: [MsalGuard] },
    { path: 'devices', component: DeviceListComponent, canActivate: [MsalGuard, CanEditGuard] },
    { path: 'device/:id', component: DeviceDetailComponent, canActivate: [MsalGuard, CanEditGuard] },
    { path: 'workbook/:id', component: WorkbookComponent, canActivate: [MsalGuard] },
    { path: 'workbook2/:id', component: OfflineWorkbookComponent, canActivate: [MsalGuard] },
    { path: 'workbook2/:id/:consumerJobId', component: OfflineWorkbookComponent, canActivate: [MsalGuard] },
    { path: 'workbook2/detail/:companyId/:jobId/:id', component: OfflineWorkbookDetailComponent, canActivate: [MsalGuard] },
    { path: 'requests', component: RequestComponent, canActivate: [MsalGuard, FeatureGuard] },
    { path: 'requests/:requestType', component: RequestComponent, canActivate: [MsalGuard, FeatureGuard] },
    { path: 'vendorjob/:id', component: VendorJobComponent, canActivate: [MsalGuard] },
    { path: 'consumerjob/:id', component: ConsumerJobComponent, canActivate: [MsalGuard] },
    { path: 'joblinkers', component: JobLinkerAdminComponent, canActivate: [MsalGuard] },
    { path: 'home', component: JobsRedirectComponent, canActivate: [MsalGuard] },
    { path: 'files', component: GlobalFilesContainerComponent, canActivate: [MsalGuard] },
    { path: 'tickets', component: TicketListComponent, canActivate: [MsalGuard] },
    
];

export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    useHash: false,
    // Don't perform initial navigation in iframes or popups
    //initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled'
  } );
//export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {initialNavigation: !isIframe ? 'legacy_enabled' : 'disabled'});
