export class Configuration {
    name: string;
    display: boolean;
    isText: boolean;

    constructor(name:string, display:boolean, isText:boolean) {
        this.name = name;
        this.display = display;
        this.isText = isText;
    }
}