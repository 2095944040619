import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFeaturesService } from './iFeatures.service';

@Injectable()
export class FeaturesService extends IFeaturesService {
    featureCache: string[] = null;

    constructor(private http: HttpClient) {
        super();
    }

    isFeatureEnabled(feature: string): Observable<boolean> {
         if(this.featureCache != null && this.featureCache.indexOf(feature) >= 0)
            return of(true);
        return this.http.get('api/features/' + feature)
        .pipe(
            map(result => result as boolean)
        );
    }

    getEnabledFeatures(): Observable<string[]> {
       if(this.featureCache != null)
            return of(this.featureCache);
        return this.http.get('api/features/')
        .pipe(
            tap(result => this.featureCache = result as string[]),
            map(result => result as string[]),
        );
    }
}