import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";


@Injectable()
export abstract class ICurrentUserService {
    abstract getUser():AccountInfo;
    // abstract logout();
    abstract getUserEmail():string;
    abstract getAccessToken(): Observable<string>;
    // abstract get authenticated():Observable<boolean>;
    // abstract get token();
    // abstract login();
    // abstract getToken(): Observable<string>;
}