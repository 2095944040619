<div class="grid field col-12">
    <div class="grid field col-12">
        <h2>Device Details</h2>
    </div>
    <div *ngIf="!everythingReady" class="grid field col-12">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="everythingReady" class="grid field col-12">
        <!-- Device Properties -->

        <!-- Id -->
        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Id</label>
            </div>
            <div class="col-12 md:col-6">
                <input type="text" pInputText placeholder="Enter Device Id" [(ngModel)]="device.id"
                    [disabled]="!isNew" />
                <div *ngIf="modelInvalid && !device.id" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Id is required!</label>
                </div>
            </div>
        </div>

        <!-- Name -->
        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Name</label>
            </div>
            <div class="col-12 md:col-6">
                <input type="text" pInputText placeholder="Enter Device Name" [(ngModel)]="device.name" />
                <div *ngIf="modelInvalid && !device.name" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Name is required!</label>
                </div>
            </div>
        </div>

        <!-- Prefix -->
        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Category</label>
            </div>
            <div class="col-12 md:col-6">
                <p-dropdown [options]="deviceCategories" [(ngModel)]="device.category"></p-dropdown>
                <div *ngIf="modelInvalid && !device.category" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Category is required!</label>
                </div>
            </div>
        </div>

        <!-- Company Selection -->
        <div *ngIf="deviceConfigurationEnabled" class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Company</label>
            </div>
            <div class="col-12 md:col-6">
                <p-dropdown [options]="companyOptions" [(ngModel)]="device.companyId" placeholder="Select Company"
                    [disabled]="isNew"></p-dropdown>
                <div *ngIf="modelInvalid && !device.companyId" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Company is required!</label>
                </div>
            </div>
        </div>

        <!-- TCP Configuration -->
        <app-tcp-device *ngIf="device.category == 'TCP'" [(ngModel)]="device.tcpDeviceConfiguration" class="col-12" ></app-tcp-device>
        

         <!-- Measurment Channel Configuration -->
       
            <app-device-configuration *ngIf="deviceConfigurationEnabled" [(ngModel)]="device.measurementChannelConfigurations"
                [company]="selectedCompany"
                [wellKnownMeasurements]="wellKnownMeasurements" (errors)="handleConfigurationErrors($event)" class="col-12" ></app-device-configuration>
        

        <!-- Action Buttons -->
        <div class="grid field col-12">
            <button pButton class="mr-2"  *ngIf="isNew"
                (click)="saveDevice()">Create
            </button>
            <button pButton *ngIf="!isNew" class="mr-2"
                (click)="saveDevice()">Update
            </button>
            <button pButton 
                (click)="returnToDeviceList()">Cancel
            </button>
        </div>
    </div>
</div>

<!--Invalid Model Dialog-->
<p-dialog header="Cannot Save Changes" [(visible)]="showModelInvalidDialog" [modal]="true"
    [style]="{width: '350px'}"  [minY]="70" [baseZIndex]="999" [autoZIndex]="false" [dismissableMask]="true">
    <span>
        One or more fields is empty or invalid. Please correct and try again.
    </span>
    <p-footer>
        <button type="button" pButton icon="fa-check" (click)="closeInvalidModelDialog()" label="Ok"></button>
    </p-footer>
</p-dialog>