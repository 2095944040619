

  <div *ngIf="loading" class="col-12">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <div class="field grid col-12" *ngIf="job">
    


    <!--Page Title and Dashboard Link-->
    <div class="col-4">
      <h2>Service Provider Job Details</h2>
    </div>

    <div class="col-8">
      <ng-container *ngIf="!isNew">
        <button pButton class="ui-button-primary" (click)="navigateToDashboard()" icon="far fa-chart-bar"
          label="Dashboard" style="margin-top:17px; height:30px; width:145px; float:right; vertical-align:bottom;">
        </button>
        <button pButton class="ui-button-primary" (click)="navigateToWorkbook()" icon="fa fa-book" label="Workbook"
          style="margin-top:17px; height:30px; width:145px; float:right; vertical-align:bottom;">
        </button>
        <button pButton class="ui-button-primary" (click)="showManageFiles = true" icon="fa fa-file" label="Files"
          style="margin-top:17px; height:30px; width:145px; float:right; vertical-align:bottom;">
        </button>
        <button pButton class="ui-button-primary" icon="fa fa-retweet fa-lg" label="Republish"
          (click)="republishWorkbook()"
          style="margin-top:17px; margin-left: 5px; height:30px; width:145px; float:right; vertical-align:bottom;"></button>
      </ng-container>
    </div>




    <div class="field grid col-12">
    <label class="col-2" >Job Number</label>
    <div class="col-10">
      <input type="text" pInputText placeholder="Enter Job Number" [(ngModel)]="job.name" [disabled]="!isNew" />
      <div *ngIf="modelInvalid && !job.name">
        <label style="color:red; font-weight:bold;">Id is required!</label>
      </div>
    </div>
    </div>

    <!-- Vendor Company -->
    <div class="field grid col-12">
    <label class="col-2">Service Provider (Vendor)</label>
    <div class="col-10">
      <p-dropdown [options]="vendorCompanies" placeholder="Must Be A Service Provider" [(ngModel)]="job.companyId"
        (onChange)="setServiceProvider()" [filter]="true" filterBy="label" [disabled]="true">
      </p-dropdown>
      <div *ngIf="modelInvalid && invalidCompanyId">
        <label style="color:red; font-weight:bold;">User must belong to Service Provider</label>
      </div>
    </div>
  </div>




    <!-- Consumer Company -->
    <div class="field grid col-12">
        <label class="col-2" >Operator (Consumer)</label>
      <div class="col-10">
        <p-dropdown [options]="consumerCompanies" placeholder="Select a Company" [(ngModel)]="job.consumerCompanyId"
           (onChange)="setOperator()" [filter]="true" filterBy="label"
          [disabled]="!isNew || isUserServiceProvider">
        </p-dropdown>
        <div *ngIf="modelInvalid && !job.consumerCompanyId">
          <label style="color:red; font-weight:bold;">Operator is required!</label>
        </div>
      </div>
      <div class="ui-g-1">
        <i *ngIf="job.consumerCompanyId" class="fas fa-info-circle clear-end-time" (mouseover)="showContactInfo()"></i>
      </div>
    </div>

    <!-- Other Service Providers -->
    <div *ngIf="!isUserServiceProvider" class="field grid col-12">
    
        <label class="col-2" >OFS Shared With</label>
      <div class="col-10">
        <p-multiSelect [disabled]="!serviceProvidersReady" [options]="serviceProviderOptions"
          defaultLabel="Service Providers" [(ngModel)]="job.serviceProviderIds"></p-multiSelect>
      </div>
    </div>

    <!-- Well Name -->
    <div class="field grid col-12">
    
        <label class="col-2">Well Name</label>
      <div class="col-10">
        <input type="text" pInputText placeholder="Enter WellName" [(ngModel)]="job.wellInfo.name" [disabled]="!isNew"
          (change)="wellNameUpdated()" />
        <div *ngIf="modelInvalid && (!job.wellInfo.name && !job.apiNumber)">
          <label style="color:red; font-weight:bold;">Well Name or Api Number is required!</label>
        </div>
      </div>
    </div>

    <!-- Api Number -->
    <div class="field grid col-12">
        <label class="col-2" >Api Number</label>
      
      <div class="col-10">
        <p-inputMask pInputText mask="99-999-99999" [(ngModel)]="job.apiNumber" [disabled]="!isNew"
          placeholder="99-999-99999" (onComplete)="apiNumberUpdated()"></p-inputMask>
        <div *ngIf="modelInvalid && (!job.wellInfo.name && !job.apiNumber)" >
          <label style="color:red; font-weight:bold;">Well Name or Api Number is required!</label>
        </div>
      </div>
    </div>

    <!-- Job Type -->
    <div class="field grid col-12">
        <label class="col-2">Job Type</label>
      
      <div class="col-10">
        <p-dropdown [options]="jobTypes" placeholder="Select Job Type" [(ngModel)]="job.workbookType"
          (onChange)="jobTypeUpdated()"  [disabled]="!isNew"></p-dropdown>
        <div *ngIf="modelInvalid && !job.workbookType" >
          <label style="color:red; font-weight:bold;">Job Type is required!</label>
        </div>
      </div>
    </div>

    <!-- Start & End Time -->
    <div class="field grid col-12">
      
        <label class="col-2" >Arrive on Loc/Begin RU on Well</label>
      <div class="col-10">
        <p-calendar [(ngModel)]="calendarStartTime" [showIcon]="true" [showTime]="true" [hourFormat]="24"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038" (onClose)="startDateUpdated()"
          (onInput)="startDateUpdated()">
        </p-calendar>
        <div *ngIf="modelInvalid && !calendarStartTime">
          <label style="color:red; font-weight:bold;">Start Time is required!</label>
        </div>
      </div>
    </div>

    <div class="field grid col-12">
        <label class="col-2" >Release from Loc/RD from Well </label>
      <div class="col-10">
        <p-calendar [(ngModel)]="calendarEndTime" [showIcon]="true" [showTime]="true" [hourFormat]="24"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
        </p-calendar>
        <i *ngIf="calendarEndTime" class="far fa-trash-alt clear-end-time" (click)="clearEndDate()"
          pTooltip="Clear Release from Loc/RD from Well Time"></i>
      </div>
    </div>

    <!-- Job Linking -->
    <div *ngIf="jobLinkerReady" class="field grid col-12">
      <app-job-linker *ngIf="isNew" [job]="job" [companySelectItems]="consumerCompanies"
        (selectedJobLinker)="jobLinkerUpdated($event)"></app-job-linker>
      <app-job-linker *ngIf="!isNew" [vendorJobId]="job.id" [companySelectItems]="consumerCompanies"
        (selectedJobLinker)="jobLinkerUpdated($event)"></app-job-linker>
    </div>

    <!-- DeviceList -->
    <div *ngIf="devicesReady" class="field grid col-12">
      <label class="col-2" >Devices</label>
      <div class="col-10">
        <p-multiSelect [options]="devices" [(ngModel)]="job.deviceList"></p-multiSelect>
        <!-- <div *ngIf="modelInvalid && job.deviceList.length < 1" class="grid field col-12">
                <label style="color:red; font-weight:bold;">At least one device is required!</label>
            </div> -->
      </div>
    </div>

    <div *ngIf="!isNew && isEditorOrAdmin()" class="field grid col-12">
      <!-- Job Exports -->
      <div class="col-2">
        <label>Available Exports</label>
      </div>
      <div class="col-2">
        <button pButton class="ui-button-primary" icon="fa fa-download" label="Job Data" (click)="downloadJobExport()"
          style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
      </div>
      <div class="col-2">
        <button pButton class="ui-button-primary" icon="fa fa-download" label="Job Summary"
          (click)="downloadJobSummaryExport()"
          style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
      </div>
    </div>
    <!-- Sand Report -->
    <div *ngIf="!isNew && isEditorOrAdmin() && job.workbookType == 'Flowback/Well Testing'" class="field grid col-12">
      <div class="col-2">
        <label>Sand Report</label>
      </div>
      <div class="col-3">
        <p-calendar [(ngModel)]="sandReportBeginTime" [showIcon]="true" [showTime]="true" [hourFormat]="24"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
        </p-calendar>
      </div>

      <div class="col-3">
        <p-calendar [(ngModel)]="sandReportEndTime" [showIcon]="true" [showTime]="true" [hourFormat]="24"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
        </p-calendar>
      </div>
      <div class="col-4">
        <button pButton class="ui-button-primary" icon="fa fa-download" label="Sand Report"
          (click)="downloadSandReport()"
          style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
      </div>
    </div>

    <!-- Data Sheet Report -->
    <div *ngIf="!isNew && isEditorOrAdmin() && job.workbookType == 'Flowback/Well Testing'" class="field grid col-12">
      <div class="col-2">
        <label>Data Sheet Report</label>
      </div>
      <div class="col-3">
        <p-calendar [(ngModel)]="dataSheetReportBeginTime" [showIcon]="true" [showTime]="true" [hourFormat]="24"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
        </p-calendar>
      </div>

      <div class="col-3">
        <p-calendar [(ngModel)]="dataSheetReportEndTime" [showIcon]="true" [showTime]="true" [hourFormat]="24"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
        </p-calendar>
      </div>
      <div class="col-4">
        <button pButton class="ui-button-primary" icon="fa fa-download" label="Data Sheet"
          (click)="downloadDataSheetReport()"
          style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
      </div>
    </div>

    <div class="col-12">
      <app-vendor-job-common [(ngModel)]="job" [modelInvalid]="modelInvalid" [disabled]="disabled"
        [showWorkbookLink]="false"></app-vendor-job-common>
    </div>
    <div class="col-12">
      <p-accordion [multiple]="false">
        <p-accordionTab *ngIf="readyToLoadWorkbookConfiguration" header="Workbook Configuration">
          <app-workbook-configuration [companyId]="job.companyId" [jobId]="job.id" [jobType]="job.workbookType"
            [companyUnitSystem]="company.unitSystem" (jobConfiguration)="updateWorkbookConfiguration($event)"
            (errors)="handleErrorsFromConfiguration($event)">
          </app-workbook-configuration>
        </p-accordionTab>
      </p-accordion>
    </div>

    <!-- Action Buttons -->
    <div class="col-12">
      <button pButton  *ngIf="isNew" (click)="saveJob()" [disabled]="saving"
        [ngClass]="{ 'action-button': !saving,
                         'action-button-disabled': saving }">
        Create
      </button>
      <button pButton  *ngIf="!isNew" (click)="saveJob()"
        [disabled]="saving || isUserServiceProvider" [ngClass]="{ 'action-button': !saving,
                         'action-button-disabled': saving }">
        Update
      </button>
      <button pButton  (click)="returnToJobList()" class="action-button">
        Cancel
      </button>
    </div>



  </div>

  <!--Invalid Model Dialog-->
  <p-dialog header="Cannot Save Changes" [(visible)]="showModelInvalidDialog" [modal]="true" [responsive]="true"
    [style]="{width: '350px'}" [minY]="70" [baseZIndex]="999" [autoZIndex]="false" [dismissableMask]="true">
    <span>
      One or more fields is empty or invalid. Please correct and try again.
    </span>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="closeInvalidModelDialog()" label="Ok"></button>
    </p-footer>
  </p-dialog>

  <!-- ContactInfo Dialog -->
  <p-dialog *ngIf="jobReady" header="Company Contact Information" [(visible)]="showCompanyContactInfoDialog"
    [width]="525">
    <app-contact-info [(ngModel)]="job.consumerCompanyId"></app-contact-info>
  </p-dialog>

  <!-- Files Dialog -->
  <p-dialog *ngIf="showManageFiles" header="Job Files" [(visible)]="showManageFiles">
    <app-filelist [consumerJobId]="job.consumerJobId"></app-filelist>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="showManageFiles = false" label="Close"></button>
    </p-footer>
  </p-dialog>
