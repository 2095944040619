
    <div class="grid field col-12">
        <h1>Workbook Log</h1>
        <p-progressSpinner *ngIf="!everythingReady"></p-progressSpinner>
    </div>
    <div class="grid col-12">
        <div class="col-12 md:col-2 mb-2">
            <button pButton class="ui-button-primary" (click)="navigateToJobDetails()" icon="fa fa-edit" label="Job Details"></button>
        </div>
        <div class="col-12 md:col-2">
            <button pButton class="ui-button-primary" (click)="navigateToDashboard()" icon="fa fa-bar-chart" label="Dashboard"></button>
        </div>
        <div class="col-12 md:col-8">
            <button pButton type="button" label="&nbsp;&nbsp;Export" (click)="dt.exportCSV()" icon="fa fa-download" ></button>
        </div>
            <div class="col-12 pr-0">
                    <p-table #dt 
                    [columns]="getAllColumns()" 
                    [value]="workbookData" 
                    [resizableColumns]="true"
                    columnResizeMode="expand"
                    [scrollable]="true" scrollHeight="calc(100vh - 325px)"
                    [loading]="!everythingReady">
                            <!-- <ng-template pTemplate="colgroup" let-columns>
                                <colgroup>
                                    <col *ngFor="let col of columns" [style.width]="col.width" />
                                </colgroup>
                            </ng-template> -->
                            <ng-template pTemplate="header" let-columns>
                                <tr style="height:45px;">
                                    <th *ngFor="let col of columns" pResizableColumn pFrozenColumn  [frozen]="col.frozen"  [ngStyle]="{'display':col.display}"
                                        [pTooltip]="col.header" tooltipPosition="top" tooltipEvent="hover">
                                        {{col.header}}
                                    </th>
                                </tr>
                                <tr style="height:31px;">
                                    <th *ngFor="let col of columns" pFrozenColumn  [frozen]="col.frozen" [ngStyle]="{'display':col.display}">
                                        {{col.unit}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-workbookLog let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns" pFrozenColumn  [frozen]="col.frozen" [ngStyle]="{'display':col.display}">
                                        <div *ngIf="col.field == 'icons'" >
                                                <i *ngIf="isVendor()" class="far fa-edit actionIcon" (click)="navigateToDetail(workbookLog)" style="margin-right:5px;"></i>
                                                <i *ngIf="isVendor() && isAdmin()" class="far fa-trash-alt actionIcon" (click)="confirmDelete(workbookLog)"></i>
                                        </div>
                                        <div *ngIf="col.field == 'Time'" >
                                            {{workbookLog[col.field] | date: 'MM/dd/yy HH:mm'}}
                                        </div>
                                        <div *ngIf="col.field == 'relativeTime'">
                                            {{workbookLog[col.field]  | number:'1.1-2'}}
                                        </div>
                                        <div *ngIf="col.field != 'Time' && col.field != 'relativeTime'" 
                                            [pTooltip]="workbookLog[col.field]" tooltipPosition="top" tooltipEvent="hover">
                                            {{workbookLog[col.field]}}
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                         
                        </p-table>
                    
            </div>
            <div class="col-12">
                <button pButton type="button" label="Add Workbook Log" (click)="addWorkbookLog()" icon="fa fa-plus" class="add-button" [disabled]="!isVendor()"></button>
            </div>
    </div>
