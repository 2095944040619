import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewCompanyRequest } from '../../models/models';
import { INewCompanyRequestService } from './iNewCompanyRequest.service';

@Injectable()
export class NewCompanyRequestService extends INewCompanyRequestService {
  baseUrl = 'api/newcompanyrequest/';

  constructor(private _http: HttpClient) {
    super();
  }

  getNewCompanyRequests(): Observable<NewCompanyRequest[]> {
    const request = this._http.get(this.baseUrl).pipe(map(
      results => {
        if (results) {
          const resultsAsArray = results as NewCompanyRequest[];
          const newCompanyRequests: NewCompanyRequest[] = [];
          resultsAsArray.map(r => {
            const newCompanyRequest = new NewCompanyRequest();
            newCompanyRequest.mapFromObject(r);
            newCompanyRequests.push(newCompanyRequest);
          });
          return newCompanyRequests;
        }
        return null;
      }
    ));
    return request;
  }

  getNewCompanyRequestById(id: string): Observable<NewCompanyRequest> {
    const request = this._http.get(this.baseUrl + id).pipe(map(
      result => {
        if (result) {
          const newCompanyRequest = new NewCompanyRequest();
          newCompanyRequest.mapFromObject(result);
          return newCompanyRequest;
        }
        return null;
      }
    ));
    return request;
  }

  createNewCompanyRequest(newCompanyRequest: NewCompanyRequest) {
    const request = this._http.post(this.baseUrl, newCompanyRequest).pipe(map(
      response => {
          return response as string;
      }
    ));
    return request;
  }

  approveNewCompanyRequest(id: string) {
    const request = this._http.post(this.baseUrl + 'approve/' + id, {}).pipe(map(
      response => {
          return response as string;
      }
    ));
    return request;
  }

  rejectNewCompanyRequest(id: string, reason: string) {
    const request = this._http.post(this.baseUrl + 'reject/' + id, { reasonMessage: reason }).pipe(map(
      response => {
          return response as string;
      }
    ));
    return request;
  }

  deleteNewCompanyRequest(id: string) {
    const request = this._http.delete(this.baseUrl + id).pipe(map(
      response => {
          return response as string;
      }
    ));
    return request;
  }

  getPendingNewCompanyRequestsForUser(userId: string): Observable<NewCompanyRequest[]> {
    return this._http.get<NewCompanyRequest[]>(this.baseUrl + 'pending/' + userId);
  }
}
