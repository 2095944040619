import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyWorkbookConfiguration,
         WellKnownMeasurementChoice,
         UnitChoice,
         Configuration,
         WorkbookMeasurementChannnelConfiguration } from '../models/models';
import { Injectable } from '@angular/core';
import { ICompanyWorkbookConfigurationService } from './iCompanyWorkbookConfiguration.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { List } from 'linqts';

@Injectable()
export class CompanyWorkbookConfigurationService extends ICompanyWorkbookConfigurationService {
    baseUrl = 'api/companyworkbookconfiguration/';

    constructor(private _http: HttpClient) {
        super();
    }

    getActivities(): Observable<string[]> {
        const request = this._http.get(this.baseUrl + 'activities').pipe(map(
            result => {
                return result as string[];
            }
        ));
        return request;
    }

    getWellKnownMeasurements(): Observable<WellKnownMeasurementChoice[]> {
        const request = this._http.get(this.baseUrl + 'wellknown').pipe(map(
            result => {
                return result as WellKnownMeasurementChoice[];
            }
        ));
        return request;
    }

    getUnitChoices(): Observable<UnitChoice[]> {
        const request = this._http.get<WellKnownMeasurementChoice[]>(this.baseUrl + 'wellknown').pipe(map(
            result => {
                const measurements = new List<WellKnownMeasurementChoice>(result);
                const unitChoices = measurements.SelectMany(u =>
                    new List<UnitChoice>(u.imperialUnits)).Union(measurements.SelectMany(u => new List<UnitChoice>(u.siUnits))).ToArray();
                unitChoices.push({unitKey: 'Dimensionless', unitName: 'N/A' });
                return unitChoices;
            }
        ));
        return request;
    }

    getConfigurationForCompany(companyId: string): Observable<CompanyWorkbookConfiguration> {
        const request = this._http.get(this.baseUrl + 'company/' + companyId).pipe(map(
            result => {
                // Until this service is replaced with NSwag generated ones, do the following as a stop gap
                if (result) {
                    const resultAsCompanyConfiguration = result as CompanyWorkbookConfiguration;
                    const configuration = new CompanyWorkbookConfiguration(
                        resultAsCompanyConfiguration.id,
                        resultAsCompanyConfiguration.companyId);
                    configuration.workbookMeasurementChannelConfigurations = [];
                    resultAsCompanyConfiguration.workbookMeasurementChannelConfigurations.forEach(r => {
                        const measurementChannel = new WorkbookMeasurementChannnelConfiguration();
                        measurementChannel.mapFromObject(r);
                        configuration.workbookMeasurementChannelConfigurations.push(measurementChannel);
                    });
                    return configuration;
                }
                return null;
            }
        ));
        return request;
    }

    getConfigurationById(id: string): Observable<CompanyWorkbookConfiguration> {
        const request = this._http.get(this.baseUrl + id).pipe(map(
            result => {
                return result as CompanyWorkbookConfiguration;
            }
        ));
        return request;
    }

    getDefaultConfigurationForCompany(companyId: string) : Observable<CompanyWorkbookConfiguration> {
        const request = this._http.get(this.baseUrl + 'default/' + companyId).pipe(map(
            result => {
                if(result) {
                    const resultAsCompanyConfiguration = result as CompanyWorkbookConfiguration;
                    const configuration = new CompanyWorkbookConfiguration(
                        resultAsCompanyConfiguration.id,
                        resultAsCompanyConfiguration.companyId);
                    configuration.workbookMeasurementChannelConfigurations = [];
                    resultAsCompanyConfiguration.workbookMeasurementChannelConfigurations.forEach(r => {
                        const measurementChannel = new WorkbookMeasurementChannnelConfiguration();
                        measurementChannel.mapFromObject(r);
                        configuration.workbookMeasurementChannelConfigurations.push(measurementChannel);
                    });
                    return configuration;
                }
                return null;
            }
        ));
        return request;
    }


    createOrUpdateConfiguration(configuration: CompanyWorkbookConfiguration): Observable<any> {
        const request = this._http.post(this.baseUrl, configuration).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    deleteConfigurationByCompanyId(companyId: string): Observable<any> {
        const request = this._http.delete(this.baseUrl + 'company/' + companyId).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }
}
