<div class="grid field col-12">
    <div class="grid field col-12">
        <p-table [value]="activities" [scrollable]="true" dataKey="guid">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3em"></th>
                    <th style="width: 6em"></th>
                    <th>Activity</th>
                    <th>Duration (hr)</th>
                    <th>Pipe Speed (ft/min)</th>
                    <th>Circ Rate (bbl/min)</th>
                    <th>Return Rate (bbl/min)</th>
                    <th colspan="3">Calculations</th>
                    <th>Relative Time (hr)</th>
                    <th style="width:3em"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-act let-expanded="expanded" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                    <td style="width: 3em">
                        <a href="#" [pRowToggler]="act">
                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                    </td>
                    <td style="width: 6em;vertical-align: middle;">
                        <i class="fa fa-bars" style="margin-right:5px;" pReorderableRowHandle></i>
                        <span>&nbsp;</span>
                        <i class="fa fa-plus" (click)="addActivityBelow(act)"></i>
                    </td>
                    <td>
                        <div *ngIf="isInitialActivity(act)">{{act.activity}}</div>
                        <div *ngIf="isNotInitialActivity(act)">
                            <p-dropdown 
                                [options]="optionalActivities"
                                [(ngModel)]="act.activity"
                                placeholder="Select Activity"
                                [style]="{'width':'90%'}">
                            </p-dropdown>
                        </div>
                    </td>
                    <td>
                        <input type="number" pInputText size="3" [(ngModel)]="act.duration" min="0" [readonly]="hasDepthStart(act)"
                            pTooltip="Duration is calculated from select Pipe Speed and Depth for moving activities, but is user input for all other activities"
                            />
                    </td>
                    <td>
                        <input type="number" pInputText size="5" [(ngModel)]="act.pipeSpeed" min="0" />
                    </td>
                    <td>
                        <input type="number" pInputText size="5" [(ngModel)]="act.circulationRate" min="0" />
                    </td>
                    <td>
                        <input type="number" pInputText size="5" [(ngModel)]="act.returnRate" min="0" />
                    </td>
                    <td [attr.colspan]="3">
                        <table>
                            <tr>
                                <td>AV:</td>
                                <td>{{act.calcAv | number}}</td>
                                <td>RE#:</td>
                                <td>{{act.calcRe | number}}</td>
                            </tr>
                            <tr>
                                <td>Depth:</td>
                                <td>{{act.calcDepth | number}}</td>
                                <td>Plug:</td>
                                <td>{{act.calcPlugNumber | number}}</td>
                            </tr>
                        </table>
                    </td>
                    <td>{{act.calcRelativeTime | number}}</td>
                    <td style="width: 3em">
                        <i *ngIf="isNotInitialActivity(act)" class="far fa-trash-alt" (click)="removeActivity(act)"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-act>
                <tr>
                    <td [attr.colspan]="11">
                        <div class="grid field col-12" style="padding:20px">
                            <div class="col-6 md:col-4 ui-lg-2">
                                Activity: {{act.activity}}
                            </div>
                            <fieldset *ngIf="hasDepthStart(act)" class="col-6 md:col-4 ui-lg-3">
                                <legend>Depth/Plug Start</legend>
                                <div>
                                    <p-selectButton [options]="depthSelector" [(ngModel)]="act.isDepthStart"></p-selectButton>
                                </div>
                                <div>
                                    <label>Value: </label>
                                    <input type="number" pInputText size="5" [(ngModel)]="act.depthStart" min="0" />
                                </div>
                            </fieldset>
                            <fieldset *ngIf="hasDepthStart(act)" class="col-6 md:col-4 ui-lg-3">
                                <legend>Depth/Plug End</legend>
                                <div>
                                    <p-selectButton [options]="depthSelector" [(ngModel)]="act.isDepthEnd"></p-selectButton>
                                </div>
                                <div>
                                    <label>Value: </label>
                                    <input type="number" pInputText size="5" [(ngModel)]="act.depthEnd" min="0" />
                                </div>
                                <span *ngIf="act.isDepthStart != act.isDepthEnd">*Different Depth types.</span>
                            </fieldset>
                            <fieldset *ngIf="act.activity === 'Short Trip'" class="col-6 md:col-4 ui-lg-3">
                                <legend>Short Trip Distance</legend>
                                <div>
                                    <label>Value: </label>
                                    <input type="number" pInputText size="5" [(ngModel)]="act.depthStart" min="0" />
                                </div>
                            </fieldset>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

