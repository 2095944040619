import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ICompanyService } from 'src/app/services/services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyContactInfoPerson } from 'src/app/models/companyContactInfoPerson';
import { CompanyContactInfo } from 'src/app/models/models';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ContactInfoComponent),
    multi: true
  }]
})
export class ContactInfoComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;
  public isDisabled: boolean;

  @Input() _companyId: string = null;
  get companyId() {
    return this._companyId;
  }
  set companyId(val) {
    this._companyId = val;
    this.propagateChange(this._companyId);
  }

  companyContactInfoColumns: any[] = [];
  companyContactInfo: CompanyContactInfo = null;

  constructor(private _companyService: ICompanyService) { }

  ngOnInit() {
    this.companyContactInfoColumns = this.buildColumns();
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => {};

  async writeValue(obj: any): Promise<void> {
    if (obj) {
      this.companyId = obj;
      this.companyContactInfo = await this.getContactInfo();
    } else {
      this.companyId = null;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  buildColumns(): any[] {
    return [
      { field: 'name', header: 'Name', width: '150px' },
      { field: 'email', header: 'Email', width: '250px' },
      { field: 'phone', header: 'Number', width: '100px' },
    ];
  }

  async getContactInfo(): Promise<CompanyContactInfo> {
    let companyContactInfo: CompanyContactInfo = null;
    if (this.companyId) {
      companyContactInfo = await this._companyService.getCompanyContactInfo(this.companyId).toPromise();
    }
    return companyContactInfo;
  }

}
