import { SelectItem } from 'primeng/api';

export class CTWOTubingOD {
    id: number;
    value: number;
    constructor(id:number, value:number)
    {
        this.id = id;
        this.value = value;
    }
}

export var CTWOTubingODs: SelectItem[] = [
    // { label: '1"', value: 1.0 },
    // { label: '1 1/4"', value: 1.25 },
    // { label: '1 1/2"', value: 1.75 },
    // { label: '2"', value: 2.0 },
    // { label: '2 3/8"', value: 2.375 },
    // { label: '2 5/8"', value: 2.625 },
    // { label: '2 7/8"', value: 2.875 },
    // { label: '3 1/2"', value: 3.5 }
    { label: '0.750 in OD, 1.14/1.20 lbs/ft  , 0.824 in ID, 0.730 in Drift', value: new CTWOTubingOD(1, 0.750) },
    { label: '1.000 in OD, 1.70/1.80 lbs/ft  , 1.049 in ID, 0.955 in Drift', value: new CTWOTubingOD(2, 1.000) },
    { label: '1.250 in OD, 2.30/2.40 lbs/ft  , 1.380 in ID, 1.286 in Drift', value: new CTWOTubingOD(3, 1.250) },
    { label: '1.250 in OD, 1.400 lbs/ft      , 1.286 in ID', value: new CTWOTubingOD(4, 1.250) },
    { label: '1.500 in OD, 2.75/2.90 lbs/ft  , 1.610 in ID, 1.516 in Drift', value: new CTWOTubingOD(5, 1.500) },
    { label: '1.500 in OD, 1.650 lbs/ft      , 1.516 in ID', value: new CTWOTubingOD(6, 1.500) },
    { label: '2.063 in OD, N/A               , 1.751 in ID, N/A           ', value: new CTWOTubingOD(7, 2.063) },
    { label: '2.375 in OD, 4.00      lbs/ft  , 2.041 in ID, 1.947 in Drift', value: new CTWOTubingOD(8, 2.375) },
    { label: '2.375 in OD, 4.60/4.70 lbs/ft  , 1.995 in ID, 1.901 in Drift', value: new CTWOTubingOD(9, 2.375) },
    { label: '2.875 in OD, 6.40/6.50 lbs/ft  , 2.441 in ID, 2.347 in Drift', value: new CTWOTubingOD(10, 2.875) },
    { label: '2.875 in OD, 8.60/8.70 lbs/ft  , 2.259 in ID, 2.165 in Drift', value: new CTWOTubingOD(11, 2.875) },
    { label: '3.500 in OD, 7.70 lbs/ft       , 3.068 in ID, 2.943 in Drift', value: new CTWOTubingOD(12, 3.500) },
    { label: '3.500 in OD, 8.81 lbs/ft       , 2.981 in ID, 2.867 in Drift', value: new CTWOTubingOD(27, 3.500) },
    { label: '3.500 in OD, 9.20/9.30 lbs/ft  , 2.992 in ID, 2.867 in Drift', value: new CTWOTubingOD(13, 3.500) },
    { label: '3.500 in OD, 10.20 lbs/ft      , 2.922 in ID, 2.797 in Drift', value: new CTWOTubingOD(14, 3.500) },
    { label: '4.000 in OD, 9.50 lbs/ft       , 3.476 in ID, 3.423 in Drift', value: new CTWOTubingOD(15, 4.000) },
    { label: '4.000 in OD, 11.00 lbs/ft      , 3.548 in ID, 3.351 in Drift', value: new CTWOTubingOD(16, 4.000) },
    { label: '4.500 in OD, 12.60/12.75 lbs/ft, 3.958 in ID, 3.833 in Drift', value: new CTWOTubingOD(17, 4.500) },
    { label: '6.000 in OD, 24.50 lbs/ft      , 5.20 in ID, 5.07 in Drift', value: new CTWOTubingOD(27, 6.000) },
    { label: '6.000 in OD, 24.50 lbs/ft      , 5.250 in ID, 5.075 in Drift', value: new CTWOTubingOD(26, 6.000) },
    { label: '1.000 in OD', value: new CTWOTubingOD(18, 1.000) },
    { label: '1.250 in OD', value: new CTWOTubingOD(19, 1.250) },
    { label: '1.750 in OD', value: new CTWOTubingOD(20, 1.750) },
    { label: '2.000 in OD', value: new CTWOTubingOD(21, 2.000) },
    { label: '2.375 in OD', value: new CTWOTubingOD(22, 2.375) },
    { label: '2.625 in OD', value: new CTWOTubingOD(23, 2.625) },
    { label: '2.875 in OD', value: new CTWOTubingOD(24, 2.875) },
    { label: '3.500 in OD', value: new CTWOTubingOD(25, 3.500) },
]