/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { ICurrentUserService } from './icurrentuser.service';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-browser';
import { AppConfig } from 'src/app/models/AppConfig';
import { TelemetryService } from '../telemetry.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
//import { User } from 'msal';

@Injectable()
export class CurrentUserService implements ICurrentUserService {
  constructor(private _authService: MsalService, private _telemetry: TelemetryService) {
  }
  public getUserEmail(): string {
    const account = this.getOpsAccount();
    if (!account) {return null;}
    return account.username;
  }
  public getUser(): AccountInfo {
    return this.getOpsAccount();
  }

  private getOpsAccount() {
    const accounts = this._authService.instance.getAllAccounts();
    const opsviewerAccounts = accounts.filter(a => a.environment.indexOf('opsviewer') > -1);
    if (opsviewerAccounts.length === 0) {
      console.warn('No opsviewer account found.' + JSON.stringify(accounts));
      return null;
    }
    return opsviewerAccounts[0];
  }

  public getAccessToken(): Observable<string> {
    const account = this.getOpsAccount();
    return this._authService.acquireTokenSilent({ scopes: this.getScopes(), account }).pipe(first(), map(t => t.accessToken), catchError(err => this.handleError(err)));
  }
  private getScopes(): string[] {
    const subtenant = AppConfig.GLOBAL_CONFIG.tenant.split('.')[0];
    const scopes = ['openid', 'profile', `https://${subtenant}.onmicrosoft.com/web/read`];
    return scopes;
  }
  private handleError(error: any): Observable<string> {
    if (error instanceof InteractionRequiredAuthError) {
      this._telemetry.trackTrace({ message: 'InteractionRequiredAuthError. Going to try to redirect', severityLevel: SeverityLevel.Warning });
      this._authService.instance.handleRedirectPromise().then((a) => {
        this._telemetry.trackTrace({ message: `Redirected successfully ${(a?'token':'no token')}`, severityLevel: SeverityLevel.Information });
        return this._authService.acquireTokenRedirect({ scopes: this.getScopes() }).pipe(map(t => 'not a real token'), catchError(err => {
          this._telemetry.trackTrace({ message: `Error in acquireTokenRedirect ${err}`, severityLevel: SeverityLevel.Error });
          return  'not a real token';
        }));
      }).catch(err => {
        this._telemetry.trackTrace({ message: `Error in handleRedirectPromise ${err}`, severityLevel: SeverityLevel.Error });
      });

    }
    return throwError(error);
  }

}
