import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';

export const wellKnownMeasurementsLanguageId = 'wellKnownMeasurementsLanguage';

export const wellKnownMeasurementsLanguageTokens = [
  [/PlugNumber*/, 'PlugNumber'],
  [/Depth*/, 'Depth'],
  [/SweepVolume*/, 'SweepVolume'],
  [/FunnelViscosity*/, 'FunnelViscosity'],
  [/FunnelViscositySlw*/, 'FunnelViscositySlw'],
  [/FunnelViscositySwp*/, 'FunnelViscositySwp'],
  [/CirculatingPressure*/, 'CirculatingPressure'],
  [/WellheadPressure*/, 'WellheadPressure'],
  [/PumpRate*/, 'PumpRate'],
  [/FlowbackRate*/, 'FlowbackRate'],
  [/AnnularVelocity*/, 'AnnularVelocity'],
  [/SlwRe*/, 'SlwRe'],
  [/FluidBalance*/, 'FluidBalance'],
  [/CtRunningLength*/, 'CtRunningLength'],
  [/Weight*/, 'Weight'],
  [/Ph*/, 'Ph'],
  [/Fann35ViscositySlw*/, 'Fann35ViscositySlw'],
  [/Fann35ViscositySwp*/, 'Fann35ViscositySwp'],
  [/ReelSpeed*/, 'ReelSpeed'],
  [/ChokeUpstream*/, 'ChokeUpstream'],
  [/DumpIntervalUpstream*/, 'DumpIntervalUpstream'],
  [/CasingTubingProduction*/, 'CasingTubingProduction'],
  [/CasingTubingINT*/, 'CasingTubingINT'],
  [/CasingTubingSurface*/, 'CasingTubingSurface'],
  [/ManifoldPressure*/, 'ManifoldPressure'],
  [/SandSizeUpstream*/, 'SandSizeUpstream'],
  [/SandVolumeUpstream*/, 'SandVolumeUpstream'],
  [/DamagesChargebacksUpstream*/, 'DamagesChargebacksUpstream'],
  [/PriceUpstream*/, 'PriceUpstream'],
  [/ChokeDownstream*/, 'ChokeDownstream'],
  [/DumpIntervalDownstream*/, 'DumpIntervalDownstream'],
  [/SeparatorPress*/, 'SeparatorPress'],
  [/Accumulator*/, 'Accumulator'],
  [/SeparatorTemp*/, 'SeparatorTemp'],
  [/DifferentialPsi*/, 'DifferentialPsi'],
  [/H2OPerHr*/, 'H2OPerHr'],
  [/OilPerHr*/, 'OilPerHr'],
  [/Chlorides*/, 'Chlorides'],
  [/FluidWeight*/, 'FluidWeight'],
  [/SandSizeDownstream*/, 'SandSizeDownstream'],
  [/SandWeightDownstream*/, 'SandWrightDownstream'],
  [/SandVolumeDownstream*/, 'SandVolumeUpstream'],
  [/DamagesChargebacksDownstream*/, 'DamagesChargebacksDownstream'],
  [/PriceDownstream*/, 'PriceDownstream'],
  [/ProducedGasRate*/, 'ProducedGasRate'],
  [/WelheadTemp*/, 'WellheadTemp'],
  [/ProductionTemp*/, 'ProductionTemp'],
  [/DifferentialPressureSales*/, 'DifferentialPressureSales'],
  [/LinePress*/, 'LinePress'],
  [/H2S*/, 'H2S'],
  [/CO2*/, 'CO2'],
  [/FluidDensity*/, 'FluidDensity'],
  [/SolidsHeight*/, 'SolidsHeight'],
  [/SolidsWeight*/, 'Solids'],
  [/TubingTorque*/, 'TubingTorque'],
  [/TubingWeight*/, 'TubingWeight'],
  [/PickupSetdown*/, 'PickupSetdown'],
  [/GasInFlowback*/, 'GasInFlowback'],
  [/FreeTorque*/, 'FreeTorque'],
  [/StallsBetweenPlug*/, 'StallsBetweenPlug'],
  [/DrillTime*/, 'DrillTime'],
];

export const wellKNownMeasurementsThemeId = 'wellKnownMeasurementsTheme';

export const wellKnownMeasurementsEditorRules = [
  { token: 'PlugNumber', foreground: '008800', fontStyle: 'bold' },
  { token: 'Depth', foreground: '008800', fontStyle: 'bold' },
  { token: 'SweepVolume', foreground: '008800', fontStyle: 'bold' },
  { token: 'FunnelViscosity', foreground: '008800', fontStyle: 'bold' },
  { token: 'FunnelViscositySlw', foreground: '008800', fontStyle: 'bold' },
  { token: 'FunnelViscositySwp', foreground: '008800', fontStyle: 'bold' },
  { token: 'CirculatingPressure', foreground: '008800', fontStyle: 'bold' },
  { token: 'WellheadPressure', foreground: '008800', fontStyle: 'bold' },
  { token: 'PumpRate', foreground: '008800', fontStyle: 'bold' },
  { token: 'FlowbackRate', foreground: '008800', fontStyle: 'bold' },
  { token: 'AnnularVelocity', foreground: '008800', fontStyle: 'bold' },
  { token: 'SlwRe', foreground: '008800', fontStyle: 'bold' },
  { token: 'FluidBalance', foreground: '008800', fontStyle: 'bold' },
  { token: 'CtRunningLength', foreground: '008800', fontStyle: 'bold' },
  { token: 'SolidsPlugParts', foreground: '008800', fontStyle: 'bold' },
  { token: 'Weight', foreground: '008800', fontStyle: 'bold' },
  { token: 'Ph', foreground: '008800', fontStyle: 'bold' },
  { token: 'Fann35ViscositySlw', foreground: '008800', fontStyle: 'bold' },
  { token: 'Fann35ViscositySwp', foreground: '008800', fontStyle: 'bold' },
  { token: 'ReelSpeed', foreground: '008800', fontStyle: 'bold' },
  { token: 'ChokeUpstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'DumpIntervalUpstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'CasingTubingProduction', foreground: '008800', fontStyle: 'bold' },
  { token: 'CasingTubingINT', foreground: '008800', fontStyle: 'bold' },
  { token: 'CasingTubingSurface', foreground: '008800', fontStyle: 'bold' },
  { token: 'ManifoldPressure', foreground: '008800', fontStyle: 'bold' },
  { token: 'SandSizeUpstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'SandVolumeUpstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'DamagesChargebacksUpstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'PriceUpstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'ChokeDownstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'DumpIntervalDownstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'SeparatorPress', foreground: '008800', fontStyle: 'bold' },
  { token: 'Accumulator', foreground: '008800', fontStyle: 'bold' },
  { token: 'DifferentialPsi', foreground: '008800', fontStyle: 'bold' },
  { token: 'H2OPerHr', foreground: '008800', fontStyle: 'bold' },
  { token: 'OilPerHr', foreground: '008800', fontStyle: 'bold' },
  { token: 'Chlorides', foreground: '008800', fontStyle: 'bold' },
  { token: 'FluidWeight', foreground: '008800', fontStyle: 'bold' },
  { token: 'SandSizeDownstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'SandWeightDownstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'SandVolumeDownstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'DamagesChargebacksDownstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'PriceDownstream', foreground: '008800', fontStyle: 'bold' },
  { token: 'ProdducedGasRate', foreground: '008800', fontStyle: 'bold' },
  { token: 'WellheadTemp', foreground: '008800', fontStyle: 'bold' },
  { token: 'ProductionTemp', foreground: '008800', fontStyle: 'bold' },
  { token: 'DifferentialPressureSales', foreground: '008800', fontStyle: 'bold' },
  { token: 'LinePress', foreground: '008800', fontStyle: 'bold' },
  { token: 'H2S', foreground: '008800', fontStyle: 'bold' },
  { token: 'CO2', foreground: '008800', fontStyle: 'bold' },
  { token: 'FluidDensity', foreground: '008800', fontStyle: 'bold' },
  { token: 'SolidsHeight', foreground: '008800', fontStyle: 'bold' },
  { token: 'SolidsWeight', foreground: '008800', fontStyle: 'bold' },
  { token: 'TubingTorque', foreground: '008800', fontStyle: 'bold' },
  { token: 'TubingWeight', foreground: '008800', fontStyle: 'bold' },
  { token: 'PickupSetdown', foreground: '008800', fontStyle: 'bold' },
  { token: 'GasInFlowback', foreground: '008800', fontStyle: 'bold' },
  { token: 'FreeTorque', foreground: '008800', fontStyle: 'bold' },
  { token: 'StallsBetweenPlug', foreground: '008800', fontStyle: 'bold' },
  { token: 'DrillTime', foreground: '008800', fontStyle: 'bold' },
];

export function provideCompletionItemsFunction() {
  return [
    {
        label: 'PlugNumber',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Plug Number'
    },
    {
        label: 'Depth',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Depth in Length'
    },
    {
        label: 'SweepVolume',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Sweeep Volume'
    },
    {
        label: 'FunnelViscosity',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Funnel Viscosity'
    },
    {
        label: 'FunnelViscositySlw',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Funnel Viscosity for SLW'
    },
    {
        label: 'FunnelViscositySwp',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Funnel Viscosity for SWP'
    },
    {
        label: 'CirculatingPressure',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Circulating Pressure'
    },
    {
        label: 'WellheadPressure',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Wellhead Pressure'
    },
    {
        label: 'PumpRate',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Pump Rate'
    },
    {
        label: 'AnnularVelocity',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Annular Velocity'
    },
    {
        label: 'SlwRe',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Called Pump Rate'
    },
    {
        label: 'FluidBalance',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Fluid Balance'
    },
    {
        label: 'CtRunningLength',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Ct Running Length'
    },
    {
        label: 'SolidsPlugParts',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Solid Plug Parts'
    },
    {
        label: 'SolidsSandAndOther',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Solid Sands & Other'
    },
    {
        label: 'Weight',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Weight'
    },
    {
        label: 'Ph',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'PH'
    },
    {
        label: 'Fann35ViscositySlw',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Fann 35 Viscosity for SLW'
    },
    {
        label: 'Fann35ViscositySwp',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Fann 35 Viscosity for Swp'
    },
    {
        label: 'ReelSpeed',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Reel Speed'
    },
    {
        label: 'ChokeUpstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Choke (Upstream)'
    },
    {
        label: 'DumpIntervalUpstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Dump Interval (Upstream) '
    },
    {
        label: 'CasingTubingProduction',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Casing/Tubing (Production)'
    },
    {
        label: 'CasingTubingINT',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Casing/Tubing (INT)'
    },
    {
        label: 'CasingTubingSurface',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Casing/Tubing Surface'
    },
    {
        label: 'ManifoldPressure',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Manifold Pressure'
    },
    {
        label: 'SandSizetUpstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Sand Size (Upstream)'
    },
    {
        label: 'SandVolumeUpstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Sand Volume (Upstream)'
    },
    {
        label: 'DamagesChargebacksUpstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Damages/Chargebacks (Upstream)'
    },
    {
        label: 'PriceUpstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Price (Upstream)'
    },
    {
        label: 'ChokeDownstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Choke (Downstream)'
    },
    {
        label: 'DumpIntervalDownstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Dump Interval (Downstream)'
    },
    {
        label: 'SeparatorPress',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Separator Pressure'
    },
    {
        label: 'DifferentialPsi',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Differential Pressure'
    },
    {
        label: 'Accumulator',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Accumulator'
    },
    {
        label: 'SeparatorTemp',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Separator Temperature'
    },
    {
        label: 'H2OPerHr',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Water Per Hour'
    },
    {
        label: 'OilPerHr',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Oil per Hour'
    },
    {
        label: 'FluidWeight',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Fluid Weight'
    },
    {
        label: 'SandSizeDownstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Sand Size (Downstream)'
    },
    {
        label: 'SandWeightDownstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Sand Weight (Downstream)'
    },
    {
        label: 'SandVolumeDownstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Sand Volume (Downstream)'
    },
    {
        label: 'DamagesChargebacksDownstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Damages/Chargebacks (Downstream)'
    },
    {
        label: 'PriceDownstream',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Price (Downstream)'
    },
    {
        label: 'ProducedGasRate',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Produced Gas Rate'
    },
    {
        label: 'Chlorides',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Chlorides'
    },
    {
        label: 'WellheadTemp',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Wellhead Temperature'
    },
    {
        label: 'ProductionTemp',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Production Temperature'
    },
    {
        label: 'DifferentialPressureSales',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Differential Pressure (Sales Meter)'
    },
    {
        label: 'LinePress',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Line Pressure'
    },
    {
        label: 'H2S',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'H2S'
    },
    {
        label: 'CO2',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'CO2'
    },
    {
        label: 'FluidDensity',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Fluid Density'
    },
    {
        label: 'SolidsHeight',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Solids Height'
    },
    {
        label: 'SolidsWeight',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Solids Weight'
    },
    {
        label: 'TubingTorque',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Tubing Torque'
    },
    {
        label: 'TubingWeight',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Tubing Weight'
    },
    {
        label: 'PickupSetdown',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'PickupSetdown'
    },
    {
        label: 'GasInFlowback',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Gas Present in Flowback',
    },
    {
        label: 'FreeTorque',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Free Torque',
    },
    {
        label: 'StallsBetweenPlug',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Stalls Between Plug',
    },
    {
        label: 'DrillTime',
        kind: (<any>window).monaco.languages.CompletionItemKind.Text,
        documentation: 'Drill Time',
    },
];
}

export function monacoLoadFunction() {
  (<any>window).monaco.languages.register({ id: wellKnownMeasurementsLanguageId });
      (<any>window).monaco.languages.setMonarchTokensProvider(wellKnownMeasurementsLanguageId, {
          tokenizer: {
              root: wellKnownMeasurementsLanguageTokens
          }
      });
      (<any>window).monaco.editor.defineTheme(wellKNownMeasurementsThemeId, {
          base: 'vs',
          inherit: false,
          rules: wellKnownMeasurementsEditorRules
      });
      (<any>window).monaco.languages.registerCompletionItemProvider(wellKnownMeasurementsLanguageId, {
        provideCompletionItems: provideCompletionItemsFunction
    });
}

export const wellKnownMeasurementsMonacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad: monacoLoadFunction
};
