import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CompanyAssociationRequest, RequestStatuses, UserRoles, User } from 'src/app/models/models';
import { ICompanyAssociationRequestService,
         IUserService,
         ICompanyService,
         IOpsViewerMessagesService,
         IApplicationContextService } from 'src/app/services/services';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-association-request',
  templateUrl: './company-association-request.component.html',
  styleUrls: ['./company-association-request.component.css']
})
export class CompanyAssociationRequestComponent implements OnInit, OnChanges {

  @Input() user: User;
  @Input() dialogOnly: boolean;
  @Output() error = new EventEmitter();
  private _user: User;
  requests: CompanyAssociationRequest[] = [];
  requestCols: any[] = [];
  _requestsReady = false;
  _requestNamesUpdated = false;

  companies: SelectItem[] = [];
  _companiesReady = false;

  userReady = false;

  users: SelectItem[] = [];
  _usersReady = false;

  roles: SelectItem[] = [
    { label: UserRoles.admin, value: UserRoles.admin },
    { label: UserRoles.editor, value: UserRoles.editor },
    { label: UserRoles.reader, value: UserRoles.reader }
  ];

  loading = true;

  showRejectDialog = false;
  _requestToReject: CompanyAssociationRequest;
  rejectReason: string;

  showAddCompanyAssociationRequest = false;
  requestToAdd = new CompanyAssociationRequest();
  requestToAddModelValid = true;


  constructor(
    private _companyAssociationRequestService: ICompanyAssociationRequestService,
    private _userService: IUserService,
    private _companyService: ICompanyService,
    private _confirmationService: ConfirmationService,
    private _messageService: IOpsViewerMessagesService,
    private _applicationContextService: IApplicationContextService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.setRequestCols();
    this.getCompanies();
    if (this.dialogOnly) {
      this.addRequest();
    }
  }

  ngOnChanges() {
    // Only changes is userId
    if (this.user && (this._user !== this.user)) {
      this._user = this.user;
      this.userReady = true;
      this.getRequests();
    }
  }

  setRequestCols() {
    this.requestCols = [
      { field: 'companyName', header: 'Company' },
      { field: 'userName', header: 'User' },
      { field: 'role', header: 'Role' },
      { field: 'requestedByName', header: 'Requested By' },
      { field: 'status', header: 'Status' },
      { field: '', header: '' },
    ];
  }

  getRequests() {
    this._requestsReady = false;
    this._companyAssociationRequestService.getCompanyAssociationRequests().subscribe(
      results => {
        if (results && results.length > 0) {
          this.requests = results;
          this._requestsReady = true;
          const distinctUserIds = this.getUserIdsToGetNamesFor();
          this.getUsers(distinctUserIds);
        } else {
          this._requestNamesUpdated = true;
          this._requestsReady = true;
          this._usersReady = true; // No users to get
        }
        this.checkIfEvertythingReady();
      },
      error => {
        this.handleError(error, 'Could not get Company Association Requests');
      }
    );
  }

  getCompanies() {
    this._companyService.getCompanyNames().subscribe(
      results => {
        if (results) {
          results.map(r => {
            this.companies.push({ label: r.companyName, value: r.companyId });
          });
        }
        this._companiesReady = true;
        this.checkIfCanSetNames();
      },
      error => {
        this.handleError(error, 'Could not get companies');
      }
    );
  }

  getUsers(distinctUserIds: string[]) {
    if (distinctUserIds) {
      this.users = [];
      distinctUserIds.map(u => {
        this._userService.getUserName(u).toPromise().then(
          user => {
            if (user) {
              this.users.push({ label: user.userName, value: user.userId });
            } else {
              this.users.push({ label: 'unknown', value: 'unknown'});
            }
            if (this.users.length === distinctUserIds.length) {
              this._usersReady = true;
              this.checkIfCanSetNames();
            }
          }
        );
      });
    }
  }

  checkIfCanSetNames() {
    if (this._companiesReady && this._requestsReady && this._usersReady) {
      this.setRequestNames();
    }
  }

  setRequestNames() {
    this._requestNamesUpdated = false;
    this.addRequestedByNameToRequests();
  }

  getUserIdsToGetNamesFor(): string[] {
    const distinctUserIds: string[] = [];
    this.requests.map(r => {
      if (!distinctUserIds.includes(r.requestedById)) {
        distinctUserIds.push(r.requestedById);
      }
      if (!distinctUserIds.includes(r.userId)) {
        distinctUserIds.push(r.userId);
      }
    });
    return distinctUserIds;
  }

  addRequestedByNameToRequests() {
    this.requests.map(r => {
      const companyFound = this.companies.find(c => c.value === r.companyId);
      if (companyFound) {
        r.companyName = companyFound.label;
      }
      const userFound = this.users.find(u => u.value === r.userId);
      if (userFound) {
        r.userName = userFound.label;
      }
      const requestedUserFound = this.users.find(u => u.value === r.requestedById);
      if (requestedUserFound) {
        r.requestedByName = requestedUserFound.label;
      }
    });
    this._requestNamesUpdated = true;
    this.checkIfEvertythingReady();
  }

  checkIfEvertythingReady() {
    this.loading = !this._companiesReady ||
      !this._requestsReady ||
      !this._usersReady ||
      !this._requestNamesUpdated;
  }

  confirmApprove(request: CompanyAssociationRequest) {
    this._confirmationService.confirm({
      key: 'confirmationDialog',
      header: 'Approve Confirmation',
      message: 'Are you sure you want to Approve ' + request.userName + ' ?',
      icon: 'fa fa-question-circle',
      accept: () => { this.approve(request); }
    });
  }

  approve(request: CompanyAssociationRequest) {
    this._applicationContextService.showApplicationSpinner(false);
    this._companyAssociationRequestService.approveCompanyAssociationRequest(request.id).subscribe(
      result => {
        request.status = RequestStatuses.Approved;
        this._applicationContextService.hideApplicationSpinner();
        this._messageService.successMessage('Company Association Request successfully Approved.', '');
        this._applicationContextService.alertRequestsUpdated();
        this._applicationContextService.alertCompaniesListUpdated();
      },
      error => {
        this._applicationContextService.hideApplicationSpinner();
        this.handleError(error, 'Unable to Approve Requeest');
      }
    );
  }

  confirmReject(request: CompanyAssociationRequest) {
    this.rejectReason = null;
    this.showRejectDialog = true;
    this._requestToReject = request;
  }

  cancelReject() {
    this.showRejectDialog = false;
    this._requestToReject = null;
  }

  reject() {
    if (this.rejectReason) {
      this._applicationContextService.showApplicationSpinner(false);
      this._companyAssociationRequestService.rejectCompanyAssociationRequest(this._requestToReject.id, this.rejectReason).subscribe(
        result => {
          const request = this.requests.find(r => r.id === this._requestToReject.id);
          request.status = RequestStatuses.Rejected;
          this.showRejectDialog = false;
          this._requestToReject = null;
          this._applicationContextService.hideApplicationSpinner();
          this._messageService.successMessage('Company Association Request successfully Rejected.', '');
          this._applicationContextService.alertRequestsUpdated();
        },
        error => {
          this._applicationContextService.hideApplicationSpinner();
          this.handleError(error, 'Unable to Reject Request');
          this.showRejectDialog = false;
          this._requestToReject = null;
        }
      );
    }
  }

  addRequest() {
    this.requestToAdd = new CompanyAssociationRequest();
    this.requestToAddModelValid = true;
    this.showAddCompanyAssociationRequest = true;
  }

  saveAdd() {
    this.autoSetRequestProperties();
    this.requestToAddModelValid = this.validateAddModel();
    if (this.requestToAddModelValid) {
      this._applicationContextService.showApplicationSpinner(false);
      this._companyAssociationRequestService.createCompanyAssociationRequest(this.requestToAdd).subscribe(
        result => {
          this.showAddCompanyAssociationRequest = false;
          this._applicationContextService.hideApplicationSpinner();
          this._messageService.successMessage('Company Association Request successfully submitted.', '');
          this._applicationContextService.alertRequestsUpdated();
          // In case of auto approval:
          this._applicationContextService.alertCompaniesListUpdated();
          if (this.dialogOnly) {
            this.navigateHome();
          } else {
            this.getRequests();
          }
        },
        error => {
          this._applicationContextService.hideApplicationSpinner();
          this.handleError(error, 'Unable to create Company Association Request');
        }
      );
    } else {
      this._messageService.warnMessage('Validation Error', 'One or more required input fields invalid');
    }
  }

  autoSetRequestProperties() {
    this.requestToAdd.userId = this.user.id;
    this.requestToAdd.role = UserRoles.reader;
  }

  validateAddModel(): boolean {
    if (!this.requestToAdd.companyId) { return false; }
    if (!this.requestToAdd.userId) { return false; }
    if (!this.requestToAdd.role) { return false; }

    return true;
  }

  cancelAdd() {
    if (this.dialogOnly) {
      this.navigateHome();
    }
    this.showAddCompanyAssociationRequest = false;
  }

  confirmDelete(request: CompanyAssociationRequest) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to Delete request for ' + request.userName + ' ?',
        icon: 'fa fa-question-circle',
        accept: () => { this.delete(request); }
      }
    );
  }

  delete(request: CompanyAssociationRequest) {
    this._applicationContextService.showApplicationSpinner(false);
    this._companyAssociationRequestService.deleteCompanyAssociationRequest(request.id).subscribe(
      result => {
        this._applicationContextService.hideApplicationSpinner();
        this.requests = this.requests.filter(r => r.id !== request.id);
      },
      error => {
        this._applicationContextService.hideApplicationSpinner();
        this.handleError(error, 'Could not delete request');
      }
    );
  }

  handleError(error: any, displayMessage: string) {
    this._messageService.errorMessage(error, displayMessage);
  }

  navigateHome() {
    this._router.navigate(['/home']);
  }

}
