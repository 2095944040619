export class CasingTubings {
  tubing1: CasingTubingDetail;
  tubing2: CasingTubingDetail;
  casing1Vertical: CasingTubingDetail;
  casing1Horizontal: CasingTubingDetail;
  casing2Vertical: CasingTubingDetail;
  casing2Horizontal: CasingTubingDetail;

  constructor() {
    this.tubing1 = null;
    this.tubing2 = null;
    this.casing1Vertical = new CasingTubingDetail();
    this.casing1Horizontal = new CasingTubingDetail();
    this.casing2Vertical = null;
    this.casing2Horizontal = null;
  }
}

export class CasingTubingDetail {
  type: string;
  length: number;
  volume: number;

  constructor() {}
}
