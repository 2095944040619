import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Message, SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { Device, DeviceCategories, Company, User, Features, CompanyName } from '../../../models/models';

import { IDeviceService,
         IUserService,
         ICompanyService,
         IFeaturesService,
         IApplicationContextService,
         IOpsViewerMessagesService } from '../../../services/services';
import { Title } from '@angular/platform-browser';

import { Subscription } from 'rxjs';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { first } from 'rxjs/operators';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'device-list',
    templateUrl: './device-list.component.html',
    styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit, OnDestroy {

    cols: any[] = [];
    devicesReady = false;
    devices: Device[] = [];
    messages: Message[] = [];
    deviceCategories: SelectItem[] = [];
    companies: Company[] = [];
    companiesReady = false;
    isCompanyAdministrationEnabled = false;
    featuresReady = false;
    user: User = null;
    isUserReady = false;
    everythingReady = false;

    private _companyContextSubscription: Subscription;

    constructor(
        private _deviceService: IDeviceService,
        private _companyService: ICompanyService,
        private _userService: IUserService,
        private _featureService: IFeaturesService,
        private _userManagerService: IUserManagerService,
        private _router: Router,
        private _confirmationService: ConfirmationService,
        private _titleService: Title,
        private _applicationContextService: IApplicationContextService,
        private _messageService: IOpsViewerMessagesService) { }

    ngOnInit() {
        this.getFeatures();
        this.setPageTitle();
        this.setColumns();
        this.getDevices();
        this.getCompanies();
        this.getAuthenticatedUser();
        this.listenForCompanyContext();
    }

    ngOnDestroy() {
        this._companyContextSubscription.unsubscribe();
    }

    setPageTitle() {
        this._titleService.setTitle('OpsViewer Devices');
    }

    setColumns() {
        this.cols = [
            { field: 'id', header: 'Id', width: '325px', filterMatchMode: 'contains' },
            { field: 'name', header: 'Name', width: '250px', filterMatchMode: 'contains' },
            { field: 'category', header: 'Category', width: '150px', filterMatchMode: 'in' },
            { field: 'company', header: 'Company', width: '150px', filterMatchMode: 'in' },
            { field: 'active', header: 'Active', width: '100px', filterMatchMode: 'in' },
            { field: '', header: '', width: '60px' }
        ];

        this.deviceCategories = [];
        this.deviceCategories.push({ label: DeviceCategories.DPU, value: DeviceCategories.DPU });
        this.deviceCategories.push({ label: DeviceCategories.Ultralight, value: DeviceCategories.Ultralight });
        this.deviceCategories.push({ label: DeviceCategories.VisionDesktop, value: DeviceCategories.VisionDesktop });
        this.deviceCategories.push({ label: DeviceCategories.FlowBack, value: DeviceCategories.FlowBack });
        this.deviceCategories.push({ label: DeviceCategories.TCP, value: DeviceCategories.TCP });
        
    }

    getIsActive(device:any){
        if (device.category!="TCP") return "";
        return device.tcpDeviceConfiguration.active ? "yes":"no";
    }
    getDevices() {
        this.messages = [];
        this._deviceService.getDevices().subscribe(
            result => {
                this.devices = result;
                this.updateDeviceList();
                this.devicesReady = true;
                this.checkIfEverythingReady();
            },
            error => { this._messageService.errorMessage(error, 'Could not Get Devices'); }
        );
    }

    getCompanies() {
        this._companyService.getCompanies().subscribe(
            results => {
                if (results) {
                    this.companies = results;
                }
                this.companiesReady = true;
                this.checkIfEverythingReady();
            },
            error => { this._messageService.errorMessage(error, 'Could not Get Companies'); }
        );
    }

    getFeatures() {
        this._featureService.isFeatureEnabled(Features.CompanyAdministration).subscribe(
          result => {
            this.isCompanyAdministrationEnabled = result;
            this.updateDeviceList();
            this.featuresReady = true;
            this.checkIfEverythingReady();
          },
          error => { this.handleError(error, 'Could not get features'); }
        );
      }

      getAuthenticatedUser() {

        this._userManagerService.getCurrentUser().subscribe(u=>{
          this.user = u;
          this.isUserReady = true;
          this.checkIfEverythingReady();
        });
      }
 

  listenForCompanyContext() {
    // Initialize
    this.updateDeviceList();

    // Listen for changes to selected company
    this._companyContextSubscription = this._applicationContextService.companyContextObserver.subscribe(
        c => this.updateDeviceList(c));
  }

  updateDeviceList(company: CompanyName = null) {
    if (this.isCompanyAdministrationEnabled) {
      // If not passed in attempt to get current value
      if (!company) {
        company = this._applicationContextService.getCompanyContext();
      }
      // Filter list to selected company
      if (company && this.devices) {
        this.devices = this.devices.filter(d => d.companyId === company.companyId);
      }
    }
  }

    isAdmin(device: Device): boolean {
        if (this.isCompanyAdministrationEnabled) {
            return this._userService.isUserAdmin(this.user, device.companyId, false);
        } else {
            return this._userService.isUserAdmin(this.user, null, false);
        }
    }

    checkIfEverythingReady() {
        this.everythingReady = this.featuresReady && this.devicesReady && this.isUserReady && this.companiesReady;
    }

    navigateToAddDevice() {
        this._router.navigate(['/device/' + encodeURIComponent('new')]);
    }

    navigateToDetail(deviceId: string) {
        this._router.navigate(['/device/' + encodeURIComponent(deviceId)]);
    }

    refresh() {
        this.cols = [];
        this.devicesReady = false;
        this.devices = [];
        this.messages = [];
        this.ngOnInit();
    }

    confirmDelete(device: Device) {
        this._confirmationService.confirm(
            {
                key: 'confirmationDialog',
                header: 'Delete Confirmation',
                message: 'Are you sure you want to delete ' + device.name + '?',
                icon: 'fa fa-question-circle',
                accept: () => { this.deleteDevice(device.id); }// ,
                // reject: () => { }
            }
        );
    }

    deleteDevice(deviceId: String) {
        this._deviceService.deleteDevice(deviceId).subscribe(
            result => {
                this.refresh();
                this._messageService.infoMessage('Device has been deleted', '');
            },
            error => { this._messageService.errorMessage(error, 'Could not Delete Device'); }
        );
    }

    getCompanyName(companyId: string): string {
        const foundCompany = this.companies.find(c => c.id === companyId);
        if (foundCompany) {
            return foundCompany.name;
        }
        return '';
    }

    handleError(error: any, displayMessage: string) {
        this._messageService.errorMessage(error, displayMessage);
    }
}
