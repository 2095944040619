import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { JobWorkbookConfiguration } from '../models/models';

@Injectable()
export abstract class IJobWorkbookConfigurationService {
  abstract getJobWorkbookConfiguration(jobId: string): Observable<JobWorkbookConfiguration>;

  abstract createDefaultJobWorkbookConfiguration(companyId: string, companyUnitSystem: string, jobId: string, jobType: string):
    Observable<JobWorkbookConfiguration>;

  abstract addOrUpdateJobWorkbookConfiguration(jobWorkbookConfiguration: JobWorkbookConfiguration): Observable<any>;
}
