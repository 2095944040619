import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Job } from '../models/models';

@Injectable()
export abstract class IJobService  {
    abstract getJobs(): Observable<Job[]>;

    abstract getJob(jobId: string): Observable<Job>;

    abstract getDashboardLink(jobId: string): Observable<string>;

    abstract createJob(job: Job): Observable<any>;

    abstract updateJob(jobId: string, updatedJob: Job): Observable<any>;

    abstract deleteJob(jobId): Observable<any>;

    abstract republishWorkbook(jobId: string): Observable<any>;

    abstract isUserServiceProvider(jobId: string): Observable<boolean>;

    abstract getCompanyIdForJob(jobId: string): Observable<string>;
}
