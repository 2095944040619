
<div *ngIf="featuresReady" id="content">
    <div [ngClass]="{ 'menu-collapse-btn-collapsed': isCollapsed }">
        <!-- <p-button (onClick)="onCollapsedClick()" icon="fa fa-bars"></p-button> -->
      <a class="menu-item" (click)="onCollapsedClick()">
        <span class="fa fa-bars"></span>
      </a>
    </div>
    <div *ngIf="userProfileUiEnabled" [ngClass]="{ 'hide-content': isCollapsed }">
      <app-user-profile></app-user-profile>
    </div>
    <div *ngFor="let item of routes" [ngClass]="{ 'hide-content': !isCollapsed && userProfileUiEnabled }">
      <a class="menu-item" (click)="onRouteClick(item)" role="button">
        <span class="{{item.icon}}"></span>
        <span class="item-name">{{item.name}}</span>
        <span *ngIf="item.subroutes.length > 0" class="collapsable glyphicon" [ngClass]="{'glyphicon-menu-down': !item.showSub, 'glyphicon-menu-up': item.showSub}"></span>
      </a>
      <div class="submenu" *ngIf="item.subroutes.length > 0" [ngClass]="{'hidden': !item.showSub}">
        <a *ngFor="let subroute of item.subroutes" class="submenu-item" (click)="onRouteClick(subroute)" role="button">
          <span class="item-name">{{subroute.name}}</span>
        </a>
      </div>
    </div>
    <div *ngIf="companyAdministrationFeatureEnabled && isCollapsed">
      <a class="menu-item" routerLink="/requests" role="button">
        <span class="far fa-envelope"></span>
        <span class="item-name">Requests</span>
      </a>
    </div>
  </div>