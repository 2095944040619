import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConsumerJob } from '../models/models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { IConsumerJobService } from './iConsumerJob.service';

@Injectable()
export class ConsumerJobService extends IConsumerJobService {

  constructor(
    private _http: HttpClient
  ) {
    super();

  }
  getJobs(): Observable<ConsumerJob[]> {
    return this._http.get<ConsumerJob[]>('api/consumerjob/');
  }
  getJob(jobId: string): Observable<ConsumerJob> {
    return this._http.get<ConsumerJob>('api/consumerjob/' + jobId);
  }
  associateVendorToConsumerJob(consumerJobId: string, vendorId: string): Observable<any> {
    return this._http.post('api/consumerjob/associateVendor/' + consumerJobId + '/' + vendorId, null);
  }
  deassociateVendorToConsumerJob(consumerJobId: string, vendorId: string): Observable<any> {
    return this._http.post('api/consumerjob/deassociateVendor/' + consumerJobId + '/' + vendorId, null);
  }
  deassociateVendorJobToConsumerJob(consumerJobId: string, vendorJobId: string): Observable<any> {
    return this._http.post('api/consumerjob/deassociateVendorJob/' + consumerJobId + '/' + vendorJobId, null);
  }
  updateConsumerJob(consumerJob: ConsumerJob): Observable<any> {
    return this._http.post('api/consumerjob/', consumerJob);
  }
  deleteConsumerJob(consumerJobId: string): Observable<any> {
    return this._http.delete('api/consumerjob/' + consumerJobId);
  }
  getDashboardLink(consumerJobId: string): Observable<string> {
    return this._http.get<string>('api/consumerjob/dashboardlink/' + consumerJobId);
  }
}
