import { Injectable } from '@angular/core';
import { PlugSet } from '../models/models';

@Injectable()
export abstract class IImportPlugDataService {
  abstract autoChooseImportMethod(plugSets: PlugSet[], importData: string): PlugSet[];
  abstract parseData(importData: string): PlugSet[];
  abstract parseDataNoImportGroups(plugSets: PlugSet[], importData: string): PlugSet[];
  abstract getGroupName(plugSet: PlugSet): string;
}
