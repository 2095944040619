import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'fileName'
})
export class FileNamePipe implements PipeTransform {
    
    transform(blobFileName: string): string {
        if (blobFileName.startsWith('global/')) {
            return blobFileName.substring('global/'.length);
        }

        if (blobFileName.startsWith('job/')) {
            const jobFileNameSample = 'job/5012D80F-5CA8-55C7-85CD-9CED2CDD4EF4/'
            return blobFileName.substring(jobFileNameSample.length);
        }

        return blobFileName;
    }

}