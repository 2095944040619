import { Component, Inject, ViewChild } from '@angular/core';
import { ConsumerJob } from 'src/app/models/consumerJob';
import { JobCacheService } from 'src/app/services/job-cache.service';
import { IJobTicketClient, Ticket, VendorJob } from 'src/app/services/nswag/nswagclient';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { BlobClient, BlobServiceClient, BlockBlobClient, ContainerClient, Metadata } from '@azure/storage-blob';
import { AppConfig } from 'src/app/models/AppConfig';
import { IOpsViewerMessagesService } from 'src/app/services/iOpsViewerMessages.service';
import {ticket} from './ticket';
import { IApplicationContextService } from 'src/app/services/iApplication-context.service';
import { map, mergeMap, take } from 'rxjs/operators';
import { forkJoin, Observable, combineLatest } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { fork } from 'child_process';
@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.css']
})
export class TicketListComponent {

  @ViewChild(FileUpload)
  _fileUploader: FileUpload;

  constructor(
    private _jobsCacheService: JobCacheService, 
    private _messageService: IOpsViewerMessagesService, 
    @Inject('IJobTicketClient') private _jobTicketClient: IJobTicketClient,
    private _applicationContextService: IApplicationContextService,
    private _currentUserService: ICurrentUserService,
    private confirmationService: ConfirmationService) {
      let jobs$ =this._applicationContextService.companyContextObserver.pipe(mergeMap(c=>this._jobsCacheService.getVendorJobsForVendorCompany(c.companyId)));
      let userToken$ = this._currentUserService.getAccessToken();
      //this.viewModel$ = forkJoin({jobs:this.jobs$, userToken:this.userToken$});
      this.viewModel$ = combineLatest([jobs$, userToken$]).pipe(map(([jobs, userToken])=>({jobs:jobs, userToken:userToken})));
      // this.viewModel$.subscribe(v=>console.log(v));
      // console.log(this.viewModel$);
      //this.jobs$ = this._jobsCacheService.getVendorJobsForVendorCompany("477fd328-3ad9-3a1d-700b-f76aac5c5b75");

  }
  _jobTickets = new Map<string, Promise<Ticket[]>>();
  getJobTickets(jobId:string) : Promise<Ticket[]>{
    
    if (this._jobTickets.has(jobId)) {
      return this._jobTickets.get(jobId);
    }

    this._jobTickets.set(jobId, this.loadTickets2(jobId));
    return this._jobTickets.get(jobId);
  }
 
  viewModel$:Observable<{jobs:VendorJob[],userToken:string}>;

  async delete(ticket:Ticket, jobId:string){

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure that you want to delete this job ticket?',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        const blockBlobClient = new BlockBlobClient(ticket.fileUri);
        await blockBlobClient.deleteIfExists();
        this._jobTickets.delete(jobId);
      },
      reject: () => {
          
      }
  });

    
  }

  async loadTickets2(jobId:string) : Promise<Ticket[]> {
  
    var ts = await this._jobTicketClient.getJobTicketsForJob(jobId).toPromise();
    
    console.log(ts);
    return ts;
  }




   async updateAccounting(ticket:Ticket){
    const blockBlobClient = new BlockBlobClient(ticket.fileUri);
  
     await blockBlobClient.setMetadata(this.getMetaData(ticket));
   
   }

   getMetaData(ticket:Ticket) : Metadata{
    return {ticketName: ticket.ticketName, field: ticket.field.toString(), accounting: ticket.accounting.toString()};
    }

   async updateField(ticket:Ticket){
    const blockBlobClient = new BlockBlobClient(ticket.fileUri);
    await blockBlobClient.setMetadata(this.getMetaData(ticket));
  }

   async upload2(event, jobId: string) {
    console.log(jobId);
    
    for (let i=0;i<event.files.length;i++) {
      const file = event.files[i];
      let url = await this._jobTicketClient.getUploadUrl(jobId, file.name).toPromise();
    
      const blockBlobClient = new BlockBlobClient(url);
      this._fileUploader.uploading = true;
      await blockBlobClient.uploadBrowserData(file, {blobHTTPHeaders:{blobContentType:file.type}}); try {
        let ticketName:string;
        ticketName = file.ticketName||"";
        ticketName = ticketName.replace(/([\n])/g,"\\n");
        await blockBlobClient.setMetadata({ticketName: ticketName });
      } catch(err){
        this._messageService.errorMessage(err, "could not set ticketName. It may be too long");
        throw err;
      }
      this._fileUploader.clear();
      this._fileUploader.uploading = false;
    }
    this._jobTickets.delete(jobId);
    this._messageService.successMessage("Upload", "File Uploaded");
  }
  // getContainerClient(): ContainerClient {
  //   let url:string = AppConfig.GLOBAL_CONFIG.storageUri;
  //    const blobServiceClient = new BlobServiceClient(url);
  //    const containerClient = blobServiceClient.getContainerClient('files');
  //    return containerClient;
  //  }
  // async upload(event, jobId: string) {
  //   console.log(jobId);
  //   const containerClient = this.getContainerClient();
  //   for (let i=0;i<event.files.length;i++) {
  //     const file = event.files[i];
  //    // const fileNamePrefix = this.isGlobal ? 'global/' : 'job/' + this.jobId + '/';
  //    const fileNamePrefix = `ticket/${jobId}/`;
       
  //    const blockBlobClient = containerClient.getBlockBlobClient(fileNamePrefix + file.name);
  //     this._fileUploader.uploading = true;
  //     await blockBlobClient.uploadBrowserData(file, {blobHTTPHeaders:{blobContentType:file.type}}); try {
  //       let ticketName:string;
  //       ticketName = file.ticketName||"";
  //       ticketName = ticketName.replace(/([\n])/g,"\\n");
  //       await blockBlobClient.setMetadata({ category: file.category ||"", ticketName: ticketName });
  //     } catch(err){
  //       this._messageService.errorMessage(err, "could not set ticketName. It may be too long");
  //       throw err;
  //     }
  //     this._fileUploader.clear();
  //     this._fileUploader.uploading = false;
  //   }
  //   this._jobTickets.delete(jobId);
  //   this._messageService.successMessage("Upload", "File Uploaded");
  // }
    // async loadTickets(jobId:string) : Promise<ticket[]> {
  //   let containerClient = this.getContainerClient();
  //   const prefix = `ticket/${jobId}/`;
  //   let blobIter = containerClient.listBlobsFlat({prefix: prefix, includeMetadata: true});
  //   //let blobIter = containerClient.listBlobsByHierarchy("/", {prefix: prefix});
  //   let tickets:ticket[] = [];
  //   for await (const item of blobIter) {
   
  //       console.log(`\tBlobItem: name - ${item.name}, last modified - ${item.properties.lastModified}`);
  //       let tick = new ticket();
  //       tick.name = item.name.replace(prefix, "");
  //       console.log(item);
  //       tickets.push(tick);
  //   }
  //   console.log(tickets);
  //   return tickets;
  // }
}
