export class PerformanceSummary { 
    plugsDrilled:number;
    jobTime:number;
    shortTrips:number;
    plugPerShortTrip:number;
    timePerPlug:number;
    totalRunningPipe:number;
    runningFtMdRatio:number;
    productiveFtPerHour:number;

    constructor() {}
}