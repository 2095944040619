import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Device } from '../models/models';

@Injectable()
export abstract class IDeviceService  {
    abstract getDevices(): Observable<Device[]>;

    abstract getDevice(deviceId: string): Observable<Device>;

    abstract createDevice(device: Device): Observable<any>;

    abstract updateDevice(id: string, updatedDevice: Device): Observable<any>;

    abstract deleteDevice(deviceId): Observable<any>;

    abstract getDevicesForCompany(companyId: string): Observable<Device[]>;
}
