import { SimulationActivity, SimulationHeader, Simulation, SimulationExpense, SimulationChemical, SimulationSiteDetails } from "src/app/models/simulation";
import { WellInfo, PlugSet, PerformanceSummary } from "src/app/models/models";
import { SelectItem } from "primeng/api";
import { SimulationCommon } from "./simulationCommon";

export class SimulationExport {
    static activityHeader = 'Activity, Duration, Starting, Starting Depth/Plug, Ending, Ending Depth/Plug, Est. AV, Est. RE, Est. Depth, Est. Rel. Time \r\n';
    static chemicalHeader = 'Chemical, System, Dosage, Cost, Start at, Depth or Plug \r\n';
    static depthsHeader = 'XO/EOT, KOP, 30 Degree, 60 Degree, 90 Deg, PBTD\r\n';
    static expenseHeader = 'Description, Cost/Factor, Factor \r\n';
    static surfaceHeader = 'Well Name, API Number, District, State, County, Field/Formation\r\n'

    // https://stackoverflow.com/questions/18848860/javascript-array-to-csv/18849208#18849208
    static activityToCSVRow(dataObject: SimulationActivity) : string {
        // let dataArray: string[] = [];
        // for (var o in dataObject) {
        //     var result = !dataObject[o] || dataObject[o]===null ? '' : SimulationExport.toStringQuote(dataObject[o]);
        //     dataArray.push(result);
        // }
        // return dataArray.join(', ');
        const activity = [
            dataObject.activity,
            dataObject.duration || 0,
            dataObject.isDepthStart ? 'Depth' : 'Plug',
            dataObject.depthStart || 0,
            dataObject.isDepthEnd ? 'Depth' : 'Plug',
            dataObject.depthEnd || 0,
            dataObject.calcAv || 0,
            dataObject.calcRe || 0,
            dataObject.calcDepth || 0,
            dataObject.calcRelativeTime || 0,
        ];
        return activity.map(SimulationExport.toStringQuote).join(', ');
    }

    static chemicalToCSVRow(chem: SimulationChemical) : string {
        const data = [
            chem.chemicalId,
            chem.chemicalFluidSystemId,
            chem.dosage,
            chem.cost,
            chem.hasDepth ? 'Depth' : 'Plug',
            chem.depthOrPlug,
        ];
        return data.map(SimulationExport.toStringQuote).join(', ');
    }

    static costEstimateCsvBlock(chemicalCost: number, serviceCost: number) : string {
        return `\r\nChemical Cost, ${SimulationExport.toStringQuote(chemicalCost || 0)} \r\n`
            + `Service Cost, ${SimulationExport.toStringQuote(serviceCost || 0)} \r\n`
            + `Total Cost, ${SimulationExport.toStringQuote(chemicalCost + serviceCost)} \r\n`
    }

    static expenseToCSVRow(expense: SimulationExpense) : string {
        const data = [
            expense.description,
            expense.costPerFactor,
            expense.expenseCostFactorId,
        ]
        return data.map(SimulationExport.toStringQuote).join(', ');
    }

    static async exportToCSV(arrayOfObjects: SelectItem[]) {
        if (!arrayOfObjects.length) {
            return;
        }
        var csvContent = "data:text/csv;charset=utf-8,";
    
        // sections
        csvContent += arrayOfObjects.map(i => `\r\n"${i.label}"\r\n${i.value}`).join('\r\n');
    
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "simulation.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
    }

    static jobTotalsCsvBlock(act: SimulationActivity) : string {
        return `Job Time, ${SimulationExport.toStringQuote(act.calcRelativeTime)} \r\n`
            + `Pumped (BBLs), ${SimulationExport.toStringQuote(act.calcPumpedBbls)} \r\n`
            + `Flowback (BBLs), ${SimulationExport.toStringQuote(act.calcFlowbackBbls)} \r\n`;
    }

    static headerCsvBlock(hdr: SimulationHeader) : string {
        let customerName = '';
        if(hdr.customer) {
            customerName = hdr.customer.companyName;
        }
        return `Customer:, ${SimulationExport.toStringQuote(customerName)} \r\n`
            + `Version:, ${SimulationExport.toStringQuote(hdr.majorVersion)}, ${SimulationExport.toStringQuote(hdr.minorVersion)}, ${SimulationExport.toStringQuote(hdr.revisionVersion)}`;
    }

    static siteDetailsCsvBlock(site: SimulationSiteDetails) : string {
        const surfaceData = [
            site.wellName,
            site.api,
            site.districtId,
            site.stateId,
            site.countyId,
            site.field,
        ];
        return SimulationExport.surfaceHeader
            + surfaceData.map(SimulationExport.toStringQuote).join(', ') + '\r\n\r\n'
            + `Procedure, ${SimulationExport.toStringQuote(site.procedure)} \r\n`
            + `WellType, ${SimulationExport.toStringQuote(site.wellType)}`;
    }

    static statisticsCsvBlock(sta: PerformanceSummary) : string {
        return `Total Short Trips, ${SimulationExport.toStringQuote(sta.shortTrips)} \r\n`
            + `Plugs per Short Trip, ${SimulationExport.toStringQuote(sta.plugPerShortTrip)} \r\n`
            + `Time per Plug, ${SimulationExport.toStringQuote(sta.timePerPlug)} \r\n`
            + `Total Running Pipe Feet, ${SimulationExport.toStringQuote(sta.totalRunningPipe)} \r\n`
            + `Running Ft/MD Ratio, ${SimulationExport.toStringQuote(sta.runningFtMdRatio)} \r\n`
            + `Productive Ft per Hour, ${SimulationExport.toStringQuote(sta.productiveFtPerHour)} \r\n`;
    }

    static toStringQuote(obj: any) : string {
        if(!obj) {
            if(obj === 0) {
                return '0';
            }
            return '';
        }
        let innerValue: string = obj.toString();
        if(innerValue.indexOf('\"') >= 0) {
            innerValue.replace(/"/g, '""');
            return '"' + innerValue + '"';
        }
        return innerValue;
    }

    static wellInfoToCsvBlock(wellInfo: WellInfo) : string {
        let wellDepths = [
            wellInfo.xoEot,
            wellInfo.kop,
            wellInfo.thirtyDegree,
            wellInfo.sixtyDegree,
            wellInfo.tvd,
            wellInfo.md,
        ];
        return '\r\n"General Info"\r\n'
            + SimulationExport.depthsHeader
            + wellDepths.map(SimulationExport.toStringQuote).join(', ') + '\r\n\r\n'
            + `Workstring Type, ${SimulationExport.toStringQuote(wellInfo.stringType)} \r\n`
            + `Tubing OD, ${SimulationExport.toStringQuote(wellInfo.ctWoTubingOd)} \r\n`
            + `Capacity (BBLS), ${SimulationExport.toStringQuote(wellInfo.coilCapacity)} \r\n`
            + `Frac Stages, ${SimulationExport.toStringQuote(wellInfo.fracPlugStages)} \r\n`
            + `SITP, ${SimulationExport.toStringQuote(wellInfo.sitp)} \r\n`
            + `SICP, ${SimulationExport.toStringQuote(wellInfo.sicp)} \r\n`
            + `Number of Plugs, ${SimulationExport.toStringQuote(SimulationCommon.getTotalPlugs(wellInfo.plugSets) || 0)} \r\n`;
    }
    // + `, ${SimulationExport.toStringQuote()} \r\n`
}