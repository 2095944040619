import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { SimulationHeader } from 'src/app/models/simulation';

@Component({
  selector: 'simulation-header',
  templateUrl: './header.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() onNewVersion = new EventEmitter();

  header: SimulationHeader;
  consumerCompanies: SelectItem[] = [];

  constructor() { }

  ngOnInit() {
    this.header = <SimulationHeader> { };
  }

  /* Send an event to request a new version */
  requestNewVersion() {
    this.onNewVersion.emit();
  }
}
