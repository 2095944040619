<div class="ui-g ui-fluid config-table">
    <div class="grid field col-12">
        <p-table #dt [columns]="measurementConfigurationCols" [value]="deviceMeasurementChannelConfigurations"
          [scrollable]="true" class="config-table" sortField="name">
          <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                  <col *ngFor="let col of columns" [style.width]="col.width" />
              </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
              <tr>
                  <th *ngFor="let col of columns">
                      {{col.header}}
                  </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-configuration let-index="rowIndex">
              <tr [pReorderableRow]="index">
                  <td>
                      <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="configuration.measurementDisplayName" 
                        (change)="displayNameChanged(configuration)"/>
                  </td>
                  <td>
                      <p-dropdown [options]="wellKnownMeasurementOptions" placeholder="Select Known Measurement"
                          [(ngModel)]="configuration.wellKnownMeasurementName" appendTo="body" (onChange)="wellKnownMeasurementChanged(configuration)"
                          [style]="{'width':'100%'}"></p-dropdown>
                  </td>
                  <td>
                      <p-dropdown [options]="measurementDataTypes" placeholder="Select Type" [(ngModel)]="configuration.measurementDataType"
                          appendTo="body" (onChange)="measurementTypeChanged(configuration)" [style]="{'width':'100%'}"></p-dropdown>
                  </td>
                  <td>
                      <p-dropdown [options]="configuration.unitOptions" placeholder="Select Units" 
                          [disabled]="configuration.measurementDataType == 'Text'"
                          [(ngModel)]="configuration.unitOfMeasurementId" appendTo="body" [style]="{'width':'100%'}"
                          (onChange)="unitsChanged(configuration)"></p-dropdown>
                  </td>
                  <td>
                      <i class="fas fa-sort" style="margin-right:2px;" pReorderableRowHandle></i>
                      <i class="far fa-trash-alt" (click)="confirmDeleteConfiguration(configuration)"></i></td>
              </tr>
          </ng-template>
          <ng-template pTemplate="summary">
              <button pButton type="button" label="Add Configuration" (click)="addConfiguration()" icon="fa fa-plus"
                  class="add-button"></button>
          </ng-template>
        </p-table>
</div>
