<div class="grid field col-12">
    <div class="grid field col-12" *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>
    <p-progressSpinner *ngIf="!everythingReady"></p-progressSpinner>
    <div class="grid field col-12" *ngIf="everythingReady">
            <div class="col-12 md:col-10"><h2 style="margin-bottom:2px; padding-bottom:0px;">Devices</h2></div>
        <div class="col-12 md:col-2">
            <button pButton type="button" label="Add" (click)="navigateToAddDevice()" icon="fa fa-plus" style="width:100px; margin-top:20px; float:right; vertical-align:bottom;"></button>
        </div>
        <div class="col-12">
                <p-table #dt [columns]="cols" [value]="devices" [scrollable]="true" [paginator]="true" [rows]="25"[loading]="!devicesReady">
                    <!-- <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col *ngFor="let col of columns" [style.width]="col.width" />
                        </colgroup>
                    </ng-template> -->
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                {{col.header}}
                                <p-sortIcon *ngIf="col.field != ''" [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" pResizableColumn>
                                <div *ngSwitchCase="'id'">
                                    <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"
                                    />
                                </div>
                                <div *ngSwitchCase="'name'">
                                    <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"
                                    />
                                </div>
                                <p-multiSelect *ngSwitchCase="'category'" [options]="deviceCategories" defaultLabel="Categories" (onChange)="dt.filter($event.value, col.field, col.filterMatchMode)"
                                    appendTo="body"></p-multiSelect>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-device>
                        <tr>
                            <td class="ui-resizable-column">{{device.id}}</td>
                            <td class="ui-resizable-column">{{device.name}}</td>
                            <td class="ui-resizable-column">{{device.category}}</td>
                            <td class="ui-resizable-column">{{getCompanyName(device.companyId)}}</td>
                            <td class="ui-resizable-column">{{getIsActive(device)}}</td>
                            <td class="ui-resizable-column">
                                <i class="far fa-edit fa-xl" (click)="navigateToDetail(device.id)" style="margin-right:5px;"></i>
                                <i *ngIf="isAdmin(device)" class="far fa-trash-alt fa-xl" (click)="confirmDelete(device)"></i>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="paginatorleft">
                        Showing {{dt.totalRecords < 1 ? 0 : dt.first + 1}} to 
                        {{dt.totalRecords > 0 ? ((dt.rows + dt.first) <= dt.totalRecords ? (dt.rows + dt.first) : 
                        dt.totalRecords) : 0}} of {{dt.totalRecords ? dt.totalRecords : 0}} entries
                    </ng-template>
                </p-table>
            </div>
    </div>
</div>