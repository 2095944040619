import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { VendorJob } from '../models/models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { IVendorJobService } from './iVendorJob.service';

@Injectable()
export class VendorJobService extends IVendorJobService {

  constructor(
    private _http: HttpClient
  ) {
    super();
  }

  createJob(job: VendorJob): Observable<any> {
    const request = this._http.post('api/vendorjob', job).pipe(map(
      response => {
          return response as string;
      }
  ));
  return request;
  }

  getJobs(): Observable<VendorJob[]> {
    return this._http.get<VendorJob[]>('api/vendorjob/');
  }
  republishWorkbook(jobId:string): Observable<any> {
    let request = this._http.get('api/vendorjob/republish/' + jobId);
    return request;
}
  getJob(jobId: string): Observable<VendorJob> {
    return this._http.get<VendorJob>('api/vendorjob/' + jobId);
  }

  getJobFromConsumerJob(jobId: string): Observable<VendorJob> {
    return this._http.get<VendorJob>('api/vendorjob/consumer/' + jobId);
  }

  getDashboardLink(jobId: string): Observable<string> {
    return this._http.get<string>('api/vendorjob/dashboardlink/' + jobId);
  }

  updateJob(jobId: string, updatedJob: VendorJob): Observable<any> {
    return this._http.put('api/vendorjob/' + jobId, updatedJob);
  }

  deleteJob(jobId: any): Observable<any> {
    return this._http.delete('api/vendorjob/' + jobId);
  }

  isUserServiceProvider(jobId: string): Observable<boolean> {
    return this._http.get<boolean>('api/vendorjob/isServiceProvider/' + jobId);
  }

  getCompanyIdForJob(jobId: string): Observable<string> {
    return this._http.get<string>('api/vendorjob/' + jobId + '/companyId');
  }

  getVendorJobsForVendor(vendorCompanyId: string): Observable<VendorJob[]> {
    return this._http.get<VendorJob[]>('api/vendorjob/company/' + vendorCompanyId);
  }

  isDataOnJob(jobId: string): Observable<boolean> {
    return this._http.get<boolean>('api/vendorjob/hasdata/' + jobId);
  }
}
