import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { INewCompanyRequestService,
         IUserService,
         IOpsViewerMessagesService,
         IApplicationContextService,
         ICompanyWorkbookConfigurationService } from 'src/app/services/services';
import { NewCompanyRequest,
         RequestStatuses,
         Address,
         CompanyTypes,
         UnitSystemChoice,
         CompanyWorkbookConfiguration,
         WellKnownMeasurementChoice,
         User} from 'src/app/models/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-company-request',
  templateUrl: './new-company-request.component.html',
  styleUrls: ['./new-company-request.component.css']
})
export class NewCompanyRequestComponent implements OnInit, OnChanges {

  @Input() user: User;
  @Input() dialogOnly: boolean;
  @Output() error = new EventEmitter();
  private _user: User;
  userReady = false;
  requests: NewCompanyRequest[] = [];
  requestCols: any[] = [];
  _requestsReady = false;
  _requestsNamesUpdated = false;
  unitSystemChoices: SelectItem[] = [
    {label: UnitSystemChoice.si, value: UnitSystemChoice.si  },
    {label: UnitSystemChoice.imperial, value: UnitSystemChoice.imperial  }
  ];
  companyTypes: SelectItem[] = [
    { label: CompanyTypes.Consumer, value: CompanyTypes.Consumer },
    { label: CompanyTypes.Vendor, value: CompanyTypes.Vendor }
  ];
  loading = true;

  showRejectDialog = false;
  _requestToReject: NewCompanyRequest;
  rejectReason: string;

  showAddNewCompanyRequest = false;
  requestToAdd = new NewCompanyRequest();
  requestToAddAutoApprovedEmailDomains: string;
  requestToAddModelValid = true;
  isShippingSameAsBilling = true;
  billingEmailAddressValid = true;
  shippingEmailAddressValid = true;

  constructor(
    private _newCompanyRequestService: INewCompanyRequestService,
    private _userService: IUserService,
    private _confirmationService: ConfirmationService,
    private _messageService: IOpsViewerMessagesService,
    private _companyWorkbookConfiguration: ICompanyWorkbookConfigurationService,
    private _applicationContextService: IApplicationContextService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.setRequestCols();
  }

  ngOnChanges() {
    if (this.user && (this._user !== this.user)) {
      this._user = this.user;
      this.userReady = true;
      this.getRequests();
      if (this.dialogOnly) {
        this.addRequest();
      }
    }
  }

  setRequestCols() {
    this.requestCols = [
      { field: 'companyName', header: 'Company' },
      { field: 'companyType', header: 'Type' },
      { field: 'requestedByName', header: 'Requested By' },
      { field: 'status', header: 'Status' },
      { field: '', header: '' },
    ];
  }

  getRequests() {
    this._requestsReady = false;
    this._newCompanyRequestService.getNewCompanyRequests().subscribe(
      results => {
        if (results && results.length > 0) {
          this.requests = results;
          this.setRequestedByNames();
        } else {
          this._requestsNamesUpdated = true;
        }
        this._requestsReady = true;
        this.checkIfEvertythingReady();
      },
      error => {
        this.handleError(error, 'Could not get New Company Requests');
      }
    );
  }

  setRequestedByNames() {
    this._requestsNamesUpdated = false;
    const distinctUserIds = this.getUserIdsToGetNamesFor();
    this.addRequestedByNameToRequests(distinctUserIds);
  }

  getUserIdsToGetNamesFor(): string[] {
    const distinctUserIds: string[] = [];
    this.requests.map(r => {
      if (!distinctUserIds.includes(r.requestedById)) {
        distinctUserIds.push(r.requestedById);
      }
    });
    return distinctUserIds;
  }

  addRequestedByNameToRequests(distinctUserIds: string[]) {
    let requestedNamesUpdated = 0;
    distinctUserIds.map(u => {
      this._userService.getUserName(u).subscribe(
        result => {
          if (result) {
            this.requests.map(r => {
              if (r.requestedById === u) {
                r.requestedByName = result.userName;
              }
            });
          }
          requestedNamesUpdated++;
          if (requestedNamesUpdated === distinctUserIds.length) {
            this._requestsNamesUpdated = true;
            this.checkIfEvertythingReady();
          }
        },
        error => {
          this.handleError(error, 'Could not get New Company Requests');
        }
      );
    });
  }

  checkIfEvertythingReady() {
    this.loading = this._requestsReady && this._requestsNamesUpdated;
    if (this.loading) {
      this.showAddNewCompanyRequest = this.dialogOnly;
    }
  }

  confirmApprove(request: NewCompanyRequest) {
    this._confirmationService.confirm({
      key: 'confirmationDialog',
      header: 'Approve Confirmation',
      message: 'Are you sure you want to Approve' + request.companyName + ' ?',
      icon: 'fa fa-question-circle',
      accept: () => { this.approve(request); }
    });
  }

  approve(request: NewCompanyRequest) {
    this._applicationContextService.showApplicationSpinner(false);
    this._newCompanyRequestService.approveNewCompanyRequest(request.id).subscribe(
      async (result) => {
        request.status = RequestStatuses.Approved;
        await this.createNewCompanyWorkbookConfigurationOnceApproved(request);
        this._applicationContextService.hideApplicationSpinner();
        this._messageService.successMessage('New Company Request successfully Approved.', '');
        // TODO: Create default workbook configuration
        this._applicationContextService.alertRequestsUpdated();
        this._applicationContextService.alertCompaniesListUpdated();
      },
      error => {
        this._applicationContextService.hideApplicationSpinner();
        this.handleError(error, 'Unable to Approve Requeest');
      }
    );
  }

  async createNewCompanyWorkbookConfigurationOnceApproved(request: NewCompanyRequest) {
    if (request.companyType === CompanyTypes.Vendor) {
      const configuration = new CompanyWorkbookConfiguration(null, request.companyId);
      const workbookActivities = await this._companyWorkbookConfiguration.getActivities().toPromise().catch(
        error => {
          this.handleError(error, 'Could not Create Company Configuration');
          return;
        });
      const workbookWellKnownMeasurements = await this._companyWorkbookConfiguration.getWellKnownMeasurements().toPromise().catch(
        error => {
          this.handleError(error, 'Could not Create Company Configuration');
          return;
        });
      configuration.setDefaultMeasurements(
        workbookActivities as string[],
        request.companyUnitSystem,
        workbookWellKnownMeasurements as WellKnownMeasurementChoice[]);
      await this._companyWorkbookConfiguration.createOrUpdateConfiguration(configuration).toPromise().catch(
        error => { this.handleError(error, 'Could not Create Company Configuration'); }
      );
    }
  }

  confirmReject(request: NewCompanyRequest) {
    this.rejectReason = null;
    this.showRejectDialog = true;
    this._requestToReject = request;
  }

  cancelReject() {
    this.showRejectDialog = false;
    this._requestToReject = null;
  }

  reject() {
    if (this.rejectReason) {
      this._applicationContextService.showApplicationSpinner(false);
      this._newCompanyRequestService.rejectNewCompanyRequest(this._requestToReject.id, this.rejectReason).subscribe(
        result => {
          const request = this.requests.find(r => r.id === this._requestToReject.id);
          request.status = RequestStatuses.Rejected;
          this.showRejectDialog = false;
          this._requestToReject = null;
          this._applicationContextService.hideApplicationSpinner();
          this._messageService.successMessage('New Company Request Rejected.', '');
          this._applicationContextService.alertRequestsUpdated();
        },
        error => {
          this._applicationContextService.hideApplicationSpinner();
          this.handleError(error, 'Unable to Reject Request');
          this.showRejectDialog = false;
          this._requestToReject = null;
        }
      );
    }
  }

  addRequest() {
    this.requestToAddAutoApprovedEmailDomains = null;
    this.requestToAdd = new NewCompanyRequest();
    // company doesn't exist yet, but same id will be used to create company
    // when request is approved
    this.requestToAdd.companyId = uuidv4();
    this.requestToAddModelValid = true;
    this.showAddNewCompanyRequest = true;
  }

  saveAdd() {
    if (this.requestToAddAutoApprovedEmailDomains) {
      this.requestToAdd.autoApprovedEmailDomains = this.requestToAddAutoApprovedEmailDomains.split(';');
    }
    if (this.isShippingSameAsBilling) {
      this.requestToAdd.shippingAddress = this.requestToAdd.billingAddress;
    }

    this.requestToAddModelValid = this.validateAddModel();
    if (this.requestToAddModelValid) {
      this._applicationContextService.showApplicationSpinner(false);
      this._newCompanyRequestService.createNewCompanyRequest(this.requestToAdd).subscribe(
        result => {
          this.showAddNewCompanyRequest = false;
          this._applicationContextService.hideApplicationSpinner();
          this._messageService.successMessage('New Company Request successfully submitted.', '');
          this._applicationContextService.alertRequestsUpdated();
          if (this.dialogOnly) {
            this.navigateHome();
          } else {
            this.getRequests();
          }
        },
        error => {
          this._applicationContextService.hideApplicationSpinner();
          this.handleError(error, 'Unable to create New Company Request');
        }
      );
    } else {
      this._messageService.warnMessage('Validation Error:', 'One or more required input fields is invalid.');
    }
  }

  validateAddModel(): boolean {
    if (!this.requestToAdd.companyName) { return false; }
    if (!this.requestToAdd.companyUnitSystem) { return false; }
    // if (!this.requestToAdd.companyPrefix) { return false; }
    if (!this.validateAddress(this.requestToAdd.billingAddress, this.billingEmailAddressValid)) {
      return false;
    }
    if (!this.validateAddress(this.requestToAdd.shippingAddress, this.shippingEmailAddressValid)) {
      return false;
    }

    return true;
  }

  validateAddress(address: Address, isEmailValid: boolean): boolean {
    if (!address.name) { return false; }
    if (!address.street) { return false; }
    if (!address.city) { return false; }
    if (!address.state) { return false; }
    if (!address.zip) { return false; }
    if (!address.email || !isEmailValid) { return false; }
    if (!address.phone) { return false; }

    return true;
  }

  validateBillingEmail(event: any) {
    this.billingEmailAddressValid = event;
  }

  validateShippingEmail(event: any) {
    this.shippingEmailAddressValid = event;
  }

  cancelAdd() {
    if (this.dialogOnly) {
      this.navigateHome();
    }
    this.showAddNewCompanyRequest = false;
  }

  confirmDelete(request: NewCompanyRequest) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to Delete request for ' + request.companyName + ' ?',
        icon: 'fa fa-question-circle',
        accept: () => { this.delete(request); }
      }
    );
  }

  delete(request: NewCompanyRequest) {
    this._applicationContextService.showApplicationSpinner(false);
    this._newCompanyRequestService.deleteNewCompanyRequest(request.id).subscribe(
      result => {
        this.requests = this.requests.filter(r => r.id !== request.id);
        this._applicationContextService.hideApplicationSpinner();
      },
      error => {
        this._applicationContextService.hideApplicationSpinner();
        this.handleError(error, 'Could not delete request');
      }
    );
  }

  handleError(error: any, displayMessage: string) {
    this._messageService.errorMessage(error, displayMessage);
  }

  navigateHome() {
    this._router.navigate(['/home']);
  }
}
