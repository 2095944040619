import { BaseEntity } from './baseEntity';
import { DeviceMeasurementChannelConfiguration } from './deviceMeasurementChannelConfiguration';
import { TcpDeviceConfiguration } from './tcpDeviceConfiguration';

export class Device implements BaseEntity {
    id: string;
    name: string;
    category: string;
    companyId: string;
    measurementChannelConfigurations: DeviceMeasurementChannelConfiguration[];
    tcpDeviceConfiguration : TcpDeviceConfiguration
    constructor(id: string, name: string, category: string, companyId: string = null) {
        this.id = id;
        this.name = name;
        this.category = category;
        this.companyId = companyId;
        this.measurementChannelConfigurations = [];
        this.tcpDeviceConfiguration = new TcpDeviceConfiguration();
    }
}
