import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { CompanyWorkbookConfiguration,
         WorkbookMeasurementChannnelConfiguration,
         WellKnownMeasurementChoice,
         JobWorkbookConfiguration,
         UnitSystemChoice,
         WorkbookJobTypes } from 'src/app/models/models';
import { ICompanyWorkbookConfigurationService, IJobWorkbookConfigurationService } from 'src/app/services/services';

import { getWorkbookTypesSelection } from 'src/app/models/workbookJobTypes';
import { ConfirmationService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-workbook-configuration',
  templateUrl: './workbook-configuration.component.html',
  styleUrls: ['./workbook-configuration.component.css']
})
export class WorkbookConfigurationComponent implements OnChanges {
  @Input() companyUnitSystem: string;
  @Input() companyId: string; // TODO: How to handle when new company?
  @Input() jobId: string;
  @Input() jobType: string;
  @Input() showUnitSystemChoices: boolean;

  @Output() companyConfiguration = new EventEmitter();
  @Output() jobConfiguration = new EventEmitter();
  @Output() errors = new EventEmitter();

  _unitSystem: string;
  _companyId: string;
  _jobId: string;
  _jobType: string;

  _companyConfiguration: CompanyWorkbookConfiguration = new CompanyWorkbookConfiguration(null, null);
  _jobConfiguration: JobWorkbookConfiguration;

  workbookConfigurationUnitSystemChoices: SelectItem[] = [
    {label: UnitSystemChoice.si, value: UnitSystemChoice.si  },
    {label: UnitSystemChoice.imperial, value: UnitSystemChoice.imperial  }
  ];
  allWorkbookConfigurationJobTypes: SelectItem[] = getWorkbookTypesSelection();
  currentWorkbookConfigurationJobTypes: SelectItem[] = [];

  workbookWellKnownMeasurements: WellKnownMeasurementChoice[] = [];
  workbookWellKnownMeasurementOptions: SelectItem[] = [];
  workbookActivities: SelectItem[] = [];
  workbookWellKnownMeasurementsReady = false;
  workbookActivitiesReady = false;
  workbookConfigurationsDataTypes: SelectItem[] = [
    { label: 'Number', value: 'Number' },
    { label: 'Text', value: 'Text' }
  ];
  workbookConfigurationCols: any[] = [];
  workbookConfigurationDisplayDialog = false;
  workbookConfigurationUnitOptions: SelectItem[] = [];
  workbookConfigurationsTableReady = false;
  workbookConfigurationMeasurements: WorkbookMeasurementChannnelConfiguration[] = [];

  constructor(
    private companyConfigurationService: ICompanyWorkbookConfigurationService,
    private jobConfigurationService: IJobWorkbookConfigurationService,
    private confirmationService: ConfirmationService ) { }

  // ngOnInit() {
  //   this.initializeComponent();
  // }

  ngOnChanges() {
    if (this.checkIfInputsChanged()) {
      this.workbookActivitiesReady = false;
      this.workbookWellKnownMeasurementsReady = false;
      this.initializeComponent();
    }
  }

  // --------- Initialization Methods --------------------------------------------------------------------

  initializeComponent() {
    this.setInputs();
    this.getWorkbookActivities();
    this.getWellKnownMeasurements();
    this.setConfigurationCols();
    this.filterJobTypes();
  }

  setInputs() {
    this._companyId = this.companyId;
    this.getUnitSystem();
    this._jobId = this.jobId;
    this._jobType = this.jobType;
    if (this.showUnitSystemChoices !== false) {
      this.showUnitSystemChoices = true;
    }
  }

  checkIfInputsChanged(): boolean {
    if (this.companyId !== this._companyId) {
      return true;
    }
    if (this.companyUnitSystem !== this._unitSystem) {
      return true;
    }
    if (this.jobId !== this._jobId) {
      return true;
    }
    if (this.jobType !== this._jobType) {
      return true;
    }

    return false;
  }

  getUnitSystem() {
    if (this.companyUnitSystem) {
      this._unitSystem = this.companyUnitSystem;
    } else {
      this._unitSystem = this.companyUnitSystem = UnitSystemChoice.imperial;
    }
  }

  getWellKnownMeasurements() {
    this.companyConfigurationService.getWellKnownMeasurements().subscribe(
      results => {
        this.workbookWellKnownMeasurements = results;
        results.forEach(result => {
          this.workbookWellKnownMeasurementOptions.push({label: result.displayName, value: result.name});
        });
      },
      error => {
        const messages = [{
          severity: 'error',
          summary: 'Could not get Workbook Well Known Measurements',
          detail: ''
        }];
        this.errors.emit(messages);
      },
      () => {
        this.workbookWellKnownMeasurementsReady = true;
        this.checkIfReadyToGetConfiguration();
      }
    );
  }

  getWorkbookActivities() {
    this.companyConfigurationService.getActivities().subscribe(
      results => {
        this.workbookActivities = results.map(
          x => ({
            label: x, value: x
          })
        );
      },
      error => {
        
        const messages = [{
          severity: 'error',
          summary: 'Could not get Workbook Activities',
          detail: ''
        }];
        this.errors.emit(messages);
      },
      () => {
        this.workbookActivitiesReady = true;
        this.checkIfReadyToGetConfiguration();
      }
    );
  }

  setConfigurationCols() {
    this.workbookConfigurationCols = [
      {field: 'measurementName', header: 'Measurement Name', width: '125px'},
      {field: 'wellKnownMeasurement', header: 'Well Known Measurement', width: '125px'},
      {field: 'dataType', header: 'Data Type', width: '70px'},
      {field: 'unitChoice', header: 'Unit', width: '70px'},
      {field: 'jobTypes', header: 'Job Types', width: '100px'},
      {field: 'activities', header: 'Activities', width: '100px'},
      {field: 'lookupChoices', header: 'Defined Values', width: '100px'},
      {field: 'mininumLimit', header: 'Mininum', width: '100px'},
      {field: 'maximumLimit', header: 'Maximum', width: '100px'},
      {field: '', header: '', width: '55px'}
    ];
  }

  filterJobTypes() {
    if (this._jobType) {
      this.currentWorkbookConfigurationJobTypes = this.allWorkbookConfigurationJobTypes.filter(t => t.value === this._jobType);
    } else {
      this.currentWorkbookConfigurationJobTypes = this.allWorkbookConfigurationJobTypes;
    }
  }

  checkIfReadyToGetConfiguration() {
    if (this.workbookActivitiesReady && this.workbookWellKnownMeasurementsReady) {
      this.getConfiguration();
    }
  }

  getConfiguration() {
    // Get configuration based on component inputs
    // Populate companyId to configure CompanyWorkbookConfiguration
    // Populate jobId to configure JobWorkbookConfiguration
    // JobId overrides companyId if both populated
    // If no Job Configuration exists as in case of new job
    // then the company configuration is used
    if (this._jobId && this._companyId) {
      this.getJobConfiguration();
    } else if (this._companyId) {
      this.getCompanyConfiguration();
    } else {
      const messages = [{
        severity: 'error',
        summary: 'Could not get or build configuration.',
        detail: 'No Company or Job Id specified'
      }];
      this.errors.emit(messages);
    }
  }

  getJobConfiguration() {
    this.jobConfigurationService.getJobWorkbookConfiguration(this._jobId).subscribe(
      result => {
        if (result) {
          this._jobConfiguration = result;
          this.buildJobConfiguration();
        } else {
          this.createDefaultJobWorkbookConfiguration();
        }
      },
      error => {
        const messages = [{
          severity: 'error',
          summary: 'Could not get Job Workbook Configuration',
          detail: ''
        }];
        this.errors.emit(messages);
      }
    );
  }

  getCompanyConfiguration() {
    // Check for existing company configuration
    // If no configuration, create default
    this.companyConfigurationService.getConfigurationForCompany(this._companyId).subscribe(
      result => {
        if (result) {
          this._companyConfiguration = result;
          this._companyConfiguration.workbookMeasurementChannelConfigurations =
            this.prepareMeasurementConfigurationForUI(this._companyConfiguration.workbookMeasurementChannelConfigurations);
        } else {
          this._companyConfiguration = this.createDefaultCompanyWorkbookConfiguration();
        }
        this.workbookConfigurationMeasurements = this._companyConfiguration.workbookMeasurementChannelConfigurations;
        this.companyConfiguration.emit(this._companyConfiguration);
        this.workbookConfigurationsTableReady = true;
      },
      error => {
        const messages = [{
          severity: 'error',
          summary: 'Could not get Job Workbook Configuration',
          detail: ''
        }];
        this.errors.emit(messages);
      }
    );
  }

  createDefaultCompanyWorkbookConfiguration(): CompanyWorkbookConfiguration {
    const configuration = new CompanyWorkbookConfiguration(null, this._companyId);
    const activitiesOptions = this.workbookActivities.map(a => {
      return a.value;
    });
    configuration.setDefaultMeasurements(activitiesOptions, this._unitSystem, this.workbookWellKnownMeasurements);
    return configuration;
  }

  createDefaultJobWorkbookConfiguration() {
    this.jobConfigurationService.createDefaultJobWorkbookConfiguration(
      this._companyId, this._unitSystem, this._jobId, this._jobType).subscribe(
        result => {
          this._jobConfiguration = result;
          this.buildJobConfiguration();
        },
        error => {
          console.error(error);
          const messages = [{
            severity: 'error',
            summary: 'Could not set default Job Workbook Configuration from Company',
            detail: ''
          }];
          this.errors.emit(messages);
        }
      );
  }

  buildJobConfiguration() {
    this._jobConfiguration.workbookMeasurementChannelConfigurations =
          this.prepareMeasurementConfigurationForUI(this._jobConfiguration.workbookMeasurementChannelConfigurations);
        this.jobConfiguration.emit(this._jobConfiguration);
        this.workbookConfigurationMeasurements = this._jobConfiguration.workbookMeasurementChannelConfigurations;
        // Additionally JobConfiguration must set the unit system choice since it is driven from company
        this._unitSystem = this._jobConfiguration.unitSystem;
        this.workbookConfigurationsTableReady = true;
  }

  // -------- Change Methods from Template Actions --------------------------------------------------------------------

  displayNameChanged(configuration: WorkbookMeasurementChannnelConfiguration) {
    configuration.setMeasurementName();
    this.updateConfigurationEmitters();
  }

  unitSystemChanged() {
    const activitiesOptions = this.workbookActivities.map(a => {
      return a.value;
    });
    // For each configuration retain it the measurement, but rebuild it to reset unit selection
    this.workbookConfigurationMeasurements.forEach(c => {
      const wellKnownMeasurement = this.workbookWellKnownMeasurements.find(w => w.name === c.wellKnownMeasurementName);
      c.buildMeasurementWithDefinedWellKnownMeasurement(
        c.measurementName,
        c.measurementDisplayName,
        wellKnownMeasurement,
        this._unitSystem,
        c.measurementDataType,
        c.activities,
        activitiesOptions,
        c.jobTypes
      );
    });
    this.updateConfigurationEmitters();
  }

  wellKnownMeasurementChanged(configuration: WorkbookMeasurementChannnelConfiguration) {
    configuration.unitOptions = this.setUnitSelectItems(configuration.wellKnownMeasurementName);
    const measurementType = this.setMeasurementType(configuration.wellKnownMeasurementName);
    if (measurementType) {
      configuration.measurementDataType = measurementType;
    }
    this.updateConfigurationEmitters();
  }

  measurementTypeChanged(configuration: WorkbookMeasurementChannnelConfiguration) {
    if (configuration.measurementDataType === 'Text') {
      configuration.unitOfMeasurementId = 'Dimensionless';
    }
    this.updateConfigurationEmitters();
  }

  unitsChanged() {
    this.updateConfigurationEmitters();
  }

  jobTypesChanged() {
    this.updateConfigurationEmitters();
  }

  activitiesChanged() {
    this.updateConfigurationEmitters();
  }

  lookupChoicesChanged(configuration: WorkbookMeasurementChannnelConfiguration) {
    if (configuration.lookupChoicesAsString) {
      configuration.lookupChoices = configuration.lookupChoicesAsString.split(',');
    }
    this.updateConfigurationEmitters();
  }

  limitsChanged() {
    this.updateConfigurationEmitters();
  }

  addConfiguration() {
    const newConfig = new WorkbookMeasurementChannnelConfiguration();
    if (this._jobType) {
      newConfig.jobTypes = [this._jobType];
    }
    this.workbookConfigurationMeasurements.push(newConfig);
    let newArray = [];
    newArray.push(...this.workbookConfigurationMeasurements);
    this.workbookConfigurationMeasurements = newArray;//need to create new reference to refresh grid
   
    this.updateConfigurationEmitters();
  }

  confirmDeleteConfiguration(configuration: WorkbookMeasurementChannnelConfiguration) {
    this.confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + configuration.measurementName + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteWorkBookConfiguration(configuration); }// ,
        // reject: () => { }
      }
    );
  }

  // ----------- Component Helper Methods ----------------------------------------------------------

  prepareMeasurementConfigurationForUI(workbookMeasurementChannnelConfigurations: WorkbookMeasurementChannnelConfiguration[])
  : WorkbookMeasurementChannnelConfiguration[] {
    workbookMeasurementChannnelConfigurations.forEach(m => {
      m.unitOptions = this.setUnitSelectItems(m.wellKnownMeasurementName);
      m.lookupChoicesAsString = this.setLookupChoicesAsString(m.lookupChoices);
    });
    return workbookMeasurementChannnelConfigurations;
  }

  setUnitSelectItems(selectedWellKnownMeasurementName: string): SelectItem[] {
    const items: SelectItem[] = [];
    const selectedWellKnownMeasurement = this.workbookWellKnownMeasurements.find(
      w => w.name === selectedWellKnownMeasurementName
    );

    if (selectedWellKnownMeasurement) {
      if (this._unitSystem === UnitSystemChoice.si) {
        selectedWellKnownMeasurement.siUnits.forEach(u => {
          items.push({ label: u.unitName, value: u.unitKey });
        });
      } else if (this._unitSystem === UnitSystemChoice.imperial) {
        selectedWellKnownMeasurement.imperialUnits.forEach(u => {
          items.push({ label: u.unitName, value: u.unitKey });
        });
      }
    }

    // Add dimensionless choice for text
    items.push({ label: 'N/A', value: 'Dimensionless' });

    return items;
  }

  setLookupChoicesAsString(lookupChoices: string[]): string {
    if (lookupChoices) {
      return lookupChoices.join(',');
    }
    return '';
  }

  setMeasurementType(selectedWellKnownMeasurementName: string): string {
    const selectedWellKnownMeasurement = this.workbookWellKnownMeasurements.find(
      w => w.name === selectedWellKnownMeasurementName
    );
    if (selectedWellKnownMeasurement) {
      if (selectedWellKnownMeasurement.siUnits.length > 0) {
        if (selectedWellKnownMeasurement.siUnits.find(u => u.unitName === 'N/A')) {
          return 'Text';
        } else {
          return 'Number';
        }
      } else if (selectedWellKnownMeasurement.imperialUnits.length > 0) {
        if (selectedWellKnownMeasurement.imperialUnits.find(u => u.unitName === 'N/A')) {
          return 'Text';
        } else {
          return 'Number';
        }
      }
    }
    return null;
  }

  deleteWorkBookConfiguration(configuration: WorkbookMeasurementChannnelConfiguration) {
    this.workbookConfigurationMeasurements =
        this.workbookConfigurationMeasurements.filter(c =>
          c.measurementName !== configuration.measurementName);
    // Update company configuration to match configuration shown in table.
    // For some reason this automatically happens on add configuration push but not on filter
    if (this._companyConfiguration) {
      this._companyConfiguration.workbookMeasurementChannelConfigurations = this.workbookConfigurationMeasurements;
    }
    this.updateConfigurationEmitters();
  }

  updateConfigurationEmitters() {
    if (this._jobConfiguration) {
      this.jobConfiguration.emit(this._jobConfiguration);
    }
    if (this._companyConfiguration) {
      this.companyConfiguration.emit(this._companyConfiguration);
    }
  }
}
