import { Component, OnInit } from '@angular/core';
import { IFeaturesService } from 'src/app/services/services';
import { Router } from '@angular/router';
import { Features } from 'src/app/models/models';

@Component({
  selector: 'app-jobs-redirect',
  templateUrl: './jobs-redirect.component.html',
  styleUrls: ['./jobs-redirect.component.css']
})
export class JobsRedirectComponent implements OnInit {

  constructor(
    private _featuresService: IFeaturesService,
    private _router: Router) { }

  ngOnInit() {
    this.isCompanyAdministrationEnabled().then(enabled => {
      if (enabled) {
        this._router.navigate(['/jobs2']);
      } else {
        this._router.navigate(['/jobs']);
      }
    });
  }

  async isCompanyAdministrationEnabled(): Promise<boolean> {
    return await this._featuresService.isFeatureEnabled(Features.CompanyAdministration).toPromise();
  }

}
