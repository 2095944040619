import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-files-container',
  templateUrl: './global-files-container.component.html',
  styleUrls: ['./global-files-container.component.css']
})
export class GlobalFilesContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
