import { Component, OnInit } from '@angular/core';
import { SimulationExpense } from 'src/app/models/simulation';
import { SelectItem } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'simulation-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css']
})
export class ExpensesComponent implements OnInit {
  expenseCostFactor: SelectItem[];
  expenses: SimulationExpense[];
  clonedItem: SimulationExpense;

  constructor() { }

  ngOnInit() {
    this.expenseCostFactor = [
      {label: 'Cost per Day', value: 'PerDay'},
      {label: 'Cost per Quarter Day', value: 'PerQuarterDays'},
      {label: 'Cost per Hour', value: 'PerHour'},
      {label: 'Once per Well', value: 'PerWell'},
      {label: 'Pipe feet RIH only', value: 'PerRihFeet'},
      {label: 'Cost per 42 gallon BBL', value: 'PerBarrel'},
      {label: 'Cost per Plug', value: 'PerPlug'},
    ];
  }

  addExpense() {
    this.expenses.push(<SimulationExpense> { id: uuidv4(), description: '', costPerFactor: 0.0, expenseCostFactorId: 'PerWell' });
  }

  remove(exp: SimulationExpense) {
    this.expenses = this.expenses.filter((x) => x != exp);
  }

  onRowEditInit(item: SimulationExpense) {
    this.clonedItem = {...item};
  }

  onRowEditSave(item: SimulationExpense) {
  }

  onRowEditCancel(item: SimulationExpense, index: number) {
    item = {...this.clonedItem};
  }
}
