import { BaseEntity } from './baseEntity';
import { v4 as uuidv4 } from 'uuid';
import { WorkbookMeasurementChannnelConfiguration } from './workbookMeasurementChannelConfiguration';

export class JobWorkbookConfiguration implements BaseEntity {
  id: string;
  jobId: string;
  workbookMeasurementChannelConfigurations: WorkbookMeasurementChannnelConfiguration[];
  unitSystem: string;

  constructor(id: string, jobId: string, unitSystem: string) {
    if (id) {
        this.id = id;
    } else {
        this.id = uuidv4();
    }
    this.jobId = jobId;
    this.unitSystem = unitSystem;
    this.workbookMeasurementChannelConfigurations = [];
  }
}
