import { BaseRequest } from './baseRequest';

export class CompanyAssociationRequest extends BaseRequest {

  userId: string;
  role: string;

  // Client side only properties
  companyName: string;
  userName: string;
  requestedByName: string;

  constructor() {
    super();
  }

  mapFromObject(object: any) {
    if (object) {
      this.id = object.id;
      this.created = object.id;
      this.companyId = object.companyId;
      this.requestedById = object.requestedById;
      this.requestNotes = object.requestNotes;
      this.status = object.status;
      this.approvedById = object.approvedById;
      this.approvedDate = object.approvedDate;
      this.rejectedById = object.rejectedById;
      this.rejectedDate = object.rejectedDate;
      this.rejectedReason = object.rejectedReason;

      this.userId = object.userId;
      this.role = object.role;

      if (object.companyName) {
        this.companyName = object.companyName;
      } else {
        this.companyName = null;
      }
      if (object.userName) {
        this.userName = object.userName;
      } else {
        this.userName = null;
      }
      if (object.requestedByName) {
        this.requestedByName = object.requestedByName;
      } else {
        this.requestedByName = null;
      }
    }
  }
}

