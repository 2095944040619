import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyAssociationRequest } from '../../models/models';
import { ICompanyAssociationRequestService } from './iCompanyAssociationRequest.service';

@Injectable()
export class CompanyAssociationRequestService extends ICompanyAssociationRequestService {
  baseUrl = 'api/companyassociationrequest/';

  constructor(private _http: HttpClient) {
    super();
  }

  getCompanyAssociationRequests(): Observable<CompanyAssociationRequest[]> {
    const request = this._http.get(this.baseUrl).pipe(map(
      results => {
        if (results) {
          const resultsAsArray = results as CompanyAssociationRequest[];
          const companyAssociationRequests: CompanyAssociationRequest[] = [];
          resultsAsArray.map(r => {
            const companyAssociationRequest = new CompanyAssociationRequest();
            companyAssociationRequest.mapFromObject(r);
            companyAssociationRequests.push(companyAssociationRequest);
          });
          return companyAssociationRequests;
        }
        return null;
      }
    ));
    return request;
  }

  getCompanyAssociationRequestById(id: string): Observable<CompanyAssociationRequest> {
    const request = this._http.get(this.baseUrl + id).pipe(map(
      result => {
        if (result) {
          const companyAssociationRequest = new CompanyAssociationRequest();
          companyAssociationRequest.mapFromObject(result);
          return companyAssociationRequest;
        }
        return null;
      }
    ));
    return request;
  }

  createCompanyAssociationRequest(companyAssociationRequest: CompanyAssociationRequest) {
    const request = this._http.post(this.baseUrl, companyAssociationRequest).pipe(map(
      response => {
          return response as string;
      }
    ));
    return request;
  }

  approveCompanyAssociationRequest(id: string) {
    const request = this._http.post(this.baseUrl + 'approve/' + id, {}).pipe(map(
      response => {
          return response as string;
      }
    ));
    return request;
  }

  rejectCompanyAssociationRequest(id: string, reason: string) {
    const request = this._http.post(this.baseUrl + 'reject/' + id, { reasonMessage: reason }).pipe(map(
      response => {
          return response as string;
      }
    ));
    return request;
  }

  deleteCompanyAssociationRequest(id: string) {
    const request = this._http.delete(this.baseUrl + id).pipe(map(
      response => {
          return response as string;
      }
    ));
    return request;
  }

  getPendingCompanyAssoicationRequestsForUser(userId: string): Observable<CompanyAssociationRequest[]> {
    return this._http.get<CompanyAssociationRequest[]>(this.baseUrl + 'pending/' + userId);
  }
}
