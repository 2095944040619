import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WorkbookLog } from '../models/models';

@Injectable()
export abstract class IWorkbookV2Service {
    abstract get(jobId: string): Observable<WorkbookLog[]>;
    abstract addOrUpdate(workbookLogToUpdate: WorkbookLog, allJobWorkbookLogs: WorkbookLog[]): Observable<any>;
    abstract delete(id: WorkbookLog): Observable<any>;
    abstract calculateRelativeTimeValue(workbookLogToUpdate: WorkbookLog, allJobWorkbookLogs: WorkbookLog[]): number;
}
