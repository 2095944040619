export class Address {
    name: string;
    phone: string;
    email: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    
    constructor() {}
}