import { BaseEntity } from './baseEntity';

// for use with the offline workbook
export class WorkbookLog {
  [x: string]: string | number | Date;
    id = '';
    Time = new Date();
    jobId = '';
    relativeTime = 0;
    activity = '';
    CreatedBy = '';
}
