<div class="grid">
  <div *ngIf="showWorkbookLink" class="col-12">
      <button pButton class="ui-button-primary" (click)="navigateToWorkbook()" icon="fa fa-book" label="Workbook"
        style="margin-top:17px; margin-left: 5px; height:30px; width:145px; float:right; vertical-align:bottom;">
      </button>
      <button pButton class="ui-button-primary" icon="fa fa-download" label="Job Data" (click)="downloadJobExport()"
        style="margin-top:17px; margin-left: 5px; height:30px; width:145px; float:right; vertical-align:bottom;">
      </button>
      <button pButton class="ui-button-primary" icon="fa fa-download" label="Job Summary" (click)="downloadJobSummaryExport()"
        style="margin-top:17px; margin-left: 5px; height:30px; width:145px; float:right; vertical-align:bottom;">
      </button>
   </div>
  <div class="col-12 mt-1">
      <p-accordion [multiple]="true">
          <p-accordionTab header="Job Site Details" [selected]="true">
            <app-job-details [(ngModel)]="job" [modelInvalid]="modelInvalid" [disabled]="disabled"></app-job-details>
          </p-accordionTab>
          <p-accordionTab header="Well Information" [selected]="false">
            <app-job-well-information [(ngModel)]="job" [modelInvalid]="modelInvalid" [disabled]="disabled"></app-job-well-information>
          </p-accordionTab>
          <p-accordionTab header="Fluid Information" [selected]="false">
            <app-job-fluid-information [(ngModel)]="job" [modelInvalid]="modelInvalid" [disabled]="disabled"></app-job-fluid-information>
          </p-accordionTab>
          <p-accordionTab header="Performance Summary" [selected]="false">
            <app-job-performance-summary [(ngModel)]="job"></app-job-performance-summary>
          </p-accordionTab>
          <p-accordionTab header="Fluid Summary" [selected]="false">
            <app-job-fluid-summary [(ngModel)]="job"></app-job-fluid-summary>
          </p-accordionTab>
        </p-accordion>
  </div>
</div>

