import { BaseEntity } from '../baseEntity';

export class BaseRequest implements BaseEntity {

  id: string;
  created:  Date;  // Should be in BaseEntity but isn't currently and would affect all models to add
  companyId: string;
  requestedById: string;
  requestNotes: string;
  status: string;
  approvedDate: Date;
  approvedById: string;
  rejectedDate: Date;
  rejectedById: string;
  rejectedReason: string;

  constructor() {}
}
