import { Injectable } from '@angular/core';
import { ThrottlingUtils } from '@azure/msal-common';
import { get, set } from 'idb-keyval';
import { ConsumerJob, ConsumerJobClient, EntityBase, Job, VendorJob, VendorJobClient } from './nswag/nswagclient';


type JobDictionary<T extends EntityBase> = { [key: string]: T } 

@Injectable({
  providedIn: 'root'
})
export class JobCacheService {
  static cache_key: string = "cache_key"
  constructor(private _vendorJobClient : VendorJobClient, private _consumerJobClient:ConsumerJobClient) { 

  }
  private vendorJobsForVendorCompanyKey(userCompanyId:string):string{
    return JobCacheService.cache_key+"vendorJobsForVendorCompany"+userCompanyId;
  }
  private consumerJobsKey():string{
    return JobCacheService.cache_key+"consumerJobs";
  }
  async getVendorJobsForVendorCompany(userCompanyId:string):Promise<VendorJob[]>{
    let cached = await get<JobDictionary<VendorJob>>(this.vendorJobsForVendorCompanyKey(userCompanyId)) || {};
    var newest = JobCacheService.newest(Object.values(cached));

    var countRequest = this._vendorJobClient.getVendorJobsForVendorCompanyCount(userCompanyId);

    const servicefoundVendorJobs = await this._vendorJobClient.getVendorJobsForVendorCompany(userCompanyId, newest).toPromise();
    let all = JobCacheService.AddTo(cached, servicefoundVendorJobs);

    var serviceCount = await countRequest.toPromise();
    if (serviceCount !== Object.values(all).length) {
      console.info("cached did not equal service count");
      var jobsReload = await this._vendorJobClient.getVendorJobsForVendorCompany(userCompanyId, null).toPromise();
      all = JobCacheService.AddTo({}, jobsReload);
    }

    set(this.vendorJobsForVendorCompanyKey(userCompanyId), all);
    return Object.values(all);
  }

  async getConsumerJobs():Promise<ConsumerJob[]>{
    let cached = await get<JobDictionary<ConsumerJob>>(this.consumerJobsKey()) || {};
    var newest = JobCacheService.newest(Object.values(cached));

    var countRequest = this._consumerJobClient.getConsumerJobsCount();

    const servicefoundVendorJobs = await this._consumerJobClient.getConsumerJobs(newest).toPromise();
    let all = JobCacheService.AddTo(cached, servicefoundVendorJobs);

    var serviceCount = await countRequest.toPromise();

    if (serviceCount !== Object.values(all).length) {
      console.info("cached consumer did not equal service count");
      var jobsReload = await this._consumerJobClient.getConsumerJobs(null).toPromise();
      all = JobCacheService.AddTo({}, jobsReload);
    }

    set(this.consumerJobsKey(), all);
    return Object.values(all);
  }

  static AddTo<T extends EntityBase>(jobs:JobDictionary<T>, newJobs:T[]) : JobDictionary<T>{
    for (const job of newJobs) {
      jobs[job.id] = job;
    }
    return jobs;
  }
  static newest(jobs:EntityBase[]) : Date{
    let newest = new Date(1990,1).getTime();
    for(let job of jobs){
      if (job.modified.getTime() > newest){
        newest = job.modified.getTime();
      }
    }
    return new Date(newest);
  }
  
}
