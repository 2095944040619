<div class="grid field col-12">
    <div class="grid field col-12">
        <h1>Workbook Log</h1>
        <p-progressSpinner *ngIf="!everythingReady"></p-progressSpinner>
    </div>
    <div class="grid field col-12" *ngIf="everythingReady">
        <!-- Time -->
        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Log Time</label>
            </div>
            <div class="col-12 md:col-6">
                <p-calendar [(ngModel)]="workbookLogCalendarTime" [showIcon]="true" [showTime]="true" [hourFormat]="24"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038" (onClose)=validateTime(false) [style]="{'width':'200px'}">
                </p-calendar>
            </div>
            <div *ngIf="modelInvalid" class="col-12 md:col-6">
                <div *ngIf="modelInvalid && !workbookLogCalendarTime" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Log time is required!</label>
                </div>
                <div *ngIf="modelInvalid && badTimeRange" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Log time must be within Job's defined start and end
                        times!</label>
                </div>
                <div *ngIf="modelInvalid && timeExists" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Another log with this time already exists!</label>
                </div>
            </div>
        </div>
        <!-- Activity -->
        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Activity</label>
            </div>
            <div class="col-12 md:col-6">
                <p-dropdown [options]="activities" [(ngModel)]="workbookLog.activity" placeholder="Select Activity"
                [style]="{'width':'200px'}" (onChange)="activityChosen()"></p-dropdown>
                <div *ngIf="modelInvalid && !workbookLog.activity" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Activity is required!</label>
                </div>
            </div>
        </div>

        <ng-container *ngIf="measurementsToShow && measurementsToShow.length > 0">
            <ng-container *ngFor="let measurement of measurementsToShow">
                <div class="grid field col-12">
                    <div class="col-12 md:col-2">
                        <label>{{measurement.measurementDisplayName}}</label>
                    </div>
                    <div *ngIf="isTextConfig(measurement)" class="col-12 md:col-10"  >
                        <input *ngIf="isTextConfig(measurement)" type="text" pInputText class="w-full"
                            placeholder="Enter {{measurement.measurementDisplayName}} value"
                            [(ngModel)]="workbookLog[measurement.workbookPropertyName]" />
                    </div>
                    <ng-container *ngIf="isNumberConfig(measurement)">
                        <div class="col-8 md:col-10">
                            <input *ngIf="isNumberConfig(measurement)" type="number" pInputText style="max-width: 200px;"
                                placeholder="Enter {{measurement.measurementDisplayName}} value"
                                [(ngModel)]="workbookLog[measurement.workbookPropertyName]" class="w-full" />
                                <label class="ml-1" >{{getUnitName(measurement.unitOfMeasurementId)}}</label>
                            <p-message *ngIf="isOutOfRange(measurement)" severity="error"
                                text="Input out of range.  If input is valid, adjust min and max limits in the job's workbook configuration.">
                            </p-message>
                        </div>
                        <!-- <div class="col-4 md:col-2">
                            <label>{{getUnitName(measurement.unitOfMeasurementId)}}</label>
                        </div> -->
                    </ng-container>
                    <div *ngIf="isLookupConfig(measurement)" class="col-12 md:col-6">
                        <p-dropdown [options]="measurement.lookupChoicesAsSelectItems"
                            [(ngModel)]="workbookLog[measurement.workbookPropertyName]" placeholder="Select Value"
                            [style]="{'width':'200px'}"></p-dropdown>
                    </div>
                    <div *ngIf="modelInvalid && ![measurement.measurementName]" class="grid field col-12">
                        <label style="color:red; font-weight:bold;">{{measurement.measurementName}} is required!</label>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div *ngIf="!isNew" class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Created By</label>
            </div>
            <div class="col-12 md:col-6">
                <label>{{workbookLog.CreatedBy}}</label>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="grid field col-12">
            <button pButton (click)="saveWorkbookLog(false)"
                [disabled]="saving" [ngClass]="{ 'action-button': !saving,
                                 'action-button-disabled': saving }">
                Save
            </button>
            <button pButton (click)="saveWorkbookLog(true)"
                [disabled]="saving || isUserServiceProvider" [ngClass]="{ 'action-button': !saving,
                                 'action-button-disabled': saving }">
                Save and New
            </button>
            <button pButton class="action-button" (click)="returnToWorkbook()">
                Cancel
            </button>
        </div>
    </div>
</div>