<div class="grid field col-12">
    <div class="grid field col-12" *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>
    <div *ngIf="waiting" class="grid field col-12">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="grid field col-12" *ngIf="everythingReady">
        <div class="col-12 md:col-10"><h2 style="margin-bottom:2px; padding-bottom:0px;">Jobs</h2></div>
        <div class="col-12 md:col-2">
            <button pButton type="button" label="Add" (click)="navigateToAddJob()" icon="fa fa-plus" style="width:100px; margin-top:20px; float:right; vertical-align:bottom;"></button>
        </div>
        <div class="grid field col-12">
            <p-table #dt [columns]="cols" [value]="jobs" [scrollable]="true"  [paginator]="true" [rows]="25" [loading]="!everythingReady">
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns" [style.width]="col.width"/>
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.field != ''" [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field">
                            <div *ngSwitchCase="'name'">
                                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"
                                />
                            </div>
                            <div *ngSwitchCase="'company.name'">
                                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;" />
                            </div>
                            <div *ngSwitchCase="'devices'" style="width: 100%;">
                                <input pInputText type="text" (input)="dt.filter($event.target.value, col.alternativeFilterField, col.filterMatchMode)" style="width: 100%;" />
                                <!-- <p-multiSelect [options]="deviceNamesSelection" defaultLabel="All Devices" (onChange)="dt.filter($event.value, col.alternativeFilterField, col.filterMatchMode)" appendTo="body"></p-multiSelect> -->
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-job>
                    <tr>
                        <td>{{job.name}}</td>
                        <td>{{(job.company) ? job.company.name : "" }}</td>
                        <td>{{job.startTime | date: 'MM/dd/yy HH:mm'}}</td>
                        <td>{{job.endTime | date: 'MM/dd/yy HH:mm'}}</td>
                        <!-- <td><span *ngFor="let device of job.devices; index as i" [attr.data-index]="i">{{(i > 0) ? ', ' + device.label : device.label}}</span></td> -->
                        <td>{{job.deviceNamesAsString}}</td>
                        <td>
                            <!-- <div style="display:block">
                                <button pButton 
                                    icon="far fa-edit" 
                                    (click)="navigateToDetail(job.id)" 
                                    style="margin:auto; max-width:35px;">
                                </button>
                                <button pButton 
                                    icon="far fa-trash-alt" 
                                    (click)="confirmDelete(job)" 
                                    style="margin:auto; max-width:35px; margin-left:5px;">
                                </button>
                            </div> -->
                            <i class="far fa-edit" (click)="navigateToDetail(job.id)" style="margin-right:5px;" pTooltip="Navigate to Details for this Job."></i>
                            <i class="far fa-chart-bar" (click)="navigateToDashboard(job.id)" style="margin-right:5px;" pTooltip="Navigate to the Job Dashboard."></i>
                            <i class="fa fa-book" (click)="navigateToWorkbook(job)" style="margin-right:5px;" pTooltip="Navigate to the Job Workbook."></i>
                            <i *ngIf="isEditorOrAdmin(job)" class="fa fa-table" (click)="downloadJobExport(job.id)" style="margin-right:5px;" pTooltip="Download the Job 'DATA' Export"></i>
                            <i *ngIf="isEditorOrAdmin(job)" class="far fa-file" (click)="downloadJobSummaryExport(job.id)" style="margin-right:5px;" pTooltip="Download the Job 'SUMMARY' Export"></i>
                            <!-- pop up blocker is getting this one because of async look up-->
                            <!-- <i class="far fa-chart-bar" (click)="navigateToDashboard(job.id)" style="margin-right:5px;"></i> -->
                            <i *ngIf="isAdmin(job)" class="far fa-trash-alt" (click)="confirmDelete(job)" pTooltip="Delete this Job."></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    Showing {{dt.totalRecords < 1 ? 0 : dt.first + 1}} to 
                    {{dt.totalRecords > 0 ? ((dt.rows + dt.first) <= dt.totalRecords ? (dt.rows + dt.first) : 
                    dt.totalRecords) : 0}} of {{dt.totalRecords ? dt.totalRecords : 0}} entries
                </ng-template>
            </p-table>
        </div>
        <div class="col-12 md:col-6"></div>
        <div class="col-12 md:col-3" *ngIf="postJobAnalysisFeatureenabled">
            <button pButton type="button" label=" Post Job Analysis" (click)="navigateToPostJobAnalysis()" icon="far fa-chart-bar" style="width:180px; margin-top:10px; float:right; vertical-align:bottom;text-align: right;"></button>
        </div>
    </div>
</div>