import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WorkbookLog } from "../models/models";
import { IWorkbookQueueEntry } from "./offlineworkbook.service";

@Injectable()
export abstract class IOfflineWorkbookService  {
    abstract queueCreate(logentry: WorkbookLog): Promise<number | {}[]>;
    abstract queueUpdate(logentry: WorkbookLog);
    abstract getById(id: string): Promise<IWorkbookQueueEntry>;
    abstract queueDelete(logentry: WorkbookLog);
    abstract getQueueEntries(jobId: string): Promise<IWorkbookQueueEntry[]>;
    abstract removeQueuedEntry(entry: IWorkbookQueueEntry)
    
}