import { Casing } from './casing';
import { Plug } from './plug';
import { CasingTubings } from './casingTubings';
import { PlugSet } from './plugSet';

export class WellInfo {
    name: string;
    wellType: string;
    xoEot: number;
    kop: number;
    thirtyDegree: number;
    sixtyDegree: number;
    tvd: number;
    md: number;
    coilCapacity: number;
    ctWoTubingOd: number;
    hozTubingOd: number;
    // ctWOTubitngOdId is used in the client for knowing which CTWOTubingOD option was selected
    // since there are multiple options with the same OD value
    stringType: string;
    ctWoTubingOdId: number;
    hozTubingOdId: number;
    csgId: number;
    lateralLengthEst: number;
    casings: Casing[];
    casingTubings: CasingTubings;
    plugs: Plug[];
    plugSets: PlugSet[];
    fracPlugStages: number;
    sitp: number;
    sicp: number;

    constructor() {
        this.casings = [];
        this.plugs = [];
    }
}

export enum WellTypes {
    horizontal = 'Horizontal',
    vertical = 'Vertical'
  }

  export enum StringTypes {
    coilTubing = 'Coiled Tubing',
    jointTubing = 'Jointed Tubing'
  }
