<div *ngIf="ready" class="grid field col-12">
  
  <!-- Consumer -->
  <div class="grid field col-12">
      <div class="col-12 md:col-2">
          <label>Operator</label>
      </div>
      <div class="col-12 md:col-10">
          <p-dropdown [options]="companySelectItems" placeholder="Select Company" [(ngModel)]="jobLinkerToEdit.consumerId" [disabled]="true"></p-dropdown>
          <div *ngIf="modelInvalid && !jobLinkerToEdit.consumerId" class="grid field col-12">
              <label style="color:red; font-weight:bold;">Operator is required!</label>
          </div>
      </div>
  </div>

  <!-- WellName -->
  <div class="grid field col-12">
      <div class="col-12 md:col-2">
          <label>Well Name</label>
      </div>
      <div class="col-12 md:col-10">
          <input type="text" pInputText placeholder="Enter Well Name" [(ngModel)]="jobLinkerToEdit.wellName" />
          <div *ngIf="modelInvalid && !(jobLinkerToEdit.wellName || jobLinkerToEdit.apiNumber)" class="grid field col-12">
              <label style="color:red; font-weight:bold;">Well Name or Api Number is required!</label>
          </div>
      </div>
  </div>

  <!-- Api Number -->
  <div class="grid field col-12">
      <div class="col-12 md:col-2">
          <label>Api Number</label>
      </div>
      <div class="col-12 md:col-10">
          <p-inputMask pInputText mask="99-999-99999" [(ngModel)]="jobLinkerToEdit.apiNumber" placeholder="99-999-99999">
          </p-inputMask>
          <div *ngIf="modelInvalid && !(jobLinkerToEdit.wellName || jobLinkerToEdit.apiNumber)" class="grid field col-12">
              <label style="color:red; font-weight:bold;">Well Name or Api Number is required!</label>
          </div>
      </div>
  </div>

  <!-- Job Types -->
  <div class="grid field col-12">
      <div class="col-12 md:col-2">
          <label>Job Type</label>
      </div>
      <div class="col-12 md:col-10">
          <p-dropdown [options]="jobTypes" placeholder="Select Type" [(ngModel)]="jobLinkerToEdit.jobType" [disabled]="true"></p-dropdown>
          <div *ngIf="modelInvalid && !jobLinkerToEdit.jobType" class="grid field col-12">
              <label style="color:red; font-weight:bold;">Type is required!</label>
          </div>
      </div>
  </div>

  <!-- VendorJobs -->
  <div class="grid field col-12">
      <div class="col-12 md:col-2">
          <label>Service Provider Jobs</label>
      </div>
      <div class="col-12 md:col-10">
          <p-multiSelect [options]="vendorJobSelectItems" defaultLabel="Select Jobs" [(ngModel)]="jobLinkerToEdit.vendorJobIds" [disabled]="true"></p-multiSelect>
          <div *ngIf="modelInvalid && !jobLinkerToEdit.vendorJobIds" class="grid field col-12">
              <label style="color:red; font-weight:bold;">At least one Job is required!</label>
          </div>
      </div>
  </div>

 <!-- Consumer Job -->
 <div class="grid field col-12">
    <div class="col-12 md:col-2">
        <label>Operator Job (If exists yet)</label>
    </div>
    <div class="col-12 md:col-10">
        <p-dropdown [options]="consumerJobSelectItems" placeholder="Select ConsumerJob" [(ngModel)]="jobLinkerToEdit.consumerJobId" (onChange)="consumerJobSelected($event)" [disabled]="true"></p-dropdown>
    </div>
</div>

  <!-- Start Time -->
  <div class="grid field col-12">
      <div class="col-12 md:col-2">
          <label>Opertor Job Start</label>
      </div>
      <div class="col-12 md:col-10" pTooltip="Start time is auto set if an existing Consumer Job is selected">
          <p-calendar 
              [(ngModel)]="calendarStartTime" 
              [showIcon]="true" 
              [showTime]="true"
              [hourFormat]="24" 
              [monthNavigator]="true"
              [yearNavigator]="true" 
              yearRange="2018:2038">
          </p-calendar>
          <div *ngIf="modelInvalid && !calendarStartTime" class="grid field col-12">
              <label style="color:red; font-weight:bold;">Start Time is required!</label>
          </div>
      </div>
  </div>

  <!-- Action Buttons -->
  <div class="grid field col-12">
      <button pButton 
          class="col-12 md:col-3 ui-button-primary action-button" 
          *ngIf="isNew" 
          (click)="saveJobLinker()" >
          Create
      </button>
      <button pButton 
          class="col-12 md:col-3 ui-button-primary action-button" 
          *ngIf="!isNew" 
          (click)="saveJobLinker()">
          Update
      </button>
      <button pButton 
          class="col-12 md:col-3 p-button-secondary action-button" 
          (click)="cancel()">
          Cancel
      </button>
  </div>

</div>
