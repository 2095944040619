import { Observable, Subject ,  of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IOfflineService } from './iOffline.service';

@Injectable()
export class OfflineService implements IOfflineService {
    private _online =  new Subject<boolean>();

    constructor(private _http: HttpClient) {
        this.initializeOnline();
        window.addEventListener('online',  () => {console.info('going online'); this._online.next(false);});
        window.addEventListener('offline', () => {console.info('going offline'); this._online.next(true);});
    }

    isOffline(): Observable<boolean> {
        // tslint:disable-next-line:no-console
       // console.info(`isonline?${navigator.onLine}`);
        return of(!navigator.onLine);
       // return of(!navigator.onLine)
        // return this._http.get('/ping').pipe(
        //     map((res:boolean)=>res),
        //     catchError((err: HttpErrorResponse)=>of(false))
        //     );
    }
    isOfflineObservable(): Observable<boolean> {
        return this._online;
    }

    private initializeOnline() {
        this.isOffline().subscribe(
            result => { this._online.next(result); }
        );
    }
}
