import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JobLinker, WorkbookJobTypes } from 'src/app/models/models';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-job-linker-list',
  templateUrl: './job-linker-list.component.html',
  styleUrls: ['./job-linker-list.component.css']
})
export class JobLinkerListComponent implements OnInit {

  @Input() jobLinkers: JobLinker[] = [];
  @Input() showAdminControls = false;
  @Input() jobTypes: SelectItem[] = [];
  @Input() statuses: SelectItem[] = [];
  @Input() vendorJobSelectItems: SelectItem[] = [];
  @Output() openJobLinkerToEdit = new EventEmitter<JobLinker>();
  @Output() jobLinkerDeleted = new EventEmitter<JobLinker>();
  @Output() showEdit = new EventEmitter<boolean>();

  jobLinkerCols: any[] = [];

  constructor() { }

  ngOnInit() {
    this.jobLinkerCols = this.setupJobLinkerTable(this.showAdminControls);
  }

  setupJobLinkerTable(showAdminControls: boolean): any[] {
    const cols = [
      { field: 'consumer', header: 'Operator', filterMatchMode: 'contains' },
      { field: 'wellName', header: 'Well Name', filterMatchMode: 'contains' },
      { field: 'apiNumber', header: 'API Number', filterMatchMode: 'contains' },
      { field: 'jobType', header: 'Job Type', filterMatchMode: 'in' },
      { field: 'consumerJobStartTime', header: 'Operator Job Start', filterMatchMode: '' },
      { field: 'consumerJobStatus', header: 'Operator Job Status', filterMatchMode: 'in' }
    ];
    if (showAdminControls) {
      cols.push({ field: 'vendorJobNames', header: 'Service Provider Jobs', filterMatchMode: 'contains'});
      cols.push({ field: '', header: '', filterMatchMode: '' } );
    }
    return cols;
  }

  addJobLinker() {
    this.openJobLinkerToEdit.emit(null);
  }

  editJobLinker(jobLinker: JobLinker) {
    this.openJobLinkerToEdit.emit(jobLinker);
  }

  deleteJobLinker(jobLinker: JobLinker) {
    this.jobLinkerDeleted.emit(jobLinker);
  }

  closeJobLinkerEdit() {
    this.showEdit.emit(false);
  }

}
