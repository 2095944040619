import { BaseEntity } from './baseEntity';
import { SelectItem } from 'primeng/api';
import { CompanyRole } from './companyRole';
import { User as NswagUser} from '../services/nswag/nswagclient'
export class User implements BaseEntity {
    id: string;
    email: string;
    lastName: string;
    firstName: string;
    contactNumber: string;
    active: boolean;
    isEmployee: boolean;
    role: string;
    companyIds: string[];
    hasAllCompany: boolean;
    grafanaUserId: number;
    azureObjectId: string;
    isGlobalAdmin: boolean;
    companyRoles: CompanyRole[] = [];
    companyJobTitle: string;

    name: string;
    companies: SelectItem[];
    companyNames: string;
    public static fromNSwag(user:NswagUser) : User{
        let result = new User(user.id, user.email, user.lastName, user.firstName, user.contactNumber, user.isEmployee, user.role, user.hasAllCompany, user.active, user.companyRoles);
        return Object.assign(result, user);
    }
    constructor(
        id: string,
        email: string,
        lastName: string,
        firstName: string,
        contactNumber: string,
        isEmployee: boolean,
        role: string,
        hasAllCompany: boolean = false,
        active: boolean = true,
        companyRoles:CompanyRole[] =[]
    ) {
        this.id = id;
        this.email = email;
        this.lastName = lastName;
        this.firstName = firstName;
        this.contactNumber = contactNumber;
        this.isEmployee = isEmployee;
        this.role = role;
        this.hasAllCompany = hasAllCompany;
        this.active = (active === null) ? true : active;

        this.name = firstName + ' ' + lastName;
        this.companies = [];
        this.companyRoles = companyRoles;

        this.grafanaUserId = 0;
        this.azureObjectId = null;
        
    }
}

