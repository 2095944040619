import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User, UserName } from '../models/models';
import { UserRoles } from '../models/models';
import { Injectable } from '@angular/core';
import { IUserService } from './iUser.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUser, UserClient } from './nswag/nswagclient';

@Injectable()
export class UserService extends IUserService {

    constructor(private _http: HttpClient, private _userClient:UserClient) {
        super();
    }

    getUsers(): Observable<User[]> {
        const request = this._http.get('api/user').pipe(map(
            result => {
                return result as User[];
            }
        ));
        return request;
    }

    getUser(userId: string): Observable<User> {
        const request = this._http.get('api/user/' + userId).pipe(map(
            result => {
                return result as User;
            }
        ));
        return request;
    }

    createUser(user: User): Observable<any> {
        const request = this._http.post('api/user', user).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    updateUser(userId: string, updatedUser: User): Observable<any> {
        const request = this._http.put('api/user/' + userId, updatedUser).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    deleteUser(userId: string): Observable<any> {
        const request = this._http.delete('api/user/' + userId).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }




    isAdmin(email: string, companyId: string = null, oldRoles = true): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this._userClient.getByEmail(email).subscribe(
                user => {
                    if (user) {
                        // For Company Role based roels
                        if (companyId && !oldRoles) {
                            let isCompanyAdmin = false;
                            if (user.companyRoles) {
                                const companyRole = user.companyRoles.find(r => r.companyId === companyId);
                                if (companyRole.role === UserRoles.admin) {
                                    isCompanyAdmin = true;
                                }
                            }
                            observer.next(isCompanyAdmin);
                            observer.complete();
                        }
                        if (user.role === UserRoles.admin) {
                            observer.next(true);
                        } else { observer.next(false); }
                        observer.complete();
                    } else {
                        observer.error('Could not get User Role from email: ' + email);
                    }
                },
                error => {
                    observer.error('Could not get User from email: ' + email);
                });
        });
    }

    isEditorOrAdmin(email: string, companyId: string = null, oldRoles = true): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this._userClient.getByEmail(email).subscribe(
                user => {
                    if (user) {
                        // For Company Role based roels
                        if (companyId && !oldRoles) {
                            let isCompanyAdmin = false;
                            if (user.companyRoles) {
                                const companyRole = user.companyRoles.find(r => r.companyId === companyId);
                                if (companyRole.role === UserRoles.admin || companyRole.role === UserRoles.editor) {
                                    isCompanyAdmin = true;
                                }
                            }
                            observer.next(isCompanyAdmin);
                            observer.complete();
                        }
                        if (user.role === UserRoles.admin || user.role === UserRoles.editor) {
                            observer.next(true);
                        } else { observer.next(false); }
                        observer.complete();
                    } else {
                        observer.error('Could not get User Role from email: ' + email);
                    }
                },
                error => {
                    observer.error('Could not get User from email: ' + email);
                });
        });
    }

    isUserAdmin(user: User, companyId: string, oldRoles: boolean = false): boolean {
        if (user) {
            if (user.isGlobalAdmin) { return true; }
            if (oldRoles) { // Before Company Administration Feature
                if (user.role === UserRoles.admin) {
                    return true;
                }
            } else if (user.companyRoles) {
                let anyRolesFound = false;
                user.companyRoles.map(r => {
                  if (r.companyId === companyId && r.role === UserRoles.admin) {
                    anyRolesFound = true;
                  }
                });
                return anyRolesFound;
            }
        }
        return false;
    }

    isUserAnyRoleAdmin(user: User, oldRoles: boolean = false): boolean {
        if (user) {
            if (user.isGlobalAdmin) { return true; }
            if (oldRoles) {
                if (user.role === UserRoles.admin) { return true; }
            } else {
                let anyRolesFound = false;
                user.companyRoles.map(r => {
                    if (r.role === UserRoles.admin) {
                        anyRolesFound = true;
                    }
                });
                return anyRolesFound;
            }
        }
        return false;
    }

    isUserEditorOrAdmin(user: User, companyId: string, oldRoles: boolean = false): boolean {
        if (user) {
            if (user.isGlobalAdmin) { return true; }
            if (oldRoles) { // Before Company Administration Feature
                if (user.role === UserRoles.admin || user.role === UserRoles.editor) {
                    return true;
                }
            } else if (user.companyRoles) {
                let anyRolesFound = false;
                user.companyRoles.map(r => {
                  if (r.companyId === companyId && (r.role === UserRoles.admin || r.role === UserRoles.editor)) {
                    anyRolesFound = true;
                  }
                });
                return anyRolesFound;
            }
        }
        return false;
    }

    isUserAnyRoleEditorOrAdmin(user: User, oldRoles: boolean = false): boolean {
        if (user) {
            if (user.isGlobalAdmin) { return true; }
            if (oldRoles) {
                if (user.role === UserRoles.admin || user.role === UserRoles.editor) {
                    return true; }
            } else {
                let anyRolesFound = false;
                user.companyRoles.map(r => {
                    if (r.role === UserRoles.admin || user.role === UserRoles.editor) {
                        anyRolesFound = true;
                    }
                });
                return anyRolesFound;
            }
        }
        return false;
    }

    getUserNames(): Observable<UserName[]> {
        return this._http.get<UserName[]>('api/user/names');
    }

    getUserName(userId: string): Observable<UserName> {
        return this._http.get<UserName>('api/user/name/' + userId);
    }

    getSpecifiedUserNames(userIds: string[]): Observable<UserName[]> {
        const params = new HttpParams();
        params.append('userIds', userIds.join(','));
        return this._http.get<UserName[]>('api/user/specifiednames', { params : params });
    }

    getSupervisorUserNamesForCompany(companyId: string): Observable<UserName[]> {
        return this._http.get<UserName[]>('api/user/supervisors/' + companyId);
    }

}
