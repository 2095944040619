<div class="grid field col-12">
    <div class="grid field col-12">
        <!-- Default Duration -->
        <div class="col-12 md:col-2">
            <label>Default Duration (hrs)</label>
        </div>
        <div class="col-12 md:col-3">
            <div >
                <input type="number" pInputText size="5" placeholder="Enter Duration" [(ngModel)]="defaults.duration" min="0" />
            </div>
        </div>
        <!-- Default Reel Speed -->
        <div class="col-12 md:col-2">
            <label>Default Pipe Speed (ft/min)</label>
        </div>
        <div class="col-12 md:col-3">
            <div >
                <input type="number" pInputText size="5" placeholder="Enter Pipe Speed" [(ngModel)]="defaults.pipeSpeed" min="0" />
            </div>
        </div>
    </div>
    <div class="grid field col-12">
        <!-- Default Circulation -->
        <div class="col-12 md:col-2">
            <label>Default Circulation Rate (bbl/min)</label>
        </div>
        <div class="col-12 md:col-3">
            <div >
                <input type="number" pInputText size="5" placeholder="Enter Circulation" [(ngModel)]="defaults.circulationRate" min="0" />
            </div>
        </div>
        <!-- Default Flowback Rate -->
        <div class="col-12 md:col-2">
            <label>Default Return Rate (bbl/min)</label>
        </div>
        <div class="col-12 md:col-3">
            <div >
                <input type="number" pInputText size="5" placeholder="Enter Return Rate" [(ngModel)]="defaults.returnRate" min="0" />
            </div>
        </div>
    </div>
</div>