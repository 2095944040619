import { Component, OnInit } from '@angular/core';
import { SimulationDefault } from 'src/app/models/simulation';

@Component({
  selector: 'simulation-activity-defaults',
  templateUrl: './activity-defaults.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css'],
})
export class ActivityDefaultsComponent implements OnInit {
  //@Output() defaultsChanged = new EventEmitter();
  
  defaults: SimulationDefault;
  
  constructor() { }

  ngOnInit() {
    this.defaults = <SimulationDefault> { };
  }

  /* Send an event of the configuration change */
  // onDefaultsChange() {
  //   this.defaultsChanged.emit();
  // }
}
