import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CompanyAssociationRequest } from '../../models/requests/companyAssociationRequest';

@Injectable()
export abstract class ICompanyAssociationRequestService {
  abstract getCompanyAssociationRequests(): Observable<CompanyAssociationRequest[]>;
  abstract getCompanyAssociationRequestById(id: string): Observable<CompanyAssociationRequest>;
  abstract createCompanyAssociationRequest(companyAssociationRequest: CompanyAssociationRequest): any;
  abstract approveCompanyAssociationRequest(id: string): any;
  abstract rejectCompanyAssociationRequest(id: string, reason: string): any;
  abstract deleteCompanyAssociationRequest(id: string): any;
  abstract getPendingCompanyAssoicationRequestsForUser(userId: string): Observable<CompanyAssociationRequest[]>;
}
