import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message, SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { Job, Features, User } from '../../../models/models';
import { IJobService, ICompanyService, IDeviceService, IUserService, IFeaturesService } from '../../../services/services';
import { environment } from '../../../../environments/environment';
import { Title } from '@angular/platform-browser';

import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { first } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {

  public cols: any[] = [];
  public jobsReady = false;
  public jobs: Job[] = [];

  public deviceNamesSelection: SelectItem[] = [];
  public companiesReady = false;
  public devicesReady = false;
  public user: User;
  public userReady = false;
  public offlineWorkbookFeatureEnabled = false;
  public postJobAnalysisFeatureenabled = false;
  public isCompanyAdministrationEnabled = false;
  public featuresReady = false;
  public everythingReady = false;
  public securityToken: string;
  messages: Message[] = [];
  waiting = false;

  constructor(
    private _jobService: IJobService,
    private _companyService: ICompanyService,
    private _deviceService: IDeviceService,
    private _userService: IUserService,
    private _featuresService: IFeaturesService,
    private _router: Router,
    private _userManagerService: IUserManagerService,
    private _confirmationService: ConfirmationService,
    private _titleService: Title,
    private _currentUserService: ICurrentUserService
  ) { }


  ngOnInit() {
    this.waiting = true;
    this.setPageTitle();
    this.setColumns();
    this.getJobs();
    this.getFeatures();
    this.getAuthenticatedUser();
    this.setSecurityToken();
  }
  setSecurityToken(){

    this._currentUserService.getAccessToken().subscribe(s=>{
      this.securityToken = s;
    });
  }
  setPageTitle() {
    this._titleService.setTitle('OpsViewer Jobs');
  }

  setColumns() {
    this.cols = [
      {field: 'name', header: 'Name', width: '200px', filterMatchMode: 'contains'},
      {field: 'company.name', header: 'Company', width: '200px', filterMatchMode: 'contains'},
      {field: 'startTime', header: 'Start', width: '150px'},
      {field: 'endTime', header: 'End', width: '150px'},
      {field: 'devices', header: 'Devices', width: '150px', filterMatchMode: 'contains', alternativeFilterField: 'deviceNamesAsString'},
      {field: '', header: '', width: '175px'},
    ];
  }

  getJobs() {
    this.messages = [];
    this._jobService.getJobs().subscribe(
      results => {
        this.jobs = results;
        this.jobs = this.jobs.filter(j => j.__type === 'Job');
        this.getCompanies();
        this.getDevices();
        this.jobsReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        const detail = error.error ? error.error.Message : error.message;
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get Jobs',
          detail: detail
        });
        console.error(error);
      }
    );
  }

  getCompanies() {
    this._companyService.getCompanies().subscribe(
      results => {
        results.forEach(result => {
          const jobs = this.jobs.filter(job => job.companyId === result.id);
          jobs.forEach(job => { job.company = result; });
        });
        this.companiesReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get list of companies',
          detail: ''
        });
        console.error(error);
      }
    );
  }

  getDevices() {
    this._deviceService.getDevices().subscribe(
      results => {
        this.deviceNamesSelection = [];
        results.forEach(result => {
          this.deviceNamesSelection.push({ label: result.name, value: result.id });
          const jobs = this.jobs.filter(j => j.deviceList !== null && j.deviceList.includes(result.id) );
          jobs.forEach(job => {
            if (!job.devices) { job.devices = []; }
            const deviceToAdd = { label: result.name, value: result.id };
            if (!job.devices.includes(deviceToAdd)) { job.devices.push(deviceToAdd); }
            if (!job.deviceNamesAsString) {
              job.deviceNamesAsString = deviceToAdd.label;
            } else {
              job.deviceNamesAsString = job.deviceNamesAsString + ', ' + deviceToAdd.label;
            }
          });
        });
        this.devicesReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        this.handleError(error, 'Could not get Companies');
      }
    );
  }

  getFeatures() {
    // Offline Workbook
    this._featuresService.getEnabledFeatures().subscribe( // .isFeatureEnabled(Features.OfflineWorkbook).subscribe(
      result => {
        if (result === null) { return; }
        this.offlineWorkbookFeatureEnabled = result.indexOf(Features.OfflineWorkbook) >= 0;
        this.postJobAnalysisFeatureenabled = result.indexOf(Features.PostJobAnalysis) >= 0;
        this.isCompanyAdministrationEnabled = result.indexOf(Features.CompanyAdministration) >= 0;
      },
      error => {},
      () => {
        this.featuresReady = true;
        this.checkIfEverythingReady();
      }
    );
  }

  getAuthenticatedUser() {

    this._userManagerService.getCurrentUser().subscribe(u=>{
      this.user = u;
      this.userReady = true;
      this.checkIfEverythingReady();
    });
  }
  // getAuthenticatedUser() {
  //   this._msalService.getUserEmail().subscribe(
  //     userEmail => {
  //       if (userEmail) {
  //         this._userService.getUserByEmail(userEmail).subscribe(
  //           userResult => {
  //             this.user = userResult;
  //             this.userReady = true;
  //             this.checkIfEverythingReady();
  //           },
  //           error => { this.handleError(error, 'Could not get user'); }
  //         );
  //       }
  //     },
  //     error => { this.handleError(error, 'Could not get user'); }
  //   );
  // }

  isEditorOrAdmin(job: Job): boolean {
    if (this.isCompanyAdministrationEnabled) {
      return this._userService.isUserEditorOrAdmin(this.user, job.companyId, false);
    } else {
      return this._userService.isUserEditorOrAdmin(this.user, job.companyId, true);
    }
  }

  isAdmin(job: Job): boolean {
    if (this.isCompanyAdministrationEnabled) {
      return this._userService.isUserAdmin(this.user, job.companyId, false);
    } else {
      return this._userService.isUserAdmin(this.user, job.companyId, true);
    }
  }

  getYearReport() {
    window.open('/api/job/exportannualsummary/' + ((new Date()).getFullYear() - 1) + '?token=' + this.securityToken, '_blank');
  }

  checkIfEverythingReady() {
    this.everythingReady = this.jobsReady && this.companiesReady && this.devicesReady && this.userReady && this.featuresReady;
    this.waiting = !this.everythingReady;
  }

  navigateToAddJob() {
    // this._msalService.login();
    this._router.navigate(['/job/' + encodeURIComponent('new')]);
  }

  navigateToDetail(jobId: string) {
    this._router.navigate(['/job/' + encodeURIComponent(jobId)]);
  }

  navigateToDashboard(jobId: string) {
    this._jobService.getDashboardLink(jobId)
        .subscribe(l =>  {
          window.open(l,"_blank");
        });
  }

  navigateToPostJobAnalysis() {
    if (this.postJobAnalysisFeatureenabled) {
      this._router.navigate(['/jobs/postjobanalysis/']);
    }
  }

  navigateToWorkbook(job: Job) {
    if (this.offlineWorkbookFeatureEnabled && job.enableOfflineWorkbook) {
      this._router.navigate(['/workbook2/' + encodeURIComponent(job.id)]);
    } else {
      this._router.navigate(['/workbook/' + encodeURIComponent(job.id)]);
    }
  }


  refresh() {
    this.cols = [];
    this.jobsReady = false;
    this.jobs = [];
    this.messages = [];
    this.ngOnInit();
  }

  confirmDelete(job: Job) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + job.name + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteJob(job.id); }// ,
        // reject: () => { }
      }
    );
  }

  deleteJob(jobId: String) {
    this.waiting = true;
    this._jobService.deleteJob(jobId).subscribe(
      result => {
        this.waiting = false;
        this.refresh();
        this.messages = [];
        this.messages.push({
          severity: 'info',
          summary: 'Job Deleted!',
          detail: ''
        });
      },
      errorResult => {
        let returnedErrors = '';
        if (errorResult.error.Message.includes('Validation Error')) {
          returnedErrors = errorResult.error.Message;
        }
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not Delete Job',
          detail: returnedErrors
        });
      }
    );
  }


  downloadJobExport(jobId: string) {
    window.open('/api/job/export/' + jobId + '?token=' + this.securityToken, '_blank');
  }

  downloadJobSummaryExport(jobId: string) {
    window.open('/api/job/exportsummary/' + jobId + '?token=' + this.securityToken, '_blank');
  }

  handleError(error: any, displayMessage: string) {
    this.messages = [{
      severity: 'error',
      summary: displayMessage,
      detail: error as string
    }];
    console.error(error);
  }

}
