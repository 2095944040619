import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyCalculatedFieldConfiguration,
         CalculatedFieldConfiguration } from '../models/models';
import { Injectable } from '@angular/core';
import { ICalculatedFieldConfigurationService } from './iCalculatedFieldConfiguration.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { List } from 'linqts';

@Injectable()
export class CalculatedFieldConfigurationService extends ICalculatedFieldConfigurationService {
    baseUrl = 'api/calculatedfield/';

    constructor(private _http: HttpClient) {
        super();
    }

    getConfigurationForCompany(companyId: string): Observable<CompanyCalculatedFieldConfiguration> {
        const request = this._http.get(this.baseUrl + companyId).pipe(map(
            result => {
                return result as CompanyCalculatedFieldConfiguration;
            }
        ));
        return request;
    }

    createOrUpdateConfiguration(configuration: CompanyCalculatedFieldConfiguration): Observable<any> {
        console.log("configuration", configuration);
        const request = this._http.post(this.baseUrl, configuration).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }
}
