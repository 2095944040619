<div class="grid col-12">
 
  <div *ngIf="!userReady" class="col-12">
      <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="col-12" *ngIf="messages.length > 0">
      <p-messages [(value)]="messages"></p-messages>
  </div>
  <div class="col-12" *ngIf="!fromNewCompanyLayoutButton && !fromCompanyAssociationLayoutButton">
      <p-accordion [multiple]="true">
          <p-accordionTab *ngIf="isUserGlobalAdmin()" header="New Company Requests" [selected]="true">
            <app-new-company-request [user]="user" (error)="displayErrors($event)"></app-new-company-request>
          </p-accordionTab>
          <p-accordionTab header="Company Association Requests" [selected]="true">
              <app-company-association-request [user]="user" (error)="displayErrors($event)"></app-company-association-request>
            </p-accordionTab>
        </p-accordion>
  </div>
  <div class="col-12" *ngIf="fromNewCompanyLayoutButton">
      <app-new-company-request [user]="user" (error)="displayErrors($event)" [dialogOnly]="fromNewCompanyLayoutButton"></app-new-company-request>
  </div>
  <div class="col-12" *ngIf="fromCompanyAssociationLayoutButton">
      <app-company-association-request [user]="user" (error)="displayErrors($event)" [dialogOnly]="fromCompanyAssociationLayoutButton"></app-company-association-request>
  </div>
</div>
