
<div *ngIf="viewModel$ | async;let viewModel" style="height: calc(100vh - 68px);" class="pr-1">
    <p-confirmPopup></p-confirmPopup>
    <p-table #dt1 [value]="viewModel.jobs" [scrollable]="true" scrollHeight="flex" sortField="Start" [sortOrder]="-1" sortField="startTime" dataKey="name" [globalFilterFields]="['name', 'apiNumber', 'wellInfo.name']" >
        
        <ng-template pTemplate="caption">
            <div class="flex">
                
                    <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>API</th>
                <th>Well Name</th>
                <!-- <th>Operator Name</th> -->
                <th pSortableColumn="startTime">Start<p-sortIcon field="startTime"></p-sortIcon></th>
                <th>End</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-job let-expanded="expanded">
            <tr>
                <td> <button title="expand" type="button" pButton pRipple [pRowToggler]="job" class="p-button-text p-0"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    {{ job.name }}</td>
                <td>{{job.apiNumber}}</td>
                <td>{{job.wellInfo.name}}</td>
                <!-- <td>{{job.consumerCompanyName}}</td> -->
                <td>{{job.startTime | date: 'MM/dd/yy HH:mm'}}</td>
                <td>{{job.endTime | date: 'MM/dd/yy HH:mm'}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-job>
            <ng-container *ngIf="getJobTickets(job.id) | async; let tickets">
                <tr>
                    <td colspan="7">
                        <p-table *ngIf="tickets.length > 0" [value]="tickets" dataKey="name">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>File Name <a [href]="'/api/JobTicket/downloadzip/' + job.id +'?token=' + viewModel.userToken">download all</a> </th>
                                    <th>Ticket</th>
                                    <th>Accounting</th>
                                    <th>Field</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-ticket>
                                <tr>
                                    <td><button title="delete" type="button" pButton pRipple (click)="delete(ticket, job.id)" class="p-button-text p-0"
                                        [icon]="'pi pi-trash'"></button>  <a [href]="ticket.fileUri">{{ticket.name}}</a></td>
                                    <td>{{ticket.ticketName}}</td>
                                    <td><input type="checkbox" title="accounting" [(ngModel)]="ticket.accounting" (ngModelChange)="updateAccounting(ticket)" /> </td>
                                    <td><input type="checkbox" title="field" [(ngModel)]="ticket.field" (ngModelChange)="updateField(ticket)" /> </td>
                                </tr>
                            </ng-template>
                        </p-table>
                     
                        <div>
                        <div>Select a ticket to upload</div>
                            <p-fileUpload (uploadHandler)="upload2($event, job.id)" customUpload="true"
                                [multiple]="false">
                                <ng-template let-file pTemplate="file">
                                    <div class="grid">
                                        <div class="col-2" >{{file.name | fileName}}</div>

                                        <div class="col-3">
                                            <!-- <label htmlFor="username">Ticket Name</label> -->
                                            <input title="name" pInputText id="username" [(ngModel)]="file.ticketName" placeholder="ticket name" />
                                            <!-- <small id="ticket-help">Enter the ticket name</small> aria-describedby="ticket-help" -->
                                        </div>

                                        <!-- <div class="col-2">
                                            <button title="upload" type="button" icon="pi pi-times" pButton
                                                [disabled]="uploading"></button>
                                        </div> -->
                                    </div>
                                </ng-template>
                            </p-fileUpload>

                        </div>
                    </td>
                </tr>
            </ng-container>
        </ng-template>

    </p-table>
</div>