import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConsumerJob } from '../models/models';

@Injectable()
export abstract class IConsumerJobService  {

  abstract getJobs(): Observable<ConsumerJob[]>;

  abstract getJob(jobId: string): Observable<ConsumerJob>;

  abstract associateVendorToConsumerJob(consumerJobId: string, vendorId: string): Observable<any>;

  abstract deassociateVendorToConsumerJob(consumerJobId: string, vendorId: string): Observable<any>;

  abstract deassociateVendorJobToConsumerJob(consumerJobId: string, vendorJobId: string): Observable<any>;

  abstract updateConsumerJob(consumerJob: ConsumerJob): Observable<any>;

  abstract deleteConsumerJob(consumerJobId: string): Observable<any>;

  abstract getDashboardLink(consumerJobId: string): Observable<string>;
}
