import { Component, OnInit } from '@angular/core';
import { IUserService, IOpsViewerMessagesService } from 'src/app/services/services';
import { Message } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/models';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {

  user: User;
  userReady = false;
  messages: Message[] = [];

  fromNewCompanyLayoutButton = false;
  fromCompanyAssociationLayoutButton = false;
  routeCheckReady = false;

  everythingReady = false;

  constructor(
    private _userService: IUserService,
    private _messageService: IOpsViewerMessagesService,
    private _userManagerService : IUserManagerService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.checkIfCameFromRoute();
    this.getAuthenticatedUser();
  }

  checkIfCameFromRoute() {
    this._route.params.subscribe(
      params => {
        const requestType: string = params['requestType'];
        if (requestType && requestType.toLowerCase() === 'newcompany') {
          this.fromNewCompanyLayoutButton = true;
        } else if (requestType && requestType.toLowerCase() === 'companyassociation') {
          this.fromCompanyAssociationLayoutButton = true;
        }
      });
    this.routeCheckReady = true;
    this.checkIfEverythingReady();
  }



  getAuthenticatedUser() {

    this._userManagerService.getCurrentUser().subscribe(u=>{
      this.user = u;
      this.userReady = true;
      this.checkIfEverythingReady();
    });
  }

  checkIfEverythingReady() {
    this.everythingReady = this.userReady && this.routeCheckReady;
  }

  displayErrors(event: any) {
    this.messages = event;
  }

  handleError(error: any, displayMessage: string) {
    this._messageService.errorMessage(error, displayMessage);
  }

  isUserGlobalAdmin(): boolean {
    if (!this.user) { return false; }
    return this.user.isGlobalAdmin;
  }
}
