<!-- Host Port -->
<div class="grid field col-12">
    <div class="col-12 md:col-2">
        <label>Host:Port</label>
    </div>
    <div class="col-12 md:col-6">
        <input *ngIf="config!=null" type="text" pInputText placeholder="Enter Host:Port"  [(ngModel)]="config.hostPort" />
    </div>
</div>
<!-- Active -->
<div class="grid field col-12">
    <div class="col-12 md:col-2">
        <label>Active?</label>
    </div>
    <div class="col-12 md:col-6">
        <p-checkbox *ngIf="config!=null" [(ngModel)]="config.active" binary="true"></p-checkbox>
    </div>
</div>
<!-- Last Message -->
<div class="grid field col-12">
    <div class="col-12 md:col-2">
        <label>Last Message</label>
    </div>
    <div class="col-12 md:col-6">
        {{config?.lastMessage}}
    </div>
</div>