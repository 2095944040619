<p-progressSpinner *ngIf="!everythingReady"></p-progressSpinner>
<div class="grid p-1 m-1"  *ngIf="everythingReady" >
    <div class="col-12" *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>
        <div class="col-6"><h2 style="margin-bottom:2px; padding-bottom:0px;">Users</h2></div>
        <div class="col-6">
            <button pButton type="button" label="Add" (click)="navigateToAddUser()" icon="fa fa-plus" style="margin-top:20px; float:right; vertical-align:bottom;"></button>
        </div>
        <div class="col-12">
            <p-table #dt  [columns]="cols" [value]="users" [scrollable]="true" [paginator]="true" [rows]="25" [loading]="!usersReady" sortField="companyNames">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.max-width]="col.width" >
                            {{col.header}}
                            <p-sortIcon *ngIf="col.field != 'isEmployee' && col.field != 'allCompanies' && col.field != 'active' && col.field != ''" [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <div>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'contains')" 
                                />
                            </div>
                            </th>
                            <th>
                            <div >
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'contains')"  />
                            </div>
                        </th>
                        <th>
                            <div >
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'companyNames', 'contains')" />
                            </div>
                        </th>
                        <th [style.max-width]="'90px'"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td>{{user.email}}</td>
                        <td>{{user.name}}</td>
                        <td>{{user.companyNames}}</td>
                        <td style="max-width: 90px;">
                            <i class="fas fa-edit fa-lg" (click)="navigateToDetail(user.id)" style="margin-right:5px;"></i>
                            <i *ngIf="isSuperAdmin()" class="fas fa-trash-alt fa-lg" (click)="confirmDelete(user)"></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    Showing {{dt.totalRecords < 1 ? 0 : dt.first + 1}} to 
                    {{dt.totalRecords > 0 ? ((dt.rows + dt.first) <= dt.totalRecords ? (dt.rows + dt.first) : 
                        dt.totalRecords) : 0}} of {{dt.totalRecords ? dt.totalRecords : 0}} entries
                </ng-template>
            </p-table>
        </div>
    
</div>