<div *ngIf="wellInfo != null" class="grid field col-12">
    <h4>Information related to the Well</h4>
    <div class="grid field col-12">
        <!-- XO/EOT -->
        <div class="col-12 md:col-1">
            <label>XO/EOT</label>
        </div>
        <div class="col-12 md:col-1">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.xoEot" (change)="setLateralLengthEst()" min="0" />
        </div>
        <!-- KOP -->
        <div class="col-12 md:col-1">
            <label>KOP</label>
        </div>
        <div class="col-12 md:col-1">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.kop" (change)="setLateralLengthEst()" min="0" />
        </div>
        <!-- 30 Degree -->
        <div class="col-12 md:col-1">
            <label>30 Degree</label>
        </div>
        <div class="col-12 md:col-1">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.thirtyDegree" (change)="setLateralLengthEst()" min="0" />
        </div>
        <!-- 60 Degree -->
        <div class="col-12 md:col-1">
            <label>60 Degree</label>
        </div>
        <div class="col-12 md:col-1">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.sixtyDegree" (change)="setLateralLengthEst()" min="0" />
        </div>
        <!-- 90 Degree (used be calleded TVD) -->
        <div class="col-12 md:col-1">
            <label>90 Deg</label>
        </div>
        <div class="col-12 md:col-1">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.tvd" (change)="setLateralLengthEst()" min="0" />
        </div>
        <!-- PBTD (Used to be called MD) -->
        <div class="col-12 md:col-1">
            <label>PBTD</label>
        </div>
        <div class="col-12 md:col-1">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.md" (change)="setLateralLengthEst()" min="0" />
        </div>
    </div>
    <div class="grid field col-12">
        <!--Lateral Length Est -->
        <div class="col-12 md:col-1">
            <label>Lateral Length Est.</label>
        </div>
        <div class="col-12 md:col-3">
            <label class="readOnly-label">{{wellInfo.lateralLengthEst}}</label>
        </div>
    </div>
    <div class="grid field col-12">
        <!-- String Type -->
        <div class="col-12 md:col-1">
            <label>Workstring Type</label>
        </div>
        <div class="col-12 md:col-2">
            <p-dropdown [options]="stringTypes" [(ngModel)]="wellInfo.stringType" placeholder="Select String Type" [style]="{'width':'100%'}"></p-dropdown>
        </div>
        <div class="col-12 md:col-1" *ngIf="wellInfo.stringType != 'Jointed Tubing'">
            <label>Tubing OD (in)</label>
        </div>
        <div class="col-12 md:col-1" *ngIf="wellInfo.stringType == 'Jointed Tubing'">
            <label>VIR Tubing OD</label>
        </div>
        <div class="col-12 md:col-2">
            <p-dropdown [options]="ctwoTubingODs" [(ngModel)]="selectedCTWOTubingOD" (onChange)="setCTWOTubingOD()" placeholder="Select Size" [style]="{'width':'100%'}"></p-dropdown>
        </div>
        <!-- Coil Capacity -->
        <div class="col-12 md:col-1">
            <label>Capacity (BBLS)</label>
        </div>
        <div class="col-12 md:col-1">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.coilCapacity" min="0" />
        </div>
    </div>
    <div class="grid field col-12" *ngIf="wellInfo.stringType == 'Jointed Tubing'">
        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-2"></div>
        <div class="col-12 md:col-1">
            <label>HOZ Tubing OD</label>
        </div>
        <div class="col-12 md:col-2">
            <p-dropdown [options]="hozTubingODs" [(ngModel)]="selectedHozTubingOD" (onChange)="setHozTubingOD()" placeholder="Select Size" [style]="{'width':'100%'}"></p-dropdown>
        </div>
    </div>
    <div class="grid field col-12">
        <div class="col-12 md:col-1">
            <label># Plugs in Well</label>
        </div>
        <div class="col-12 md:col-3">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.fracPlugStages" min="0" />
        </div>
        <!--SITP Est -->
        <div class="col-12 md:col-1">
            <label>SITP</label>
        </div>
        <div class="col-12 md:col-3">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.sitp" />
        </div>
        <!--SICP Est -->
        <div class="col-12 md:col-1">
            <label>SICP</label>
        </div>
        <div class="col-12 md:col-3">
            <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="wellInfo.sicp" />
        </div>
    </div>
    <p-fieldset legend="Production Casing/Tubing" class="grid field col-12" [toggleable]="true" [collapsed]="false" >
        <app-casing-tubing [(ngModel)]="wellInfo.casingTubings"></app-casing-tubing>
    </p-fieldset>
    <p-fieldset legend="Plugs" class="grid field col-12" [toggleable]="true" [collapsed]="true" >
        <app-plugs [(ngModel)]="wellInfo.plugSets"></app-plugs>
    </p-fieldset>
</div>