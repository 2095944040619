<h2>Job Simulations</h2>
<nav>
    <div class="grid field col-12">
        <div class="col-12 md:col-2">
            <a routerLink="./" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Simulations List</a>
        </div>
        <div class="col-12 md:col-2">
            <a routerLink="./jobsimulator" routerLinkActive="active">New Simulation</a>
        </div>
    </div>
</nav>
<router-outlet></router-outlet>