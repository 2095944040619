import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Device } from '../models/models';
import { Injectable } from '@angular/core';
import { IDeviceService } from './iDevice.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

//TODO: Updated HttpClient ==> AuthHttp for jwt token based requests

@Injectable()
export class DeviceService extends IDeviceService {
    
    constructor(private _http:HttpClient) {
        super();
    }
    
    getDevices(): Observable<Device[]> {
       let request = this._http.get('api/device').pipe(map(
           result => {
               return result as Device[];
           }
       ));
       return request;
    };

    getDevice(deviceId: string): Observable<Device> {
        let request = this._http.get('api/device/' + deviceId).pipe(map(
            result => {
                return result as Device;
            }
        ));
        return request;
    }

    createDevice(device: Device): Observable<any> {
        let request = this._http.post('api/device', device).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    updateDevice(id:string, device: Device): Observable<any> {
        let request = this._http.put('api/device/' + id, device).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    deleteDevice(deviceId): Observable<any> {
        let request = this._http.delete('api/device/' + deviceId).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    getDevicesForCompany(companyId: string): Observable<Device[]> {
        return this._http.get<Device[]>('api/device/company/' + companyId);
    }
}