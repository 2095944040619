export class FluidInfo {
    chlorides:number;
    naCl:number;
    density:number;
    hardness:number;
    iron:number;
    sulfates:number;
    alkalinitty:number;
    ph:number;

    constructor() {}
}