<div *ngIf="!dialogOnly" >
  
      <p-table  #dt [columns]="requestCols" [value]="requests"
      [scrollable]="true" class="request-table" [loading]="loading" sortField="name">
      <!-- <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
          </colgroup>
      </ng-template> -->
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th *ngFor="let col of columns">
                  {{col.header}}
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-request let-index="rowIndex">
          <tr [pReorderableRow]="index">
              <td>{{request.companyName}}</td>
              <td>{{request.userName}}</td>
              <td>{{request.role}}</td>
              <td>{{request.requestedByName}}</td>
              <td>{{request.status}}</td>
              <td>
                  <i *ngIf="request.requestNotes" class="far fa-comment-alt" pTooltip="{{request.requestNotes}}" style="margin-right:5px;"></i>
                  <i *ngIf="request.status == 'Pending'" class="fas fa-check" style="margin-right:5px;" (click)="confirmApprove(request)"  pTooltip="Approve"></i>
                  <i *ngIf="request.status == 'Pending'" class="fas fa-times" style="margin-right:5px;" (click)="confirmReject(request)"  pTooltip="Reject"></i>
                  <i class="far fa-trash-alt" (click)="confirmDelete(request)"  pTooltip="Delete"></i></td>
          </tr>
      </ng-template>
      <ng-template pTemplate="summary">
          <button pButton type="button" label="Add Request" (click)="addRequest()" icon="fa fa-plus"
              class="add-button"></button>
      </ng-template>
  </p-table>
 
</div>

<p-dialog header="Reject Request?" [(visible)]="showRejectDialog" (onHide)="cancelReject()">
    <div class="ui-g add-content">
        <div class="grid field col-12">
          <div class="grid field col-12">
              <label>Rejection Reason</label>
          </div>
          <div class="grid field col-12">
                <textarea [rows]="2" cols="47" pInputTextarea autoResize="autoResize" [(ngModel)]="rejectReason"></textarea>
              <div *ngIf="!rejectReason" class="grid field col-12">
                  <label style="color:red; font-weight:bold;">Reason is required!</label>
              </div>
          </div>
        </div>
    </div>
  <p-footer>
    <button type="button" pButton (click)="reject()" label="Reject"></button>
    <button type="button" pButton (click)="cancelReject()" label="Cancel" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Add Company Association Request" [(visible)]="showAddCompanyAssociationRequest" (onHide)="cancelAdd()">
    <div class="ui-g add-content">
        <div class="grid field col-12">
          <div class="grid field col-12">
              <label>Company Name</label>
          </div>
          <div class="grid field col-12">
              <p-dropdown [options]="companies" placeholder="Select Company" [(ngModel)]="requestToAdd.companyId" scrollHeight="120px" [style]="{'width':'100%'}">
                </p-dropdown>
              <div *ngIf="!requestToAddModelValid && !requestToAdd.companyId" class="grid field col-12">
                  <label style="color:red; font-weight:bold;">Company is required!</label>
              </div>
          </div>
        </div>
          <div class="grid field col-12">
                  <div class="grid field col-12">
                      <label>Optional Notes</label>
                  </div>
                  <div class="grid field col-12">
                      <textarea [rows]="2" cols="47" pInputTextarea autoResize="autoResize" [(ngModel)]="requestToAdd.requestNotes"></textarea>
                  </div>
                </div>
                <div class="grid field col-12">
                    <p>Requests may take some time to be authorized.  Contact support at 1-866-290-5029 for any questions regarding this request.</p>
                </div>
    </div>
      <p-footer>
          <button type="button" pButton (click)="saveAdd()" label="Save"></button>
          <button type="button" pButton (click)="cancelAdd()" label="Cancel" class="p-button-secondary"></button>
        </p-footer>
  </p-dialog>
