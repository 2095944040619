import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CompanyCalculations } from '../models/models';

@Injectable()
export abstract class ICompanyCalculationsService {
  abstract getCompanyCalculations(companyId: string): Observable<CompanyCalculations>;
  abstract addOrUpdateCompanyCalculations(companyCalculations: CompanyCalculations): Observable<any>;
  abstract deleteCompanyCalculations(companyId: string): Observable<any>;
}
