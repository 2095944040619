import { Component, OnInit } from '@angular/core';
import { SimulationSiteDetails } from 'src/app/models/simulation';
import {
  ICountyDataService,
  IDistrictService,
} from '../../services/services';
import {
  WellTypes,
  States,
} from '../../models/models';
import { Message, SelectItem } from 'primeng/api';

@Component({
  selector: 'simulation-site-detail',
  templateUrl: './site-detail.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css']
})
export class SiteDetailComponent implements OnInit {
  public counties: SelectItem[] = [];
  public districts: SelectItem[] = []
  public messages: Message[] = [];
  public modelInvalid = false;
  public procedures: SelectItem[];
  public siteDetails: SimulationSiteDetails;
  public states: SelectItem[];
  public wellTypes: SelectItem[];

  constructor(
    private _districtService: IDistrictService,
    private _countyDataService: ICountyDataService,
  ) { }

  ngOnInit() {
    this.getDistricts();
    this.procedures = [
      { label: 'Drill Out', value: 'Drill Out'},
      { label: 'Drill Out <2 Plugs', value: 'Drill Out <2 Plugs'},
      { label: 'DO w/ Logging Run', value: 'DO w/ Logging Run'},
      { label: 'DO w/ Fishing Trip', value: 'DO w/ Fishing Trip'},
      { label: 'Fishing Trip', value: 'Fishing Trip'},
      { label: 'Wash Out', value: 'Wash Out'},
      { label: 'Toe Prep', value: 'Toe Prep'},
      { label: 'Other', value: 'Other'}
    ];
    this.states = States;
    this.wellTypes = [
      { label: WellTypes.horizontal, value: WellTypes.horizontal },
      { label: WellTypes.vertical, value: WellTypes.vertical },
    ]
    this.siteDetails = <SimulationSiteDetails> { };
  }

  getCounties(stateName: string) {
    this.counties = [];
    if (stateName == null || stateName === '') {
      return;
    }
    this._countyDataService.getCounties(stateName).subscribe(
      data => {
        data.forEach(result => {
          this.counties.push({
            label: result.name,
            value: result.name
          });
        });
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get list of counties',
          detail: ''
        });
        console.error(error);
      }
    );
  }

  getDistricts() {
    this._districtService.getDistricts().subscribe(
      results => {
        this.districts = results.map(p => Object.assign( <SelectItem>{}, p, { label: p.name, value: p.id }));
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get list of districts',
          detail: ''
        });
        console.error(error);
      }
    );
  }
}
