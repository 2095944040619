
<!--Name-->
<div class="grid field col-12">
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon" style="min-width: 50px; min-height:23px; padding-top:12px;">Name</span>
        <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="address.name" required="true" />
    </div>
    <div *ngIf="displayModelInvalid && !address.name" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Name is required!</label>
                </div>
</div>

<!--Phone-->
<div class="grid field col-12">
   
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon" style="min-width: 50px; min-height:23px; padding-top:12px;">
            <i class="fa fa-phone"></i>
        </span>
        <p-inputMask pInputText mask="(999) 999-9999" 
            placeholder="(999) 999-9999" [(ngModel)]="address.phone" ></p-inputMask>
    </div>
    <div *ngIf="displayModelInvalid && !address.phone" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Phone is required!</label>
                </div>
</div>
<!--Email-->
<div class="grid field col-12">
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon" style="min-width: 50px; min-height:23px; padding-top:12px;">
            <i class="far fa-envelope"></i>
        </span>
        <input type="email" pInputText placeholder="Enter Email Address" [(ngModel)]="address.email"
            (input)="validateEmails()" required="true" />
    </div>
    <div *ngIf="displayModelInvalid && !address.email || !emailInputValid" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">A Valid Email is required!</label>
                </div>
</div>
<!--Street-->
<div class="grid field col-12">
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon" style="min-width: 50px; min-height:23px; padding-top:12px;;">Street</span>
        <input type="text" pInputText placeholder="Enter Street Address" [(ngModel)]="address.street" required="true" />
    </div>
    <div *ngIf="displayModelInvalid && !address.street" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Street Address is required!</label>
                </div>
</div>
<!--City-->
<div class="grid field col-12">
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon" style="min-width: 50px; min-height:23px; padding-top:12px;">City</span>
        <input type="text" pInputText placeholder="Enter City" [(ngModel)]="address.city" required="true" />
    </div>
    <div *ngIf="displayModelInvalid && !address.city" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">City is required!</label>
                </div>
</div>
<!--State-->
<div class="grid field col-12">
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon" style="min-width: 50px; min-height:23px; padding-top:12px;">State</span>
        <input type="text" pInputText placeholder="Enter State" [(ngModel)]="address.state" required="true" />
    </div>
    <div *ngIf="displayModelInvalid && !address.state" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">State is required!</label>
                </div>
</div>
<!--Zip-->
<div class="grid field col-12">
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon" style="min-width: 50px; padding-top:10px;">Zip</span>
        <p-inputMask #zip pInputText mask="99999?-9999" [(ngModel)]="address.zip" required="true" (onBlur)="validateZip()" placeholder="99999-9999"></p-inputMask>
    </div>
    <div *ngIf="displayModelInvalid && !address.zip" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Zip Code is required!</label>
                </div>
</div>
