import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User, UserName } from '../models/models';
import { IUser } from './nswag/nswagclient';

@Injectable()
export abstract class IUserService  {
    abstract getUsers(): Observable<User[]>;

    abstract getUser(userId: string): Observable<User>;

    abstract createUser(user: User): Observable<any>;

    abstract updateUser(userId: string, updatedUser: User): Observable<any>;

    abstract deleteUser(userId: string): Observable<any>;

    abstract isAdmin(email: string): Observable<boolean>;

    abstract isEditorOrAdmin(email: string): Observable<boolean>;

   // abstract getUserByEmail(email: string): Observable<User>;

    //abstract addUserIfNeeded(email: string): Observable<User>;
    
    abstract getUserNames(): Observable<UserName[]>;

    abstract getUserName(userId: string): Observable<UserName>;

    abstract getSpecifiedUserNames(userIds: string[]): Observable<UserName[]>;

    abstract getSupervisorUserNamesForCompany(companyId: string): Observable<UserName[]>;

    abstract isUserAdmin(user: User, companyId: string, oldRoles: boolean): boolean;

    abstract isUserAnyRoleAdmin(user: User, oldRoles: boolean): boolean;

    abstract isUserEditorOrAdmin(user: User, companyId: string, oldRoles: boolean): boolean;

    abstract isUserAnyRoleEditorOrAdmin(user: User, oldRoles: boolean): boolean;
}
