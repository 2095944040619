  
    <p-accordion [multiple]="true">
      <p-accordionTab *ngIf="isUserCompanyVendor" header="Service Provider Jobs" [selected]="true">
        <div class="grid">
        <div class="col-12">
          <button pButton type="button" label="Create Job" (click)="navigateToAddVendorJob()" icon="fa fa-plus"></button>
        </div>
        <div class="col-12">
          <p-table #vendorDt 
            [columns]="vendorJobCols"
            [value]="vendorJobs"
            [scrollable]="true" 
            [paginator]="true"
            [rows]="20"
            [loading]="vendorTableLoading">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.max-width]="col.width" >
                        {{col.header}}
                        <p-sortIcon *ngIf="col.field != ''" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field">
                        <div *ngSwitchCase="'name'">
                            <input pInputText type="text" (input)="vendorDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"/>
                        </div>
                        <div *ngSwitchCase="'apiNumber'">
                            <input pInputText type="text" (input)="vendorDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"/>
                        </div>
                        <div *ngSwitchCase="'wellInfo.name'">
                            <input pInputText type="text" (input)="vendorDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"/>
                        </div>
                        <div *ngSwitchCase="'consumerCompanyName'">
                            <input pInputText type="text" (input)="vendorDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;" />
                        </div>
                        <div *ngSwitchCase="'deviceNamesAsString'">
                            <input pInputText type="text" (input)="vendorDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;" />
                        </div>
                    </th>
                </tr>
            </ng-template>
            
            <ng-template pTemplate="body" let-job>
                <tr>
                    <td>{{job.name}}</td>
                    <td>{{job.apiNumber}}</td>
                    <td>{{job.wellInfo.name}}</td>
                    <td>{{job.consumerCompanyName}}</td>
                    <td>{{job.startTime | date: 'MM/dd/yy HH:mm'}}</td>
                    <td>{{job.endTime | date: 'MM/dd/yy HH:mm'}}</td>
                    <td>{{job.deviceNamesAsString}}</td>
                    <td>
                        <i class="far fa-edit actionIcon" (click)="navigateToVendorDetail(job.id)"  pTooltip="Navigate to Details for this Job." tooltipPosition="left" ></i>
                        <i class="far fa-chart-bar actionIcon" (click)="navigateToVendorDashboard(job.id)"  pTooltip="Navigate to the Job Dashboard." tooltipPosition="left" ></i>
                        <i class="fa fa-book actionIcon" (click)="navigateToVendorWorkbook(job)"  pTooltip="Navigate to the Job Workbook." tooltipPosition="left" ></i>
                        <i *ngIf="isEditorOrAdmin(job.companyId)" class="fa fa-table actionIcon" (click)="downloadJobExport(job.id)"  pTooltip="Download the Job 'DATA' Export" tooltipPosition="left" ></i>
                        <i *ngIf="isEditorOrAdmin(job.companyId)" class="far fa-file actionIcon" (click)="downloadJobSummaryExport(job.id)" pTooltip="Download the Job 'SUMMARY' Export" tooltipPosition="left" ></i>
                        <i class="far fa-folder-open actionIcon" (click)="openFilePopUp(job.consumerJobId)" pTooltip="Manage Files for this Job." tooltipPosition="left"></i>
                        <i *ngIf="isAdmin(job.companyId)" class="far fa-trash-alt actionIcon" (click)="confirmDeleteVendorJob(job)" pTooltip="Delete this Job." tooltipPosition="left"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                Showing {{vendorDt.totalRecords < 1 ? 0 : vendorDt.first + 1}} to 
                {{vendorDt.totalRecords > 0 ? ((vendorDt.rows + vendorDt.first) <= vendorDt.totalRecords ? (vendorDt.rows + vendorDt.first) : 
                  vendorDt.totalRecords) : 0}} of {{vendorDt.totalRecords ? vendorDt.totalRecords : 0}} entries
            </ng-template>
          </p-table>
        </div>
      </div>
      </p-accordionTab>
      <p-accordionTab *ngIf="isUserCompanyConsumer" header="Operator Jobs" [selected]="true">
          <p-table #consumerDt 
          [columns]="consumerJobCols"
          [value]="consumerJobs"
          [scrollable]="true" 
          [paginator]="true"
          [rows]="20"
          [loading]="consumerTableLoading">
          <!-- <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                  <col *ngFor="let col of columns" [style.width]="col.width"/>
              </colgroup>
          </ng-template> -->
          <ng-template pTemplate="header" let-columns>
              <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                      {{col.header}}
                      <p-sortIcon *ngIf="col.field != ''" [field]="col.field"></p-sortIcon>
                  </th>
              </tr>
              <tr>
                  <th *ngFor="let col of columns">
                      <!-- <div *ngSwitchCase="'number'">
                          <input pInputText type="text" (input)="consumerDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"/>
                      </div>
                      <div *ngSwitchCase="'apiNumber'">
                        <input pInputText type="text" (input)="consumerDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"/>
                    </div>
                      <div *ngSwitchCase="'wellName'">
                          <input pInputText type="text" (input)="consumerDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"/>
                      </div> -->
                      <div *ngIf="col.filterMatchMode == 'contains'" >
                          <input pInputText type="text" (input)="consumerDt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;" />
                      </div>
                  </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-job>
              <tr>
                  <td>{{job.number}}</td>
                  <td>{{job.wellApiNumber}}</td>
                  <td>{{job.wellName}}</td>
                  <td>{{job.vendorCompanyNames}}</td>
                  <td>{{job.startTime | date: 'MM/dd/yy HH:mm'}}</td>
                  <td>{{job.endTime | date: 'MM/dd/yy HH:mm'}}</td>
                  <td>
                      <i class="far fa-edit actionIcon" (click)="navigateToConsumerDetail(job.id)" pTooltip="Navigate to Details for this Job." tooltipPosition="left" ></i>
                      <i class="far fa-chart-bar actionIcon " (click)="navigateToConsumerDashboard(job)" pTooltip="Navigate to the Job Dashboard." tooltipPosition="left" ></i>
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="paginatorleft">
              Showing {{consumerDt.totalRecords < 1 ? 0 : consumerDt.first + 1}} to 
              {{consumerDt.totalRecords > 0 ? ((consumerDt.rows + consumerDt.first) <= consumerDt.totalRecords ? (consumerDt.rows + consumerDt.first) : 
                consumerDt.totalRecords) : 0}} of {{consumerDt.totalRecords ? consumerDt.totalRecords : 0}} entries
          </ng-template>
        </p-table>
      </p-accordionTab>
      <p-accordionTab *ngIf="isCompanyTypeNotDefined()" [selected]="true">
          <h4>Current Company is not defined as Service Provider or Operator</h4>
      </p-accordionTab>
    </p-accordion>  

