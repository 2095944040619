import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Simulation, SimulationHeader } from '../models/simulation';

@Injectable()
export abstract class ISimulationService  {
    abstract getSimulation(id: string) : Observable<Simulation>;

    abstract getCompanySimulations(companyId: string) : Observable<SimulationHeader[]>;

    abstract saveSimulation(simulation: Simulation) : Observable<Simulation>;

    abstract deleteSimulation(id: string) : Observable<any>;
}