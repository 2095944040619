import { BaseRequest } from './baseRequest';
import { Address} from '../address';

export class NewCompanyRequest extends BaseRequest {

  companyName: string;
  companyPrefix: string;
  companyUnitSystem: string;
  companyType: string;
  autoApprovedEmailDomains: string[];
  billingAddress: Address;
  shippingAddress: Address;

  // Client side only
  requestedByName: string;

  constructor() {
    super();
    this.autoApprovedEmailDomains = [];
    this.billingAddress = new Address();
    this.shippingAddress = new Address();
  }

  mapFromObject(object: any) {
    if (object) {
      this.id = object.id;
      this.created = object.id;
      this.companyId = object.companyId;
      this.requestedById = object.requestedById;
      this.requestNotes = object.requestNotes;
      this.status = object.status;
      this.approvedById = object.approvedById;
      this.approvedDate = object.approvedDate;
      this.rejectedById = object.rejectedById;
      this.rejectedDate = object.rejectedDate;
      this.rejectedReason = object.rejectedReason;

      this.companyName = object.companyName;
      this.companyPrefix = object.companyPrefix;
      this.companyUnitSystem = object.companyUnitSystem;
      this.companyType = object.companyType;
      this.autoApprovedEmailDomains = object.autoApprovedEmailDomains;
      this.billingAddress = object.billingAddress;
      this.shippingAddress = object.shippingAddress;

      if (object.requestedByName) {
        this.requestedByName = object.requestedByName;
      } else {
        this.requestedByName = null;
      }
    }
  }
}
