<div class="grid field col-12">
    <div class="grid field col-12" *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>

    <div class="grid field col-12">
        <div class="col-12 md:col-12"><h2 style="margin-bottom:2px; padding-bottom:0px;">Post Job Analysis</h2></div>
    </div>
    
    <div class="grid field col-12">
        <div class="col-6 md:col-1">
            <label>Start</label>
        </div>
        <div class="col-6 md:col-3">
            <p-calendar 
                [(ngModel)]="calendarStartTime"
                [showIcon]="true" 
                [showTime]="true"
                [hourFormat]="24" 
                [monthNavigator]="true"
                [yearNavigator]="true" 
                yearRange="2018:2038">
                <div *ngIf="!calendarStartTime" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Start Time is required!</label>
                </div>
            </p-calendar>
        </div>
        <div class="col-6 md:col-1">
            <label>End</label>
        </div>
        <div class="col-6 md:col-3">
            <p-calendar 
                [(ngModel)]="calendarEndTime"
                [showIcon]="true" 
                [showTime]="true"
                [hourFormat]="24" 
                [monthNavigator]="true"
                [yearNavigator]="true" 
                yearRange="2018:2038">
                <div *ngIf="!calendarStartTime" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Start Time is required!</label>
                </div>
            </p-calendar>
        </div>
    </div>
    <div class="grid field col-12">
        <div class="col-4 md:col-2" *ngIf="everythingReady">
            <button pButton type="button" label="Search" (click)="searchJobs()" style="width:100px; margin-top:20px; float:right; vertical-align:bottom;"></button>
        </div>
        <div class="col-4 md:col-2">
            <button pButton type="button" label="{{clearString}}" (click)="clearSelection()" style="width:100px; margin-top:20px; float:right; vertical-align:bottom;"></button>
        </div>
        <div class="col-4 md:col-3" *ngIf="everythingReady">
            <button pButton type="button" label="Analyze" (click)="runAnalysis()" style="width:200px; margin-top:20px; float:right; vertical-align:bottom;"></button>
        </div>
    </div>
    <div class="grid field col-12">
        <p-table #dt [columns]="cols" [value]="jobs" [scrollable]="true" [loading]="!everythingReady" [(selection)]="selectedJobs" dataKey="id" (onRowSelect)="updateClearString" (onRowUnselect)="updateClearString">
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col />
                    <col *ngFor="let col of columns" [style.width]="col.width"/>
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width:3em;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.field != ''" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th></th>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field">
                        <div *ngSwitchCase="'name'">
                            <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;" />
                        </div>
                        <div *ngSwitchCase="'company.name'">
                            <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;" />
                        </div>
                        <div *ngSwitchCase="'devices'" style="width: 100%;">
                            <input pInputText type="text" (input)="dt.filter($event.target.value, col.alternativeFilterField, col.filterMatchMode)" style="width: 100%;" />
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-job>
                <tr [pSelectableRow]="job">
                    <td style="width:3em;">
                        <p-tableCheckbox [value]="job" ></p-tableCheckbox>
                    </td>
                    <td>{{job.name}}</td>
                    <td>{{(job.company) ? job.company.name : "" }}</td>
                    <td>{{job.startTime | date: 'short'}}</td>
                    <td>{{job.endTime | date: 'short'}}</td>
                    <td>{{job.deviceNamesAsString}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                Showing {{dt.totalRecords < 1 ? 0 : dt.first + 1}} to 
                {{dt.totalRecords > 0 ? ((dt.rows + dt.first) <= dt.totalRecords ? (dt.rows + dt.first) : 
                dt.totalRecords) : 0}} of {{dt.totalRecords ? dt.totalRecords : 0}} entries
            </ng-template>
        </p-table>
    </div>
</div>