export class CasingOrTubingId {
  public casingOrTubingType: string;
  public value: number;
}

export enum CasingOrTubingDirection {
  horizontal = 'Horizontal',
  vertical = 'Vertical',
  tubing = 'Tubing'
}
