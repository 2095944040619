import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { SelectItem } from 'primeng/api';
import { IVendorJobService,
         IOpsViewerMessagesService,
         ICompanyService,
         IJobWorkbookConfigurationService,
         IUserService,
         IDeviceService,
         IApplicationContextService } from 'src/app/services/services';
import { VendorJob,
         JobWorkbookConfiguration,
         Company,
         User,
         WorkbookJobTypes,
         CompanyName,
         JobLinker} from 'src/app/models/models';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { first } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { getWorkbookTypesSelection } from 'src/app/models/workbookJobTypes';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { VendorJobClient, VendorJob as VendorJobNSwag } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-vendor-job',
  templateUrl: './vendor-job.component.html',
  styleUrls: ['./vendor-job.component.css']
})
export class VendorJobComponent implements OnInit, OnDestroy {

  //#region Component Properties
  // UI State properties
  loading = true;
  modelInvalid = false;
  invalidCompanyId = false;
  showModelInvalidDialog = false;
  isNew = false;
  disabled = false;

  // Job Properties
  jobId: string;
  job: VendorJob = null;
  jobReady = false;

  // Company properties
  vendorCompanies: SelectItem[] = [];
  vendorCompaniesReady = false;
  consumerCompanies: SelectItem[] = [];
  consumerCompaniesReady = false;
  serviceProviderOptions: SelectItem[] = [];
  serviceProvidersReady = false;
  company: Company;

  // Workbook properties
  jobTypes: SelectItem[] = [];
  jobWorkbookConfiguration: JobWorkbookConfiguration;
  jobTypeSet = false;
  readyToLoadWorkbookConfiguration = false;

  // User properties
  user: User;
  isUserServiceProvider = false;
  userReady = false;
  companyContext: CompanyName;

  // Device properties
  devices: SelectItem[] = [];
  devicesReady = false;

  // Job Linker
  jobLinkerReady = false;
  jobLinker: JobLinker = null;

  // Other UI properties
  calendarStartTime: Date;
  calendarEndTime: Date;
  securityToken: string;
  sandReportBeginTime: Date;
  sandReportEndTime: Date;
  dataSheetReportBeginTime: Date;
  dataSheetReportEndTime: Date;
  showCompanyContactInfoDialog = false;
  showManageFiles = false;

  //#endregion

  private _companyContextSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _jobService: IVendorJobService,
    private _companyService: ICompanyService,
    private _jobWorkbookConfigurationService: IJobWorkbookConfigurationService,
    private _userService: IUserService,
    private _deviceService: IDeviceService,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService,
    private _userManagerService:IUserManagerService,
    private _titleService: Title,
    private _currentUserService: ICurrentUserService,
    private _vendorJobClient: VendorJobClient
  ) { }

  ngOnInit() {
    this.listenToCompanyContext();
    this.getJobNameFromRoute();
    this.getAuthenticatedUser();
    this.getVendorCompanies();
    this.getConsumerCompanies();
    this.setJobTyes();
    this.setSecurityToken();
    
  }

  setSecurityToken(){

    this._currentUserService.getAccessToken().subscribe(s=>{
      //console.warn(s);
      this.securityToken = s;
    });

    
  }

  ngOnDestroy() {
    this._companyContextSubscription.unsubscribe();
  }

  //#region *********** Initialization methods ************/
  getJobNameFromRoute() {
    this._route.params.subscribe(
      params => {
        this.jobId = params['id'];
        if (!this.jobId || this.jobId.toUpperCase() === 'NEW') {
          this.isNew = true;
          this.createNewJob();
        } else {
          this.getJob();
          this.checkIfCanEnableJobLinker();
        }
        this.setPageTitle(this.jobId);

      });
  }

  republishWorkbook() {
    this._jobService.republishWorkbook(this.jobId).subscribe(
      result => {
        this._messageService.infoMessage('published',
          `published workbook data for - ${this.job.name}`
         );
      },
      errorResult => {
        this._messageService.errorMessage(errorResult,
         'Could not Republish Workbook Data'
        );
      }
    );
  }

  setPageTitle(jobId: string) {
    if (!jobId || jobId === 'New' || jobId === '') {
      this._titleService.setTitle('New Job');
    } else { this._titleService.setTitle(jobId); }
  }

  getAuthenticatedUser() {

    this._userManagerService.getCurrentUser().subscribe(u=>{
      this.user = u;
      this.userReady = true;
      this.checkIfEverythingReady();
    });
  }
  // getAuthenticatedUser() {
  //   this._msalService.getUserEmail().subscribe(
  //     userEmail => {
  //       if (userEmail) {
  //         this._userService.getUserByEmail(userEmail).subscribe(
  //           userResult => {
  //             this.user = userResult;
  //             this.userReady = true;
  //             this.checkIfEverythingReady();
  //           },
  //           error => { this._messageService.errorMessage(error, 'Could not get user'); }
  //         );
  //       }
  //     },
  //     error => { this._messageService.errorMessage(error, 'Could not get user'); }
  //   );
  // }

  setJobTyes() {
    this.jobTypes = getWorkbookTypesSelection();
  }

  listenToCompanyContext() {
    // Initialize Company Context once and listen for changes
    this.updateCompanyContext(this._applicationContextService.getCompanyContext());
    this._companyContextSubscription = this._applicationContextService.companyContextObserver.subscribe(
      c => {
        this.updateCompanyContext(c);
        this.isUserServiceProvider = this.updateIsUserServiceProviderForJob(c);
      });
  }

  updateCompanyContext(companyContext: CompanyName) {
    this.companyContext = companyContext;
    if (this.isNew && this.companyContext) {
      this.createNewJob();
    }
  }

  //#endregion

  //#region *********** Getting or Creating new Job methods *******************/
  createNewJob() {
    // Job.Id is set to uppercase guid to stay consistent with previous
    // rules when job.id was matching job.name and it was always uppercase then
    this.job = new VendorJob(uuidv4().toUpperCase(), null, null, [], null, null);
    this.job.consumerJobId
    this.jobReady = true;
    this.calendarStartTime = new Date();
    this.calendarEndTime = null;
    if (this.companyContext) {
      this.job.companyId = this.companyContext.companyId;
      this.setServiceProvider();
    }
    this.checkIfEverythingReady();
  }

  getJob() {
    this._jobService.getJob(this.jobId).subscribe(
      result => {
        if (result == null) {
          this._messageService.warnMessage('Job could not be found', '');
        } else {
          this.job = result;
          this.setCalendarDates();
          this.setServiceProvider();

          this.jobReady = true;
          this.checkIfEverythingReady();
          this.checkIfCanEnableJobLinker();

          this.checkToEnableWorkbookConfiguration();

          this.isUserServiceProvider = this.updateIsUserServiceProviderForJob(this.companyContext);
        }
      },
      error => {
        this._messageService.errorMessage(error, 'Could not get Job');
        if (error && error.error && error.error.Message && error.error.Message.includes('Request document of wrong type')) {
          this._messageService.warnMessage('ID for wrong job type was passed: An Operator Job ID was attempted to query a Service Provider Job','Verify link from the dashboard is correct');
          this._messageService.errorNoUiMessage(
            'WrongJobTypeRequestError',
            'Attempted to access a Vendor Job Workbook with Consumer Job Id.  Job Id requested: (' +
            this.jobId +
            '). '+
            'Router snapshot: ' + JSON.stringify(this._route.snapshot.url));
        }
      });
  }

  setCalendarDates() {
    // Convert from UTC
    this.calendarStartTime = new Date(this.job.startTime);
    if (this.job.endTime) {
      this.calendarEndTime = new Date(this.job.endTime);
    }
  }

  clearEndDate() {
    this.calendarEndTime = null;
    this.job.endTime = null; // Will not be updated in setDates if calendarEndTime is null
  }

  updateIsUserServiceProviderForJob(companyContext: CompanyName): boolean {
    if (this.job && companyContext) {
      if (this.job.companyId !== companyContext.companyId) {
        return true;
      }
    }
    return false;
  }

  setServiceProviderOptions() {
    if (this.job && this.job.companyId) {
      this.serviceProviderOptions = this.vendorCompanies.filter(c => c.value !== this.job.companyId)
                                    .concat(this.consumerCompanies.filter(c => c.value !== this.job.companyId));
    } else { this.serviceProviderOptions = this.vendorCompanies.concat(this.consumerCompanies); }
    this.serviceProvidersReady = true;
  }

  checkIfEverythingReady() {
    this.loading = !(this.jobReady && this.userReady && this.vendorCompaniesReady && this.consumerCompaniesReady);
  }

  //#endregion

  //#region *********** UI Event/Data Changed methods ********/
  // setJobIdAndName() {
  //   // Added rule to make all Job Ids to be Uppercase to aid in desktop matching
  //   this.job.id = this.job.id.toUpperCase();
  //   this.job.name = this.job.id;
  //   this.checkToEnableWorkbookConfiguration();
  // }

  setServiceProvider() {
    if (this.job && this.job.companyId && this.vendorCompaniesReady) {
      this.getCompany(this.job.companyId).subscribe(
        result => {
          this.company = result;
          if (this.isNew) {
            this.setDefaultServiceProviders(this.company);
          } else {
            this.setServiceProviderOptions();
          }
          this.getDevices();
          this.checkToEnableWorkbookConfiguration();
        }
      );
      // Due to ngModel not firing wrtievalue() control method from property changes of input(Job),
      // A new instance of Job must be created with new property value to fire writevalue
      // This is needed when CompanyId changes as child component for Job Details needs to refresh
      // their supervisor list based on CompanyId change
      const newJob = VendorJob.BuildNewInstanceVendorJobFromCurrent(this.job);
      this.job = newJob;
    }
  }

  setOperator() {
    this.checkIfCanEnableJobLinker();
  }

  getDevices() {
    this.devicesReady = false;
    this._deviceService.getDevicesForCompany(this.job.companyId).subscribe(
      results => {
        results.map(r => {
          this.devices.push({ label: r.name, value: r.id} );
        });
        this.devicesReady = true;
      },
      error => { this._messageService.errorMessage(error, 'Could not get devices'); }
    );
  }

  jobTypeUpdated() {
    this.checkToEnableWorkbookConfiguration();
    this.checkIfCanEnableJobLinker();
  }

  wellNameUpdated() {
    this.checkIfCanEnableJobLinker();
  }

  apiNumberUpdated() {
    this.checkIfCanEnableJobLinker();
  }

  startDateUpdated() {
    this.checkIfCanEnableJobLinker();
  }

  closeInvalidModelDialog() {
    this.showModelInvalidDialog = false;
  }

  navigateToDashboard() {
    this._jobService.getDashboardLink(this.jobId)
    .subscribe(l =>  {
      window.open(l,"_blank");
    });
  }

  navigateToWorkbook() {
    // const decodedJobId = decodeURIComponent(this.jobId);
    if (this.job.useOldWorkbookInReadOnlyMode) {
      this._router.navigate(['/workbook/' + this.job.id]);
    } else {
      this._router.navigate(['/workbook2/' + this.job.id]);
    }
  }

  returnToJobList() {
    this._router.navigate(['/jobs2/']);
  }

  endJob() {
    this.job.status = 'Finished';
    // Does end date need to be updated to now?
    // this.job.endTime = new Date(Date.now());
    this.saveJob();
  }

  downloadJobExport() {
    window.open('/api/vendorjob/export/' + this.job.id + '?token=' + this.securityToken, '_blank');
  }

  downloadJobSummaryExport() {
    window.open('/api/vendorjob/exportsummary/' + this.job.id + '?token=' + this.securityToken, '_blank');
  }

  downloadSandReport() {
    const beginTime = moment(this.sandReportBeginTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(this.sandReportEndTime).format('YYYY-MM-DDTHH:mm:ss');
    window.open('/api/reports/sand/' +
    this.job.id +
    '?beginTime=' + beginTime +
    '&endTime=' + endTime +
    '&token=' + this.securityToken, '_blank');
  }

  downloadDataSheetReport() {
    const beginTime = moment(this.dataSheetReportBeginTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(this.dataSheetReportEndTime).format('YYYY-MM-DDTHH:mm:ss');
    window.open('/api/reports/datasheet/' +
    this.job.id +
    '?beginTime=' + beginTime +
    '&endTime=' + endTime +
    '&token=' + this.securityToken, '_blank');
  }

  saveJob() {
    this.setDates();
    this.showModelInvalidDialog = this.modelInvalid = !this.validateJobModel();

    if (!this.showModelInvalidDialog) {
      this._applicationContextService.showApplicationSpinner(false);
      if (this.isNew) {

        this._vendorJobClient.createVendorJob(new VendorJobNSwag(this.job)).subscribe(result =>{
          this.saveJobConfiguration();
          // In case this is the first consumer job with this consumer company
          // the company list in the user profile needs to update
          this._applicationContextService.alertCompaniesListUpdated();
          this._applicationContextService.hideApplicationSpinner();
          // this._jobLinkerService.createJobLinker(this.jobLinker).subscribe(
          //   jobLinkerResult => {},
          //   error => { this._messageService.errorMessage(error, 'Could not save Job Linking'); }
          // );
         },
        error => {
          this._applicationContextService.hideApplicationSpinner();
          this._messageService.errorMessageWithObjectDetail(error, 'Could not create job', JSON.stringify(this.job));
        }
        );


        // this._jobService.createJob(this.job).subscribe(
        //   result => {
        //     this.saveJobConfiguration();
        //     // In case this is the first consumer job with this consumer company
        //     // the company list in the user profile needs to update
        //     this._applicationContextService.alertCompaniesListUpdated();
        //     this._applicationContextService.hideApplicationSpinner();
        //     // this._jobLinkerService.createJobLinker(this.jobLinker).subscribe(
        //     //   jobLinkerResult => {},
        //     //   error => { this._messageService.errorMessage(error, 'Could not save Job Linking'); }
        //     // );
        //    },
        //   error => {
        //     this._applicationContextService.hideApplicationSpinner();
        //     this._messageService.errorMessageWithObjectDetail(error, 'Could not create job', JSON.stringify(this.job));
        //   }
        // );
      } else {
        this._jobService.updateJob(this.jobId, this.job).subscribe(
          result => {
            this.saveJobConfiguration();
            this._applicationContextService.hideApplicationSpinner();
            // this._jobLinkerService.associateVendorJobToJobLinker(this.job.id, this.jobLinker).subscribe(
            //   jobLinkerResult => {},
            //   error => { this._messageService.errorMessage(error, 'Could not update Job Linking'); }
            // );
          },
          error => {
            this._applicationContextService.hideApplicationSpinner();
            this._messageService.errorMessageWithObjectDetail(error, 'Could not update job', JSON.stringify(this.job));
          }
        );
      }
    }
  }

  //#endregion

  //#region *********** Company Methods *************/
  getVendorCompanies() {
    this._companyService.getVendorCompanyNames().subscribe(
      results => {
        results.forEach(result => {
          this.vendorCompanies.push({
            label: result.companyName,
            value: result.companyId }
          );
        });
        this.vendorCompaniesReady = true;
        // In case Vendor Companies come after SetServiceProvider ran
        this.setServiceProvider();
        this.checkIfEverythingReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get companies for job'); });
  }

  getConsumerCompanies() {
    this._companyService.getConsumerCreatedAfterOrMigratedCompanyNames().subscribe(
      results => {
        results.forEach(result => {
          this.consumerCompanies.push({
            label: result.companyName,
            value: result.companyId }
          );
        });
        this.consumerCompaniesReady = true;
        this.checkIfEverythingReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get companies for job'); });
  }

  getCompany(companyId: string): Observable<Company> {
    return new Observable<Company>(observer => {
      this._companyService.getCompany(companyId).subscribe(
        result => {
          observer.next(result);
          observer.complete();
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  setDefaultServiceProviders(company: Company) {
    this.serviceProvidersReady = false;
      this.job.serviceProviderIds = [];
      // Default selected Service Providers to those defined by
      // company that is associated to job
      if (company.serviceProviderIds) {
        company.serviceProviderIds.forEach(s => {
          this.job.serviceProviderIds.push(s);
        });
      }
      this.setServiceProviderOptions();
  }

  showContactInfo() {
    this.showCompanyContactInfoDialog = true;
  }

  //#endregion

  //#region *********** Workbook Configuration Methods ********/
  checkToEnableWorkbookConfiguration() {
    if (this.job.id && this.job.workbookType && this.company) {
      this.readyToLoadWorkbookConfiguration = true;
    }
  }

  updateWorkbookConfiguration(event: any) {
    this.jobWorkbookConfiguration = event;
  }

  handleErrorsFromConfiguration(event: any) {
    this._messageService.errorMessage(event, 'Error with Job Workbook Configuration');
  }

  saveJobConfiguration() {
    this._jobWorkbookConfigurationService.addOrUpdateJobWorkbookConfiguration(this.jobWorkbookConfiguration).subscribe(
      result => {
        this.returnToJobList();
      },
      error => { this._messageService.errorMessage(error, 'Could not save Job Workbook Configuration'); }
    );
  }

  //#endregion

  //#region ***** Job Linker Methods ******/
  checkIfCanEnableJobLinker() {
    if (this.isNew) {
      if (this.job &&
        this.job.consumerCompanyId &&
        ((this.job.wellInfo && this.job.wellInfo.name) || this.job.apiNumber) &&
        this.job.workbookType &&
        this.calendarStartTime) {
          this.forceChangeOnJobLinker();
      }
   } else if (this.job && this.job.id) {
      this.jobLinkerReady = true;
   }
  }

  forceChangeOnJobLinker() {
    this.setDates();
    this.job = {...this.job} as VendorJob;
    this.jobLinkerReady = true;
  }

  jobLinkerUpdated(jobLinker: JobLinker) {
    this.jobLinker = jobLinker;
  }
  //#endregion

  //#region *********** Other Helper methods ***********/
  isAdmin(): boolean {
    return this._userService.isUserAdmin(this.user, this.job.companyId, false);
  }

  isEditorOrAdmin(): boolean {
    return this._userService.isUserEditorOrAdmin(this.user, this.job.companyId, false);
  }

  setDates() {
    // Convert To UTC
    this.job.startTime = new Date(this.calendarStartTime);
    if (this.calendarEndTime) {
      this.job.endTime = new Date(this.calendarEndTime);
    }
  }

  validateJobModel(): boolean {
    let valid = true;
    this.invalidCompanyId = false;
    if (!this.job.name) { valid = false; }
    if (!this.job.companyId) { valid = false; }
    if (this.job.companyId && !this.vendorCompanies.find(v => v.value === this.job.companyId)) {
      this.invalidCompanyId = true;
      valid = false;
    }
    if (!this.job.consumerCompanyId) { valid = false; }
    // TODO: Does at least one device need to be associated?  If so uncomment
    //      Also uncomment HTML validation message
    // if(!this.job.deviceList || this.job.deviceList.length < 1) valid = false;
    if (!this.job.startTime) { valid = false; }
    if (!this.job.apiNumber && !this.job.wellInfo.name) { valid = false; }
    // if(!this.job.endTime) valid = false;
    if (!this.job.workbookType) { valid = false; }
    if ((this.job.workbookType === WorkbookJobTypes.flowbackWellTesting) && !this.job.wellInfo.wellType) {
      valid = false;
    }
    if (this.job.enableOfflineWorkbook && !this.job.workbookType) { valid = false; }

    if ((this.job.workbookType === WorkbookJobTypes.flowbackWellTesting) &&
        (!this.job.frcsDayEngineer || !this.job.dayOperators ||
         !this.job.frcsNightEngineer || ! this.job.nightOperators)) {
           valid = false;
         }

    if ((this.jobWorkbookConfiguration) && this.jobWorkbookConfiguration.workbookMeasurementChannelConfigurations.length <1){
      valid = false;
      this._messageService.errorMessageWithObjectDetail("invalid workbook configuration", 'Could not update job', JSON.stringify(this.job));
    }
    if (this.job.fluidInfo.density != Number(this.job.fluidInfo.density)){
      this._messageService.errorMessageWithObjectDetail("invalid fluild density", 'Could not update job', JSON.stringify(this.job));
      valid = false;
    }

    if (this.job.wellInfo && this.job.wellInfo.kop && this.job.wellInfo.kop != Number(this.job.wellInfo.kop)){
      this._messageService.errorMessageWithObjectDetail("invalid kop", 'Could not update job', JSON.stringify(this.job));
      valid = false;
    }

    if (this.job.wellInfo && this.job.wellInfo.sicp && this.job.wellInfo.sicp != Number(this.job.wellInfo.sicp)){
      this._messageService.errorMessageWithObjectDetail("invalid scip", 'Could not update job', JSON.stringify(this.job));
      valid = false;
    }


    return valid;
  }

  //#endregion
}
