<div class="grid field col-12">
    <div class="col">
        <p-table [value]="simulationHeaders" [scrollable]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Customer</th>
                    <th>Name</th>
                    <th>Version</th>
                    <th>Date</th>
                    <th style="width:8em"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-hdr>
                <tr>
                    <td>
                        <div *ngIf="hdr.customer">{{hdr.customer.companyName}}</div>
                    </td>
                    <td>{{hdr.name}}</td>
                    <td>{{hdr.majorVersion}}.{{hdr.minorVersion}}.{{hdr.revisionVersion}}</td>
                    <td>{{hdr.timestamp | date }}</td>
                    <td style="width: 8em">
                        <a routerLink="./jobsimulator/{{hdr.simulationId}}">
                            <i class="far fa-edit fa-lg" style="margin-right:5px;" pTooltip="Navigate to Details for this Simulation."></i>
                        </a>
                        <a (click)="cloneSimulation(hdr.simulationId)" style="color: #dedede; margin-right: 5px;">
                            <i class="far fa-clone fa-lg" pTooltip="Make a copy of this Job."></i>
                        </a>
                        <a (click)="deleteSimulation(hdr.simulationId)" style="color: #dedede">
                            <i class="far fa-trash-alt fa-lg" pTooltip="Delete this Job."></i>
                        </a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>