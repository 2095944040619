import { SelectItem } from 'primeng/api';

export let CasingTubingTypes: SelectItem[] = [
    { label: '0.750 in OD, 1.14/1.20 lbs/ft  , 0.824 in ID, 0.730 in Drift', value: '0.750 in OD, 1.14/1.20 lbs/ft  , 0.824 in ID, 0.730 in Drift' },
    { label: '1.000 in OD, 1.70/1.80 lbs/ft  , 1.049 in ID, 0.955 in Drift', value: '1.000 in OD, 1.70/1.80 lbs/ft  , 1.049 in ID, 0.955 in Drift' },
    { label: '1.250 in OD, 2.30/2.40 lbs/ft  , 1.380 in ID, 1.286 in Drift', value: '1.250 in OD, 2.30/2.40 lbs/ft  , 1.380 in ID, 1.286 in Drift' },
    { label: '1.500 in OD, 2.75/2.90 lbs/ft  , 1.610 in ID, 1.516 in Drift', value: '1.500 in OD, 2.75/2.90 lbs/ft  , 1.610 in ID, 1.516 in Drift' },
    { label: '2.375 in OD, 4.00      lbs/ft  , 2.041 in ID, 1.947 in Drift', value: '2.375 in OD, 4.00      lbs/ft  , 2.041 in ID, 1.947 in Drift' },
    { label: '2.375 in OD, 4.60/4.70 lbs/ft  , 1.995 in ID, 1.901 in Drift', value: '2.375 in OD, 4.60/4.70 lbs/ft  , 1.995 in ID, 1.901 in Drift' },
    { label: '2.875 in OD, 6.40/6.50 lbs/ft  , 2.441 in ID, 2.347 in Drift', value: '2.875 in OD, 6.40/6.50 lbs/ft  , 2.441 in ID, 2.347 in Drift' },
    { label: '2.875 in OD, 8.60/8.70 lbs/ft  , 2.259 in ID, 2.165 in Drift', value: '2.875 in OD, 8.60/8.70 lbs/ft  , 2.259 in ID, 2.165 in Drift' },
    { label: '3.250 in OD, 7.98 lbs/ft       , 2.760 in ID, Unknown  Drift', value: '3.250 in OD, 7.98 lbs/ft       , 2.760 in ID, Unknown  Drift' },
    { label: '3.500 in OD, 7.70 lbs/ft       , 3.068 in ID, 2.943 in Drift', value: '3.500 in OD, 7.70 lbs/ft       , 3.068 in ID, 2.943 in Drift' },
    { label: '3.500 in OD, 9.20/9.30 lbs/ft  , 2.992 in ID, 2.867 in Drift', value: '3.500 in OD, 9.20/9.30 lbs/ft  , 2.992 in ID, 2.867 in Drift'},
    { label: '3.500 in OD, 10.20 lbs/ft      , 2.922 in ID, 2.797 in Drift', value: '3.500 in OD, 10.20 lbs/ft      , 2.922 in ID, 2.797 in Drift' },
    { label: '4.000 in OD, 9.50 lbs/ft       , 3.476 in ID, 3.423 in Drift', value: '4.000 in OD, 9.50 lbs/ft       , 3.476 in ID, 3.423 in Drift'},
    { label: '4.000 in OD, 11.00 lbs/ft      , 3.548 in ID, 3.351 in Drift', value: '4.000 in OD, 11.00 lbs/ft      , 3.548 in ID, 3.351 in Drift' },
    { label: '4.000 in OD, 11.60 lbs/ft      , 3.428 in ID, 3.303 in Drift', value: '4.000 in OD, 11.60 lbs/ft      , 3.428 in ID, 3.303 in Drift' },
    { label: '4.5 in OD, 9.5 lbs/ft, 4.09 in ID, 3.965 in Drift', value: '4.5 in OD, 9.5 lbs/ft, 4.09 in ID, 3.965 in Drift' },
    { label: '4.5 in OD, 10.5 lbs/ft, 4.052 in ID, 3.927 in Drift', value: '4.5 in OD, 10.5 lbs/ft, 4.052 in ID, 3.927 in Drift' },
    { label: '4.5 in OD, 11.6 lbs/ft, 4.0 in ID, 3.875 in Drift', value: '4.5 in OD, 11.6 lbs/ft, 4.0 in ID, 3.875 in Drift' },
    { label: '4.5 in OD, 12.6 lbs/ft, 3.958 in ID, 3.833 in Drift', value: '4.5 in OD, 12.6 lbs/ft, 3.958 in ID, 3.833 in Drift' },
    { label: '4.5 in OD, 13.5 lbs/ft, 3.92 in ID, 3.795 in Drift', value: '4.5 in OD, 13.5 lbs/ft, 3.92 in ID, 3.795 in Drift' },
    { label: '4.5 in OD, 15.1 lbs/ft, 3.826 in ID, 3.701 in Drift', value: '4.5 in OD, 15.1 lbs/ft, 3.826 in ID, 3.701 in Drift' },
    { label: '4.5 in OD, 16.6 lbs/ft, 3.754 in ID, 3.629 in Drift', value: '4.5 in OD, 16.6 lbs/ft, 3.754 in ID, 3.629 in Drift' },
    { label: '4.5 in OD, 16.9 lbs/ft, 3.74 in ID, 3.615 in Drif', value: '4.5 in OD, 16.9 lbs/ft, 3.74 in ID, 3.615 in Drif' },
    { label: '4.5 in OD, 17.7 lbs/ft, 3.696 in ID, 3.571 in Drift', value: '4.5 in OD, 17.7 lbs/ft, 3.696 in ID, 3.571 in Drift' },
    { label: '4.5 in OD, 18.8 lbs/ft, 3.64 in ID, 3.515 in Drift', value: '4.5 in OD, 18.8 lbs/ft, 3.64 in ID, 3.515 in Drift' },
    { label: '4.5 in OD, 21.6 lbs/ft, 3.5 in ID, 3.375 in Drift', value: '4.5 in OD, 21.6 lbs/ft, 3.5 in ID, 3.375 in Drift' },
    { label: '4.5 in OD, 24.6 lbs/ft, 3.38 in ID, 3.255 in Drift', value: '4.5 in OD, 24.6 lbs/ft, 3.38 in ID, 3.255 in Drift' },
    { label: '4.5 in OD, 26.5 lbs/ft, 3.24 in ID, 3.115 in Drift', value: '4.5 in OD, 26.5 lbs/ft, 3.24 in ID, 3.115 in Drift' },
    { label: '5.0 in OD, 11.5 lbs/ft, 4.56 in ID, 4.435 in Drift', value: '5.0 in OD, 11.5 lbs/ft, 4.56 in ID, 4.435 in Drift' },
    { label: '5.0 in OD, 13.0 lbs/ft, 4.494 in ID, 4.369 in Drift', value: '5.0 in OD, 13.0 lbs/ft, 4.494 in ID, 4.369 in Drift' },
    { label: '5.0 in OD, 15.0 lbs/ft, 4.408 in ID, 4.283 in Drift', value: '5.0 in OD, 15.0 lbs/ft, 4.408 in ID, 4.283 in Drift' },
    { label: '5.0 in OD, 18.0 lbs/ft, 4.276 in ID, 4.151 in Drift', value: '5.0 in OD, 18.0 lbs/ft, 4.276 in ID, 4.151 in Drift' },
    { label: '5.0 in OD, 20.3 lbs/ft, 4.184 in ID, 4.059 in Drift', value: '5.0 in OD, 20.3 lbs/ft, 4.184 in ID, 4.059 in Drift' },
    { label: '5.0 in OD, 20.8 lbs/ft, 4.156 in ID, 4.031 in Drift', value: '5.0 in OD, 20.8 lbs/ft, 4.156 in ID, 4.031 in Drift' },
    { label: '5.0 in OD, 21.4 lbs/ft, 4.126 in ID, 4.001 in Drift', value: '5.0 in OD, 21.4 lbs/ft, 4.126 in ID, 4.001 in Drift' },
    { label: '5.0 in OD, 23.2 lbs/ft, 4.044 in ID, 3.919 in Drift', value: '5.0 in OD, 23.2 lbs/ft, 4.044 in ID, 3.919 in Drift' },
    { label: '5.0 in OD, 24.2 lbs/ft, 4.0 in ID, 3.875 in Drift', value: '5.0 in OD, 24.2 lbs/ft, 4.0 in ID, 3.875 in Drift' },
    { label: '5.0 in OD, 26.7 lbs/ft, 3.876 in ID, 3.751 in Drift', value: '5.0 in OD, 26.7 lbs/ft, 3.876 in ID, 3.751 in Drift' },
    { label: '5.5 in OD, 13.0 lbs/ft, 5.044 in ID, 4.919 in Drift', value: '5.5 in OD, 13.0 lbs/ft, 5.044 in ID, 4.919 in Drift' },
    { label: '5.5 in OD, 13.0 lbs/ft, 5.044 in ID, 4.919 in Drift', value: '5.5 in OD, 13.0 lbs/ft, 5.044 in ID, 4.919 in Drift' },
    { label: '5.5 in OD, 14.0 lbs/ft, 5.012 in ID, 4.887 in Drift', value: '5.5 in OD, 14.0 lbs/ft, 5.012 in ID, 4.887 in Drift' },
    { label: '5.5 in OD, 15.5 lbs/ft, 4.95 in ID, 4.825 in Drift', value: '5.5 in OD, 15.5 lbs/ft, 4.95 in ID, 4.825 in Drift' },
    { label: '5.5 in OD, 17.0 lbs/ft, 4.892 in ID, 4.767 in Drift', value: '5.5 in OD, 17.0 lbs/ft, 4.892 in ID, 4.767 in Drift' },
    { label: '5.5 in OD, 20.0 lbs/ft, 4.778 in ID, 4.653 in Drift', value: '5.5 in OD, 20.0 lbs/ft, 4.778 in ID, 4.653 in Drift' },
    { label: '5.5 in OD, 23.0 lbs/ft, 4.67 in ID, 4.545 in Drift', value: '5.5 in OD, 23.0 lbs/ft, 4.67 in ID, 4.545 in Drift' },
    { label: '5.5 in OD, 26.0 lbs/ft, 4.548 in ID, 4.423 in Drift', value: '5.5 in OD, 26.0 lbs/ft, 4.548 in ID, 4.423 in Drift' },
    { label: '5.5 in OD, 28.4 lbs/ft, 4.44 in ID, 4.315 in Drift', value: '5.5 in OD, 28.4 lbs/ft, 4.44 in ID, 4.315 in Drift' },
    { label: '5.5 in OD, 29.7 lbs/ft, 4.376 in ID, 4.251 in Drift', value: '5.5 in OD, 29.7 lbs/ft, 4.376 in ID, 4.251 in Drift' },
    { label: '5.5 in OD, 32.3 lbs/ft, 4.276 in ID, 4.151 in Drift', value: '5.5 in OD, 32.3 lbs/ft, 4.276 in ID, 4.151 in Drift' },
    { label: '5.5 in OD, 36.4 lbs/ft, 4.09 in ID, 3.965 in Drift', value: '5.5 in OD, 36.4 lbs/ft, 4.09 in ID, 3.965 in Drift' },
    { label: '5.5 in OD, 39.3 lbs/ft, 4.044 in ID, 3.919 in Drift', value: '5.5 in OD, 39.3 lbs/ft, 4.044 in ID, 3.919 in Drift' },
    { label: '6.0 in OD, 15.0 lbs/ft, 5.542 in ID, 5.399 in Drift', value: '6.0 in OD, 15.0 lbs/ft, 5.542 in ID, 5.399 in Drift' },
    { label: '6.0 in OD, 18.0 lbs/ft, 5.424 in ID, 5.299 in Drift', value: '6.0 in OD, 18.0 lbs/ft, 5.424 in ID, 5.299 in Drift' },
    { label: '6.0 in OD, 20.0 lbs/ft, 5.352 in ID, 5.227 in Drift', value: '6.0 in OD, 20.0 lbs/ft, 5.352 in ID, 5.227 in Drift' },
    { label: '6.0 in OD, 23.0 lbs/ft, 5.24 in ID, 5.115 in Drift', value: '6.0 in OD, 23.0 lbs/ft, 5.24 in ID, 5.115 in Drift' },
    { label: '6.0 in OD, 24.5 lbs/ft, 5.20 in ID, 5.07 in Drift', value: '6.0 in OD, 24.5 lbs/ft, 5.20 in ID, 5.07 in Drift'},
    { label: '6.0 in OD, 26.0 lbs/ft, 5.132 in ID, 5.007 in Drift', value: '6.0 in OD, 26.0 lbs/ft, 5.132 in ID, 5.007 in Drift' },
    { label: '6.625 in OD, 17.0 lbs/ft, 6.135 in ID, 6.01 in Drift', value: '6.625 in OD, 17.0 lbs/ft, 6.135 in ID, 6.01 in Drift' },
    { label: '6.625 in OD, 20.0 lbs/ft, 6.049 in ID, 5.924 in Drift', value: '6.625 in OD, 20.0 lbs/ft, 6.049 in ID, 5.924 in Drift' },
    { label: '6.625 in OD, 24.0 lbs/ft, 5.921 in ID, 5.796 in Drift', value: '6.625 in OD, 24.0 lbs/ft, 5.921 in ID, 5.796 in Drift' },
    { label: '6.625 in OD, 28.0 lbs/ft, 5.791 in ID, 5.666 in Drift', value: '6.625 in OD, 28.0 lbs/ft, 5.791 in ID, 5.666 in Drift' },
    { label: '6.625 in OD, 32.0 lbs/ft, 5.675 in ID, 5.55 in Drift', value: '6.625 in OD, 32.0 lbs/ft, 5.675 in ID, 5.55 in Drift' },
    { label: '6.625 in OD, 35.0 lbs/ft, 5.575 in ID, 5.45 in Drift', value: '6.625 in OD, 35.0 lbs/ft, 5.575 in ID, 5.45 in Drift' },
    { label: '6.625 in OD, 43.2 lbs/ft, 5.375 in ID, 5.25 in Drift', value: '6.625 in OD, 43.2 lbs/ft, 5.375 in ID, 5.25 in Drift' },
    { label: '6.625 in OD, 69.63 lbs/ft, 4.375 in ID, 4.25 in Drift', value: '6.625 in OD, 69.63 lbs/ft, 4.375 in ID, 4.25 in Drift' },
    { label: '7.0 in OD, 17.0 lbs/ft, 6.538 in ID, 6.413 in Drift', value: '7.0 in OD, 17.0 lbs/ft, 6.538 in ID, 6.413 in Drift' },
    { label: '7.0 in OD, 20.0 lbs/ft, 6.456 in ID, 6.331 in Drift', value: '7.0 in OD, 20.0 lbs/ft, 6.456 in ID, 6.331 in Drift' },
    { label: '7.0 in OD, 23.0 lbs/ft, 6.366 in ID, 6.241 in Drift', value: '7.0 in OD, 23.0 lbs/ft, 6.366 in ID, 6.241 in Drift' },
    { label: '7.0 in OD, 26.0 lbs/ft, 6.276 in ID, 6.151 in Drift', value: '7.0 in OD, 26.0 lbs/ft, 6.276 in ID, 6.151 in Drift' },
    { label: '7.0 in OD, 29.0 lbs/ft, 6.184 in ID, 6.059 in Drift', value: '7.0 in OD, 29.0 lbs/ft, 6.184 in ID, 6.059 in Drift' },
    { label: '7.0 in OD, 32.0 lbs/ft, 6.094 in ID, 5.969 in Drift', value: '7.0 in OD, 32.0 lbs/ft, 6.094 in ID, 5.969 in Drift' },
    { label: '7.0 in OD, 35.0 lbs/ft, 6.004 in ID, 5.879 in Drift', value: '7.0 in OD, 35.0 lbs/ft, 6.004 in ID, 5.879 in Drift' },
    { label: '7.0 in OD, 38.0 lbs/ft, 5.92 in ID, 5.795 in Drift', value: '7.0 in OD, 38.0 lbs/ft, 5.92 in ID, 5.795 in Drift' },
    { label: '7.0 in OD, 41.0 lbs/ft, 5.82 in ID, 5.695 in Drift', value: '7.0 in OD, 41.0 lbs/ft, 5.82 in ID, 5.695 in Drift' },
    { label: '7.0 in OD, 42.7 lbs/ft, 5.75 in ID, 5.625 in Drift', value: '7.0 in OD, 42.7 lbs/ft, 5.75 in ID, 5.625 in Drift' },
    { label: '7.0 in OD, 44.0 lbs/ft, 5.72 in ID, 5.595 in Drift', value: '7.0 in OD, 44.0 lbs/ft, 5.72 in ID, 5.595 in Drift' },
    { label: '7.0 in OD, 45.4 lbs/ft, 5.66 in ID, 5.535 in Drift', value: '7.0 in OD, 45.4 lbs/ft, 5.66 in ID, 5.535 in Drift' },
    { label: '7.0 in OD, 49.5 lbs/ft, 5.54 in ID, 5.415 in Drift', value: '7.0 in OD, 49.5 lbs/ft, 5.54 in ID, 5.415 in Drift' },
    { label: '7.0 in OD, 56.1 lbs/ft, 5.376 in ID, 5.251 in Drift', value: '7.0 in OD, 56.1 lbs/ft, 5.376 in ID, 5.251 in Drift' },
    { label: '7.0 in OD, 58.0 lbs/ft, 5.24 in ID, 5.115 in Drift', value: '7.0 in OD, 58.0 lbs/ft, 5.24 in ID, 5.115 in Drift' },
    { label: '7.0 in OD, 66.5 lbs/ft, 5.04 in ID, 4.915 in Drift', value: '7.0 in OD, 66.5 lbs/ft, 5.04 in ID, 4.915 in Drift' },
    { label: '7.625 in OD, 20.0 lbs/ft, 7.125 in ID, 7.0 in Drift', value: '7.625 in OD, 20.0 lbs/ft, 7.125 in ID, 7.0 in Drift' },
    { label: '7.625 in OD, 24.0 lbs/ft, 7.025 in ID, 6.9 in Drift', value: '7.625 in OD, 24.0 lbs/ft, 7.025 in ID, 6.9 in Drift' },
    { label: '7.625 in OD, 26.4 lbs/ft, 6.969 in ID, 6.844 in Drift', value: '7.625 in OD, 26.4 lbs/ft, 6.969 in ID, 6.844 in Drift' },
    { label: '7.625 in OD, 29.7 lbs/ft, 6.875 in ID, 6.75 in Drift', value: '7.625 in OD, 29.7 lbs/ft, 6.875 in ID, 6.75 in Drift' },
    { label: '7.625 in OD, 33.7 lbs/ft, 6.765 in ID, 6.64 in Drift', value: '7.625 in OD, 33.7 lbs/ft, 6.765 in ID, 6.64 in Drift' },
    { label: '7.625 in OD, 39.0 lbs/ft, 6.625 in ID, 6.5 in Drift', value: '7.625 in OD, 39.0 lbs/ft, 6.625 in ID, 6.5 in Drift' },
    { label: '7.625 in OD, 42.8 lbs/ft, 6.501 in ID, 6.376 in Drift', value: '7.625 in OD, 42.8 lbs/ft, 6.501 in ID, 6.376 in Drift' },
    { label: '7.625 in OD, 45.3 lbs/ft, 6.435 in ID, 6.31 in Drift', value: '7.625 in OD, 45.3 lbs/ft, 6.435 in ID, 6.31 in Drift' },
    { label: '7.625 in OD, 47.1 lbs/ft, 6.375 in ID, 6.25 in Drift', value: '7.625 in OD, 47.1 lbs/ft, 6.375 in ID, 6.25 in Drift' },
    { label: '7.625 in OD, 51.2 lbs/ft, 6.249 in ID, 6.125 in Drift', value: '7.625 in OD, 51.2 lbs/ft, 6.249 in ID, 6.125 in Drift' },
    { label: '7.625 in OD, 52.8 lbs/ft, 6.201 in ID, 6.0 in Drift', value: '7.625 in OD, 52.8 lbs/ft, 6.201 in ID, 6.0 in Drift' },
    { label: '7.625 in OD, 55.75 lbs/ft, 6.201 in ID, 6.176 in Drift', value: '7.625 in OD, 55.75 lbs/ft, 6.201 in ID, 6.176 in Drift' },
    { label: '7.75 in OD, 46.1 lbs/ft, 6.56 in ID, 6.5 in Drift', value: '7.75 in OD, 46.1 lbs/ft, 6.56 in ID, 6.5 in Drift' },
    { label: '8.625 in OD, 24.0 lbs/ft, 8.097 in ID, 7.972 in Drift', value: '8.625 in OD, 24.0 lbs/ft, 8.097 in ID, 7.972 in Drift' },
    { label: '8.625 in OD, 28.0 lbs/ft, 8.017 in ID, 7.892 in Drift', value: '8.625 in OD, 28.0 lbs/ft, 8.017 in ID, 7.892 in Drift' },
    { label: '8.625 in OD, 32.0 lbs/ft, 7.921 in ID, 7.796 in Drift', value: '8.625 in OD, 32.0 lbs/ft, 7.921 in ID, 7.796 in Drift' },
    { label: '8.625 in OD, 36.0 lbs/ft, 7.825 in ID, 7.7 in Drift', value: '8.625 in OD, 36.0 lbs/ft, 7.825 in ID, 7.7 in Drift' },
    { label: '8.625 in OD, 40.0 lbs/ft, 7.725 in ID, 7.6 in Drift', value: '8.625 in OD, 40.0 lbs/ft, 7.725 in ID, 7.6 in Drift' },
    { label: '8.625 in OD, 44.0 lbs/ft, 7.625 in ID, 7.5 in Drift', value: '8.625 in OD, 44.0 lbs/ft, 7.625 in ID, 7.5 in Drift' },
    { label: '8.625 in OD, 49.0 lbs/ft, 7.511 in ID, 7.386 in Drift', value: '8.625 in OD, 49.0 lbs/ft, 7.511 in ID, 7.386 in Drift' },
    { label: '8.625 in OD, 52.0 lbs/ft, 7.435 in ID, 7.31 in Drift', value: '8.625 in OD, 52.0 lbs/ft, 7.435 in ID, 7.31 in Drift' },
    { label: '8.75 in OD, 49.7 lbs/ft, 7.636 in ID, 7.5 in Drift', value: '8.75 in OD, 49.7 lbs/ft, 7.636 in ID, 7.5 in Drift' },
    { label: '9.625 in OD, 29.3 lbs/ft, 9.063 in ID, 8.907 in Drift', value: '9.625 in OD, 29.3 lbs/ft, 9.063 in ID, 8.907 in Drift' },
    { label: '9.625 in OD, 32.3 lbs/ft, 9.001 in ID, 8.845 in Drift', value: '9.625 in OD, 32.3 lbs/ft, 9.001 in ID, 8.845 in Drift' },
    { label: '9.625 in OD, 36.0 lbs/ft, 8.921 in ID, 8.765 in Drift', value: '9.625 in OD, 36.0 lbs/ft, 8.921 in ID, 8.765 in Drift' },
    { label: '9.625 in OD, 38.0 lbs/ft, 8.885 in ID, 8.76 in Drift', value: '9.625 in OD, 38.0 lbs/ft, 8.885 in ID, 8.76 in Drift' },
    { label: '9.625 in OD, 40.0 lbs/ft, 8.835 in ID, 8.679 in Drift', value: '9.625 in OD, 40.0 lbs/ft, 8.835 in ID, 8.679 in Drift' },
    { label: '9.625 in OD, 43.5 lbs/ft, 8.755 in ID, 8.599 in Drift', value: '9.625 in OD, 43.5 lbs/ft, 8.755 in ID, 8.599 in Drift' },
    { label: '9.625 in OD, 47.0 lbs/ft, 8.681 in ID, 8.525 in Drift', value: '9.625 in OD, 47.0 lbs/ft, 8.681 in ID, 8.525 in Drift' },
    { label: '9.625 in OD, 53.5 lbs/ft, 8.535 in ID, 8.379 in Drift', value: '9.625 in OD, 53.5 lbs/ft, 8.535 in ID, 8.379 in Drift' },
    { label: '9.625 in OD, 58.4 lbs/ft, 8.435 in ID, 8.279 in Drift', value: '9.625 in OD, 58.4 lbs/ft, 8.435 in ID, 8.279 in Drift' },
    { label: '9.625 in OD, 59.4 lbs/ft, 8.407 in ID, 8.251 in Drift', value: '9.625 in OD, 59.4 lbs/ft, 8.407 in ID, 8.251 in Drift' },
    { label: '9.625 in OD, 61.1 lbs/ft, 8.375 in ID, 8.219 in Drift', value: '9.625 in OD, 61.1 lbs/ft, 8.375 in ID, 8.219 in Drift' },
    { label: '9.625 in OD, 64.9 lbs/ft, 8.281 in ID, 8.125 in Drift', value: '9.625 in OD, 64.9 lbs/ft, 8.281 in ID, 8.125 in Drift' },
    { label: '9.625 in OD, 70.3 lbs/ft, 8.157 in ID, 8.001 in Drift', value: '9.625 in OD, 70.3 lbs/ft, 8.157 in ID, 8.001 in Drift' },
    { label: '9.625 in OD, 71.8 lbs/ft, 8.125 in ID, 7.969 in Drift', value: '9.625 in OD, 71.8 lbs/ft, 8.125 in ID, 7.969 in Drift' },
    { label: '9.75 in OD, 59.2 lbs/ft, 8.56 in ID, 8.5 in Drift', value: '9.75 in OD, 59.2 lbs/ft, 8.56 in ID, 8.5 in Drift' },
    { label: '9.875 in OD, 62.8 lbs/ft, 8.625 in ID, 8.5 in Drift', value: '9.875 in OD, 62.8 lbs/ft, 8.625 in ID, 8.5 in Drift' },
    { label: '10.75 in OD, 32.75 lbs/ft, 10.192 in ID, 10.036 in Drift', value: '10.75 in OD, 32.75 lbs/ft, 10.192 in ID, 10.036 in Drift' },
    { label: '10.75 in OD, 35.75 lbs/ft, 10.136 in ID, 10.011 in Drift', value: '10.75 in OD, 35.75 lbs/ft, 10.136 in ID, 10.011 in Drift' },
    { label: '10.75 in OD, 40.5 lbs/ft, 10.05 in ID, 9.894 in Drift', value: '10.75 in OD, 40.5 lbs/ft, 10.05 in ID, 9.894 in Drift' },
    { label: '10.75 in OD, 45.5 lbs/ft, 9.95 in ID, 9.794 in Drift', value: '10.75 in OD, 45.5 lbs/ft, 9.95 in ID, 9.794 in Drift' },
    { label: '10.75 in OD, 51.0 lbs/ft, 9.85 in ID, 9.694 in Drift', value: '10.75 in OD, 51.0 lbs/ft, 9.85 in ID, 9.694 in Drift' },
    { label: '10.75 in OD, 55.5 lbs/ft, 9.76 in ID, 9.604 in Drift', value: '10.75 in OD, 55.5 lbs/ft, 9.76 in ID, 9.604 in Drift' },
    { label: '10.75 in OD, 60.7 lbs/ft, 9.66 in ID, 9.504 in Drift', value: '10.75 in OD, 60.7 lbs/ft, 9.66 in ID, 9.504 in Drift' },
    { label: '10.75 in OD, 65.7 lbs/ft, 9.56 in ID, 9.404 in Drift', value: '10.75 in OD, 65.7 lbs/ft, 9.56 in ID, 9.404 in Drift' },
    { label: '10.75 in OD, 71.1 lbs/ft, 9.45 in ID, 9.294 in Drift', value: '10.75 in OD, 71.1 lbs/ft, 9.45 in ID, 9.294 in Drift' },
    { label: '10.75 in OD, 73.2 lbs/ft, 9.406 in ID, 9.25 in Drift', value: '10.75 in OD, 73.2 lbs/ft, 9.406 in ID, 9.25 in Drift' },
    { label: '10.75 in OD, 76.0 lbs/ft, 9.35 in ID, 9.194 in Drift', value: '10.75 in OD, 76.0 lbs/ft, 9.35 in ID, 9.194 in Drift' },
    { label: '10.75 in OD, 79.2 lbs/ft, 9.282 in ID, 9.126 in Drift', value: '10.75 in OD, 79.2 lbs/ft, 9.282 in ID, 9.126 in Drift' },
    { label: '10.75 in OD, 81.0 lbs/ft, 9.25 in ID, 9.094 in Drift', value: '10.75 in OD, 81.0 lbs/ft, 9.25 in ID, 9.094 in Drift' },
    { label: '11.75 in OD, 38.0 lbs/ft, 11.15 in ID, 10.994 in Drift', value: '11.75 in OD, 38.0 lbs/ft, 11.15 in ID, 10.994 in Drift' },
    { label: '11.75 in OD, 42.0 lbs/ft, 11.084 in ID, 10.928 in Drift', value: '11.75 in OD, 42.0 lbs/ft, 11.084 in ID, 10.928 in Drift' },
    { label: '11.75 in OD, 47.0 lbs/ft, 11.0 in ID, 10.844 in Drift', value: '11.75 in OD, 47.0 lbs/ft, 11.0 in ID, 10.844 in Drift' },
    { label: '11.75 in OD, 54.0 lbs/ft, 10.88 in ID, 10.724 in Drift', value: '11.75 in OD, 54.0 lbs/ft, 10.88 in ID, 10.724 in Drift' },
    { label: '11.75 in OD, 60.0 lbs/ft, 10.772 in ID, 10.616 in Drift', value: '11.75 in OD, 60.0 lbs/ft, 10.772 in ID, 10.616 in Drift' },
    { label: '11.75 in OD, 65.0 lbs/ft, 10.682 in ID, 10.526 in Drift', value: '11.75 in OD, 65.0 lbs/ft, 10.682 in ID, 10.526 in Drift' },
    { label: '11.75 in OD, 66.7 lbs/ft, 10.656 in ID, 10.5 in Drift', value: '11.75 in OD, 66.7 lbs/ft, 10.656 in ID, 10.5 in Drift' },
    { label: '11.75 in OD, 71.0 lbs/ft, 10.586 in ID, 10.43 in Drift', value: '11.75 in OD, 71.0 lbs/ft, 10.586 in ID, 10.43 in Drift' },
    { label: '11.75 in OD, 73.6 lbs/ft, 10.532 in ID, 10.376 in Drift', value: '11.75 in OD, 73.6 lbs/ft, 10.532 in ID, 10.376 in Drift' },
    { label: '11.75 in OD, 75.0 lbs/ft, 10.514 in ID, 10.358 in Drift', value: '11.75 in OD, 75.0 lbs/ft, 10.514 in ID, 10.358 in Drift' },
    { label: '11.75 in OD, 76.0 lbs/ft, 10.5 in ID, 10.344 in Drift', value: '11.75 in OD, 76.0 lbs/ft, 10.5 in ID, 10.344 in Drift' },
    { label: '11.75 in OD, 79.0 lbs/ft, 10.438 in ID, 10.282 in Drift', value: '11.75 in OD, 79.0 lbs/ft, 10.438 in ID, 10.282 in Drift' },
    { label: '11.75 in OD, 80.5 lbs/ft, 10.406 in ID, 10.25 in Drift', value: '11.75 in OD, 80.5 lbs/ft, 10.406 in ID, 10.25 in Drift' },
    { label: '11.75 in OD, 83.0 lbs/ft, 10.368 in ID, 10.212 in Drift', value: '11.75 in OD, 83.0 lbs/ft, 10.368 in ID, 10.212 in Drift' },
    { label: '11.75 in OD, 87.2 lbs/ft, 10.282 in ID, 10.126 in Drift', value: '11.75 in OD, 87.2 lbs/ft, 10.282 in ID, 10.126 in Drift' },
    { label: '11.75 in OD, 95.0 lbs/ft, 10.124 in ID, 9.968 in Drift', value: '11.75 in OD, 95.0 lbs/ft, 10.124 in ID, 9.968 in Drift' },
    { label: '11.875 in OD, 71.8 lbs/ft, 10.711 in ID, 10.625 in Drift', value: '11.875 in OD, 71.8 lbs/ft, 10.711 in ID, 10.625 in Drift' },
    { label: '13.375 in OD, 48.0 lbs/ft, 12.715 in ID, 12.559 in Drift', value: '13.375 in OD, 48.0 lbs/ft, 12.715 in ID, 12.559 in Drift' },
    { label: '13.375 in OD, 54.5 lbs/ft, 12.615 in ID, 12.459 in Drift', value: '13.375 in OD, 54.5 lbs/ft, 12.615 in ID, 12.459 in Drift' },
    { label: '13.375 in OD, 61.0 lbs/ft, 12.515 in ID, 12.359 in Drift', value: '13.375 in OD, 61.0 lbs/ft, 12.515 in ID, 12.359 in Drift' },
    { label: '13.375 in OD, 68.0 lbs/ft, 12.415 in ID, 12.259 in Drift', value: '13.375 in OD, 68.0 lbs/ft, 12.415 in ID, 12.259 in Drift' },
    { label: '13.375 in OD, 72.0 lbs/ft, 12.347 in ID, 12.191 in Drift', value: '13.375 in OD, 72.0 lbs/ft, 12.347 in ID, 12.191 in Drift' },
    { label: '13.375 in OD, 77.0 lbs/ft, 12.275 in ID, 12.119 in Drift', value: '13.375 in OD, 77.0 lbs/ft, 12.275 in ID, 12.119 in Drift' },
    { label: '13.375 in OD, 80.7 lbs/ft, 12.215 in ID, 12.059 in Drift', value: '13.375 in OD, 80.7 lbs/ft, 12.215 in ID, 12.059 in Drift' },
    { label: '13.375 in OD, 83.0 lbs/ft, 12.175 in ID, 12.019 in Drift', value: '13.375 in OD, 83.0 lbs/ft, 12.175 in ID, 12.019 in Drift' },
    { label: '13.375 in OD, 85.0 lbs/ft, 12.159 in ID, 12.003 in Drift', value: '13.375 in OD, 85.0 lbs/ft, 12.159 in ID, 12.003 in Drift' },
    { label: '13.375 in OD, 86.0 lbs/ft, 12.125 in ID, 11.969 in Drift', value: '13.375 in OD, 86.0 lbs/ft, 12.125 in ID, 11.969 in Drift' },
    { label: '13.375 in OD, 91.0 lbs/ft, 12.055 in ID, 11.899 in Drift', value: '13.375 in OD, 91.0 lbs/ft, 12.055 in ID, 11.899 in Drift' },
    { label: '13.375 in OD, 92.0 lbs/ft, 12.031 in ID, 11.875 in Drift', value: '13.375 in OD, 92.0 lbs/ft, 12.031 in ID, 11.875 in Drift' },
    { label: '13.375 in OD, 96.0 lbs/ft, 11.975 in ID, 11.819 in Drift', value: '13.375 in OD, 96.0 lbs/ft, 11.975 in ID, 11.819 in Drift' },
    { label: '13.375 in OD, 98.0 lbs/ft, 11.937 in ID, 11.781 in Drift', value: '13.375 in OD, 98.0 lbs/ft, 11.937 in ID, 11.781 in Drift' },
    { label: '13.375 in OD, 100.3 lbs/ft, 11.907 in ID, 11.751 in Drift', value: '13.375 in OD, 100.3 lbs/ft, 11.907 in ID, 11.751 in Drift' },
    { label: '13.375 in OD, 102.0 lbs/ft, 11.889 in ID, 11.733 in Drift', value: '13.375 in OD, 102.0 lbs/ft, 11.889 in ID, 11.733 in Drift' },
    { label: '13.5 in OD, 81.4 lbs/ft, 12.34 in ID, 12.25 in Drift', value: '13.5 in OD, 81.4 lbs/ft, 12.34 in ID, 12.25 in Drift' },
    { label: '13.625 in OD, 88.2 lbs/ft, 12.375 in ID, 12.25 in Drift', value: '13.625 in OD, 88.2 lbs/ft, 12.375 in ID, 12.25 in Drift' }
];
