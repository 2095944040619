import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountyData } from './iCountyData';
import { ICountyDataService } from './iCountyData.service';

@Injectable()
export class CountyDataService extends ICountyDataService {
  constructor(private http: HttpClient) { super(); }

  /*
  getCounties(stateName:string){
    if (stateName == null || stateName == '')
      return null;
    var stateUrl = '/assets/counties/' + stateName + '.json';
    return this.http.get<CountyData[]>(stateUrl);
  }
  */

  getCounties(stateName:string) : Observable<CountyData[]> {
    var stateUrl = '/assets/counties/' + stateName + '.json';
    let request = this.http.get(stateUrl).pipe(map(
      result => {
          return result as CountyData[];
      }
    ));
    return request;
  }
}