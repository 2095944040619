import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CompanyWorkbookConfiguration, WellKnownMeasurementChoice, UnitChoice } from '../models/models';

@Injectable()
export abstract class ICompanyWorkbookConfigurationService  {
    abstract getActivities(): Observable<string[]>;

    abstract getWellKnownMeasurements(): Observable<WellKnownMeasurementChoice[]>;

    abstract getUnitChoices(): Observable<UnitChoice[]>;

    abstract getConfigurationForCompany(companyId: string): Observable<CompanyWorkbookConfiguration>;

    abstract getConfigurationById(id: string): Observable<CompanyWorkbookConfiguration>;

    abstract getDefaultConfigurationForCompany(companyId: string): Observable<CompanyWorkbookConfiguration>;

    abstract createOrUpdateConfiguration(configuation: CompanyWorkbookConfiguration): Observable<any>;

    abstract deleteConfigurationByCompanyId(compnayId: string);
}
