/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IMsalService, MsalService } from '@azure/msal-angular';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, ICustomProperties, IDependencyTelemetry, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITelemetryPlugin, ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppConfig } from '../models/AppConfig';

@Injectable()
export class TelemetryService {
    private angularPlugin = new AngularPlugin();
    private _appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: AppConfig.GLOBAL_CONFIG.applicationInsightsKey,
            extensions: [this.angularPlugin as unknown as ITelemetryPlugin],
            //auto router tracking, default pageview duration will be set to 0
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                },
            },
        },
    });

    constructor(private router: Router, private _msalService: MsalService) {
        this._appInsights.loadAppInsights();
        const user = this.getOpsAccount();
        if (user) {
            this._appInsights.setAuthenticatedUserContext(user);
        }
        this._appInsights.addTelemetryInitializer(envelope => {
            envelope.tags = envelope.tags || [];
            if (window.parent !== window) {
                envelope.tags['ai.cloud.role'] = 'frameinbrowser';
            } else {
                envelope.tags['ai.cloud.role'] = 'browser';
            }
            envelope.tags['ai.application.ver'] = '2.0.1.2';
            try {
                if (envelope.baseType === 'ExceptionData' && envelope?.baseData?.exceptions[0]?.message?.indexOf('0 Unknown Error') > -1) {
                  this._appInsights.trackTrace({ message: envelope?.baseData?.exceptions[0]?.message, severityLevel: SeverityLevel.Warning });
                  return false;
                }
                if (envelope.baseType === 'ExceptionData' && envelope?.baseData?.exceptions[0]?.message?.indexOf('InteractionRequiredAuthError: interaction_required') > -1) {
                  this._appInsights.trackTrace({ message: envelope?.baseData?.exceptions[0]?.message, severityLevel: SeverityLevel.Warning });
                  return false;
                }
                if (envelope.baseType === 'ExceptionData' && envelope?.baseData?.exceptions[0]?.message?.indexOf('Error: could not resolve endpoint') > -1) {
                  this._appInsights.trackTrace({ message: envelope?.baseData?.exceptions[0]?.message, severityLevel: SeverityLevel.Warning });
                  return false;
                }
                if (envelope.baseType === 'ExceptionData' && envelope?.baseData?.exceptions[0]?.message?.indexOf('redirect_in_iframe') > -1) {
                    this._appInsights.trackTrace({ message: envelope?.baseData?.exceptions[0]?.message, severityLevel: SeverityLevel.Warning });
                    return false;
                }
                if (envelope.baseType === 'ExceptionData' && envelope?.baseData?.exceptions[0]?.message?.indexOf('interaction_in_progress') > -1) {
                    this._appInsights.trackTrace({ message: envelope?.baseData?.exceptions[0]?.message, severityLevel: SeverityLevel.Warning });
                    return false;
                }
                if (envelope.baseType === 'ExceptionData' && envelope?.baseData?.exceptions[0]?.message?.indexOf('BrowserAuthError') > -1) {
                    this._appInsights.trackTrace({ message: envelope?.baseData?.exceptions[0]?.message, severityLevel: SeverityLevel.Warning });
                    return false;
                }
                if (envelope.baseType === 'ExceptionData' && envelope?.baseData?.exceptions[0]?.message?.indexOf('ClientAuthError') > -1) {
                    this._appInsights.trackTrace({ message: envelope?.baseData?.exceptions[0]?.message, severityLevel: SeverityLevel.Warning });
                    return false;
                }
                return true;
              } catch (error) {
                this._appInsights.trackTrace({ message: 'error in telemetry initializer', severityLevel: SeverityLevel.Warning });
                return true;
              }
        });
    }
    private getOpsAccount() {
        const accounts = this._msalService.instance.getAllAccounts();
        const opsviewerAccounts = accounts.filter(a => a.environment.indexOf('opsviewer') > -1);
        if (opsviewerAccounts.length === 0) {
            console.warn('No opsviewer account found.' + JSON.stringify(accounts));
            return null;
        }
        return opsviewerAccounts[0].username;
      }

    // expose tracking methods
    trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
        this._appInsights.trackEvent(event, customProperties);
    }

    startTrackEvent(name?: string) {
        this._appInsights.startTrackEvent(name);
    }

    stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
        this._appInsights.stopTrackEvent(name, properties, measurements);
    }

    trackPageView(pageView?: IPageViewTelemetry) {
        this._appInsights.trackPageView(pageView);
    }

    startTrackPage(name?: string) {
        this._appInsights.startTrackPage(name);
    }

    stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        this._appInsights.stopTrackPage(name, url, properties, measurements);
    }

    trackMetric(metric: IMetricTelemetry, properties?: ICustomProperties) {
        this._appInsights.trackMetric(metric, properties);
    }

    trackException(exception: IExceptionTelemetry,  properties?: ICustomProperties) {
        this._appInsights.trackException(exception, properties);
    }

    trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
        this._appInsights.trackTrace(message, properties);
    }

    trackDependencyData(dependency: IDependencyTelemetry) {
        this._appInsights.trackDependencyData(dependency);
    }

    flush() {
        this._appInsights.flush();
    }
}
