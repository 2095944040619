import { Injectable } from '@angular/core';
import * as JsStore from 'jsstore';
//import * as workerPath from 'file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js';
import { DATA_TYPE, ITable, IDataBase } from 'jsstore';
import { WorkbookLog } from '../models/workbookLog';
import { IOfflineWorkbookService } from './iOfflineWorkbook.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfflineworkbookService implements IOfflineWorkbookService {
  static _idbCon = new JsStore.Instance(new Worker("/assets/jsstore/jsstore.worker.min.js"));
  
  _dbname = 'offlineworkbook';
  _tableName = 'workbookqueue';
  _queuedItems = new Subject<number>();

  constructor() {
    this.initJsStore();
    OfflineworkbookService._idbCon.setLogStatus(false);
  }

  initJsStore() {
    this.connection.isDbExist(this._dbname).then(isExist => {
      if (isExist) {
        this.connection.openDb(this._dbname);
      } else {
        const dataBase = this.getDatabase();
        this.connection.initDb(dataBase);
      }
    }).catch(err => {
      // this will be fired when indexedDB is not supported.
      alert(err.message);
    });
  }
  get connection() {
    return OfflineworkbookService._idbCon;
  }
  public deleteDatabase(): Promise<number> {
    return this.connection.remove({ from: this._tableName });
  }

  private getDatabase() {
    const workbookqueue: ITable = {
      name: this._tableName,
      columns:
      {
        id:{primaryKey:true,dataType:DATA_TYPE.String},
        jobId:{notNull:true,dataType:DATA_TYPE.String},
        workbookLog:{notNull:true,dataType:DATA_TYPE.Object},
        operation:{notNull:true,dataType:DATA_TYPE.String,default: Operation.Insert} // update,delete
      }
      // [{
      //   name: 'id',
      //   primaryKey: true,
      //   dataType: DATA_TYPE.String
      // },
      // {
      //   name: 'jobId',
      //   notNull: true,
      //   dataType: DATA_TYPE.String
      // },
      // {
      //   name: 'workbookLog',
      //   notNull: true,
      //   dataType: DATA_TYPE.Object
      // },
      // {
      //   name: 'operation',
      //   dataType: DATA_TYPE.String,
      //   default: Operation.Insert // update,delete
      // }
      // ]
    };
    const dataBase: IDataBase = {
      name: this._dbname,
      tables: [workbookqueue]
    };
    return dataBase;
  }

  public queueCreate(logentry: WorkbookLog): Promise<number | {}[]> {
    const entry = new WorkbookQueueEntry();
    entry.workbookLog = logentry;
    entry.jobId = logentry.jobId;
    entry.id = logentry.id;
    entry.operation = Operation.Insert;
    return this.connection.insert({ into: this._tableName, values: [entry] }).then((result) => {
      this.updateQueuedItems();
      return result;
    });
  }
  public async queueUpdate(logentry: WorkbookLog) {
    const existing = await this.getById(logentry.id);
    if (existing == null) {
      const entry = new WorkbookQueueEntry();
      entry.workbookLog = logentry;
      entry.jobId = logentry.jobId;
      entry.id = logentry.id;
      entry.operation = Operation.Update;
      return await this.connection.insert({ into: this._tableName, values: [entry] }).then((result) => {
        this.updateQueuedItems();
        return result;
      });
    } else {
      return await this.connection.update({ in: this._tableName, set: { workbookLog: logentry }, where: { id: logentry.id } })
                                  .then((result) => {
                                      this.updateQueuedItems();
                                      return result;
                                    });
    }
  }

  public async getById(id: string): Promise<IWorkbookQueueEntry> {
    const results = <IWorkbookQueueEntry[]>await this.connection.select({ from: this._tableName, where: { id: id } });
    if (results.length === 0) { return null; }
    return results[0];
  }

  public async queueDelete(logentry: WorkbookLog) {
    const existing = await this.getById(logentry.id);
    if (existing == null) {
      const entry = new WorkbookQueueEntry();
      entry.workbookLog = logentry;
      entry.jobId = logentry.jobId;
      entry.id = logentry.id;
      entry.operation = Operation.Delete;
      await this.connection.insert({ into: this._tableName, values: [entry] }).then((result) => {
        this.updateQueuedItems();
        return result;
      });
    } else if (existing.operation === Operation.Insert) {
      await this.connection.remove({ from: this._tableName, where: { id: logentry.id } }).then((result) => {
        this.updateQueuedItems();
        return result;
      });
    } else if (existing.operation === Operation.Update) {
      await this.connection.update({ in: this._tableName, set: { operation: Operation.Delete }, where: { id: logentry.id } })
                            .then((result) => {
                              this.updateQueuedItems();
                              return result;
                            });
    }
  }


  public getQueueEntries(jobId: string): Promise<IWorkbookQueueEntry[]> {
    if (jobId === null) {
      return this.connection.select({ from: this._tableName });
    } else {
      return this.connection.select({ from: this._tableName, where: {jobId: jobId} });
    }
  }

  public async removeQueuedEntry(entry: IWorkbookQueueEntry) {
    await this.connection.remove({ from: this._tableName, where: { id: entry.id } }).then((result) => {
      this.updateQueuedItems();
      return result;
    });
  }

  private updateQueuedItems() {
    this.getQueueEntries(null).then(result => {
      if (result) {
        this._queuedItems.next(result.length);
      }
    });
  }

  public queuedItemsObservable(): Observable<number> {
    return this._queuedItems;
  }

}
export class IWorkbookQueueEntry {
  id: string;
  jobId: string;
  workbookLog: WorkbookLog;
  operation: Operation;
}
export class WorkbookQueueEntry implements IWorkbookQueueEntry {
  id = ''; // new guid;
  jobId = '';
  workbookLog = new WorkbookLog();
  operation = Operation.Insert;
}
export enum Operation {
  Insert = 'Insert',
  Update = 'Update',
  Delete = 'Delete',
}
