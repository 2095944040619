import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { VendorJob } from 'src/app/models/models';

@Component({
  selector: 'app-job-performance-summary',
  templateUrl: './job-performance-summary.component.html',
  styleUrls: ['./job-performance-summary.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JobPerformanceSummaryComponent),
    multi: true
  }]
})
export class JobPerformanceSummaryComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;
  public isDisabled: boolean;

  @Input() _job: VendorJob;
  get job() {
    return this._job;
  }
  set job(val) {
    this._job = val;
    this.propagateChange(this._job);
  }

  constructor() { }

  ngOnInit() {
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => { };

  writeValue(obj: any): void {
    if (obj) {
      this.job = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
