import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef, ApplicationRef, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { IOpsViewerMessagesService, IUserService } from 'src/app/services/services';
import { BlobServiceClient, ContainerClient, BlobItem, ContainerListBlobsOptions } from '@azure/storage-blob';
import { ɵMetadataOverrider } from '@angular/core/testing';
import { FileUpload } from 'primeng/fileupload';
import { AppConfig } from 'src/app/models/AppConfig';
import { first } from 'rxjs/operators';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { User } from 'src/app/models/user';
import { CommonJobDetails } from 'src/app/models/commonJobDetails';

@Component({
  selector: 'app-filelist',
  templateUrl: './filelist.component.html',
  styleUrls: ['./filelist.component.css']
})
export class FilelistComponent implements OnInit {

  @ViewChild(FileUpload)
  _fileUploader: FileUpload;

  _consumerJobId: string;
  get consumerJobId(): string {
    return this._consumerJobId;
  }
  @Input() set consumerJobId(val: string) {
    this._consumerJobId = val;
    // skip initializing on first set since ngOnInit will handle
    if (this.currentUser) {
      this.initialize(this._consumerJobId, this.currentUser);
    }
  };
  @Input() isVendorJob = true;

  constructor(
    private _messageService: IOpsViewerMessagesService, 
    private _changeDet: ChangeDetectorRef,
    private _userManagerService: IUserManagerService,
    //private _filesListService: FilesListService
     ) { }

  uploadedFiles: any[] = [];
  categories: string[] = [];
  files: BlobItem[] = [];
  results: string[] = [];
  fileContents = new Map<string, string>();
  rowGroupMetadata: any;
  canUpload: boolean = false;
  currentUser: User;
//  jobDetails: CommonJobDetails;

  get isGlobal(): boolean {
    return !this.consumerJobId;// this._filesListService.isGlobalUse(this.jobId);
  }

  async ngOnInit() {
    this.currentUser = await this._userManagerService.getCurrentUser().toPromise();
    await this.initialize(this.consumerJobId, this.currentUser);
    
  }

  async initialize(jobId: string, user: User) {
    // if (!this.isGlobal) {
    //   this.jobDetails = await this._filesListService.getJobDetails(jobId, this.isVendorJob);
    // }

    

    await this.loadFiles();
    this.canUpload = this.isGlobal && user.isGlobalAdmin || !this.isGlobal;// this._filesListService.canUserUploadFiles(user, this.jobDetails);
  }


  getScriptSrc(txt:string) : string{
    let regex = /<script.*src="(.*)".*<\/script>/g;
    let match = regex.exec(txt);
    if (match && match.length > 1){
      console.warn(match[1]);
      return match[1];
    }
    return null;
  }

  
  async loadFiles() {
    const containerClient = this.getContainerClient();
    const options: ContainerListBlobsOptions = {
      includeMetadata: true,
    };
    //options.prefix = this.isGlobal ? 'global' : 'job/' + this.jobId;
    options.prefix = this.isGlobal ? 'global' : 'job/' + this.consumerJobId;
    let files = containerClient.listBlobsFlat(options);
    this._changeDet.detach();
    this.files = [];
    let file: {
      done?: boolean;
      value: BlobItem;
  };
    do {
      file = await files.next();
      if (file.done) continue;
      this.loadFile(file.value);
      if (!this.isLink(file.value)) continue;
      let contents = await this.getContent(file.value);
      this.fileContents[file.value.name] = contents;
      this.loadScriptForContents(contents); //possible to do this instead https://www.oodlestechnologies.com/blogs/How-To-Display-Dynamic-HTML-Without-Sanitizing-Or-Filtering-Using-Pipe/
      console.warn(contents);
    } while(!file.done)

    this.files = this.files.sort((a,b)=>{
      if (!a.metadata["category"] && !b.metadata["category"]){
        return 0;
      }
      if (!a.metadata["category"]){
        return 1;
      }
      if (!b.metadata["category"]){
        return -1;
      }
      return a.metadata["category"].localeCompare(b.metadata["category"])
    });
    //this.files = this.files.splice(3);
    this.updateRowGroupMetaData();
    this._changeDet.reattach();
    this._changeDet.detectChanges();
  }
  search(event) {
    this.results = this.categories.filter((s, i, d) => { return s.includes(event.query) });
  }
  loadFile(file: BlobItem) {
    if (!file) return;
    this.files.push(file);
    let category = file.metadata["category"];
    if (category && !this.categories.includes(category)) {
      this.categories.push(category);
    }
   
  }
  getHref(file:BlobItem){
    let client = this.getContainerClient();
    return client.getBlobClient(file.name).url;
  }
  isLink(file:BlobItem){
    return file.name.endsWith(".html");
  }
  loadScriptForContents(contents:string){
    let scriptSrc = this.getScriptSrc(contents);
    if (!scriptSrc) return;
    this.loadScript(scriptSrc);

  }
  async getContent(file:BlobItem){
    let client = this.getContainerClient();
    let blobClient = client.getBlobClient(file.name);
    let content = await blobClient.download();
    let body = await content.blobBody;
    let text = await body.text();
    console.warn(text);
    return text;
  }
  getContainerClient(): ContainerClient {
   let url:string = AppConfig.GLOBAL_CONFIG.storageUri;
    const blobServiceClient = new BlobServiceClient(url);
    const containerClient = blobServiceClient.getContainerClient('files');
    return containerClient;
  }
  async upload(event) {
    const containerClient = this.getContainerClient();
    for (let i=0;i<event.files.length;i++) {
      const file = event.files[i];
     // const fileNamePrefix = this.isGlobal ? 'global/' : 'job/' + this.jobId + '/';
     const fileNamePrefix = this.isGlobal ? 'global/' : 'job/' + this.consumerJobId + '/';
       
     const blockBlobClient = containerClient.getBlockBlobClient(fileNamePrefix + file.name);
      this._fileUploader.uploading = true;
      await blockBlobClient.uploadBrowserData(file, {blobHTTPHeaders:{blobContentType:file.type}}); try {
        let description:string;
        description = file.description||"";
        description = description.replace(/([\n])/g,"\\n");
        await blockBlobClient.setMetadata({ category: file.category ||"", description: description });
      } catch(err){
        this._messageService.errorMessage(err, "could not set description. It may be too long");
        throw err;
      }
      this._fileUploader.clear();
      this._fileUploader.uploading = false;
    }
    await this.loadFiles();
    this._messageService.successMessage("Upload", "File Uploaded");
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }
  formatDescription(description:string):string {
    if (!description) return "";
    return description.replace(/(\\n)/g,"<br />");
  }
  formatSize(bytes) {
    if (bytes == 0) {
      return '0 B';
    }
    let k = 1024,
      dm = 3,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  remove(event: Event, index: number) {
    this._fileUploader.clearInputElement();
    this._fileUploader.files.splice(index, 1);
  }
  async delete(event: Event, file: BlobItem) {
 
    let client = this.getContainerClient();
    await client.deleteBlob(file.name);
    await this.loadFiles();
    
  }
  getCategory(file:BlobItem){
    if(!file.metadata['category']) return "";
    return file.metadata['category'];
  }

  public loadScript(src:string) {        
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes(src)) {
            isFound = true;
        }
    }

    if (!isFound || true) {
        var dynamicScripts = [src];

        for (var i = 0; i < dynamicScripts.length; i++) {
            let node = document.createElement('script');
            node.src = dynamicScripts [i];
            node.type = 'text/javascript';
            node.async = true;
            //node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }

    }
}

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.files) {
        for (let i = 0; i < this.files.length; i++) {
            let rowData = this.files[i];
            let brand = this.getCategory(rowData);
            if (i == 0) {
                this.rowGroupMetadata[brand] = { index: 0, size: 1 };
            }
            else {
                let previousRowData = this.files[i - 1];
                let previousRowGroup = this.getCategory(previousRowData);
                if (brand === previousRowGroup)
                    this.rowGroupMetadata[brand].size++;
                else
                    this.rowGroupMetadata[brand] = { index: i, size: 1 };
            }
        }
    }
  }

  
}

