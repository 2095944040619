import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';
import { NgDygraphsModule } from 'ng-dygraphs';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import { ActivityDefaultsComponent } from './activity-defaults/activity-defaults.component';
import { ChemistryComponent } from './chemistry/chemistry.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { GoalsComponent } from './goals/goals.component';
import { HeaderComponent } from './header/header.component';
import { IApplicationContextService, ApplicationContextService, ISimulationService, SimulationService } from '../services/services'
import { JobSimulatorComponent } from './job-simulator/job-simulator.component';
import { OrderListModule } from 'primeng/orderlist';
import { PerformanceComponent } from './performance/performance.component';
import { SequenceComponent } from './sequence/sequence.component';
import { SharedModule } from '../shared.module';
import { SimulationListComponent } from './simulation-list/simulation-list.component';
import { SimulationsComponent } from './simulations/simulations.component';
import { SiteDetailComponent } from './site-detail/site-detail.component';
import { SimulatorRoutingModule } from './simulator-routing.module';
import { WellInformationComponent } from './well-information/well-information.component';

@NgModule({
  declarations: [
    ActivityDefaultsComponent,
    ChemistryComponent,
    ExpensesComponent,
    GoalsComponent,
    HeaderComponent,
    JobSimulatorComponent,
    PerformanceComponent,
    SequenceComponent,
    SimulationListComponent,
    SimulationsComponent,
    SiteDetailComponent,
    WellInformationComponent,
  ],
  imports: [
    AccordionModule,
    BrowserModule,
    ButtonModule,
    CommonModule,
    DropdownModule,
    FieldsetModule,
    FormsModule,
    InputTextModule,
    NgDygraphsModule,
    OrderListModule,
    OverlayPanelModule,
    SelectButtonModule,
    SharedModule,
    SimulatorRoutingModule,
    TableModule,
    TabViewModule,
    TooltipModule
  ],
  providers: [
    { provide: ISimulationService, useClass: SimulationService },
    { provide: IApplicationContextService, useClass: ApplicationContextService },
    ConfirmationService,
  ],
})
export class SimulatorModule { }
