import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IApplicationContextService, ISimulationService } from 'src/app/services/services';
import { CompanyName } from 'src/app/models/models';
import { SimulationHeader } from 'src/app/models/simulation';
import { SimulationCommon } from '../functions/simulationCommon';

@Component({
  selector: 'simulation-list',
  templateUrl: './simulation-list.component.html',
  styleUrls: ['./simulation-list.component.css']
})
export class SimulationListComponent implements OnInit, OnDestroy {
  private _companyContextSubscription: Subscription;
  company: CompanyName;
  simulationHeaders: SimulationHeader[];

  constructor(
    private _applicationContextService: IApplicationContextService,
    private _simulationService: ISimulationService
  ) { }

  ngOnDestroy() {
    this._companyContextSubscription.unsubscribe();
  }

  ngOnInit() {
    this.listenToCompanyContext();
    this.updateCompany();
  }

  async cloneSimulation(simulationId: string) {
    try{
      const sim = await this._simulationService.getSimulation(simulationId).toPromise();
      const clone = SimulationCommon.cloneSimulation(sim);
      let saved = await this._simulationService.saveSimulation(clone).toPromise();
      console.log(`Saved Simulation ${ saved.header.simulationId }`);
      this.simulationHeaders = await this.getSimulations(this.company);
    }
    catch(error) {
      console.error('An error occurred: ', error.message);
    }
  }

  async deleteSimulation(id: string) {
    try{
      await this._simulationService.deleteSimulation(id).toPromise();
      this.simulationHeaders = await this.getSimulations(this.company); 
    }
    catch(error) {
      console.error('An error occurred: ', error.message);
    }
  }

  async getSimulations(company: CompanyName) : Promise<SimulationHeader[]> {
    if(!company || !company.companyId) {
      return [];
    }
    return this._simulationService.getCompanySimulations(company.companyId).toPromise();
  }

  listenToCompanyContext() {
    this._companyContextSubscription = this._applicationContextService.companyContextObserver.subscribe(() => this.updateCompany());
  }

  async updateCompany()  {
    try{
      const cmpny : CompanyName = this._applicationContextService.getCompanyContext();
      if (cmpny) {
        this.company = cmpny;
        this.simulationHeaders = await this.getSimulations(cmpny);
      }
    }
    catch(error) {
      console.error('An error occurred: ', error.message);
    }
  }
}
