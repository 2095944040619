import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IJobLinkerService } from './iJobLinker.service';
import { JobLinker, Job } from '../models/models';

@Injectable()
export class JobLinkerService extends IJobLinkerService {
  private apiUrl = '/api/joblinker/';

  constructor(private _http: HttpClient) {
    super();
  }

  getJobLinkers(): Observable<JobLinker[]> {
    return this._http.get<JobLinker[]>(this.apiUrl);
  }
  getJobLinkerById(id: string): Observable<JobLinker> {
    return this._http.get<JobLinker>(this.apiUrl + id);
  }
  createJobLinker(jobLinker: JobLinker): Observable<any> {
    return this._http.post(this.apiUrl, jobLinker);
  }
  updateJobLinker(jobLinker: JobLinker): Observable<any> {
    return this._http.put(this.apiUrl + jobLinker.id, jobLinker);
  }
  deleteJobLinker(id: string): Observable<any> {
    return this._http.delete(this.apiUrl + id);
  }
  findBestFitOrCreateNewJobLinker(job: Job): Observable<JobLinker> {
    return this._http.post<JobLinker>(this.apiUrl + 'bestfit', job);
  }
  getActiveJobLinkers(forDate: Date): Observable<JobLinker[]> {
    return this._http.get<JobLinker[]>(this.apiUrl + 'active/' + forDate);
  }
  associateVendorJobToJobLinker(vendorJobId: string, jobLinker: JobLinker): Observable<any> {
    return this._http.post(this.apiUrl + 'associate/' + vendorJobId, jobLinker);
  }
  getJobLinkerForVendorJobId(vendorJobId: string): Observable<JobLinker> {
    return this._http.get<JobLinker>(this.apiUrl + 'vendor/' + vendorJobId);
  }
}
