import { BaseEntity } from './baseEntity';

export class JobLinker implements BaseEntity {
  id: string;
  consumerId: string;
  wellName: string;
  apiNumber: string;
  consumerJobId: string;
  vendorJobIds: string[];
  jobType: string;
  consumerJobStartTime: Date;

  // Client side only properties
  consumer: string;
  consumerJobStatus: string;
  vendorJobNames: string;

  constructor() {}
}
