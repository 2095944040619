import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { IOpsViewerMessagesService } from './iOpsViewerMessages.service';
import * as moment from 'moment';

@Injectable()
export class OpsViewerMessagesService extends IOpsViewerMessagesService {

  private _key = 'global-messages';

  constructor(
    private _messageService: MessageService) {
    super();
  }

  errorMessage(error: any, summaryMessage: string): void {
    this._messageService.clear();

    let errorAsString = JSON.stringify(error);

    // Capture DateTime and Tracking Id in message
    const dateString: string = moment().format('MM-DD-YYYY HH:mm');
    const trackingIdString: string = Math.random().toString(36).substring(7);
    errorAsString = errorAsString + ` Date: ${dateString}, Error Message Id:: ${trackingIdString}`;

    // Display user friendly error in message banner
    // If validation message it will be in inner error.Message property
    let errorMessage: string = errorAsString;
    if (error.error && error.error.Message) {
      errorMessage = error.error.Message + ` Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
    } else if (error.message) {
      errorMessage = error.message + ` Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
    }
    this._messageService.add({key: this._key, severity: 'error', sticky:true, summary: summaryMessage, detail: errorMessage });

    // Log error to app insights as string value
    this.sendToApplicationInsights(errorAsString, trackingIdString);
    console.error(error);
  }

  sendToApplicationInsights(errorAsString:string, trackingIdString :string){
    if (errorAsString.includes("Validation Error:")){//do not include validation errors
     // this._appInsightsService.trackTrace(errorAsString, { "trackingId" : trackingIdString}, SeverityLevel.Warning);
    } else {
     // this._appInsightsService.trackException(new Error(errorAsString), null, { "trackingId" : trackingIdString});
    }
  }

  errorMessageWithObjectDetail(error: any, summaryMessage: string, serializedObject: string): void {
    this._messageService.clear();

    let errorAsString = JSON.stringify(error);

    // Capture DateTime and Tracking Id in message
    const dateString: string = moment().format('MM-DD-YYYY HH:mm');
    const trackingIdString: string = Math.random().toString(36).substring(7);
    errorAsString = errorAsString + ` Date: ${dateString}, Error Message Id:: ${trackingIdString}`;

    // Display user friendly error in message banner
    // If validation message it will be in inner error.Message property
    let errorMessage: string = errorAsString;
    let parsedError = this.tryParseError(error);
    if (parsedError != null) {
      errorMessage = `${parsedError} Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
    }
    this._messageService.add({key: this._key, severity: 'error',sticky:true, summary: summaryMessage, detail: errorMessage });

    const appInsightsMessage = errorAsString + ' Object Detail: ' + serializedObject;
    this.sendToApplicationInsights(appInsightsMessage, trackingIdString);

    console.error(error);
  }

  tryParseError(error):string{
    if (error.error && error.error.Message) {
      return error.error.Message;
    } 
    if (error.response){
      let response = JSON.parse(error.response);
      if (response.Message) {
        return response.Message;
      }
    }
    if (error.message) {
      error.message;
    }
    return null;
  }

  successMessage(summaryMessage: string, detailMessage: string): void {
    this._messageService.clear();
    this._messageService.add({key: this._key, severity: 'success', summary: summaryMessage, detail: detailMessage });
  }

  infoMessage(summaryMessage: string, detailMessage: string): void {
    this._messageService.clear();
    this._messageService.add({key: this._key, severity: 'info', summary: summaryMessage, detail: detailMessage });
  }

  warnMessage(summaryMessage: string, detailMessage: string): void {
    this._messageService.clear();
    this._messageService.add({key: this._key, severity: 'warn', summary: summaryMessage, detail: detailMessage });
  }

  errorNoUiMessage(error: any, summaryMessage: string): void {
    const errorAsString = JSON.stringify(error);
    console.error(error);
  }

  clearMessage(): void {
    this._messageService.clear();
  }

}
