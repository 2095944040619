import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReportService } from './iReport.service';

@Injectable()
export class ReportService extends IReportService {

    constructor(private _http:HttpClient) {
        super();
    }

    getSandReport(): Observable<any> {
        throw new Error("Method not implemented.");
    }
    
    getPostAnalysisReport(jobs: string[]): Observable<string> {
        let address = 'api/reports/postjobanalysis?';
        for(let i = 0; i < jobs.length; i++){
            let amp = ((i < jobs.length - 1) ? '&' : '');
            address += `jobIds=${ jobs[i] }${ amp }`
        }
        return this._http.get(address, {responseType: 'text'})
            .pipe(
                map(
                    result => { return result as string; }
                )
            );
    }

    getCompanyUserReport() : Observable<string> {
        let address = 'api/reports/companyusers';
        return this._http.get(address, {responseType: 'text'})
            .pipe(
                map( result => { return result as string; } )
            );
    }
}
