import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Features } from '../../models/features';
import { IFeaturesService } from '../../services/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  isCollapsed = false;
  public companyAdministrationFeatureEnabled = false;
  public userProfileUiEnabled = false;
  public featuresReady = false;

  @Output() collapse = new EventEmitter();
  @Input() routes: object[];

  constructor(
    private router: Router,
    private _featuresService: IFeaturesService,
    ) { }

  ngOnInit() {
    const test = this.routes;
    this.getFeatures();
  }

  getFeatures() {
    this._featuresService.getEnabledFeatures().subscribe(
      result => {
        if (result === null) { return; }
        this.companyAdministrationFeatureEnabled = result.indexOf(Features.CompanyAdministration) >= 0;
        this.userProfileUiEnabled = result.indexOf(Features.UserProfileUi) >= 0;
        this.featuresReady = true;
      },
      error => {
        this.companyAdministrationFeatureEnabled = false;
      },
    );
  }

  onCollapsedClick() {
    this.isCollapsed = !this.isCollapsed;
    this.collapse.emit(this.isCollapsed);
  }

  private onRouteClick(item) {
    if (item.route !== '') {
      this.router.navigate([item.route]);
    } else {
      if (item.subroutes && item.subroutes.length > 0) {
        item.showSub = !item.showSub;
      }
    }
  }

  private onSubRouteClick(route) {

  }

}
