import { Component, OnInit } from '@angular/core';
import { SimulationGoals } from 'src/app/models/simulation';

@Component({
  selector: 'simulation-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css']
})
export class GoalsComponent implements OnInit {
  goals: SimulationGoals;

  constructor() { }

  ngOnInit() {
    this.goals = <SimulationGoals> { };
  }

}
