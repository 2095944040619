<div class="grid field col-12" *ngIf="header">
  <!-- <div class="col-12 md:col-6 ui-lg-1"><label>{{ header.companyId }}</label></div> -->
  <div class="col-12 md:col-6 ui-lg-1"><label>Name</label></div>
  <div class="col-12 md:col-6 ui-lg-2"><input type="text" pInputText [(ngModel)]="header.name"></div>
  <div class="col-12 md:col-6 ui-lg-1"><label>Operator</label></div>
  <div class="col-12 md:col-6 ui-lg-2">
    <p-dropdown [options]="consumerCompanies" [(ngModel)]="header.customer" placeholder="Select Client" [style]="{'width':'100%'}"></p-dropdown>
  </div>
</div>
<div class="grid field col-12">
  <div class="col-12 md:col-6 ui-lg-1"><label>Version</label></div>
  <div class="col-12 md:col-6 ui-lg-1">
    <label>{{ header.majorVersion }}.{{ header.minorVersion }}.{{ header.revisionVersion }}</label>
  </div>
  <div class="col-12 md:col-6 ui-lg-1">
      <button pButton (click)="requestNewVersion()" label="New Version"></button>
  </div>
</div>