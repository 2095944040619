<p-table
  *ngIf="companyContactInfo"
  [columns]="companyContactInfoColumns"
  [value]="companyContactInfo.adminContacts">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width"/>
    </colgroup>
</ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
        </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contact>
    <tr>
        <td class="contact-cell">{{contact.name}}</td>
        <td class="contact-cell">{{contact.email}}</td>
        <td class="contact-cell">{{contact.phone}}</td>
    </tr>
</ng-template>
</p-table>
<p *ngIf="!companyContactInfo">
  Company Contact Information cannot be found.
</p>
