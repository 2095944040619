/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Observable, of } from 'rxjs';
import { District } from '../models/models';
import { Injectable } from '@angular/core';
import { IDistrictService } from './iDistrict.service';

@Injectable()
export class DistrictService extends IDistrictService {
    getDistricts(): Observable<District[]> {
        const districts: District[] = [
            <District> {id:'HQ', name:'HQ'},
            <District> {id:'ALTX', name:'ALTX'},
            <District> {id:'OK', name:'OK'},
            <District> {id:'STX', name:'STX'},
            <District> {id:'WTX', name:'WTX'},
            <District> {id:'ROCK', name:'ROCK'},
            <District> {id:'NE', name:'NE'},
            <District> {id:'ND', name:'ND'}

        ];
        return of(districts) ;
    };

    getDistrict(districtId: string): Observable<District> {
        throw new Error('Method not implemented.');
    }

    createDistrict(districtName: string): Observable<any> {
        throw new Error('Method not implemented.');
    }

    updateDistrict(districtId: string, updatedName: string): Observable<any> {
        throw new Error('Method not implemented.');
    }

    deleteDistrict(districtId): Observable<any> {
        throw new Error('Method not implemented.');
    }
}
