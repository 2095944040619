import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OfflineService } from 'src/app/services/offline.service';
import { OfflineworkbookService } from 'src/app/services/offlineworkbook.service';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  @Input('title') title: string;
  @Input('displayName') displayName: string;
  @Input('email') email: string;


  searchtext = '';
  showSearchResult = false;
  showLogout = false;
  clickSub: any;
  online = navigator.onLine;
  queuedItems = 0;
  enablePollForQueuedItems = false;

  constructor(
      private router: Router,
      private _msalService: MsalService,
      private offlineService: OfflineService,
      private offlineItemsService: OfflineworkbookService
      ) { }

  ngOnInit() {
    this.getOnlineUpdates();
    // this.getQueuedItems();
  }

  goHome() {
    this.router.navigate(['/home']);
  }

  onShowLogOut() {
    this.showLogout = !this.showLogout;
  }

  onLogOut() {
    this._msalService.logout();
  }

  getOnlineUpdates() {
    this.offlineService.isOfflineObservable().subscribe(
      result => {
        this.online = !result;
        if (!this.online) {
          this.startPollingQueuedItems();
        } else {
          this.stopPollingQueuedItems();
        }
      }
    );
  }

  getQueuedItems() {
    this.offlineItemsService.queuedItemsObservable().subscribe(
      result => {
        this.queuedItems = result;
      }
    );
  }

  startPollingQueuedItems() {
    this.enablePollForQueuedItems = true;
    this.pollQueuedItems();
  }

  stopPollingQueuedItems() {
    this.enablePollForQueuedItems = false;
    this.queuedItems = 0;
  }

  pollQueuedItems() {
    if (this.enablePollForQueuedItems) {
      this.offlineItemsService.getQueueEntries(null).then(itemsResult => {
        this.queuedItems = itemsResult ? itemsResult.length : 0;
      });
      setTimeout(() => this.pollQueuedItems(), 2000);
    }
  }

}
