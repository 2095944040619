<div id="content">
    <!-- TODO:Get Logo with Transparent background, also change Title css size  -->
    <!-- <span><img src="/assets/OpsViewer_Logo_72x72.png" height="50px"></span> -->
    <span class="title" (click)="goHome()">
      <img src="assets/opsviewer-app-logo.png">
    </span>
    <div class="user-block">
      <span class="name" title="{{displayName}}">{{displayName}}</span>
      <span class="userid" title="{{email}}">{{email}}</span>
      <span class="pic" (click)="onShowLogOut()">
        <span class="far fa-user"></span>
      </span>
    </div>
    <div class="connection-status" [ngClass]="{'online': online, 'offline': !online}">
        <span class="fa fa-wifi"></span>
        <span *ngIf="queuedItems" class="queued-items">{{queuedItems}}</span> 
    </div>
  </div>
  <div *ngIf="showLogout" class="logout">
      <ul>
        <li (click)="onLogOut()">
          Log Out
        </li>
      </ul>
    </div>