import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { States,
         WellTypes,
         VendorJob } from 'src/app/models/models';
import { IDistrictService,
         ICountyDataService,
         IOpsViewerMessagesService,
         IUserService} from 'src/app/services/services';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JobDetailsComponent),
    multi: true
  }]
})
export class JobDetailsComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;

  @Input() _job: VendorJob;
  get job() {
    return this._job;
  }
  set job(val) {
    this._job = val;
    this.propagateChange(this._job);
  }
  @Input() modelInvalid: boolean;
  @Input() disabled = false;

  private _companyId: string = null;

  loading = true;

  supervisors: SelectItem[];
  procedures: SelectItem[] = [];
  districts: SelectItem[] = [];
  states: SelectItem[] = [];
  counties: SelectItem[] = [];
  wellTypes: SelectItem[] = [];
  millTriCones: SelectItem[] = [];

  staticDataReady = false;
  districtsReady = false;
  supervisorsReady = false;

  constructor(
    private _userService: IUserService,
    private _districtService: IDistrictService,
    private _countyDataService: ICountyDataService,
    private _messageService: IOpsViewerMessagesService
  ) { }

  ngOnInit() {
    this.setupDropDownOptions();
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    if (obj) {
      this.job = obj;
      this.checkIfCompanyIdChanged(this.job);
      this.checkIfEverythingIsReady();
    } else {
      this._companyId = null;
    }
    // } else {
    //   this.job = new Job(null, null, null, [], null, null);
    //   this._companyId = null;
    // }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  checkIfCompanyIdChanged(job: VendorJob) {
    if (job && job.companyId) {
      if (job.companyId !== this._companyId) {
        this._companyId = job.companyId;
        this.getSupervisors();
      }
    } else {
      this.supervisors = [];
    }
  }

  /****** Componet UI control methods ******/
  setupDropDownOptions() {
    this.procedures = [
      { label: 'Drill Out', value: 'Drill Out'},
      { label: 'Drill Out <2 Plugs', value: 'Drill Out <2 Plugs'},
      { label: 'DO w/ Logging Run', value: 'DO w/ Logging Run'},
      { label: 'DO w/ Fishing Trip', value: 'DO w/ Fishing Trip'},
      { label: 'Fishing Trip', value: 'Fishing Trip'},
      { label: 'Wash Out', value: 'Wash Out'},
      { label: 'Toe Prep', value: 'Toe Prep'},
      { label: 'Other', value: 'Other'}
    ];
    this.states = States;
    this.wellTypes = [
      { label: WellTypes.horizontal, value: WellTypes.horizontal },
      { label: WellTypes.vertical, value: WellTypes.vertical },
    ];
    this.millTriCones = [
      { label: 'Mill', value: 'Mill' },
      { label: 'Tri-Cone', value: 'Tri-Cone' },
      { label: 'PDC', value: 'PDC' },
    ];
    this.staticDataReady = true;

    this.getDistricts();
  }

  getSupervisors() {
    this.supervisors = [];
    if (this.job && this.job.companyId) {
      this._userService.getSupervisorUserNamesForCompany(this.job.companyId).subscribe(
        results => {
          results.forEach(u => {
            this.supervisors.push({ label: u.userName, value: u.userId });
          });
          this.supervisorsReady = true;
          this.checkIfEverythingIsReady();
        },
        error => { this._messageService.errorMessage(error, 'Could not get supervisor data'); }
      );
    } else { this._messageService.warnMessage('Could not get supervisor data', 'Company not defined on job'); }
  }

  getDistricts() {
    this._districtService.getDistricts().subscribe(
      results => {
        this.districts = results.map(p => Object.assign( <SelectItem>{}, p, { label: p.name, value: p.id }));
        this.districtsReady = true;
        this.checkIfEverythingIsReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get district data'); }
    );
  }

  getCounties(stateName: string) {
   this.counties = [];
    if (stateName == null || stateName === '') {
      return;
    }
    this._countyDataService.getCounties(stateName).subscribe(
      results => {
        if (results !== null) {
          results.map(d => {
            this.counties.push({ label: d.name, value: d.name });
          });
      }
    },
      error => { this._messageService.errorMessage(error, 'Could not get county data'); }
    );
  }

  checkIfEverythingIsReady() {
    this.loading = !(this.staticDataReady && this.districtsReady && this.supervisorsReady && this.job);
  }

}
