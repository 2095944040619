<div class="casing-tubing">
    <!--Page Title and Dashboard Link-->
    <div class="col-12 md:col-2">
        <h2>Job Details</h2>
    </div>
    <div *ngIf="jobReady && !isNew" class="col-12 md:col-2">
        <button pButton class="ui-button-primary" (click)="navigateToDashboard()" icon="far fa-chart-bar" label="Dashboard" style="margin-top:17px; height:30px; width:145px; float:right; vertical-align:bottom;">
        </button>
    </div>
    <div *ngIf="jobReady && !isNew" class="col-12 md:col-2">
        <button pButton class="ui-button-primary" (click)="navigateToWorkbook()" icon="fa fa-book" label="Workbook" style="margin-top:17px; height:30px; width:145px; float:right; vertical-align:bottom;">
        </button>
        
    </div>
    <!--Message Banner-->
    <div class="grid field col-12" *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>
    <div *ngIf="!everythingReady" class="grid field col-12">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <!-- Editable Page for all users-->
    <div *ngIf="everythingReady" class="grid field col-12">
        <!--Job Properties-->

        <!-- Id -->
        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Job Number</label>
            </div>
            <div class="col-12 md:col-6">
                <input type="text" pInputText placeholder="Enter Job Number" [(ngModel)]="job.id" [disabled]="!isNew" (change)="setJobIdAndName()"/>
                <div *ngIf="modelInvalid && !job.id" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Id is required!</label>
                </div>
            </div>
        </div>

        <!-- Name -->
        <!-- <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Name</label>
            </div>
            <div class="col-12 md:col-6">
                <input type="text" pInputText placeholder="Enter Job Name" [(ngModel)]="job.name" />
                <div *ngIf="modelInvalid && !job.name" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Name is required!</label>
                </div>
            </div>
        </div> -->

        <!-- Company Id -->
        <div *ngIf="!isUserServiceProvider" class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Company</label>
            </div>
            <div class="col-12 md:col-6">
                <p-dropdown [options]="companies" placeholder="Select a Company" [(ngModel)]="job.companyId" 
                    [style]="{'width':'100%'}" (onChange)="setOperator()" [filter]="true" filterBy="label"
                    [disabled]="!isNew">
                </p-dropdown>
                <div *ngIf="modelInvalid && !job.companyId" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Company is required!</label>
                </div>
            </div>
        </div>

        <!-- Service Providers -->
        <div *ngIf="!isUserServiceProvider" class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Service Providers</label>
            </div>
            <div class="col-12 md:col-6">
                <p-multiSelect [disabled]="!serviceProvidersReady" [options]="serviceProviderOptions" defaultLabel="Service Providers" [(ngModel)]="job.serviceProviderIds"></p-multiSelect>
            </div>
        </div>

        <!-- Offline Workbook Enabled -->
        <div *ngIf="offlineWorkbookFeatureEnabled">
            <div *ngIf="!isNew" class="grid field col-12">
                <div class="col-12 md:col-2">
                    <label>Offline Workbook Enabled</label>
                </div>
                <div class="col-12 md:col-6" pToolTip="This property is auto-set by company configuration.">
                        <p-checkbox [(ngModel)]="job.enableOfflineWorkbook" binary="true" [disabled]="true"></p-checkbox>
                </div>
            </div>
            <div *ngIf="job.enableOfflineWorkbook" class="grid field col-12">
                    <div class="col-12 md:col-2">
                        <label>Job Type</label>
                    </div>
                    <div class="col-12 md:col-6" pToolTip="Select Job Type to determine which set of workbook properties will be applied.">
                            <p-dropdown [options]="workbookTypes" placeholder="Select Job Type" [(ngModel)]="job.workbookType"
                            (onChange)="setJobType()" 
                            [style]="{'width':'100%'}"
                            [disabled]="!isNew">
                        </p-dropdown>
                    </div>
                    <div *ngIf="modelInvalid && !job.workbookType" class="grid field col-12">
                        <label style="color:red; font-weight:bold;">Job Type is required!</label>
                    </div>
                </div>
        </div>

        <!-- Start & End Time -->
        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Arrive on Loc/Begin RU on Well</label>
            </div>
            <div class="col-12 md:col-3">
                <p-calendar 
                    [(ngModel)]="calendarStartTime" 
                    [showIcon]="true" 
                    [showTime]="true"
                    [hourFormat]="24" 
                    [monthNavigator]="true"
                    [yearNavigator]="true" 
                    yearRange="2018:2038">
                </p-calendar>
                <div *ngIf="modelInvalid && !calendarStartTime" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Start Time is required!</label>
                </div>
            </div>
        </div>

        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Release from Loc/RD from Well </label>
            </div>
            <div class="col-12 md:col-3">
                <p-calendar 
                    [(ngModel)]="calendarEndTime" 
                    [showIcon]="true" 
                    [showTime]="true" 
                    [hourFormat]="24"
                    [monthNavigator]="true"
                    [yearNavigator]="true" 
                    yearRange="2018:2038">
                </p-calendar>
                <i *ngIf="calendarEndTime" class="far fa-trash-alt clear-end-time" (click)="clearEndDate()" pTooltip="Clear Release from Loc/RD from Well Time"></i> 
            </div>
        </div>

        <!-- DeviceList -->
        <div class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Devices</label>
            </div>
            <div class="col-12 md:col-6">
                <p-multiSelect [options]="devices" [(ngModel)]="job.deviceList"></p-multiSelect>
                <!-- <div *ngIf="modelInvalid && job.deviceList.length < 1" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">At least one device is required!</label>
                </div> -->
            </div>
        </div>

        <!-- Job Actions -->
        <!-- <div class="grid field col-12"> -->
            <!-- Job Progress -->
            <!-- <div class="col-12 md:col-2">
                <label>Job Progress</label>
            </div>
            <div class="col-12 md:col-2">
                <input type="text" pInputText placeholder="Status" [(ngModel)]="job.status" readonly="true" />
            </div>
            <div class="col-12 md:col-2">
                <button pButton *ngIf="job.status != 'Finished'" class="ui-button-primary" (click)="endJob()" style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;">End Job</button>
            </div>
       </div> -->
        <div *ngIf="!isNew && isEditorOrAdmin()" class="grid field col-12">
             <!-- Job Exports -->
            <div class="col-12 md:col-2">
                <label>Available Exports</label>
            </div>
            <div class="col-12 md:col-2">
                <button pButton class="ui-button-primary" icon="fa fa-download" label="Job Data" (click)="downloadJobExport()" style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
            </div>
            <div class="col-12 md:col-2">
                <button pButton class="ui-button-primary" icon="fa fa-download" label="Job Summary" (click)="downloadJobSummaryExport()" style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
            </div>
         </div>
         <!-- Sand Report -->
         <div *ngIf="!isNew && isEditorOrAdmin() && job.workbookType == 'Flowback/Well Testing'" class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Sand Report</label>
            </div>
            <div class="col-12 md:col-2">
                <p-calendar 
                    [(ngModel)]="sandReportBeginTime" 
                    [showIcon]="true" 
                    [showTime]="true" 
                    [hourFormat]="24"
                    [monthNavigator]="true"
                    [yearNavigator]="true" 
                    yearRange="2018:2038">
                 </p-calendar>
             </div>
             
             <div class="col-12 md:col-2">
                <p-calendar 
                    [(ngModel)]="sandReportEndTime" 
                    [showIcon]="true" 
                    [showTime]="true" 
                    [hourFormat]="24"
                    [monthNavigator]="true"
                    [yearNavigator]="true" 
                    yearRange="2018:2038">
                 </p-calendar>
             </div>
            <div class="col-12 md:col-2">
                 <button pButton class="ui-button-primary" icon="fa fa-download" label="Sand Report" (click)="downloadSandReport()" style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
             </div>
         </div>

         <!-- Data Sheet Report -->
         <div *ngIf="!isNew && isEditorOrAdmin() && job.workbookType == 'Flowback/Well Testing'" class="grid field col-12">
            <div class="col-12 md:col-2">
                 <label>Data Sheet Report</label>
            </div>
            <div class="col-12 md:col-2">
                <p-calendar 
                    [(ngModel)]="dataSheetReportBeginTime" 
                    [showIcon]="true" 
                    [showTime]="true" 
                    [hourFormat]="24"
                    [monthNavigator]="true"
                    [yearNavigator]="true" 
                    yearRange="2018:2038">
                 </p-calendar>
             </div>
             
             <div class="col-12 md:col-2">
                <p-calendar 
                    [(ngModel)]="dataSheetReportEndTime" 
                    [showIcon]="true" 
                    [showTime]="true" 
                    [hourFormat]="24"
                    [monthNavigator]="true"
                    [yearNavigator]="true" 
                    yearRange="2018:2038">
                </p-calendar>
             </div>
            <div class="col-12 md:col-2">
                 <button pButton class="ui-button-primary" icon="fa fa-download" label="Data Sheet" (click)="downloadDataSheetReport()" style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
             </div>
         </div>
        <div *ngIf="!isNew && isAdmin()" class="grid field col-12">
            <div class="col-12 md:col-2">
                <label>Republish Workbook</label>
            </div>
            <div class="col-12 md:col-2">
                    <button pButton class="ui-button-primary" icon="fa fa-retweet fa-lg" label="Republish" (click)="republishWorkbook()" style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
                </div>
        </div>

        <!-- Additional Job Details -->
        <div class="grid field col-12">
            <p-accordion [multiple]="true">
                <!-- Site Detais -->
                <p-accordionTab header="Job Site Details" [selected]="true">
                    <div class="grid field col-12">
                        <p-fieldset legend="Surface" class="grid field col-12" [toggleable]="true">
                            <div class="grid field col-12">
                                <!-- Well Name -->
                                <div class="col-12 md:col-1">
                                    <label>Well Name</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <div >
                                        <input type="text" pInputText placeholder="Enter Well Name" [(ngModel)]="job.wellInfo.name" />
                                    </div>
                                    <div *ngIf="modelInvalid && !job.wellInfo.name" class="grid field col-12">
                                            <label style="color:red; font-weight:bold;">Well Info Is Required!</label>
                                        </div>
                                </div>
                    
                                <!-- Api Number -->
                                <div class="col-12 md:col-1">
                                    <label>Api Number</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Api Number" [(ngModel)]="job.apiNumber" />
                                </div>
                    
                                <!-- District -->
                                <div class="col-12 md:col-1">
                                    <label>District</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-dropdown [options]="districts" [(ngModel)]="job.district" placeholder="Select District" [style]="{'width':'100%'}"></p-dropdown>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <!-- State -->
                                <div class="col-12 md:col-1">
                                    <label>State</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-dropdown [options]="states" [(ngModel)]="job.state" placeholder="Select State" [filter]="true" filterBy="value,label"
                                        [style]="{'width':'100%'}" (onChange)="getCounties(job.state)"></p-dropdown>
                                </div>
                    
                                <!-- County -->
                                <div class="col-12 md:col-1">
                                    <label>County</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-dropdown [options]="counties" [(ngModel)]="job.county" editable="true" placeholder="Select Or Enter County" [filter]="true"
                                        filterBy="label" [style]="{'width':'100%'}"></p-dropdown>
                                </div>

                                <!-- Field -->
                                <div class="col-12 md:col-1">
                                    <label>Field / Formation</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Field" [(ngModel)]="job.field" />
                                </div>
                            </div>
                            <p-fieldset legend="BLM Land Location" class="grid field col-12" [toggleable]="true" [collapsed]="true" >
                                <div class="grid field col-12">
                                    <!-- Section -->
                                    <div class="col-12 md:col-1">
                                        <label>Section</label>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <input type="text" pInputText placeholder="Enter Section" [(ngModel)]="job.section" />
                                    </div>
                                    <!-- Township -->
                                    <div class="col-12 md:col-1">
                                        <label>Township</label>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <input type="text" pInputText placeholder="Enter Township" [(ngModel)]="job.township" />
                                    </div>
                                    <!-- Range -->
                                    <div class="col-12 md:col-1">
                                        <label>Range</label>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <input type="text" pInputText placeholder="Enter Range" [(ngModel)]="job.range" />
                                    </div>
                                </div>
                                <div class="grid field col-12">
                                    <div class="col-12 md:col-1">
                                        <label>Latitude</label>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <input type="text" pInputText placeholder="Enter Latitude" [(ngModel)]="job.latitude" />
                                    </div>
                                    <!-- Township -->
                                    <div class="col-12 md:col-1">
                                        <label>Longitude</label>
                                    </div>
                                    <div class="col-12 md:col-3">
                                        <input type="text" pInputText placeholder="Enter Longitude" [(ngModel)]="job.longitude" />
                                    </div>
                                </div>
                            </p-fieldset>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-2">
                                    <label>Directions to Well Site</label>
                                </div>
                                <div class="col-12 md:col-10">
                                    <textarea [rows]="5" pInputTextarea placeholder="Directions to Well Site" [(ngModel)]="job.wellSiteDirections" autoresize="autoresize" style="width:100%" ></textarea>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-2">
                                    <label>Special Instructions / HSE Requirements</label>
                                </div>
                                <div class="col-12 md:col-10">
                                    <textarea [rows]="5" pInputTextarea placeholder="Special Instructions" [(ngModel)]="job.specialInstructions" autoresize="true" style="width:100%" ></textarea>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-12">
                                    <label>Equipment</label>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <!-- Flowback Provider -->
                                <div class="col-12 md:col-1">
                                    <label>Flowback Provider</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.flowbackProvider" />
                                </div>
                    
                                <!-- DH Tool Provider -->
                                <div class="col-12 md:col-1">
                                    <label>DH Tool Provider</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.dhToolProvider" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>Chemical Provider</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Chemical Provider" [(ngModel)]="job.chemicalProvider" />
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <!-- Tubing Unit Description -->
                                <div class="col-12 md:col-1">
                                    <label>Tubing Unit Description</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Description" [(ngModel)]="job.tubingUnitDesc" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>CT-WOR-WL-Provider</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.ctWorWlProvider" />
                                </div>


                                <!-- 
                                    MAR 2019-02-08: Type of Plug field was hidden because the functionality is 
                                    implemented in the Well Information section (The user can now add different 
                                    types of plugs).
                                    This field has not been removed from the backend for backward compatibility and 
                                    to migrate the data from old jobs.
                                    -->
                                <!-- <div class="col-12 md:col-1">
                                    <label>Type of Plug</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Type" [(ngModel)]="job.plugType" />
                                </div> -->
                            </div>
                            <div class="grid field col-12">
                                <!-- Upstream Sand Separator Type -->
                                <!-- <div class="col-12 md:col-1">
                                    <label>Upstream Sand Separator type</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Type" [(ngModel)]="job.upstreamSandSeparatorType" />
                                </div> -->
                                <!-- Downstream Sand Separator Type -->
                                <!-- <div class="col-12 md:col-1">
                                    <label>Downstream Sand Separator type</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Type" [(ngModel)]="job.downstreamSandSeparatorType" />
                                </div> -->
                            </div>
                        </p-fieldset>
                        
                        <div class="grid field col-12">
                            <!-- Procedure Description -->
                            <div class="col-12 md:col-1">
                                <label>Procedure</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <p-dropdown [options]="procedures" [(ngModel)]="job.procedureDesc"  placeholder="Select an Option" [style]="{'width':'100%'}" ></p-dropdown>
                            </div>
                            <!-- Well Type -->
                            <div class="col-12 md:col-1">
                                <label>Well Type</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <div >
                                    <p-dropdown [options]="wellTypes" [(ngModel)]="job.wellInfo.wellType" placeholder="Select an Option" [style]="{'width':'100%'}"></p-dropdown>
                                </div>
                                <div *ngIf="modelInvalid && !job.wellInfo.wellType" class="grid field col-12">
                                    <label style="color:red; font-weight:bold;">Well Type Is Required!</label>
                                </div>
                            </div>
                        </div>
                        <p-fieldset legend="Personnel" class="grid field col-12" [toggleable]="true" [collapsed]="true">
                            <div class="grid field col-12">
                                <div class="col-12 md:col-2">
                                    <label>SS Day Supervisor</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-dropdown [options]="supervisors" [(ngModel)]="job.frcsDayEngineer" placeholder="Select Or Enter Supervisor"
                                        [filter]="true" filterBy="label" [style]="{'width':'100%'}"></p-dropdown>
                                    <div *ngIf="modelInvalid && !job.frcsDayEngineer" class="grid field col-12">
                                        <label style="color:red; font-weight:bold;">SS Day Supervisor Is Required!</label>
                                    </div>
                                </div>
                                <div class="col-12 md:col-2">
                                    <label>SS Night Supervisor</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-dropdown [options]="supervisors" [(ngModel)]="job.frcsNightEngineer" placeholder="Select Or Enter Supervisor"
                                    [filter]="true" filterBy="label" [style]="{'width':'100%'}"></p-dropdown>
                                    <div *ngIf="modelInvalid && !job.frcsNightEngineer" class="grid field col-12">
                                        <label style="color:red; font-weight:bold;">SS Night Supervisor Is Required!</label>
                                    </div>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <!-- Day Operators -->
                                <div class="col-12 md:col-2">
                                    <label>Day Operators</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-multiSelect [options]="supervisors" defaultLabel="Day Operators" [(ngModel)]="job.dayOperators"></p-multiSelect>
                                    <div *ngIf="modelInvalid && (!job.dayOperators || job.dayOperators.length == 0 )" class="grid field col-12">
                                        <label style="color:red; font-weight:bold;">Day Operators Is Required!</label>
                                    </div>
                                </div>
                                <!-- Night Operators -->
                                <div class="col-12 md:col-2">
                                    <label>Night Operators</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-multiSelect [options]="supervisors" defaultLabel="Night Operators" [(ngModel)]="job.nightOperators"></p-multiSelect>
                                    <div *ngIf="modelInvalid && (!job.nightOperators || job.nightOperators.length == 0)" class="grid field col-12">
                                        <label style="color:red; font-weight:bold;">Night Operators Is Required!</label>
                                    </div>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-2">
                                    <label>OSR/WSM Day</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter OSR/WSM Day" [(ngModel)]="job.osrDay" />
                                </div>
                                <div class="col-12 md:col-2">
                                    <label>OSR/WSM Night</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter OSR/WSM Night" [(ngModel)]="job.osrNight" />
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-2">
                                    <label>Field Engineer 1</label>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-1">
                                    <label>Name</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.fieldEngineer1Name" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>Phone</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Phone" [(ngModel)]="job.fieldEngineer1Phone" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>E-mail</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter E-mail" [(ngModel)]="job.fieldEngineer1Email" />
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-2">
                                    <label>Field Engineer 2</label>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-1">
                                    <label>Name</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.fieldEngineer2Name" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>Phone</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Phone" [(ngModel)]="job.fieldEngineer2Phone" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>E-mail</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter E-mail" [(ngModel)]="job.fieldEngineer2Email" />
                                </div>
                            </div>
                        </p-fieldset>
                        <p-fieldset legend="Downhole" class="grid field col-12" [toggleable]="true" [collapsed]="true">
                            <div class="grid field col-12">
                                <div class="col-12 md:col-1">
                                    <label>Mill/Bit Type</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Mill/Bit Type" [(ngModel)]="job.millType" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>Mill/Bit OD</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Mill/Bit OD" [(ngModel)]="job.millOd" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>Mill/Bit Style</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-dropdown [options]="millTriCones" [(ngModel)]="job.mill" placeholder="Select an Option" [style]="{'width':'100%'}"></p-dropdown>
                                </div>
                            </div>
                            <div class="grid field col-12">
                                <div class="col-12 md:col-1">
                                    <label>BHA OD</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="BHA OD" [(ngModel)]="job.bhaOd" />
                                </div>
                                <div class="col-12 md:col-1">
                                    <label>ERT Type</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="ERT Type" [(ngModel)]="job.ertType" />
                                </div>
                            </div>
                        </p-fieldset>
                        <div class="grid field col-12">
                            <!-- Email Daily Reports To -->
                            <div class="col-12 md:col-1">
                                <label>Email Daily Reports To</label>
                            </div>
                            <div class="col-12 md:col-11">
                                <input type="text" pInputText placeholder="Enter Email(s)" [(ngModel)]="job.emailDailyReportsTo" />
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- Well Information -->
                <p-accordionTab header="Well Information">
                    <div *ngIf="job.wellInfo != null" class="grid field col-12">
                        <h4>Information related to the Well</h4>
                        <div class="grid field col-12">
                            <!-- XO/EOT -->
                            <div class="col-12 md:col-1">
                                <label>XO/EOT</label>
                            </div>
                            <div class="col-12 md:col-1">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.xoEot" (change)="setLateralLengthEst()" />
                            </div>
                            <!-- KOP -->
                            <div class="col-12 md:col-1">
                                <label>KOP</label>
                            </div>
                            <div class="col-12 md:col-1">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.kop" (change)="setLateralLengthEst()" />
                            </div>
                            <!-- 30 Degree -->
                            <div class="col-12 md:col-1">
                                <label>30 Degree</label>
                            </div>
                            <div class="col-12 md:col-1">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.thirtyDegree" (change)="setLateralLengthEst()" />
                            </div>
                            <!-- 60 Degree -->
                            <div class="col-12 md:col-1">
                                <label>60 Degree</label>
                            </div>
                            <div class="col-12 md:col-1">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.sixtyDegree" (change)="setLateralLengthEst()" />
                            </div>
                            <!-- 90 Degree (used be calleded TVD) -->
                            <div class="col-12 md:col-1">
                                <label>90 Deg</label>
                            </div>
                            <div class="col-12 md:col-1">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.tvd" (change)="setLateralLengthEst()"/>
                            </div>
                            <!-- PBTD (Used to be called MD) -->
                            <div class="col-12 md:col-1">
                                <label>PBTD</label>
                            </div>
                            <div class="col-12 md:col-1">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.md" (change)="setLateralLengthEst()"/>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!--Lateral Length Est -->
                            <div class="col-12 md:col-1">
                                <label>Lateral Length Est.</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.wellInfo.lateralLengthEst}}</label>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- String Type -->
                            <div class="col-12 md:col-1">
                                <label>Workstring Type</label>
                            </div>
                            <div class="col-12 md:col-2">
                                <p-dropdown [options]="stringTypes" [(ngModel)]="job.wellInfo.stringType" placeholder="Select String Type" [style]="{'width':'100%'}"></p-dropdown>
                            </div>
                            <div class="col-12 md:col-1" *ngIf="job.wellInfo.stringType != 'Jointed Tubing'">
                                <label>Tubing OD (in)</label>
                            </div>
                            <div class="col-12 md:col-1" *ngIf="job.wellInfo.stringType == 'Jointed Tubing'">
                                <label>VIR Tubing OD</label>
                            </div>
                            <div class="col-12 md:col-2">
                                <p-dropdown [options]="ctwoTubingODs" [(ngModel)]="selectedCTWOTubingOD" (onChange)="setCTWOTubingOD()" placeholder="Select Size" [style]="{'width':'100%'}"></p-dropdown>
                            </div>
                            <!-- Coil Capacity -->
                            <div class="col-12 md:col-1">
                                <label>Capacity (BBLS)</label>
                            </div>
                            <div class="col-12 md:col-1">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.coilCapacity" />
                            </div>
                            <!-- CSG ID -->
                            <!-- <div class="col-12 md:col-1">
                                <label>CSG ID (in)</label>
                            </div>
                            <div class="col-12 md:col-1">
                                <label class="readOnly-label">{{job.wellInfo.csgId}}</label>
                            </div> -->
                        </div>
                        <div class="grid field col-12" *ngIf="job.wellInfo.stringType == 'Jointed Tubing'">
                            <div class="col-12 md:col-1"></div>
                            <div class="col-12 md:col-2"></div>
                            <div class="col-12 md:col-1">
                                <label>HOZ Tubing OD</label>
                            </div>
                            <div class="col-12 md:col-2">
                                <p-dropdown [options]="hozTubingODs" [(ngModel)]="selectedHozTubingOD" (onChange)="setHozTubingOD()" placeholder="Select Size" [style]="{'width':'100%'}"></p-dropdown>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <div class="col-12 md:col-1">
                                <label>Frac Stages</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.fracPlugStages" />
                            </div>
                            <!--SITP Est -->
                            <div class="col-12 md:col-1">
                                    <label>SITP</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.sitp" />
                                </div>
                            <!--SICP Est -->
                            <div class="col-12 md:col-1">
                                    <label>SICP</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.sicp" />
                                </div>
                        </div>
                        <p-fieldset legend="Production Casing/Tubing" class="grid field col-12" [toggleable]="true" [collapsed]="true" >
                            <div *ngIf="newCasingPlugsFeatureEnabled && job.enableOfflineWorkbook">
                                <app-casing-tubing [(ngModel)]="job.wellInfo.casingTubings" (csgIdChanged)="updateCsgId($event)"></app-casing-tubing>
                            </div>
                            <div *ngIf="!newCasingPlugsFeatureEnabled || !job.enableOfflineWorkbook">
                                <div class="col-12 md:col-10">
                                    <h4>Casing/Tubing</h4>
                                </div>
                                <div class="col-12 md:col-2">
                                    <button pButton class="ui-button-primary" (click)="addCasing()" icon="fa fa-plus" label="Casing"
                                        style="max-height:32px; width:100%;">
                                    </button>
                                </div>
                            
                                <div *ngIf="job.wellInfo.casings.length > 0">
                                    <div class="grid field col-12" *ngFor="let casing of job.wellInfo.casings">
                                        <!-- Casing/Tubing -->
                                        <div class="grid field col-12">
                                            <div class="col-12 md:col-2">
                                                <label>Casing/ Tubing {{casing.number}}</label>
                                            </div>
                                            <div class="col-12 md:col-8">
                                                <p-dropdown [options]="casingTubingTypes" [(ngModel)]="casing.type" placeholder="Select Type"
                                                    [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                                                    (onChange)="setCasingVolume(casing)"></p-dropdown>
                                            </div>
                                            <div class="col-12 md:col-2" style="float:right;">
                                                <i class="far fa-trash-alt" (click)="deleteCasing(casing.number)"></i>
                                            </div>
                                        </div>
                                        <div class="grid field col-12">
                                            <!-- Casing/Tubing Direction-->
                                            <div class="col-12 md:col-1">
                                                <label>Casing/ Tubing {{casing.number}} Direction</label>
                                            </div>
                                            <div class="col-12 md:col-3">
                                                <p-dropdown [options]="casingTubingDirections" [(ngModel)]="casing.direction"
                                                    placeholder="Select Direction" [style]="{'width':'100%'}"></p-dropdown>
                                            </div>
                                            <!-- Casing/Tubing Length -->
                                            <div class="col-12 md:col-1">
                                                <label>Casing/ Tubing {{casing.number}} Length (ft)</label>
                                            </div>
                                            <div class="col-12 md:col-3">
                                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="casing.length"
                                                    (change)="setCasingVolume(casing)" />
                                            </div>
                                            <!--Casing/Tubing Volume -->
                                            <div class="col-12 md:col-1">
                                                <label>Casing/ Tubing {{casing.number}} Volume (bbl)</label>
                                            </div>
                                            <div class="col-12 md:col-3">
                                                <label class="readOnly-label">{{casing.volume | number:'1.1-2'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="job.wellInfo.casings.length == 0" class="grid field col-12">
                                    <label>No Casings Defined</label>
                                </div>
                            </div>
                        </p-fieldset>
                        <p-fieldset legend="Plugs" class="grid field col-12" [toggleable]="true" [collapsed]="true" >
                            <div *ngIf="newCasingPlugsFeatureEnabled && job.enableOfflineWorkbook">
                                <app-plugs [(ngModel)]="job.wellInfo.plugSets"></app-plugs>
                            </div>
                            <div *ngIf="!newCasingPlugsFeatureEnabled || !job.enableOfflineWorkbook">
                                
                                    <div class="col-12 md:col-10">
                                        <h4>Plug Depths</h4>
                                    </div>
                                    <div class="col-12 md:col-2">
                                        <button pButton class="ui-button-primary" (click)="addPlug()" icon="fa fa-plus" label="Plug" style="max-height:32px; width:100%;">
                                        </button>
                                    </div>
                                    <div *ngIf="job.wellInfo.plugs.length > 0">
                                        <div class="grid field col-12" *ngFor="let plug of job.wellInfo.plugs">
                                            <!-- Depth -->
                                            <div class="col-12 md:col-1">
                                                <label>Plug {{plug.number}} Depth</label>
                                            </div>
                                            <div class="col-12 md:col-3">
                                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="plug.depth" />
                                            </div>
                                            <!-- Perf Top -->
                                            <div class="col-12 md:col-1">
                                                <label>Perf Top {{plug.number}}</label>
                                            </div>
                                            <div class="col-12 md:col-3">
                                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="plug.perfTop" />
                                            </div>
                                            <!--Perf Bottom -->
                                            <div class="col-12 md:col-1">
                                                <label>Perf Bottom {{plug.number}}</label>
                                            </div>
                                            <div class="col-12 md:col-2">
                                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="plug.perfBottom" />
                                            </div>
                                            <div class="col-12 md:col-1" style="float:right;">
                                                    <i class="far fa-trash-alt" (click)="deletePlug(plug.number)"></i>
                                                </div>
                                        </div>
                                    </div>
                                    <div *ngIf="job.wellInfo.plugs.length == 0" class="grid field col-12">
                                        <label>No Plugs Defined</label>
                                    </div>
                            </div>
                        </p-fieldset>
                    </div>
                </p-accordionTab>
                <!-- Fluid Information -->
                <p-accordionTab header="Fluid Information">
                    <div *ngIf="job.fluidInfo != null" class="grid field col-12">
                        <div class="grid field col-12">
                            <!-- Chlorides -->
                            <div class="col-12 md:col-1">
                                <label>Chlorides (ppm)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.chlorides" />
                            </div>
                            <!-- NaCL -->
                            <div class="col-12 md:col-1">
                                <label>NaCL/Brine (%w/v)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.naCl" />
                            </div>
                            <!-- Fluid Density -->
                            <div class="col-12 md:col-1">
                                <label>Fluid Density (lbs/gal)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.density" />
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- Hardness -->
                            <div class="col-12 md:col-1">
                                <label>Hardness (ppm)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.hardness" />
                            </div>
                            <!-- Iron (ppm) -->
                            <div class="col-12 md:col-1">
                                <label>Iron (ppm)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.iron" />
                            </div>
                            <!-- Sulfates -->
                            <div class="col-12 md:col-1">
                                <label>Sulfates (ppm)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.sulfates" />
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- Alkalinity -->
                            <div class="col-12 md:col-1">
                                <label>Alkalinity (ppm)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.alkalinitty" />
                            </div>
                            <!-- pH -->
                            <div class="col-12 md:col-1">
                                <label>pH</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.ph" />
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- Performance Summary -->
                <p-accordionTab header="Performance Summary">
                    <div *ngIf="job.performanceSummary != null" class="grid field col-12">
                        <div class="grid field col-12">
                            <!-- Plugs Drilled To Date -->
                            <div class="col-12 md:col-1">
                                <label>Plugs Drilled To Date</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.performanceSummary.plugsDrilled}}</label>
                            </div>
                            <!-- Job Time -->
                            <div class="col-12 md:col-1">
                                <label>Job Time (hr)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.performanceSummary.jobTime | number:'1.1-2'}}</label>
                            </div>
                            <!-- Short Trips -->
                            <div class="col-12 md:col-1">
                                <label>Short Trips</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.performanceSummary.shortTrips}}</label>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- Plugs Per Short Trip -->
                            <div class="col-12 md:col-1">
                                <label>Plugs Per Short Trip</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.performanceSummary.plugPerShortTrip | number:'1.1-2'}}</label>
                            </div>
                            <!-- Time Per Plug -->
                            <div class="col-12 md:col-1">
                                <label>Time Per Plug (hr/plug)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.performanceSummary.timePerPlug | number:'1.1-2'}}</label>
                            </div>
                            <!-- Total Running Pipe -->
                            <div class="col-12 md:col-1">
                                <label>Total Running Pipe Feet (ft)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.performanceSummary.totalRunningPipe | number:'1.0-0'}}</label>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- Running Ratio -->
                            <div class="col-12 md:col-1">
                                <label>Running Ft/MD Ratio</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.performanceSummary.runningFtMdRatio | number:'1.1-2'}}</label>
                            </div>
                            <!-- Productive Ft Per Hour -->
                            <div class="col-12 md:col-1">
                                <label>Productive Time (ft/hr)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.performanceSummary.productiveFtPerHour | number:'1.0-0'}}</label>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>

                <!-- Fluid Summary -->
                <p-accordionTab header="Fluid Summary">
                    <div *ngIf="job.fluidSummary != null" class="grid field col-12">
                        <h4>Summary information related to the fluid</h4>
                        <div class="grid field col-12">
                            <!-- Avg SLW -->
                            <div class="col-12 md:col-1">
                                <label>Avg SLW Re</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.avgSlwRe | number:'1.0-0'}}</label>
                            </div>
                            <!-- Avg SLW Visc -->
                            <div class="col-12 md:col-1">
                                <label>Avg SLW Visc(s)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.avgSlwVisc | number:'1.1-2'}}</label>
                            </div>
                            <!-- Avg Annular Vel -->
                            <div class="col-12 md:col-1">
                                <label>Avg Annular Vel (ft/min)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.avgAnnularVel | number:'1.0-0'}}</label>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- BBL Circulated -->
                            <div class="col-12 md:col-1">
                                <label>BBL Circulated (BBL)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.bblCirculated | number:'1.0-0'}}</label>
                            </div>
                            <!-- Number of Sweeps -->
                            <div class="col-12 md:col-1">
                                <label>Number of Sweeps</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.numOfSweeps}}</label>
                            </div>
                            <!-- Total Sweep Vol -->
                            <div class="col-12 md:col-1">
                                <label>Total Sweep Vol (BBL)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.totalSweepVol | number:'1.1-2'}}</label>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- Avg Sweep Size -->
                            <div class="col-12 md:col-1">
                                <label>Avg Sweep Size (BBL)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.avgSweepSize | number:'1.1-2'}}</label>
                            </div>
                            <!-- Avg Sweep Visc -->
                            <div class="col-12 md:col-1">
                                <label>Avg Sweep Visc (s)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.avgSweepVisc | number:'1.1-2'}}</label>
                            </div>
                            <!-- Avg CT Psi -->
                            <div class="col-12 md:col-1">
                                <label>Avg CT Psi (psi)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.avgCtPsi | number:'1.0-0'}}</label>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- Avg CT Psi -->
                            <div class="col-12 md:col-1">
                                    <label>Avg WH Psi (psi)</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <label class="readOnly-label">{{job.fluidSummary.avgWhPsi | number:'1.0-0'}}</label>
                                </div>
                            <!-- Avg Diff Psi -->
                            <div class="col-12 md:col-1">
                                <label>Avg Diff Psi (psi)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.avgDiffPsi | number:'1.0-0'}}</label>
                            </div>
                            <!-- Avg CT BPM -->
                            <div class="col-12 md:col-1">
                                <label>Avg CT BPM (BBL/min)</label>
                            </div>
                            <div class="col-12 md:col-3">
                                <label class="readOnly-label">{{job.fluidSummary.avgCtBpm | number:'1.1-2'}}</label>
                            </div>
                        </div>
                        <div class="grid field col-12">
                            <!-- Avg FB BPM -->
                            <div class="col-12 md:col-1">
                                    <label>Avg FB BPM (BBL/min)</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <label class="readOnly-label">{{job.fluidSummary.avgFbBpm | number:'1.1-2'}}</label>
                                </div>
                        </div>
                        <div class="col-12 md:col-10">
                                <h4>Chemicals Used</h4>
                            </div>
                            <div class="col-12 md:col-2">
                                <button pButton class="ui-button-primary" 
                                    (click)="addChemical()" 
                                    icon="fa fa-plus" label="Chemical"
                                    style="max-height:32px; width:100%;">
                                </button>
                            </div>
                        <div class="grid field col-12" *ngIf="job.fluidSummary && job.fluidSummary.chemicals && job.fluidSummary.chemicals.length > 0">
                            <div class="grid field col-12" *ngFor="let chemical of job.fluidSummary.chemicals; let i = index">
                                <div class="col-12 md:col-2">
                                    <label>Select Chemical Type:</label>
                                </div>
                                <div class="col-12 md:col-3">
                                    <p-dropdown [options]="availableChemicals" placeholder="Select a Chemical" [(ngModel)]="job.fluidSummary.chemicals[i].key" [style]="{'width':'100%'}"></p-dropdown> 
                                </div>
                                <div class="col-12 md:col-2">
                                     <label>Chemical Volume (gals):</label>
                                </div>
                                <div class="col-12 md:col-3">
                                        <input type="text" pInputText placeholder="Enter Volume" [(ngModel)]="job.fluidSummary.chemicals[i].value" />
                                </div>
                                <div class="col-12 md:col-2" style="float:right;">
                                    <i class="far fa-trash-alt" (click)="deleteChemical(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <p-accordionTab *ngIf="job.enableOfflineWorkbook && readyToLoadWorkbookConfiguration" header="Workbook Configuration">
                    <app-workbook-configuration 
                        [companyId]="job.companyId"
                        [jobId]="job.id" 
                        [jobType]="job.workbookType"
                        [companyUnitSystem]="company.unitSystem"
                        (jobConfiguration)="updateWorkbookConfiguration($event)"
                        (errors)="handleErrorsFromConfiguration($event)">
                    </app-workbook-configuration>
                </p-accordionTab>
            </p-accordion>
        </div>

        <!-- Action Buttons -->
        <div class="grid field col-12">
            <button pButton 
                class="col-12 md:col-3 ui-button-primary" 
                *ngIf="isNew" 
                (click)="saveJob()" 
                [disabled]="saving"
                [ngClass]="{ 'action-button': !saving,
                             'action-button-disabled': saving }">
                Create
            </button>
            <button pButton 
                class="col-12 md:col-3 ui-button-primary" 
                *ngIf="!isNew" 
                (click)="saveJob()" 
                [disabled]="saving || isUserServiceProvider"
                [ngClass]="{ 'action-button': !saving,
                             'action-button-disabled': saving }">
                Update
            </button>
            <button pButton 
                class="col-12 md:col-3 p-button-secondary" 
                (click)="returnToJobList()" 
                class="action-button">
                Cancel
            </button>
        </div>
    </div>
</div>

<!--Invalid Model Dialog-->
<p-dialog *ngIf="everythingReady" header="Cannot Save Changes" [(visible)]="showModelInvalidDialog" [modal]="true" [responsive]="true" [style]="{width: '350px'}" 
    [minY]="70" [baseZIndex]="999" [autoZIndex]="false" [dismissableMask]="true">
    <span>
        One or more fields is empty or invalid.  Please correct and try again.
    </span>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="closeInvalidModelDialog()" label="Ok"></button>
    </p-footer>
</p-dialog>