import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ICompanyService, IOpsViewerMessagesService, IVendorJobService, IConsumerJobService } from 'src/app/services/services';
import { CompanyName, VendorJob, ConsumerJob } from 'src/app/models/models';

@Component({
  selector: 'app-job-linker-admin',
  templateUrl: './job-linker-admin.component.html',
  styleUrls: ['./job-linker-admin.component.css']
})
export class JobLinkerAdminComponent implements OnInit {

  ready = false;
  companySelectItems: SelectItem[] = [];
  vendorJobSelectItems: SelectItem[] = [];
  consumerJobs: ConsumerJob[] = [];

  constructor(
    private _companyService: ICompanyService,
    private _vendorJobService: IVendorJobService,
    private _consumerJobService: IConsumerJobService,
    private _messageService: IOpsViewerMessagesService) { }

  async ngOnInit() {
    await this.getCompanies().then(results => { this.companySelectItems = results; });
    await this.getVendorJobs().then(results => { this.vendorJobSelectItems = results; });
    await this.getConsumerJobs().then(results => { this.consumerJobs = results; });
    this.ready = true;
  }

  async getCompanies(): Promise<SelectItem[]> {
    const companySelectItems: SelectItem[] = [];
    try {
      const companiesFound = await this._companyService.getCompanyNames().toPromise<CompanyName[]>();
      if (companiesFound) {
        companiesFound.map(c => {
          companySelectItems.push({ label: c.companyName, value: c.companyId });
        });
      }
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get Companies for Job Linker Administration');
    }
    return companySelectItems;
  }

  async getVendorJobs(): Promise<SelectItem[]> {
    const vendorJobSelectItems: SelectItem[] = [];
    try {
      const jobsFound = await this._vendorJobService.getJobs().toPromise<VendorJob[]>();
      if (jobsFound) {
        jobsFound.map(v => {
          vendorJobSelectItems.push({ label: v.name, value: v.id });
        });
      }
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get Service Provider Jobs for Job Linker Administration');
    }
    return vendorJobSelectItems;
  }

  async getConsumerJobs(): Promise<ConsumerJob[]> {
    let consumerJobs: ConsumerJob[] = [];
    try {
      consumerJobs = await this._consumerJobService.getJobs().toPromise<ConsumerJob[]>();
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get Operator Jobs for Job Linker Administration');
    }
    return consumerJobs;
  }
}
