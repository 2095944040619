<div class="grid field col-12">
    <p-fieldset legend="Surface" class="grid field col-12" [toggleable]="true">
        <div class="grid field col-12">
            <!-- Well Name -->
            <div class="col-12 md:col-1">
                <label>Well Name</label>
            </div>
            <div class="col-12 md:col-3">
                <div >
                    <input type="text" pInputText placeholder="Enter Well Name" [(ngModel)]="siteDetails.wellName" />
                </div>
            </div>

            <!-- Api Number -->
            <div class="col-12 md:col-1">
                <label>Api Number</label>
            </div>
            <div class="col-12 md:col-3">
                <input type="text" pInputText placeholder="Enter Api Number" [(ngModel)]="siteDetails.api" />
            </div>

            <!-- District -->
            <div class="col-12 md:col-1">
                <label>District</label>
            </div>
            <div class="col-12 md:col-3">
                <p-dropdown [options]="districts" [(ngModel)]="siteDetails.districtId" placeholder="Select District" [style]="{'width':'100%'}"></p-dropdown>
            </div>
        </div>
        <div class="grid field col-12">
            <!-- State -->
            <div class="col-12 md:col-1">
                <label>State</label>
            </div>
            <div class="col-12 md:col-3">
                <p-dropdown [options]="states" [(ngModel)]="siteDetails.stateId" placeholder="Select State" [filter]="true" filterBy="value,label"
                    [style]="{'width':'100%'}" (onChange)="getCounties(siteDetails.stateId)"></p-dropdown>
            </div>

            <!-- County -->
            <div class="col-12 md:col-1">
                <label>County</label>
            </div>
            <div class="col-12 md:col-3">
                <p-dropdown [options]="counties" [(ngModel)]="siteDetails.countyId" editable="true" placeholder="Select Or Enter County" [filter]="true"
                    filterBy="label" [style]="{'width':'100%'}"></p-dropdown>
            </div>

            <!-- Field -->
            <div class="col-12 md:col-1">
                <label>Field / Formation</label>
            </div>
            <div class="col-12 md:col-3">
                <input type="text" pInputText placeholder="Enter Field" [(ngModel)]="siteDetails.field" />
            </div>
        </div>
    </p-fieldset>
    <div class="grid field col-12">
        <!-- Procedure Description -->
        <div class="col-12 md:col-1">
            <label>Procedure</label>
        </div>
        <div class="col-12 md:col-3">
            <p-dropdown [options]="procedures" [(ngModel)]="siteDetails.procedure"  placeholder="Select an Option" [style]="{'width':'100%'}" ></p-dropdown>
        </div>
        <!-- Well Type -->
        <div class="col-12 md:col-1">
            <label>Well Type</label>
        </div>
        <div class="col-12 md:col-3">
            <div >
                <p-dropdown [options]="wellTypes" [(ngModel)]="siteDetails.wellType" placeholder="Select an Option" [style]="{'width':'100%'}"></p-dropdown>
            </div>
        </div>
    </div>
</div>
