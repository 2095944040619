import { BaseEntity } from './baseEntity';
import { Address } from './address';
import { UnitSystemChoice } from './unitSystemChoice';
import { v4 as uuidv4 } from 'uuid';
export class Company implements BaseEntity {
    id: string;
    name: string;
    category: string;
    prefix: string;
    district: string;
    billingAddress: Address;
    shippingAddress: Address;
    slackWebhook: string;
    serviceProviderIds: string[];
    allowedToBeServiceProvider: boolean;
    enableOfflineWorkbook: boolean;
    sandReportEmailAddresses: string[] = [];
    autoApprovedEmailDomains: string[] = [];
    companyType: string;

    grafanaOrganizationId: number;
    influxDbConnectionInfo: any;
    jobIdList: string;
    unitSystem: string;
    apiKey: string;

    constructor(
        id: string,
        name: string,
        category: string,
        prefix: string,
        district: string,
        billingAddress: Address = null,
        shippingAddress: Address = null,
        slackWebhook: string = null,
        unitSystem: string = null) {

        if (id) {
            this.id = id;
        } else {
            this.id = uuidv4();
        }
        this.name = name;
        this.category = category;
        this.prefix = prefix;
        this.district = district;

        if (!billingAddress) {billingAddress = new Address(); }
        if (!shippingAddress) {shippingAddress = new Address(); }
        this.billingAddress = billingAddress;
        this.shippingAddress = shippingAddress;

        this.slackWebhook = slackWebhook;

        if (unitSystem) {
            this.unitSystem = unitSystem;
        } else {
            this.unitSystem = UnitSystemChoice.imperial;
        }

        this.grafanaOrganizationId = 0;
        this.influxDbConnectionInfo = null;
        this.jobIdList = null;
    }

    public setBillingAddress(address: Address) {
        this.billingAddress = address;
    }

    public setShippingAddress(address: Address) {
        this.shippingAddress = address;
    }

    public getFirstPrefix(): string {
        if (this.prefix.includes(',')) {
            return this.prefix.substring(0, this.prefix.indexOf(','));
        }

        return this.prefix;
    }

    public getLastPrefix(): string {
        if (this.prefix.includes(',')) {
            return this.prefix.substring(this.prefix.lastIndexOf(',') + 1);
        }

        return this.prefix;
    }
}
