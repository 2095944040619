<div class="grid col-12">
    <div class="col-12">
        <h2>Edit User</h2>
    </div>
    <div class="col-12" *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>
    <!-- <div *ngIf="!everythingReady || saving" class="col-12">
        <p-progressSpinner></p-progressSpinner>
    </div> -->

    <!-- Company Properties -->

    <!-- Email -->
   
    <ng-container *ngIf="$user|async">
    <div class="col-2">
        <label>Email</label>
    </div>
    <div class="col-10 p-inputgroup" >
            <input type="email" [disabled]="!isNew" pInputText placeholder="Enter Email Address"
                [(ngModel)]="user.email" (input)="validateEmail()" />
            <span class="p-inputgroup-addon" style="min-width: 50px;">
                <i class="far fa-envelope ml-2"></i>
            </span>
    
        <div *ngIf="modelInvalid && (!user.email || !userEmailValid)">
            <label style="color:red; font-weight:bold;">A Valid Email is required!</label>
        </div>
    </div>

    <!-- First Name -->

    <div class="col-2">
        <label>First Name</label>
    </div>
    <div class="col-10">
        <input type="text" pInputText placeholder="Enter First Name" [(ngModel)]="user.firstName" />
        <div *ngIf="modelInvalid && !user.firstName">
            <label style="color:red; font-weight:bold;">First Name is required!</label>
        </div>
    </div>


    <!-- Last Name -->

    <div class="col-2">
        <label>Last Name</label>
    </div>
    <div class="col-10">
        <input type="text" pInputText placeholder="Enter Last Name" [(ngModel)]="user.lastName" />
        <div *ngIf="modelInvalid && !user.lastName">
            <label style="color:red; font-weight:bold;">Last Name is required!</label>
        </div>
    </div>

    <!--Contact Number-->

    <div class="col-2">
        <label>Contact Number</label>
    </div>
    <div class="col-10">
        <div class="p-inputgroup">
            <p-inputMask pInputText mask="(999) 999-9999" [(ngModel)]="user.contactNumber"
                placeholder="(999) 999-9999"></p-inputMask>
            <span class="p-inputgroup-addon">
                <i class="fa fa-phone ml-2"></i>
            </span>
        </div>
        <!-- <div *ngIf="modelInvalid && !user.contactNumber" class="col-12">
                    <label style="color:red; font-weight:bold;">Contact Number is required!</label>
                </div> -->
    </div>

    <!--  Job Title -->

    <div class="col-2">
        <label>Company Job Title</label>
    </div>
    <div class="col-10">
        <input type="text" pInputText placeholder="Enter Job Title" [(ngModel)]="user.companyJobTitle" />
    </div>


    <ng-container *ngIf="($user | async).isGlobalAdmin">
        <div class="col-2">
            <label>Role</label>
        </div>
        <div class="col-10">
            <label style="color:goldenrod; font-weight:bold;">Global Admin</label>
        </div>
    </ng-container>


    <div class="col-12">
        <label>Company Roles</label>
    </div>
    <ng-container *ngFor="let role of $rolesToDisplay|async">
        <p-dropdown class="col-12" [options]="$companies|async" [(ngModel)]="role.companyId"
        placeholder="Select a Company" [style]="{'width':'300px'}" [disabled]="true"></p-dropdown>
    <p-selectButton class="col-5" [options]="roles" [(ngModel)]="role.role"
        [disabled]="!isAuthenticatedUserACompanyAdmin2( ($authenticatedUser | async), role.companyId)"></p-selectButton>
    <p-button *ngIf="isAuthenticatedUserACompanyAdmin2(($authenticatedUser | async), role.companyId)" class="col-7 align-content-start"
        styleClass="p-button-secondary" icon="far fa-trash-alt" (click)="deleteRole(role)"></p-button>
    <div *ngIf="modelInvalid && (!role.companyId || !role.role)" class="col-12">
        <label style="color:red; font-weight:bold;">Company and Role is required!</label>
    </div>

    </ng-container>


   
    <div class="col-12">
        <p-button *ngIf="$canAddCompanyRole|async" icon="pi pi-plus" label="Company Role"
            (click)="addDisplayedCompanyRole()">
        </p-button>
    </div>



    <!-- Action Buttons -->
    <div class="col-12">
        <button pButton class="col-12 ui-md-3 ui-button-primary" *ngIf="isNew" (click)="saveUser()" [ngClass]="{ 'action-button': !saving,
                             'action-button-disabled': saving }" [disabled]="saving">Create
        </button>
        <button pButton class="col-12 ui-md-3 ui-button-primary" *ngIf="!isNew" (click)="saveUser()" [ngClass]="{ 'action-button': !saving,
                             'action-button-disabled': saving }" [disabled]="saving">Update
        </button>
        <button pButton class="col-12 ui-md-3 p-button-danger" *ngIf="isSuperAdmin2() | async" (click)="confirmDelete()"
            [ngClass]="{ 'action-button': !saving,
                             'action-button-disabled': saving }" [disabled]="saving || isNew">Delete
        </button>
        <button pButton class="col-12 ui-md-3 action-button p-button-secondary " (click)="returnToUserList()" 
            >Cancel
        </button>
    </div> 
</ng-container>
</div>


<!--Invalid Model Dialog-->
<p-dialog header="Cannot Save Changes" [(visible)]="showModelInvalidDialog" [modal]="true"
    [style]="{width: '350px'}" [minY]="70" [baseZIndex]="999" [autoZIndex]="false" [dismissableMask]="true">
    <span>
        One or more fields is empty or invalid. Please correct and try again.
    </span>
    <p-footer>
        <button type="button" pButton icon="fa-check" (click)="closeInvalidModelDialog()" label="Ok"></button>
    </p-footer>
</p-dialog>