<div class="grid field col-12">
  <div class="grid field col-12">
    <p-progressSpinner *ngIf="!ready"></p-progressSpinner>
    <app-job-linker *ngIf="ready"
      [forAdmin]="true"
      [companySelectItems]="companySelectItems"
      [vendorJobSelectItems]="vendorJobSelectItems"
      [consumerJobs]="consumerJobs">
    </app-job-linker>
  </div>
</div>
