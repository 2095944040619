import { Component, OnInit } from '@angular/core';
import { SimulationChemical, SimulationFluidSummary } from '../../models/simulation';
import { v4 as uuidv4 } from 'uuid';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'simulation-chemistry',
  templateUrl: './chemistry.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css']
})
export class ChemistryComponent implements OnInit {
  chemicals: SimulationChemical[];
  chemicalTypes: SelectItem[];
  clonedChem: SimulationChemical;
  fluidSummary: SimulationFluidSummary;
  fluidSystems: SelectItem[];
  fluidTypes: SelectItem[];
  loopTypes: SelectItem[];
  startAtOptions: SelectItem[];

  constructor() { }

  ngOnInit() {
    this.chemicalTypes = [
      {label: 'Friction Reducer (liquid)', value: 'FrictionReducerLiquid'},
      {label: 'Friction Reducer (dry powder)', value: 'FrictionReducerDryPowder'},
      {label: 'Gel (liquid)', value: 'GelLiquid'},
      {label: 'Gel (dry powder)', value: 'GelDryPowder'},
      {label: 'Pipe-on-Pipe Lube (liquid)', value: 'PipeOnPipeLubeLiquid'},
      {label: 'Foamer (liquid)', value: 'FoamerLiquid'},
      {label: 'Foamer (dry)', value: 'FoamerDry'},
      {label: 'Biocide (liquid)', value: 'BiocideLiquid'},
      {label: 'Biocide (dry)', value: 'BiocideDry'},
      {label: 'Surfactant (liquid)', value: 'SurfactantLiquid'},
      {label: 'H2S Scavenger (liquid)', value: 'H2sScavengerLiquid'},
      {label: 'KCl Substitute (liquid)', value: 'KclSubstituteLiquid'},
      {label: '"Brine (liquid)', value: 'BrineLiquid'},
      {label: 'Corrosion Inhibitor (liquid)', value: 'CorrosionInhibitorLiquid'},
      {label: 'Scale Inhibitor (liquid)', value: 'ScaleInhibitorLiquid'},
      {label: 'Packer Fluid (liquid)', value: 'PackerFluidLiquid'},
      {label: 'Lost Circulation Material (dry powder)', value: 'LostCirculationMaterialDryPowder'},
      {label: 'Nitrogen (liquified gas)', value: 'NitrogenLiquifiedGas'},
      {label: 'Remediation Treatment (liquid)', value: 'RemediationTreatmentLiquid'},
      {label: 'Gel/LCM Breaker (liquid)', value: 'GelLcmBreakerLiquid'},
      {label: 'Gel/LCM Breaker (dry powder)', value: 'GelLcmBreakerDryPowder'},
    ];
    this.fluidSystems = [
      {label: 'SLW', value: 'SLW'},
      {label: 'SWP After Tag', value: 'SwpAfterTag'},
      {label: 'SWP Before Short Trip', value: 'SwpBeforeShortTrip'},
      // {label: 'SWP POOH', value: 'SwpPooh'},
    ];
    this.fluidTypes = [
      {label: 'SLW', value: 'SLW'},
      {label: 'SWP', value: 'SWP'},
      {label: 'FW', value: 'FW'},
    ];
    this.loopTypes = [
      {label: 'Recirculate', value: 'recirculate'},
      {label: 'Open Loop', value: 'openLoop'},
    ];
    this.startAtOptions = [
      {label: 'Depth', value: true},
      {label: 'Plug', value: false},
    ];
    this.chemicals = [];
    this.fluidSummary = <SimulationFluidSummary> { };
  }

  addChemical() {
    this.chemicals.push(<SimulationChemical> {
      id: uuidv4(),
      chemicalId: 'FrictionReducerLiquid',
      chemicalFluidSystemId: 'SLW',
      // cost: 0.0,
      // depthOrPlug: 0,
      // dosage: 0.0,
      hasDepth: true,
      shutdownOnPooh: true,  } );
  }

  lookupChemicalFluidSystem(id: string) : string {
    let result = 'N/A'
    let found = this.fluidSystems.find(i => i.value === id)
    if(found) {
      result = found.label;
    }
    return result;
  }

  lookupChemicalLabel(id: string) : string{
    let result = 'N/A';
    let found = this.chemicalTypes.find(i => i.value === id)
    if(found) {
      result = found.label;
    }
    return result;
  }

  remove(chem: SimulationChemical) {
    this.chemicals = this.chemicals.filter(x => x != chem);
  }

  onRowEditInit(chem: SimulationChemical) {
    this.clonedChem = {...chem};
  }

  onRowEditSave(chem: SimulationChemical) {
  }

  onRowEditCancel(chem: SimulationChemical) {
      chem = {...this.clonedChem};
  }
}
