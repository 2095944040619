import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NewCompanyRequest } from '../../models/requests/newCompanyRequest';

@Injectable()
export abstract class INewCompanyRequestService {
  abstract getNewCompanyRequests(): Observable<NewCompanyRequest[]>;
  abstract getNewCompanyRequestById(id: string): Observable<NewCompanyRequest>;
  abstract createNewCompanyRequest(newCompanyRequest: NewCompanyRequest): any;
  abstract approveNewCompanyRequest(id: string): any;
  abstract rejectNewCompanyRequest(id: string, reason: string): any;
  abstract deleteNewCompanyRequest(id: string): any;
  abstract getPendingNewCompanyRequestsForUser(userId: string): Observable<NewCompanyRequest[]>;
}
