<div *ngIf="!dialogOnly" class="grid">

      <p-table #dt [columns]="requestCols" [value]="requests"
      [scrollable]="true" class="request-table" [loading]="!loading" sortField="name">
      <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width" />
          </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th *ngFor="let col of columns">
                  {{col.header}}
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-request let-index="rowIndex">
          <!-- <tr *ngIf="request.requestNotes" pTooltip="requ">
                <td>{{request.companyName}}</td>
                <td>{{request.companyType}}</td>
                <td>{{request.requestedByName}}</td>
                <td>{{request.status}}</td>
                <td>
                    <i *ngIf="request.status == 'Pending'" class="fas fa-check" style="margin-right:5px;" (click)="confirmApprove(request)" pTooltip="Approve"></i>
                    <i *ngIf="request.status == 'Pending'" class="fas fa-times" style="margin-right:5px;" (click)="confirmReject(request)" pTooltip="Reject" ></i>
                    <i class="far fa-trash-alt" (click)="confirmDelete(request)" pTooltip="Delete"></i></td>
          </tr> -->
          <tr>
              <td>{{request.companyName}}</td>
              <td>{{request.companyType}}</td>
              <td>{{request.requestedByName}}</td>
              <td>{{request.status}}</td>
              <td>
                  <i *ngIf="request.requestNotes" class="far fa-comment-alt" pTooltip="{{request.requestNotes}}" style="margin-right:5px;"></i>
                  <i *ngIf="request.status == 'Pending'" class="fas fa-check" style="margin-right:5px;" (click)="confirmApprove(request)" pTooltip="Approve"></i>
                  <i *ngIf="request.status == 'Pending'" class="fas fa-times" style="margin-right:5px;" (click)="confirmReject(request)" pTooltip="Reject" ></i>
                  <i class="far fa-trash-alt" (click)="confirmDelete(request)" pTooltip="Delete"></i></td>
          </tr>
      </ng-template>
      <ng-template pTemplate="summary">
          <button pButton type="button" label="Add Request" (click)="addRequest()" icon="fa fa-plus"
              class="add-button"></button>
      </ng-template>
  </p-table>
  
</div>

<p-dialog header="Reject Request?" [(visible)]="showRejectDialog" (onHide)="cancelReject()">
    <div class="ui-g add-content">
        <div class="grid field col-12">
          <div class="grid field col-12">
              <label>Rejection Reason</label>
          </div>
          <div class="grid field col-12">
                <textarea [rows]="2" cols="47" pInputTextarea autoResize="autoResize" [(ngModel)]="rejectReason"></textarea>
              <div *ngIf="!rejectReason" class="grid field col-12">
                  <label style="color:red; font-weight:bold;">Reason is required!</label>
              </div>
          </div>
        </div>
        <div class="footer-ctnr">
            <button type="button" pButton class="footer-btn p-button-secondary" (click)="cancelReject()" label="Cancel"></button>
            <button type="button" pButton class="footer-btn" (click)="reject()" label="Reject"></button>
        </div>
    </div>
</p-dialog>

<p-dialog header="Add New Company Request" [(visible)]="showAddNewCompanyRequest"  (onHide)="cancelAdd()">
  <div class="ui-g add-content">
      <div class="grid field col-12">
        <div class="grid field col-12">
            <label>Name</label>
        </div>
        <div class="grid field col-12">
            <input type="text" pInputText placeholder="Enter Company Name" [(ngModel)]="requestToAdd.companyName" />
            <div *ngIf="!requestToAddModelValid && !requestToAdd.companyName" class="grid field col-12">
                <label style="color:red; font-weight:bold;">Name is required!</label>
            </div>
        </div>
      </div>
      <div class="grid field col-12">
        <div class="grid field col-12">
            <label>Company Type</label>
        </div>
        <div class="grid field col-12">
                <p-dropdown [options]="unitSystemChoices" placeholder="Select Company Unit System" [(ngModel)]="requestToAdd.companyUnitSystem" [style]="{'width':'100%'}">
            </p-dropdown>
            <div *ngIf="!requestToAddModelValid && !requestToAdd.companyUnitSystem" class="grid field col-12">
                <label style="color:red; font-weight:bold;">Company Unit System is required!</label>
            </div>
        </div>
    </div>
      <div class="grid field col-12">
            <div class="grid field col-12">
                <label>Company Type</label>
            </div>
            <div class="grid field col-12">
                    <p-dropdown [options]="companyTypes" placeholder="Select Company Type" [(ngModel)]="requestToAdd.companyType" [style]="{'width':'100%'}">
                </p-dropdown>
                <div *ngIf="!requestToAddModelValid && !requestToAdd.companyType" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Company Type is required!</label>
                </div>
            </div>
        </div>
        <div class="grid field col-12">
                <div class="grid field col-12">
                    <label>Optional Notes</label>
                </div>
                <div class="grid field col-12">
                    <textarea [rows]="2" cols="47" pInputTextarea autoResize="autoResize" [(ngModel)]="requestToAdd.requestNotes"></textarea>
                </div>
              </div>
      <div class="grid field col-12">
        <div class="grid field col-12">
            <label>Auto Approved Email Domains</label>
        </div>
        <div class="grid field col-12">
            <input type="text" pInputText placeholder="Enter Email Domains (separated by semicolon)" [(ngModel)]="requestToAddAutoApprovedEmailDomains" />
        </div>
      </div>
      <div class="grid field col-12">
            <p-checkbox [(ngModel)]="isShippingSameAsBilling" binary="true" label="Shipping Address is the same as Billing Address"></p-checkbox>
      </div>
      <div class="grid field col-12">
            <label>Billing Address:</label>
        </div>
      <div class="grid field col-12">
          <app-address [(ngModel)]="requestToAdd.billingAddress" [displayModelInvalid]="!requestToAddModelValid" (emailValid)="validateBillingEmail($event)"></app-address>
      </div>
      <div *ngIf="!isShippingSameAsBilling" class="grid field col-12">
            <label>Shipping Address</label>
        </div>
      <div *ngIf="!isShippingSameAsBilling" class="grid field col-12">
            <app-address [(ngModel)]="requestToAdd.shippingAddress" (emailValid)="validateShippingEmail($event)"></app-address>
      </div>
      <div class="grid field col-12">
        <p>Requests may take some time to be authorized.  Contact support at 1-866-290-5029 for any questions regarding this request.</p>
    </div>
    <div class="ui-g-12 footer-ctnr">
        <button type="button" pButton class="footer-btn p-button-secondary" (click)="cancelAdd()" label="Cancel"></button>
        <button type="button" pButton class="footer-btn" (click)="saveAdd()" label="Save"></button>
    </div>
  </div>
</p-dialog>
