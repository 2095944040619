import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Features } from '../models/features';
import { IFeaturesService } from '../services/iFeatures.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,
    private _featuresService: IFeaturesService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  { // | Promise<boolean> | boolean
      const url: string = state.url;
      console.log(`Feature guard called on url ${ url }`);
      return this.checkFeatureUrl(url);
    }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.canActivate(next, state);
    }

  checkFeatureUrl(url: string): Observable<boolean> {
    if (url.indexOf('postJobAnalysis') >= 0) {
      return this._featuresService.isFeatureEnabled(Features.PostJobAnalysis);
    }
    if (url.indexOf('simulator') >= 0) {
      return this._featuresService.isFeatureEnabled(Features.JobSimulator);
    }
    if (url.indexOf('requests') >= 0) {
      return this._featuresService.isFeatureEnabled(Features.CompanyAdministration);
    }

    this._router.navigate(['/']);
    return of(false);
  }
}
