export class FluidSummary {
    avgSlwRe:number;
    avgSlwVisc:number;
    avgAnnularVel:number;
    bblCirculated:number;
    numOfSweeps:number;
    totalSweepVol:number;
    avgSweepSize:number;
    avgSweepVisc:number;
    avgCtPsi:number;
    avgWhPsi:number;
    avgDiffPsi:number;
    avgCtBpm:number;
    avgFbBpm:number;
    // blueDye3102:number;
    // nanoiPop5102:number;
    // proScav:number;
    // redDye3101:number;
    // iFR8320:number;
    // iLube5103:number;
    chemicals:any[];

    constructor() {
        this.chemicals = [];
    }
}