import { BaseEntity } from './baseEntity';
import { WellInfo } from './wellInfo';
import { FluidInfo } from './fluidInfo';
import { PerformanceSummary } from './performanceSummary';
import { FluidSummary } from './fluidSummary';
import { Company } from './company';
import { Device } from './device';
import { SelectItem } from 'primeng/api';

export class Job implements BaseEntity {
    id: string;
    name: string;
    companyId: string;
    deviceList: string[];
    startTime: Date;
    endTime: Date;
    created: Date;
    modified: Date;
    
    number: string;
    apiNumber: string;
    district: string;
    operator: string;
    state: string;
    county: string;
    field: string;
    essFrcsUnit: string;
    chemicalProvider: string;

    // Personnel
    frcsDayEngineer: string;
    frcsNightEngineer: string;
    dayOperators: string[];
    nightOperators: string[];
    osrDay: string;
    osrNight: string;
    fieldEngineer1Name: string;
    fieldEngineer1Phone: string;
    fieldEngineer1Email: string;
    fieldEngineer2Name: string;
    fieldEngineer2Phone: string;
    fieldEngineer2Email: string;

    ctWorWlProvider: string;
    flowbackProvider: string;
    dhToolProvider: string;
    procedureDesc: string;
    tubingUnitDesc: string;
    // isTricone:boolean;
    mill: string;
    plugType: string;
    status: string;
    upstreamSandSeparatorType: string;
    downstreamSandSeparatorType: string;

    // location
    section: string;
    township: string;
    range: string;
    latitude: string;
    longitude: string;

    emailDailyReportsTo: string;
    specialInstructions: string;
    wellSiteDirections: string;
    millType: string;
    millOd: string;
    bhaOd: string;
    ertType: string;
    wellInfo: WellInfo;
    fluidInfo: FluidInfo;
    performanceSummary: PerformanceSummary;
    fluidSummary: FluidSummary;
    serviceProviderIds: string[];
    enableOfflineWorkbook: boolean;
    workbookType: string;
    company: Company;

    __type: string;
    isMigrated: boolean;
    useOldWorkbookInReadOnlyMode: boolean;

    // The following are needed in UI model for table and table filtering
    devices: SelectItem[];
    deviceNamesAsString: string;

    constructor(
        id: string,
        name: string,
        companyId: string,
        deviceList: string[],
        startTime: Date,
        endTime: Date) {
            this.id = id;
            this.name = name;
            this.companyId = companyId;
            this.deviceList = deviceList;
            this.startTime = startTime;
            this.endTime = endTime;
            this.dayOperators = [];
            this.nightOperators = [];

            this.wellInfo = new WellInfo();
            this.fluidInfo = new FluidInfo();
            this.performanceSummary = new PerformanceSummary();
            this.fluidSummary = new FluidSummary();

            this.devices = [];
            // this.deviceListAsString = this.deviceList.join();

            this.wellInfo = new WellInfo();
            this.fluidInfo = new FluidInfo();
            this.performanceSummary = new PerformanceSummary();
            this.fluidSummary = new FluidSummary();
    }
}
