<div *ngIf="everythingReady" class="grid">
  <a [routerLink]="['/home']" class="col-12">
    <div class="profile-initials mr-4 ml-4" >{{userIntials}}</div>
  </a>
  
  <div class="col-12 ml-3 mr-1">
      <ul class="list-unstyled">
          <li><span class="far fa-user mr-1 icon-width"></span> {{user.firstName}} {{user.lastName}}</li>
          <li><span class="far fa-building mr-1 icon-width"></span>
            <p-dropdown [options]="availableCompanies" placeholder="No Companies Associated" [(ngModel)]="selectedCompany" filter="true"
            (onChange)="selectedCompanyChanged()"
            ></p-dropdown>
          </li>
          <li><span class="far fa-envelope mr-1 icon-width"></span> {{user.email}}</li>
          <li><span class="fa fa-mobile-alt mr-1 icon-width"></span> {{user.contactNumber}}</li>
        </ul>
  </div>
  <div class="col-12 ml-3 mr-1">
    <p-button label="Edit Profile" icon="pi pi-pencil" iconPos="left" (click)="editUserProfile()"></p-button>
  </div>
  <div class="col-12 ml-3 mr-1">
    <hr>
  </div>
  <div class="col-12 ml-3 mr-1">
    <ul class="list-unstyled">
      <li *ngIf="isUserAdmin()"><a [routerLink]="['/companies']" class="links">Manage Company</a></li>
      <li *ngIf="isUserAdmin()"><a [routerLink]="['/users']" class="links">Manage Users</a></li>
      <li *ngIf="isUserAdmin() && (selectedCompanyIsVendor$ | async)"><a [routerLink]="['/devices']" class="links">Manage Devices</a></li>
      <li><a [routerLink]="['/jobs2']" class="links">Manage Jobs</a></li>
      <li *ngIf="isUserEditorOrAdmin() && jobSimulatorFeatureEnabled"><a [routerLink]="['/simulator']" class="links">Job Simulator</a></li>
      <li *ngIf="isUserAdmin()"><a [routerLink]="['/requests']" class="links">Manage Associations</a></li>
      <li *ngIf="isUserSuperAdmin()"><a [routerLink]="['/joblinkers']" class="links">Manage Job Linking</a></li>
      <li *ngIf="isUserSuperAdmin()"><a [routerLink]="[]" class="links" (click)="getCompanyUserReport()" >Company & User Report</a></li>
      <li *ngIf="isAEmeraldSurfUser()"><a [routerLink]="['/files']" class="links" >File Vault</a></li>
    </ul>
  </div>
  <div *ngIf="requestsCount" class="col-12 ml-3 mr-1 alert alert-success" role="alert">
    <h2>Authorizations</h2>
    <p>You have {{requestsCount}} pending approval.</p>
  </div>
</div>
