<div *ngIf="featuresReady">
  <app-topbar *ngIf="userReady" [title]="title" [displayName]="" [email]="email"></app-topbar>
  <app-menu class="menu" [routes]="routes" (collapse)="onMenuCollapseEvent($event)"
    [ngClass]="{'menu-collapsed': isMenuCollapsed, 'menu': true }"></app-menu>
  <div id="view" [ngClass]="{'menu-collapse': isMenuCollapsed, 'view': true }">
    
    <div class="ml-1"> 
      <ng-container *ngIf="showRequestsBar">
        <app-navigation-top-bar></app-navigation-top-bar>
       </ng-container>
       <!-- Global Message Banner-->
       <!-- <p-messages></p-messages> -->
       <p-toast key="global-messages" ></p-toast>
    <router-outlet></router-outlet>
    </div>
    <!--Single Confrimation Dialog across app-->
    <p-confirmDialog key="confirmationDialog" appendTo="body"></p-confirmDialog> 
    <div *ngIf="showSpinner" class="global-spinner-background">
      <p-progressSpinner [style]="{width: '150px', height: '150px'}" strokeWidth="3" class="global-spinner" ></p-progressSpinner>
      <label *ngIf="showLongLoadingMessage && !isMenuCollapsed" class="long-running-message">This operation may take a few minutes ...</label>
    </div>
  </div>
</div>