<div *ngIf="loading" class="grid field col-12">
    <p-progressSpinner></p-progressSpinner>
</div>
<ng-container *ngIf="!loading">

    <p-fieldset legend="Surface" [toggleable]="true">

        <div class="field grid">
        <!-- Well Name -->
        <div class="rl3">
            <label>Well Name</label>
        </div>
        <div class="rf3">
                <input type="text" pInputText placeholder="Enter Well Name" [(ngModel)]="job.wellInfo.name"
                    [disabled]="disabled" />
            
            <div *ngIf="modelInvalid && !job.wellInfo.name">
                <label style="color:red; font-weight:bold;">Well Info Is Required!</label>
            </div>
        </div>

        <!-- Api Number -->
        <div class="rl3">
            <label>Api Number</label>
        </div>
        <div class="rf3">
            <input type="text" pInputText placeholder="Enter Api Number" [(ngModel)]="job.apiNumber"
                [disabled]="true" />
        </div>

        <!-- District -->
        <div class="rl3">
            <label>District</label>
        </div>
        <div class="rf3">
            <p-dropdown [options]="districts" [(ngModel)]="job.district" placeholder="Select District"
                 [disabled]="disabled"></p-dropdown>
        </div>

      
            <!-- State -->
            <div class="rl3">
                <label>State</label>
            </div>
            <div class="rf3">
                <p-dropdown [options]="states" [(ngModel)]="job.state" placeholder="Select State" [filter]="true"
                    filterBy="value,label" (onChange)="getCounties(job.state)"
                    [disabled]="disabled"></p-dropdown>
            </div>

            <!-- County -->
            <div class="rl3">
                <label>County</label>
            </div>
            <div class="rf3">
                <p-dropdown [options]="counties" [(ngModel)]="job.county" editable="true"
                    placeholder="Select Or Enter County" [filter]="true" filterBy="label" 
                    [disabled]="disabled"></p-dropdown>
            </div>

            <!-- Field -->
            <div class="rl3">
                <label>Field / Formation</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Enter Field" [(ngModel)]="job.field" [disabled]="disabled" />
            </div>

            <p-fieldset legend="BLM Land Location" class="col-12" [toggleable]="true" [collapsed]="true">
                <div class="field grid">
                    <!-- Section -->
                    <div class="rl3">
                        <label>Section</label>
                    </div>
                    <div class="rf3">
                        <input type="text" pInputText placeholder="Enter Section" [(ngModel)]="job.section"
                            [disabled]="disabled" />
                    </div>
                    <!-- Township -->
                    <div class="rl3">
                        <label>Township</label>
                    </div>
                    <div class="rf3">
                        <input type="text" pInputText placeholder="Enter Township" [(ngModel)]="job.township"
                            [disabled]="disabled" />
                    </div>
                    <!-- Range -->
                    <div class="rl3">
                        <label>Range</label>
                    </div>
                    <div class="rf3">
                        <input type="text" pInputText placeholder="Enter Range" [(ngModel)]="job.range"
                            [disabled]="disabled" />
                    </div>
                </div>
                <div class="grid field">
                    <div class="rl3">
                        <label>Latitude</label>
                    </div>
                    <div class="rf3">
                        <input type="text" pInputText placeholder="Enter Latitude" [(ngModel)]="job.latitude"
                            [disabled]="disabled" />
                    </div>
                    <!-- Township -->
                    <div class="rl3">
                        <label>Longitude</label>
                    </div>
                    <div class="rf3">
                        <input type="text" pInputText placeholder="Enter Longitude" [(ngModel)]="job.longitude"
                            [disabled]="disabled" />
                    </div>
                </div>
            </p-fieldset>
            <div class="grid field col-12 mt-1">
                <div class="field col-12 md:col-2">
                    <label>Directions to Well Site</label>
                </div>
                <div class="field col-12 md:col-10">
                    <textarea [rows]="5" pInputTextarea placeholder="Directions to Well Site"
                        [(ngModel)]="job.wellSiteDirections" autoresize="autoresize" style="width:100%"
                        [disabled]="disabled"></textarea>
                </div>
            </div>
            <div class="grid field col-12 mt-1">
                <div class="field col-12 md:col-2">
                    <label>Special Instructions / HSE Requirements</label>
                </div>
                <div class="field col-12 md:col-10">
                    <textarea [rows]="5" pInputTextarea placeholder="Special Instructions"
                        [(ngModel)]="job.specialInstructions" autoresize="true" style="width:100%"
                        [disabled]="disabled"></textarea>
                </div>
            </div>
            <div class="grid field col-12">
                <div class="rl3">
                    <label>Equipment</label>
                </div>
            </div>
            <div class="grid field col-12">
                <!-- Flowback Provider -->
                <div class="rl3">
                    <label>Flowback Provider</label>
                </div>
                <div class="rf3">
                    <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.flowbackProvider"
                        [disabled]="disabled" />
                </div>

                <!-- DH Tool Provider -->
                <div class="rl3">
                    <label>DH Tool Provider</label>
                </div>
                <div class="rf3">
                    <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.dhToolProvider"
                        [disabled]="disabled" />
                </div>
                <div class="rl3">
                    <label>Chemical Provider</label>
                </div>
                <div class="rf3">
                    <input type="text" pInputText placeholder="Enter Chemical Provider"
                        [(ngModel)]="job.chemicalProvider" [disabled]="disabled" />
                </div>
            </div>
            <div class="grid field col-12">
                <!-- Tubing Unit Description -->
                <div class="rl3">
                    <label>Tubing Unit Description</label>
                </div>
                <div class="rf3">
                    <input type="text" pInputText placeholder="Enter Description" [(ngModel)]="job.tubingUnitDesc"
                        [disabled]="disabled" />
                </div>
                <div class="rl3">
                    <label>CT-WOR-WL-Provider</label>
                </div>
                <div class="rf3">
                    <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.ctWorWlProvider"
                        [disabled]="disabled" />
                </div>
            </div>

        </div>
    </p-fieldset>

    <div class="grid field col-12 mt-1">
        <!-- Procedure Description -->
        <div class="rl3">
            <label>Procedure</label>
        </div>
        <div class="rf3">
            <p-dropdown [options]="procedures" [(ngModel)]="job.procedureDesc" placeholder="Select an Option"
                [style]="{'width':'100%'}" [disabled]="disabled"></p-dropdown>
        </div>
        <!-- Well Type -->
        <div class="rl3">
            <label>Well Type</label>
        </div>
        <div class="rf3">
            <div >
                <p-dropdown [options]="wellTypes" [(ngModel)]="job.wellInfo.wellType" placeholder="Select an Option"
                    [style]="{'width':'100%'}" [disabled]="disabled"></p-dropdown>
            </div>
            <div *ngIf="modelInvalid && !job.wellInfo.wellType" >
                <label style="color:red; font-weight:bold;">Well Type Is Required!</label>
            </div>
        </div>
    </div>
    <p-fieldset legend="Personnel" [toggleable]="true" [collapsed]="true">
        <div class="grid field col-12">
            <div class="rl2">
                <label>SS Day Supervisor</label>
            </div>
            <div class="rf2">
                <p-dropdown [options]="supervisors" [(ngModel)]="job.frcsDayEngineer"
                    placeholder="Select Or Enter Supervisor" [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                    [disabled]="disabled"></p-dropdown>
                <div *ngIf="modelInvalid && !job.frcsDayEngineer">
                    <label style="color:red; font-weight:bold;">SS Day Supervisor Is Required!</label>
                </div>
            </div>
            <div class="rl2">
                <label>SS Night Supervisor</label>
            </div>
            <div class="rf2">
                <p-dropdown [options]="supervisors" [(ngModel)]="job.frcsNightEngineer"
                    placeholder="Select Or Enter Supervisor" [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                    [disabled]="disabled"></p-dropdown>
                <div *ngIf="modelInvalid && !job.frcsNightEngineer" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">SS Night Supervisor Is Required!</label>
                </div>
            </div>
        </div>
        <div class="grid field col-12">
            <!-- Day Operators -->
            <div class="rl2">
                <label>Day Operators</label>
            </div>
            <div class="rl2">
                <p-multiSelect [options]="supervisors" defaultLabel="Day Operators" [(ngModel)]="job.dayOperators"
                    [disabled]="disabled"></p-multiSelect>
                <div *ngIf="modelInvalid && (!job.dayOperators || job.dayOperators.length == 0 )" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Day Operators Is Required!</label>
                </div>
            </div>
            <!-- Night Operators -->
            <div class="rl2">
                <label>Night Operators</label>
            </div>
            <div class="rl2">
                <p-multiSelect [options]="supervisors" defaultLabel="Night Operators" [(ngModel)]="job.nightOperators"
                    [disabled]="disabled"></p-multiSelect>
                <div *ngIf="modelInvalid && (!job.nightOperators || job.nightOperators.length == 0)" class="grid field col-12">
                    <label style="color:red; font-weight:bold;">Night Operators Is Required!</label>
                </div>
            </div>
        </div>
        <div class="grid field col-12">
            <div class="rl2">
                <label>OSR/WSM Day</label>
            </div>
            <div class="rl2">
                <input type="text" pInputText placeholder="Enter OSR/WSM Day" [(ngModel)]="job.osrDay"
                    [disabled]="disabled" />
            </div>
            <div class="rl2">
                <label>OSR/WSM Night</label>
            </div>
            <div class="rl2">
                <input type="text" pInputText placeholder="Enter OSR/WSM Night" [(ngModel)]="job.osrNight"
                    [disabled]="disabled" />
            </div>
        </div>
        <div class="grid field col-12">
            <div class="col-12">
                <label>Field Engineer 1</label>
            </div>
        </div>
        <div class="grid field col-12">
            <div class="rl3">
                <label>Name</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.fieldEngineer1Name"
                    [disabled]="disabled" />
            </div>
            <div class="rl3">
                <label>Phone</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Enter Phone" [(ngModel)]="job.fieldEngineer1Phone"
                    [disabled]="disabled" />
            </div>
            <div class="rl3">
                <label>E-mail</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Enter E-mail" [(ngModel)]="job.fieldEngineer1Email"
                    [disabled]="disabled" />
            </div>
        </div>
        <div class="grid field col-12">
            <div class="grid field col-12">
                <label>Field Engineer 2</label>
            </div>
        </div>
        <div class="grid field col-12">
            <div class="rl3">
                <label>Name</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="job.fieldEngineer2Name"
                    [disabled]="disabled" />
            </div>
            <div class="rl3">
                <label>Phone</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Enter Phone" [(ngModel)]="job.fieldEngineer2Phone"
                    [disabled]="disabled" />
            </div>
            <div class="rl3">
                <label>E-mail</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Enter E-mail" [(ngModel)]="job.fieldEngineer2Email"
                    [disabled]="disabled" />
            </div>
        </div>
    </p-fieldset>
    <p-fieldset legend="Downhole" class="grid field col-12" [toggleable]="true" [collapsed]="true">
        <div class="grid field col-12">
            <div class="rl3">
                <label>Mill/Bit Type</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Mill/Bit Type" [(ngModel)]="job.millType"
                    [disabled]="disabled" />
            </div>
            <div class="rl3">
                <label>Mill/Bit OD</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="Mill/Bit OD" [(ngModel)]="job.millOd"
                    [disabled]="disabled" />
            </div>
            <div class="rl3">
                <label>Mill/Bit Style</label>
            </div>
            <div class="rf3">
                <p-dropdown [options]="millTriCones" [(ngModel)]="job.mill" placeholder="Select an Option"
                    [style]="{'width':'100%'}" [disabled]="disabled"></p-dropdown>
            </div>
        </div>
        <div class="grid field col-12">
            <div class="rl3">
                <label>BHA OD</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="BHA OD" [(ngModel)]="job.bhaOd" [disabled]="disabled" />
            </div>
            <div class="rl3">
                <label>ERT Type</label>
            </div>
            <div class="rf3">
                <input type="text" pInputText placeholder="ERT Type" [(ngModel)]="job.ertType" [disabled]="disabled" />
            </div>
        </div>
    </p-fieldset>
    <div class="grid field col-12">
        <!-- Email Daily Reports To -->
        <div class="rl2">
            <label>Email Daily Reports To</label>
        </div>
        <div class="field col-12 md:col-6">
            <input type="text" pInputText placeholder="Enter Email(s)" style="width: 100%;" [(ngModel)]="job.emailDailyReportsTo"
                [disabled]="disabled" />
        </div>
    </div>
</ng-container>