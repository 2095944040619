<div class="grid field col-12">
    <div class="grid field col-12" *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>
    <!-- <div class="grid field col-12" *ngIf="(messages.length == 0) && !companiesReady">
        Loading...
    </div> -->
    <p-progressSpinner *ngIf="!everythingReady"></p-progressSpinner>
    
    <div class="grid field col-12" *ngIf="everythingReady">
        <div class="col-12 md:col-10"><h2 style="margin-bottom:2px; padding-bottom:0px;">Companies</h2></div>
        <div class="col-12 md:col-2">
            <button pButton type="button" label="Add" (click)="navigateToAddCompany()" icon="fa fa-plus" style="width:100px; margin-top:20px; float:right; vertical-align:bottom;"></button>
        </div>
        <div class="col-12">
            <p-table #dt [columns]="cols" [value]="companies" [scrollable]="true"  [paginator]="true" [rows]="25" [loading]="!companiesReady" sortField="name">
                <!-- <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns" [style.width]="col.width" />
                    </colgroup>
                </ng-template> -->
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.field != ''" [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field" pResizableColumn>
                            <div *ngSwitchCase="'name'">
                                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"
                                />
                            </div>
                            <div *ngSwitchCase="'prefix'">
                                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;" />
                            </div>
                            <!-- <div *ngSwitchCase="'category'" style="width: 100%;">
                                <p-multiSelect [options]="categories" defaultLabel="All Statuses" (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect>
                            </div> -->
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-company>
                    <tr>
                        <td class="ui-resizable-column">{{company.name}}</td>
                        <td class="ui-resizable-column">{{company.prefix}}</td>
                        <!-- <td>{{company.category}}</td> -->
                        <td class="ui-resizable-column">
                        <!-- <div style="display:block">
                            <button pButton 
                                icon="far fa-edit" 
                                (click)="navigateToDetail(company.id)" 
                                style="max-width:35px; margin:auto;">
                            </button>
                            <button pButton 
                                icon="far fa-trash-alt" 
                                (click)="confirmDelete(company)" 
                                style="margin:auto; max-width:35px; margin-left:5px;">
                            </button>
                        </div> -->
                            <i class="far fa-edit fa-xl" (click)="navigateToDetail(company.id)" style="margin-right:5px;"></i>
                            <i *ngIf="isAdmin(company)" class="far fa-trash-alt fa-xl" (click)="confirmDelete(company)"></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    Showing {{dt.totalRecords < 1 ? 0 : dt.first + 1}} to 
                    {{dt.totalRecords > 0 ? ((dt.rows + dt.first) <= dt.totalRecords ? (dt.rows + dt.first) : 
                    dt.totalRecords) : 0}} of {{dt.totalRecords ? dt.totalRecords : 0}} entries
                </ng-template>
            </p-table>
        </div>
    </div>
</div>