import { Component, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TcpDeviceConfiguration } from 'src/app/models/tcpDeviceConfiguration';

@Component({
  selector: 'app-tcp-device',
  templateUrl: './tcp-device.component.html',
  styleUrls: ['./tcp-device.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TcpDeviceComponent),
    multi: true
  }]
})
export class TcpDeviceComponent implements OnInit, ControlValueAccessor {

  public config : TcpDeviceConfiguration = new TcpDeviceConfiguration();
 /****** ControlValueAccessor methods to handle ngModel read and updates and other inputs ******/
  propagateChange = (_: any) => {};
  constructor() { }

  ngOnInit() {
  }

  writeValue(obj: any): void {
    this.config = obj;
    //this.config = new TcpDeviceConfiguration();
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
   // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
   // throw new Error("Method not implemented.");
  }
}
