<div *ngIf="job != null && job.fluidSummary != null" class="grid field col-12">
  <h4>Summary information related to the fluid</h4>
  <div class="grid field col-12">
      <!-- Avg SLW -->
      <div class="col-12 md:col-1">
          <label>Avg SLW Re</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.avgSlwRe | number:'1.0-0'}}</label>
      </div>
      <!-- Avg SLW Visc -->
      <div class="col-12 md:col-1">
          <label>Avg SLW Visc(s)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.avgSlwVisc | number:'1.1-2'}}</label>
      </div>
      <!-- Avg Annular Vel -->
      <div class="col-12 md:col-1">
          <label>Avg Annular Vel (ft/min)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.avgAnnularVel | number:'1.0-0'}}</label>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- BBL Circulated -->
      <div class="col-12 md:col-1">
          <label>BBL Circulated (BBL)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.bblCirculated | number:'1.0-0'}}</label>
      </div>
      <!-- Number of Sweeps -->
      <div class="col-12 md:col-1">
          <label>Number of Sweeps</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.numOfSweeps}}</label>
      </div>
      <!-- Total Sweep Vol -->
      <div class="col-12 md:col-1">
          <label>Total Sweep Vol (BBL)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.totalSweepVol | number:'1.1-2'}}</label>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- Avg Sweep Size -->
      <div class="col-12 md:col-1">
          <label>Avg Sweep Size (BBL)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.avgSweepSize | number:'1.1-2'}}</label>
      </div>
      <!-- Avg Sweep Visc -->
      <div class="col-12 md:col-1">
          <label>Avg Sweep Visc (s)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.avgSweepVisc | number:'1.1-2'}}</label>
      </div>
      <!-- Avg CT Psi -->
      <div class="col-12 md:col-1">
          <label>Avg CT Psi (psi)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.avgCtPsi | number:'1.0-0'}}</label>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- Avg CT Psi -->
      <div class="col-12 md:col-1">
              <label>Avg WH Psi (psi)</label>
          </div>
          <div class="col-12 md:col-3">
              <label class="readOnly-label">{{job.fluidSummary.avgWhPsi | number:'1.0-0'}}</label>
          </div>
      <!-- Avg Diff Psi -->
      <div class="col-12 md:col-1">
          <label>Avg Diff Psi (psi)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.avgDiffPsi | number:'1.0-0'}}</label>
      </div>
      <!-- Avg CT BPM -->
      <div class="col-12 md:col-1">
          <label>Avg CT BPM (BBL/min)</label>
      </div>
      <div class="col-12 md:col-3">
          <label class="readOnly-label">{{job.fluidSummary.avgCtBpm | number:'1.1-2'}}</label>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- Avg FB BPM -->
      <div class="col-12 md:col-1">
              <label>Avg FB BPM (BBL/min)</label>
          </div>
          <div class="col-12 md:col-3">
              <label class="readOnly-label">{{job.fluidSummary.avgFbBpm | number:'1.1-2'}}</label>
          </div>
  </div>
  <div class="col-12 md:col-10">
          <h4>Chemicals Used</h4>
      </div>
      <div class="col-12 md:col-2">
          <button pButton class="ui-button-primary" 
              (click)="addChemical()" 
              icon="fa fa-plus" label="Chemical"
              style="max-height:32px; width:100%;">
          </button>
      </div>
  <div class="grid field col-12" *ngIf="job.fluidSummary && job.fluidSummary.chemicals && job.fluidSummary.chemicals.length > 0">
      <div class="grid field col-12" *ngFor="let chemical of job.fluidSummary.chemicals; let i = index">
          <div class="col-12 md:col-2">
              <label>Select Chemical Type:</label>
          </div>
          <div class="col-12 md:col-3">
              <p-dropdown [options]="availableChemicals" placeholder="Select a Chemical" [(ngModel)]="job.fluidSummary.chemicals[i].key" [style]="{'width':'100%'}"></p-dropdown> 
          </div>
          <div class="col-12 md:col-2">
               <label>Chemical Volume (gals):</label>
          </div>
          <div class="col-12 md:col-3">
                  <input type="text" pInputText placeholder="Enter Volume" [(ngModel)]="job.fluidSummary.chemicals[i].value" />
          </div>
          <div class="col-12 md:col-2" style="float:right;">
              <i class="far fa-trash-alt" (click)="deleteChemical(i)"></i>
          </div>
      </div>
  </div>
</div>
