import { Plug } from './plug';

export class PlugSet {
  plugs: Plug[];
  manufacturer: string;
  model: string;
  type: string;

  constructor() {}
}

export enum PlugTypes {
  cfp = 'CFP',
  dfp = 'DFP',
  bridgeKill = 'Bridge/Kill'
}
