<p-tabView (onChange)="handleTabViewChange($event)">
    <p-tabPanel header="Simulation Data" [selected]="true">
        <div class="grid field col-12">
            <p-accordion [multiple]="false" class="grid field col-12">
                <p-accordionTab header="Header" [selected]="true">
                    <simulation-header (onNewVersion)="cloneSimulation()"></simulation-header>
                </p-accordionTab>
                <p-accordionTab header="Goals" [selected]="false">
                    <simulation-goals></simulation-goals>
                </p-accordionTab>
                <p-accordionTab header="Site Details" [selected]="false">
                    <simulation-site-detail></simulation-site-detail>
                </p-accordionTab>
                <p-accordionTab header="Expenses" [selected]="false">
                    <simulation-expenses></simulation-expenses>
                </p-accordionTab>
                <p-accordionTab header="Well Information" [selected]="false">
                    <simulation-well-information></simulation-well-information>
                </p-accordionTab>
                <p-accordionTab header="Fluid Information" [selected]="false">
                    <simulation-chemistry></simulation-chemistry>
                </p-accordionTab>
                <p-accordionTab header="Defaults" [selected]="false">
                    <simulation-activity-defaults></simulation-activity-defaults>
                </p-accordionTab>
                <!-- 
                <p-accordionTab header="Empty" [selected]="true">
                    
                </p-accordionTab>
                -->
            </p-accordion>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Activity Sequence">
        <simulation-sequence (sequenceChanged)="runCalculatedFields()" [wellInfo]="wellCom.wellInfo"></simulation-sequence>
        <div class="grid field col-12">
            <p-button 
                class="col-6 md:col-4 ui-lg-2"
                (click)="runCalculatedFields()"
                label="Calculate Changes"
                pTooltip="Apply user input changes and recalculate all data">
            </p-button>
            <p-button 
                class="col-6 md:col-4 ui-lg-2"
                (click)="generateActivitiesFromPlugs()"
                label="Apply Simulation Data"
                pTooltip="Apply data from well information's plug definitions to create simulation activities">
            </p-button>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Output">
        <div class="grid field col-12">
            <p-accordion [multiple]="true" class="grid field col-12">
                <p-accordionTab header="Cost Estimates" [selected]="true">
                    <div class="grid field col-12">
                        <!-- Chemical Cost -->
                        <div class="col-12 md:col-1">
                            <label>Chemical Cost</label>
                        </div>
                        <div class="col-12 md:col-3">
                            <label class="readOnly-label">$ {{totalChemicalCost | number}}</label>
                        </div>
                        <!-- Service Cost -->
                        <div class="col-12 md:col-1">
                            <label>Service Cost</label>
                        </div>
                        <div class="col-12 md:col-3">
                            <label class="readOnly-label">$ {{totalServiceCost | number}}</label>
                        </div>
                        <!-- Total Cost -->
                        <div class="col-12 md:col-1">
                            <label>Total Cost</label>
                        </div>
                        <div class="col-12 md:col-3">
                            <label class="readOnly-label">$ {{totalChemicalCost + totalServiceCost | number}}</label>
                        </div>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Performance Summary" [selected]="true">
                    <simulation-performance></simulation-performance>
                </p-accordionTab>
            </p-accordion>
        </div>
        <div class="grid field col-12">
            <div class="col-12 md:col-6">
                <ng-dygraphs
                    [data]="depthVsReData"
                    [options]="depthVsReOptions">
                </ng-dygraphs>
            </div>
            <div class="col-12 md:col-6">
                <ng-dygraphs
                    [data]="avData"
                    [options]="avOptions">
                </ng-dygraphs>
            </div>
            <div class="col-12 md:col-6">
                <ng-dygraphs
                    [data]="pumpedBblsData"
                    [options]="pumpedBblsOptions">
                </ng-dygraphs>
            </div>
            <div class="col-12 md:col-6">
                <ng-dygraphs
                    [data]="flowbackBblsData"
                    [options]="flowbackBblsOptions">
                </ng-dygraphs>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>
<div class="grid field col-12">
    <div class="col-6 md:col-4 ui-lg-2">
        <button pButton (click)="saveSimulation()" label="Save" class="wideish"></button>
    </div>
    <div class="col-6 md:col-4 ui-lg-2">
        <button pButton (click)="exportSimulation()" label="Export" class="wideish"></button>
    </div>
    <div class="col-6 md:col-4 ui-lg-2">
        <button pButton (click)="navigateToSimulationList()" label="Cancel" class="p-button-secondary wideish"></button>
    </div>
</div>
<div class="grid field col-12">
    <span>.</span>
</div>