<p-fileUpload #fileUploader *ngIf="canUpload" (uploadHandler)="upload($event)" multiple="multiple"
    customUpload="true">
    <ng-template let-file pTemplate="file">
        <div>
            <div><img [src]="file.objectURL" *ngIf="isImage(file)" [width]="50" /></div>
            <div>{{file.name | fileName}}</div>
            <div>{{formatSize(file.size)}}</div>
            <div><p-autoComplete [(ngModel)]="file.category" placeholder="Category" [suggestions]="results" [dropdown]="true" [suggestions]="categories" (completeMethod)="search($event)" ></p-autoComplete> </div>
            <div><span class="ui-float-label">
                    <textarea id="float-input" rows="5" cols="30" pInputTextarea
                        [(ngModel)]="file.description"></textarea>
                    <label for="float-input">Description</label>
                </span> </div>
            <div>
                <button type="button" icon="pi pi-times" pButton (click)="remove($event,i)"
                    [disabled]="uploading"></button>
            </div>
        </div>
    </ng-template>
</p-fileUpload>

<p-table [value]="files" [resizableColumns]="true" >
    <ng-template pTemplate="header">
        <tr>
            <th pResizableColumn width="150">Category</th>
            <th pResizableColumn width="200" >File</th>
            <th *ngIf="canUpload" pResizableColumn width="50" ></th>
            <th pResizableColumn>Description</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file let-rowIndex="rowIndex">
        <tr>
            
            <td *ngIf="rowGroupMetadata[getCategory(file)].index === rowIndex" [attr.rowspan]="rowGroupMetadata[getCategory(file)].size">
                {{file.metadata['category']}}
            </td>
            <td>
                <a [href]="getHref(file)" target="_blank" *ngIf="!isLink(file)" > {{file.name | fileName}}</a>
                <div *ngIf="isLink(file)" [innerHTML]="fileContents[file.name]"></div> 
            </td>
            <td *ngIf="canUpload"><button type="button" icon="pi pi-times" pButton (click)="delete($event,file)" ></button></td>
            <td><div [innerHTML]="formatDescription(file.metadata['description'])"></div></td>
        </tr>
    </ng-template>
</p-table>