import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Job, Company, Features } from '../../../models/models';
import { 
  ICompanyService,
  IDeviceService,
  IFeaturesService,
  IJobService,
  IUserService,
  IReportService,
} from '../../../services/services';
import { Title } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { Message, SelectItem } from 'primeng/api';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'post-job-analysis',
  templateUrl: './post-job-analysis.component.html',
  styleUrls: ['./post-job-analysis.component.css']
})
export class PostJobAnalysisComponent implements OnInit {
  public cols: any[] = [];
  public jobs: Job[] = [];
  public originalJobs: Job[] = [];
  public jobsReady = false;
  public selectedJobs: Job[] = [];
  public companies: Company[] = [];
  public companiesReady = false;
  public devicesReady = false;
  public deviceNamesSelection: SelectItem[] = [];
  public everythingReady = false;
  public featuresReady = false;
  public postJobFeatureEnabled = false;
  public securityToken: string;
  public clearString: string = "Clear";
  public calendarEndTime: Date = new Date();
  public calendarStartTime: Date = new Date(this.calendarEndTime.getFullYear(), 0, 1);
  messages: Message[] = [];

  constructor(
    private _companyService: ICompanyService,
    private _deviceService: IDeviceService,
    private _featuresService: IFeaturesService,
    private _jobService: IJobService,
    private _reportService: IReportService,
    private _titleService: Title,
    private _router: Router,
    private _currentUserService: ICurrentUserService
  ) { }

  ngOnInit() {
    this.setPageTitle();
    this.setColumns();
    this.getJobs();
    this.getFeatures();
    this.setSecurityToken();
  }
  setSecurityToken(){

    this._currentUserService.getAccessToken().subscribe(s=>{
      //console.warn(s);
      this.securityToken = s;
    });

    
  }
  setPageTitle() {
    this._titleService.setTitle('OpsViewer Post Job Analysis');
  }

  setColumns() {
    this.cols = [
      {field: 'name', header: 'Name', width: '200px', filterMatchMode: 'contains'},
      {field: 'company.name', header: 'Company', width: '200px', filterMatchMode: 'contains'},
      {field: 'startTime', header: 'Start', width: '150px'},
      {field: 'endTime', header: 'End', width: '150px'},
      {field: 'devices', header: 'Devices', width: '200px', filterMatchMode: 'contains', alternativeFilterField: 'deviceNamesAsString'},
    ];
  }

  getJobs() {
    this.messages = [];
    this._jobService.getJobs().subscribe(
      results => {
        this.originalJobs = results;
        this.searchJobs();
        this.getCompanies();
        this.getDevices();
        this.jobsReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        const detail = error.error ? error.error.Message : error.message;
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get Jobs',
          detail: detail
        });
        console.error(error);
      }
    );
  }

  getCompanies() {
    this._companyService.getCompanies().subscribe(
      results => {
        this.companies = results;
        results.forEach(result => {
          const jobs = this.jobs.filter(job => job.companyId === result.id);
          jobs.forEach(job => { job.company = result; });
        });
        this.companiesReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get list of companies',
          detail: ''
        });
        console.error(error);
      }
    );
  }

  getDevices() {
    this._deviceService.getDevices().subscribe(
      results => {
        this.deviceNamesSelection = [];
        results.forEach(result => {
          this.deviceNamesSelection.push({ label: result.name, value: result.id });
          const jobs = this.jobs.filter(j => j.deviceList.includes(result.id) );
          jobs.forEach(job => {
            if (!job.devices) { job.devices = []; }
            const deviceToAdd = { label: result.name, value: result.id };
            if (!job.devices.includes(deviceToAdd)) { job.devices.push(deviceToAdd); }
            if (!job.deviceNamesAsString) {
              job.deviceNamesAsString = deviceToAdd.label;
            } else {
              job.deviceNamesAsString = job.deviceNamesAsString + ', ' + deviceToAdd.label;
            }
          });
        });
        this.devicesReady = true;
        this.checkIfEverythingReady();
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get Companies',
          detail: ''
        });
        console.error(error);
      }
    );
  }

  getFeatures() {
    this._featuresService.isFeatureEnabled(Features.PostJobAnalysis).subscribe(
      result => {
        this.postJobFeatureEnabled = this.featuresReady = result;
        if (!this.postJobFeatureEnabled)
          this._router.navigate(['/']);
        this.checkIfEverythingReady();
     },
     error => {
      this.messages = [];
      this.messages.push({
        severity: 'error',
        summary: 'Could not get Features',
        detail: ''
      });
      console.error(error);
     }
   );
  }

  checkIfEverythingReady() {
    this.everythingReady = this.jobsReady && this.companiesReady && this.devicesReady && this.featuresReady;
  }

  clearSelection(){
    this.selectedJobs = [];
  }

  runAnalysis(){
    let jobIds:string[] = []
    this.selectedJobs.forEach(j => {
      jobIds.push(j.id);
    });
    this._reportService.getPostAnalysisReport(jobIds).subscribe(
      data => {
        let blob = new Blob([data], {type:'application/vnd.ms-excel'});
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    )
  }

  searchJobs(){
    this.jobs = this.originalJobs.filter(j => new Date(j.startTime) >= this.calendarStartTime && (j.endTime == null || new Date(j.endTime) <= this.calendarEndTime));
  }

  updateClearString(){
    this.clearString = `Clear (${ this.selectedJobs.length })`
  }
}

