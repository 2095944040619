import { Injectable } from '@angular/core';

@Injectable()
export abstract class IOpsViewerMessagesService  {
    abstract errorMessage(error: any, summaryMessage: string): void;
    abstract errorMessageWithObjectDetail(error: any, summaryMessage: string, serializedObject: string): void;
    abstract successMessage(summaryMessage: string, detailMessage: string): void;
    abstract infoMessage(summaryMessage: string, detailMessage: string): void;
    abstract warnMessage(summaryMessage: string, detailMessage: string): void;
    abstract errorNoUiMessage(error: any, summaryMessage: string): void;
    abstract clearMessage(): void;
}
