import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyCalculations } from '../models/models';
import { Injectable } from '@angular/core';
import { ICompanyCalculationsService } from './iCompanyCalculations.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CompanyCalculationsService extends ICompanyCalculationsService {
  baseUrl = 'api/companyCalculations/';

  constructor(private _http: HttpClient) {
      super();
  }

  getCompanyCalculations(companyId: string): Observable<CompanyCalculations> {
    throw new Error('Method not implemented.');
  }

  addOrUpdateCompanyCalculations(companyCalculations: CompanyCalculations): Observable<any> {
    throw new Error('Method not implemented.');
  }

  deleteCompanyCalculations(companyId: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
