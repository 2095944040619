import { Configuration } from './configuration';

export class WorkbookConfiguration {
    id: string;
    job_number: string;
    displayFields:string[];
    availableFields:string[];
    customFields: Configuration[];

    constructor(id:string, job_number:string, displayFields:string[] = null, availableFields:string[] = null, customFields:Configuration[] = null) {
        this.id = id;
        this.job_number = job_number;
        if(displayFields == null) {
            this.displayFields = [];
        } else {
            this.displayFields = displayFields;
        }
        if(availableFields == null) {
            this.availableFields = [];
        } else {
            this.availableFields = availableFields;
        }
        if(customFields == null) {
            this.customFields = [];
        } else {
            this.customFields = customFields;
        }
    }

    public setAsDefaultConfiguration() {
        //Id and JobNumber already set during constructor and should not change

        this.displayFields = [
            "time",
            "relative_time",
            "comments",
            "plug_number",
            "activity",
            "depth",
            "funnel_vis",
            "fluid_system",
            "swp_vol_bbls",
            "circulating_psi",
            "wh_psi",
            "called_pumped_bpm",
            "called_fb_bpm"
        ];

        this.availableFields = [
            "time",
            "relative_time",
            "activity",
            "plug_number",
            "depth",
            "fluid_system",
            "swp_vol_bbls",
            "funnel_vis",
            "funnel_vis_slw",
            "funnel_vis_swp",
            "circulating_psi",
            "wh_psi",
            "differential_psi",
            "called_pumped_bpm",
            "called_fb_bpm",
            "av",
            "slw_re",
            "called_fluid_balance",
            "ct_running_ft",
            "solids_plug_parts",
            "solids_sand_and_other",
            "Weight",
            "chlorides",
            "ph",
            "fann_35_visc_slw",
            "fann_35_visc_swp",
            "comments",
            "reel_speed"
        ];

        this.customFields = [];
    }
}

