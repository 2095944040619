import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { IWorkbookConfigurationService } from './iWorkbookConfiguration.service';
import { WorkbookConfiguration } from '../models/models';

@Injectable()
export class WorkbookConfigurationService extends IWorkbookConfigurationService {
    
    constructor(private _http:HttpClient) {
        super();
    }

    getWorkbookConfiguration(jobNumber:string): Observable<WorkbookConfiguration> {
        let request = this._http.get('api/workbookConfiguration/?jobNumber=' + encodeURIComponent(jobNumber)).pipe(map(
            result => {
                return result as WorkbookConfiguration;
            }
        ));
        return request;
    }

    updateWorkbookConfiguration(jobNumber:string, updatedWorkbookConfiguration:WorkbookConfiguration): Observable<any> {
        let request = this._http.put('api/workbookconfiguration/' + jobNumber, updatedWorkbookConfiguration).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }
}