import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CompanyName } from '../models/models';

@Injectable()
export abstract class IApplicationContextService {
  abstract companyContextObserver: Observable<CompanyName>;
  abstract requestsModifiedObserver: Observable<boolean>;
  abstract companiesListModifiedObserver: Observable<boolean>;
  abstract userDetailsModifiedObserver: Observable<boolean>;
  abstract showApplicationSpinnerObserver: Observable<boolean>;
  abstract showLongRunningObserver: Observable<boolean>;
  abstract showRequestsBarObserver: Observable<boolean>;

  abstract getCompanyContext(): CompanyName;
  abstract setCompanyContext(company: CompanyName): void;

  abstract alertRequestsUpdated(): void;
  abstract acknowledgeRequestsUpdated(): void;

  abstract alertCompaniesListUpdated(): void;
  abstract acknowledgeCompaniesListUpdated(): void;

  abstract alertUserDetailsUpdated(): void;
  abstract acknowledgeUserDetailsUpdated(): void;

  abstract showApplicationSpinner(isLongRunning: boolean): void;
  abstract hideApplicationSpinner(): void;

  abstract showApplicationRequestsBar(): void;
  abstract hideApplicationRequestsBar(): void;
}
