import { BaseEntity } from './baseEntity';
import { CalculatedFieldConfiguration } from './calculatedFieldConfiguration';
import { v4 as uuidv4 } from 'uuid';

export class CompanyCalculatedFieldConfiguration implements BaseEntity {
    id: string;
    companyId: string;
    calculatedFieldConfigurations: CalculatedFieldConfiguration[];

    constructor(id: string, companyId: string) {
        if (id) {
            this.id = id;
        } else {
            this.id = uuidv4();
        }
        this.companyId = companyId;
        this.calculatedFieldConfigurations = [];
      }
}