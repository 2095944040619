import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { IUserService, IFeaturesService, IOpsViewerMessagesService } from '../services/services';
import { Features, User, UserRoles } from '../models/models';
import { ifError } from 'assert';
import { ICurrentUserService } from '../services/currentUserService/icurrentuser.service';
import { UserClient } from '../services/nswag/nswagclient';

@Injectable()
export class CanEditGuard implements CanActivate {

    constructor(
        private router: Router,
        private featureService: IFeaturesService,
        private _userClient: UserClient,
        private _userService: IUserService,
        private messageService: IOpsViewerMessagesService,
        private _currentUserService: ICurrentUserService
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let userEmail = this._currentUserService.getUserEmail();
        return new Observable<boolean>(observer => {
            this.featureService.isFeatureEnabled(Features.CompanyAdministration).subscribe(
                isCompanyAdministrationEnabled => {
                    
                            if (userEmail) {
                                this._userClient.getByEmail(userEmail).subscribe(
                                    u => {
                                        let result = false;
                                        if (u) {
                                            let user = User.fromNSwag(u);
                                            if (isCompanyAdministrationEnabled) {
                                                result = this._userService.isUserAnyRoleEditorOrAdmin(user, false);

                                            } else {
                                                result = this._userService.isUserAnyRoleEditorOrAdmin(user, true);
                                            }
                                        } else { result = false; }
                                        if (!result) {
                                            this.messageService.warnMessage('User does not have permission to this feature.', '');
                                        }
                                        observer.next(result);
                                        observer.complete();
                                    }
                                );
                            } else {
                                observer.next(false);
                                observer.complete();
                            }
                        }
                    );
                }
            );
     
    }
}
