import { WellKnownMeasurementChoice } from './wellKnownMeasurement';
import { UnitSystemChoice } from './unitSystemChoice';

export class WorkbookMeasurementChannnelConfiguration {
    measurementName: string;
    measurementDisplayName: string;
    unitOfMeasurementId: string;
    wellKnownMeasurementName: string;
    measurementDataType: string;
    activities: string[];
    lookupChoices: string[];
    companyDrivenConfiguration: boolean;
    readOnlyJobConfiiguration: boolean;
    jobTypes: string[];
    mininumLimit: number;
    maximumLimit: number;

    // client side only properties
    unitOptions: any;
    lookupChoicesAsString: string;
    lookupChoicesAsSelectItems: any[];
    workbookPropertyName: string;

    constructor() {
        this.activities = [];
        this.lookupChoices = [];
        this.lookupChoicesAsSelectItems = [];
        this.jobTypes = [];
    }

    public mapFromObject(objectToMapFrom: any) {
        this.measurementName = objectToMapFrom.measurementName;
        this.measurementDisplayName = objectToMapFrom.measurementDisplayName;
        this.unitOfMeasurementId = objectToMapFrom.unitOfMeasurementId;
        this.wellKnownMeasurementName = objectToMapFrom.wellKnownMeasurementName;
        this.measurementDataType = objectToMapFrom.measurementDataType;
        this.activities = objectToMapFrom.activities;
        this.lookupChoices = objectToMapFrom.lookupChoices;
        this.companyDrivenConfiguration = objectToMapFrom.companyDrivenConfiguration;
        this.readOnlyJobConfiiguration = objectToMapFrom.readOnlyJobConfiiguration;
        this.jobTypes = objectToMapFrom.jobTypes;
        this.mininumLimit = objectToMapFrom.mininumLimit;
        this.maximumLimit = objectToMapFrom.maximumLimit;

        this.unitOptions = objectToMapFrom.unitOptions;
        this.lookupChoicesAsString = objectToMapFrom.lookupChoicesAsString;
        this.lookupChoicesAsSelectItems = objectToMapFrom.lookupChoicesAsString;
        this.workbookPropertyName = objectToMapFrom.workbookPropertyName;
    }

    public buildMeasurement(
        measurementName: string,
        measurementDisplayName: string,
        wellKnownMeasurementChoices: WellKnownMeasurementChoice[],
        unitSystem: string,
        measurementDataType: string,
        targetActivities: string[],
        activityOptions: string[],
        jobTypes: string[],
        lookupChoices: string[]= null) {

        let foundWellKnownMeasurementChoice = wellKnownMeasurementChoices.find(w => w.displayName === measurementDisplayName);
        foundWellKnownMeasurementChoice = foundWellKnownMeasurementChoice || wellKnownMeasurementChoices.find(w => w.name === 'None');
        this.buildMeasurementWithDefinedWellKnownMeasurement(
            measurementName,
            measurementDisplayName,
            foundWellKnownMeasurementChoice,
            unitSystem,
            measurementDataType,
            targetActivities,
            activityOptions,
            jobTypes,
            lookupChoices
        );
    }

    public buildMeasurementWithDefinedWellKnownMeasurement(
        measurementName: string,
        measurementDisplayName: string,
        wellKnownMeasurementChoice: WellKnownMeasurementChoice,
        unitSystem: string,
        measurementDataType: string,
        targetActivities: string[],
        activityOptions: string[],
        jobTypes: string[],
        lookupChoices: string[]= null) {

        this.measurementName = measurementName;
        this.measurementDisplayName = measurementDisplayName;
        this.wellKnownMeasurementName = wellKnownMeasurementChoice.name;

        this.measurementDataType = measurementDataType;

        this.activities = activityOptions.filter(a => targetActivities.includes(a));

        if (lookupChoices) {
            this.lookupChoices = lookupChoices;
            this.lookupChoicesAsString = lookupChoices.join(',');
        }

        if (wellKnownMeasurementChoice) {
            if (unitSystem === UnitSystemChoice.si) {
                if (wellKnownMeasurementChoice.defaultSiUnit) {
                    this.unitOfMeasurementId = wellKnownMeasurementChoice.siUnits.find(u =>
                        u.unitKey === wellKnownMeasurementChoice.defaultSiUnit.unitKey).unitKey;
                }
                this.unitOptions = wellKnownMeasurementChoice.siUnits.map(u => {
                    return { label: u.unitName, value: u.unitKey };
                });
            }
            if (unitSystem === UnitSystemChoice.imperial) {
                if (wellKnownMeasurementChoice.defaultImperialUnit) {
                    this.unitOfMeasurementId = wellKnownMeasurementChoice.imperialUnits.find(u =>
                        u.unitKey === wellKnownMeasurementChoice.defaultImperialUnit.unitKey).unitKey;
                }
                this.unitOptions = wellKnownMeasurementChoice.imperialUnits.map(u => {
                    return { label: u.unitName, value: u.unitKey };
                });
            }
        }
        this.jobTypes = jobTypes;
    }

    public setWorkbookPropertyName() {
        if (this.wellKnownMeasurementName && this.wellKnownMeasurementName !== 'None') {
            this.workbookPropertyName =  this.wellKnownMeasurementName.replace(/ |(|)|-|_|&/g, '', );
        } else if (this.measurementName) {
            this.workbookPropertyName =  this.measurementName.replace(/ |(|)|-|_|& |(|)|-|_|&/g, '', );
        }
    }

    public setMeasurementName() {
        if (this.measurementDisplayName) {
            this.measurementName = this.measurementDisplayName.replace(/ |(|)|-|_|&/g, '', );
        }
    }
}
