import { BaseEntity } from './baseEntity';

export class ConsumerJob implements BaseEntity {
  id: string;
  number: string;
  consumerCompanyId: string;
  wellApiNumber: string;
  wellName: string;
  startTime: Date;
  endTime: Date;
  workbookType: string;
  vendorCompanyIds: string[];
  vendorJobIds: string[];
  isMigratedFromBeforeConsumerJobs: boolean;

  // For UI model only
  consumerCompanyName: string;
  vendorCompanyNames: string;

  constructor() {
    this.vendorCompanyIds = [];
    this.vendorJobIds = [];
  }
}

