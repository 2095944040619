import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IWorkbookLogService } from './iWorkbookLog.service';

@Injectable()
export class WorkbookLogService extends IWorkbookLogService {

    constructor(private _http: HttpClient) {
        super();
    }

    getWorkbookLogs(jobNumber:string): Observable<any[]> {
        let request = this._http.get('/api/dynamicworkbook/?jobNumber=' + encodeURIComponent(jobNumber)).pipe(map(
            result => {
                return result as any[];
            }
        ));
        return request;
    }

    addWorkbookLog(workbookLogToAdd: any): Observable<any> {
        let request = this._http.post('api/dynamicworkbook', workbookLogToAdd).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }

    updateWorkBookLog(clientId:string, workbookLogToUpdate: any): Observable<any> {
        let request = this._http.put('api/dynamicworkbook/' + encodeURIComponent(clientId), workbookLogToUpdate).pipe(map(
            response => {
                return response as string;
            }
        ));
        return request;
    }
}