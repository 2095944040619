import { JobType } from '../services/nswag/nswagclient';
import { Job } from './job';

export class VendorJob extends Job {

  get vendorCompanyId() {
    return this.companyId;
  }
  set vendorCompanyId(val) {
    this.companyId = val;
  }
  consumerCompanyId: string;
  consumerJobId: string;
  jobType: JobType;
  // For UI model only
  vendorCompanyName: string;
  consumerCompanyName: string;

  constructor(
    id: string,
    name: string,
    companyId: string,
    deviceList: string[],
    startTime: Date,
    endTime: Date
  ) {
    super(
      id,
      name,
      companyId,
      deviceList,
      startTime,
      endTime
    );
  }

  // Due to ngModel not firing wrtievalue() control method from property changes of input(Job),
  // A new instance of Job must be created with new property value to fire writevalue
  // This method creates new instance of VendorJob from a passed in existing VendorJob
  public static BuildNewInstanceVendorJobFromCurrent(currentJob: VendorJob): VendorJob {
    const newJob: VendorJob = new VendorJob(
      currentJob.id,
      currentJob.name,
      currentJob.companyId,
      currentJob.deviceList,
      currentJob.startTime,
      currentJob.endTime);
    newJob.apiNumber = currentJob.apiNumber;
    newJob.bhaOd = currentJob.bhaOd;
    newJob.chemicalProvider = currentJob.chemicalProvider;
    newJob.company = currentJob.company;
    newJob.consumerCompanyId = currentJob.consumerCompanyId;
    newJob.consumerCompanyName = currentJob.consumerCompanyName;
    newJob.consumerJobId = currentJob.consumerJobId;
    newJob.county = currentJob.county;
    newJob.ctWorWlProvider = currentJob.ctWorWlProvider;
    newJob.dayOperators = currentJob.dayOperators;
    newJob.deviceNamesAsString = currentJob.deviceNamesAsString;
    newJob.devices = currentJob.devices;
    newJob.dhToolProvider = currentJob.dhToolProvider;
    newJob.district = currentJob.district;
    newJob.downstreamSandSeparatorType = currentJob.downstreamSandSeparatorType;
    newJob.emailDailyReportsTo = currentJob.emailDailyReportsTo;
    newJob.enableOfflineWorkbook = currentJob.enableOfflineWorkbook;
    newJob.ertType = currentJob.ertType;
    newJob.essFrcsUnit = currentJob.essFrcsUnit;
    newJob.field = currentJob.field;
    newJob.fieldEngineer1Email = currentJob.fieldEngineer1Email;
    newJob.fieldEngineer1Name = currentJob.fieldEngineer1Name;
    newJob.fieldEngineer1Phone = currentJob.fieldEngineer1Phone;
    newJob.fieldEngineer2Email = currentJob.fieldEngineer2Email;
    newJob.fieldEngineer2Name = currentJob.fieldEngineer2Name;
    newJob.fieldEngineer2Phone = currentJob.fieldEngineer2Phone;
    newJob.flowbackProvider = currentJob.flowbackProvider;
    newJob.fluidInfo = currentJob.fluidInfo;
    newJob.fluidSummary = currentJob.fluidSummary;
    newJob.frcsDayEngineer = currentJob.frcsDayEngineer;
    newJob.frcsNightEngineer = currentJob.frcsNightEngineer;
    newJob.latitude = currentJob.latitude;
    newJob.longitude = currentJob.longitude;
    newJob.mill = currentJob.mill;
    newJob.millOd = currentJob.millOd;
    newJob.millType = currentJob.millType;
    newJob.name = currentJob.name;
    newJob.nightOperators = currentJob.nightOperators;
    newJob.number = currentJob.number;
    newJob.operator = currentJob.operator;
    newJob.osrDay = currentJob.osrDay;
    newJob.osrNight = currentJob.osrNight;
    newJob.performanceSummary = currentJob.performanceSummary;
    newJob.plugType = currentJob.plugType;
    newJob.procedureDesc = currentJob.procedureDesc;
    newJob.range = currentJob.range;
    newJob.section = currentJob.section;
    newJob.serviceProviderIds = currentJob.serviceProviderIds;
    newJob.specialInstructions = currentJob.specialInstructions;
    newJob.state = currentJob.state;
    newJob.status = currentJob.status;
    newJob.township = currentJob.township;
    newJob.tubingUnitDesc = currentJob.tubingUnitDesc;
    newJob.upstreamSandSeparatorType = currentJob.upstreamSandSeparatorType;
    newJob.vendorCompanyName = currentJob.vendorCompanyName;
    newJob.wellInfo = currentJob.wellInfo;
    newJob.wellSiteDirections = currentJob.wellSiteDirections;
    newJob.workbookType = currentJob.workbookType;
    newJob.isMigrated = currentJob.isMigrated;
    newJob.useOldWorkbookInReadOnlyMode = currentJob.useOldWorkbookInReadOnlyMode;

    return newJob;
  }

}
