import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CompanyCalculatedFieldConfiguration } from '../models/models';

@Injectable()
export abstract class ICalculatedFieldConfigurationService  {

    abstract getConfigurationForCompany(companyId: string): Observable<CompanyCalculatedFieldConfiguration>;

    abstract createOrUpdateConfiguration(configuation: CompanyCalculatedFieldConfiguration): Observable<any>;

}