import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JobWorkbookConfiguration, WorkbookMeasurementChannnelConfiguration } from '../models/models';
import { IJobWorkbookConfigurationService } from './iJobWorkbookConfiguration.service';

@Injectable()
export class JobWorkbookConfigurationService extends IJobWorkbookConfigurationService {
  baseUrl = 'api/jobworkbookconfiguration/';

  constructor(private _http: HttpClient) {
    super();
  }

  getJobWorkbookConfiguration(jobId: string): Observable<JobWorkbookConfiguration> { //why all this mess here?
    const request = this._http.get(this.baseUrl + jobId).pipe(map(
      result => {
        if (result) {
          const resultAsJobConfiguration = result as JobWorkbookConfiguration;
          const configuration = new JobWorkbookConfiguration(
            resultAsJobConfiguration.id, resultAsJobConfiguration.jobId, resultAsJobConfiguration.unitSystem);
          configuration.workbookMeasurementChannelConfigurations = [];
          resultAsJobConfiguration.workbookMeasurementChannelConfigurations.forEach(r => {
              const measurementChannel = new WorkbookMeasurementChannnelConfiguration();
              measurementChannel.mapFromObject(r);
              configuration.workbookMeasurementChannelConfigurations.push(measurementChannel);
          });
          return configuration;
      }
      return null;
      }
    ));
    return request;
  }

  createDefaultJobWorkbookConfiguration(
    companyId: string, companyUnitSystem: string, jobId: string, jobType: string
    ): Observable<JobWorkbookConfiguration> {
    // const request = this._http.get(this.baseUrl +
    //   'default/' +
    //   companyId + '/'
    //   + encodeURIComponent(companyUnitSystem) + '/'
    //   + jobId + '/'
    //   + encodeURIComponent(jobType))
    //   .pipe(map(
    //     result => {
    //       return result as JobWorkbookConfiguration;
    //     }
    //   ));
    const httpParams = {
      companyId: companyId,
      companyUnitSystem: companyUnitSystem,
      jobId: jobId,
      jobType: jobType
    };
    const request = this._http.get(this.baseUrl + 'default', { params: httpParams })
      .pipe(map(
        result => {
          return result as JobWorkbookConfiguration;
        }
      ));
    return request;
  }

  addOrUpdateJobWorkbookConfiguration(jobWorkbookConfiguration: JobWorkbookConfiguration): Observable<any> {
    const request = this._http.post(this.baseUrl, jobWorkbookConfiguration).pipe(map(
      response => {
        return response as any;
      }
    ));
    return request;
  }
}
