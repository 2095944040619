
    <div *ngIf="casingTubings" class="grid field col-12">

        <!-- Tubing Section -->
        <p-fieldset legend="Tubing" class="col-12" [toggleable]="true" [collapsed]="isTubingCollapsed()" >
            <div class="grid field col-12">
                <button pButton class="ui-button-primary add-btn" [disabled]="isAddTubingDisabled()" (click)="addTubing()" icon="fa fa-plus" label="Tubing"></button>
            </div>
            <!-- Tubing 1 -->
            <div *ngIf="casingTubings.tubing1" class="grid field col-12">
                <div class="col-12 md:col-11">
                    Production Tubing 1:
                </div>
                <div class="col-12 md:col-1">
                    <i class="far fa-trash-alt" (click)="confirmDelete('tubing1')"></i>
                </div>
            </div>
            <div *ngIf="casingTubings.tubing1" class="grid field col-12">
                <div class="col-12 md:col-1">
                    <label>Type</label>
                </div>
                <div class="col-12 md:col-5">
                    <p-dropdown [options]="casingTubingTypes" [(ngModel)]="casingTubings.tubing1.type" placeholder="Select Type"
                        [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                        (onChange)="setCasingTubingVolume(casingTubings.tubing1)" [disabled]="disabled"></p-dropdown>
                </div>
                <div class="col-12 md:col-1">
                    <label>Length</label>
                </div>
                <div class="col-12 md:col-2">
                    <input type="text" pInputText class="w-full" placeholder="Enter Amount" [(ngModel)]="casingTubings.tubing1.length"
                        (change)="setCasingTubingVolume(casingTubings.tubing1)" [disabled]="disabled"/>
                </div>
                <div class="col-12 md:col-1">
                    <label>Volume</label>
                </div>
                <div class="col-12 md:col-2">
                    <label class="readOnly-label">{{casingTubings.tubing1.volume | number:'1.1-2'}}</label>
                </div>
            </div>
            <!-- Tubing 2 -->
            <div *ngIf="casingTubings.tubing2" class="grid field col-12">
                <div class="col-12 md:col-11">
                    Production Tubing 2:
                </div>
                <div class="col-12 md:col-1">
                    <i class="far fa-trash-alt" (click)="confirmDelete('tubing2')"></i>
                </div>
            </div>
            <div *ngIf="casingTubings.tubing2" class="grid field col-12">
                <div class="col-12 md:col-1">
                    <label>Type</label>
                </div>
                <div class="col-12 md:col-5">
                    <p-dropdown [options]="casingTubingTypes" [(ngModel)]="casingTubings.tubing2.type" placeholder="Select Type"
                        [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                        (onChange)="setCasingTubingVolume(casingTubings.tubing2)" [disabled]="disabled"></p-dropdown>
                </div>
                <div class="col-12 md:col-1">
                    <label>Length</label>
                </div>
                <div class="col-12 md:col-2">
                    <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="casingTubings.tubing2.length"
                        (change)="setCasingTubingVolume(casingTubings.tubing2)" [disabled]="disabled"/>
                </div>
                <div class="col-12 md:col-1">
                    <label>Volume</label>
                </div>
                <div class="col-12 md:col-2">
                    <label class="readOnly-label">{{casingTubings.tubing2.volume | number:'1.1-2'}}</label>
                </div>
            </div>
        </p-fieldset>
        
        <!-- Casing Vertical Section -->
        <p-fieldset legend="Vertical Casings" [toggleable]="true" class="col-12" [collapsed]="false">
            <div class="col-12">
                <button pButton class="ui-button-primary add-btn" [disabled]="isAddVerticalCasingDisabled()"
                    (click)="addVerticalCasing()" icon="fa fa-plus" label="Vertical Casing" ></button>
            </div>

            <!-- Casing Vertical 1 -->
            <div *ngIf="casingTubings.casing1Vertical" class="grid field col-12">
                <div class="col-12 md:col-12">
                    Vertical Production Casing 1:
                </div>
            </div>
            <div *ngIf="casingTubings.casing1Vertical" class="grid field col-12">
                <div class="col-12 md:col-1">
                    <label>Type</label>
                </div>
                <div class="col-12 md:col-5">
                    <p-dropdown [options]="casingTubingTypes" [(ngModel)]="casingTubings.casing1Vertical.type" placeholder="Select Type"
                        [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                        (onChange)="setCasingTubingVolume(casingTubings.casing1Vertical)" [disabled]="disabled"></p-dropdown>
                </div>
                <div class="col-12 md:col-1">
                    <label>Length</label>
                </div>
                <div class="col-12 md:col-2">
                    <input type="text" pInputText class="w-full" placeholder="Enter Amount" [(ngModel)]="casingTubings.casing1Vertical.length"
                        (change)="setCasingTubingVolume(casingTubings.casing1Vertical)" [disabled]="disabled"/>
                </div>
                <div class="col-12 md:col-1">
                    <label>Volume</label>
                </div>
                <div class="col-12 md:col-2">
                    <label class="readOnly-label">{{casingTubings.casing1Vertical.volume | number:'1.1-2'}}</label>
                </div>
            </div>

                <!-- Casing Vertical 2 -->
            <div *ngIf="casingTubings.casing2Vertical" class="grid field col-12">
                <div class="col-12 md:col-11">
                    Vertical Production Casing 2:
                </div>
                <div class="col-12 md:col-1">
                    <i class="far fa-trash-alt" (click)="confirmDelete('casingVertical2')"></i>
                </div>
            </div>
            <div *ngIf="casingTubings.casing2Vertical" class="grid field col-12">
                <div class="col-12 md:col-1">
                    <label>Type</label>
                </div>
                <div class="col-12 md:col-5">
                    <p-dropdown [options]="casingTubingTypes" [(ngModel)]="casingTubings.casing2Vertical.type" placeholder="Select Type"
                        [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                        (onChange)="setCasingTubingVolume(casingTubings.casing2Vertical)" [disabled]="disabled"></p-dropdown>
                </div>
                <div class="col-12 md:col-1">
                    <label>Length</label>
                </div>
                <div class="col-12 md:col-2">
                    <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="casingTubings.casing2Vertical.length"
                        (change)="setCasingTubingVolume(casingTubings.casing2Vertical)" [disabled]="disabled"/>
                </div>
                <div class="col-12 md:col-1">
                    <label>Volume</label>
                </div>
                <div class="col-12 md:col-2">
                    <label class="readOnly-label">{{casingTubings.casing2Vertical.volume | number:'1.1-2'}}</label>
                </div>
            </div>
        </p-fieldset>

        <!-- Casing Horizontal Section -->
        <p-fieldset legend="Horizontal Casings" class="col-12" [toggleable]="true" [collapsed]="false">
            <div class="grid field col-12">
                <button pButton class="ui-button-primary add-btn" [disabled]="isAddHorizontalCasingDisabled()"
                    (click)="addHorizontalCasing()" icon="fa fa-plus" label="Horizontal Casing"></button>
            </div>

            <!-- Casing Horizontal 1 -->
            <div *ngIf="casingTubings.casing1Horizontal" class="grid field col-12">
                    <div class="col-12 md:col-12">
                        Horizontal Production Casing 1:
                    </div>
                </div>
                <div *ngIf="casingTubings.casing1Horizontal" class="grid field col-12">
                    <div class="col-12 md:col-1">
                        <label>Type</label>
                    </div>
                    <div class="col-12 md:col-5">
                        <p-dropdown [options]="casingTubingTypes" [(ngModel)]="casingTubings.casing1Horizontal.type" placeholder="Select Type"
                            [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                            (onChange)="setCasingTubingVolume(casingTubings.casing1Horizontal)" [disabled]="disabled"></p-dropdown>
                    </div>
                    <div class="col-12 md:col-1">
                        <label>Length</label>
                    </div>
                    <div class="col-12 md:col-2">
                        <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="casingTubings.casing1Horizontal.length"
                            (change)="setCasingTubingVolume(casingTubings.casing1Horizontal)" [disabled]="disabled"/>
                    </div>
                    <div class="col-12 md:col-1">
                        <label>Volume</label>
                    </div>
                    <div class="col-12 md:col-2">
                        <label class="readOnly-label">{{casingTubings.casing1Horizontal.volume | number:'1.1-2'}}</label>
                    </div>
                </div>

            <!-- Casing Horizontal 2 -->
            <div *ngIf="casingTubings.casing2Horizontal" class="grid field col-12">
                <div class="col-12 md:col-11">
                    Horizontal Production Casing 2:
                </div>
                <div class="col-12 md:col-1">
                    <i class="far fa-trash-alt" (click)="confirmDelete('casingHorizontal2')"></i>
                </div>
            </div>
            <div *ngIf="casingTubings.casing2Horizontal" class="grid field col-12">
                <div class="col-12 md:col-1">
                    <label>Type</label>
                </div>
                <div class="col-12 md:col-5">
                    <p-dropdown [options]="casingTubingTypes" [(ngModel)]="casingTubings.casing2Horizontal.type" placeholder="Select Type"
                        [filter]="true" filterBy="label" [style]="{'width':'100%'}"
                        (onChange)="setCasingTubingVolume(casingTubings.casing2Horizontal)" [disabled]="disabled"></p-dropdown>
                </div>
                <div class="col-12 md:col-1">
                    <label>Length</label>
                </div>
                <div class="col-12 md:col-2">
                    <input type="text" pInputText class="w=full" placeholder="Enter Amount" [(ngModel)]="casingTubings.casing2Horizontal.length"
                        (change)="setCasingTubingVolume(casingTubings.casing2Horizontal)" [disabled]="disabled"/>
                </div>
                <div class="col-12 md:col-1">
                    <label>Volume</label>
                </div>
                <div class="col-12 md:col-2">
                    <label class="readOnly-label">{{casingTubings.casing2Horizontal.volume | number:'1.1-2'}}</label>
                </div>
            </div>
        </p-fieldset>
    </div>

    <div *ngIf="!casingTubings" class="grid field col-12">
        No Casing/Tubing defined
    </div>

