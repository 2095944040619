import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISimulationService } from './iSimulation.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Simulation, SimulationHeader } from '../models/simulation';

@Injectable()
export class SimulationService extends ISimulationService {
    constructor(private http: HttpClient) {
        super();
    }

    deleteSimulation(id: string) : Observable<any> {
        return this.http.delete(`api/simulation/${id}`)
        .pipe(
            catchError(this.handleError)
        );
    }

    getSimulation(id: string) : Observable<Simulation> {
        return this.http.get<Simulation>(`api/simulation/${id}`);
    }

    getCompanySimulations(companyId: string) : Observable<SimulationHeader[]> {
        return this.http.get(`api/simulation/byCompany/${companyId}`)
        .pipe(
            map(result => result as SimulationHeader[]),
        );
    }

    saveSimulation(simulation: Simulation) : Observable<Simulation> {
        simulation.header.simulationId = simulation.id;
        simulation.header.timestamp = Date.now();
        simulation.header.revisionVersion = simulation.header.revisionVersion + 1;
        if(simulation.header.revisionVersion > 9) {
            simulation.header.minorVersion = simulation.header.minorVersion + 1;
            simulation.header.revisionVersion = 0;
        }
        return this.http.post<Simulation>('api/simulation', simulation)
        .pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
      };
}